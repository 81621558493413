import axios from "axios";
import { APP_SETTINGS } from "../../config";

export function getTermSheet(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.TERM_SHEET.getTermSheetDetails}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function addTermSheet(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.TERM_SHEET.saveTermSheet}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function editTermSheet(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.TERM_SHEET.updateTermSheet}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function editBaseTermSheet(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.BASE_TERM_SHEET.updateBaseTermSheet}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function deleteTerm(termId, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.TERM_SHEET.deleteTermSheet}/${termId}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function deleteBaseTerm(termId, auth) {
    return axios.delete(`${APP_SETTINGS.API_PATH.BASE_TERM_SHEET.deleteBaseTermSheet}/${termId}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function likeDislikeTerm(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.TERM_SHEET.likeDislikeTerm}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function spaceNegotiationQuestions(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.TERM_SHEET.spaceNegotiationQuestions}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function sendComment(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.TERM_SHEET.sendComment}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function getComment(termId, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.TERM_SHEET.getComment}/${termId}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function sendMessage(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.TERM_SHEET.sendMessage}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function getMessage(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.TERM_SHEET.getMessage}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function addGlobalTermSheet(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.TERM_SHEET.saveGlobalTermSheet}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}