/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Navbar, Dropdown, Button } from "react-bootstrap";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCircle,
  faSearch,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, NavLink, useHistory } from "react-router-dom";
import { AZURE_BLOB_BASE_URL, PATH, ROLE, ROLEID } from "../../../config";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { useCookies } from "react-cookie";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import Sidebar from "../../../assets/genericComponents/SideBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getStates,
  getLocationSuggestion,
  getAllNotificationList,
  ReadNotification,
  getCheckIsSubscribed,
} from "../../../redux/actions";
import { LoginSignUpModal } from "../../../assets/genericComponents/LoginSignUpModal";
import { OpenAppModal } from "../../../assets/genericComponents/OpenAppModal";
import { FiSearch } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import {
  replaceSpace,
  dateTimeDifference,
  createImageFromInitials,
  getRandomColor,
} from "../../../assets/genericAction";
import { Loader } from "../../../assets/genericComponents/Loader";
import { userLogout } from "../../../redux/api";
import { getToken } from "../../../firebaseInit";

export function NavigationBar(props) {
  const [searchData, setSearchData] = useState(null);
  let auth = useAuth();
  const [cookies, remove] = useCookies(["real_estate_user"]);
  let autoSuggestion = useSelector((state) => state.listing);
  let notificationState = useSelector((state) => state.notification);
  const [notifiShow, setNotifiShow] = useState(false);
  let history = useHistory();
  let dispatch = useDispatch();
  let user_Data = useSelector((state) => state.user);
  let cartQuantity = useSelector((state) => state.productList.numberCart);
  const isSubscribe = useSelector((state) => state.landLordSignUpFlow);
  const [show, setShow] = useState(false);
  const [showOpenAppModal, setShowOpenAppModal] = useState(false);
  // const [text, setText] = useState("Log In");
  const [text, setText] = useState("");
  const [searchContainerShow, setSearchContainerShow] = useState(false);
  useEffect(() => {
    dispatch(getStates());
    setSearchData(null);
    dispatch(getCheckIsSubscribed(auth?.real_estate_user?.userId || ""));
  }, [dispatch]);
  useEffect(() => {
    setShow(false);
    setNotifiShow(false);
  }, [history.location.pathname]);
  const ref = React.useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.name !== "toggle"
      ) {
        setNotifiShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  // useEffect(() => {
  //     if (window.localStorage.getItem("modalVisited") === 'true') {
  //         setShowOpenAppModal(false);
  //       }
  // }, [])
  useEffect(() => {
    if (window.innerWidth < 1025) {
      setShowOpenAppModal(true);
    }
    //setShowOpenAppModal(true);
    // if (window.localStorage.getItem("modalVisited") === "true") {
    //   setShowOpenAppModal(false);
    // } else {
    //   setShowOpenAppModal(true);
    //   window.localStorage.setItem("modalVisited", "true");
    // }
  }, []);

  /*Local storage for modal starts*/

  /*Local storage for modal ends*/
  function showNotification() {
    setNotifiShow(!notifiShow);
    if (notifiShow === false) {
      dispatch(getAllNotificationList({ pageNumber: 1, pageSize: 10 }, auth));
    }
  }
  function searchMe() {
    if (
      auth &&
      auth.real_estate_user &&
      auth.real_estate_user.roleId &&
      auth.real_estate_user.roleId === ROLEID.LANDLORD
    ) {
      history.push({
        pathname: PATH.TENANTLISTING,
        state: {
          address: searchData,
        },
      });
    } else {
      history.push({
        pathname: searchData
          ? PATH.BROWSELISTING.replace(
              ":name",
              `${replaceSpace(searchData.name)}`
            )
          : PATH.BROWSELISTING.replace(":name", `all`),

        state: {
          address: searchData,
        },
      });
    }
    setSearchData(null);
  }
  const handleOnSearch = (string, results) => {
    dispatch(getLocationSuggestion(string));
  };
  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };
  const handleOnSelect = (item) => {
    setSearchData(item);
  };
  function logMeout() {
    console.log("sd");
    if (user_Data.deviceToken) {
      userLogout(user_Data.deviceToken, auth).then((response) => {
        console.log("res", response);
        if (response.data.succeeded === true) {
          remove("real_estate_user", { path: "/" });
        }
      });
    } else {
      async function tokenFunc() {
        let data_Token = await getToken(setTokenFound);
        if (data_Token) {
          console.log("Token is", data_Token);
          dispatch(
            success(REGISTRATION_V2_REQUEST.DEVICE_TOKEN_RECIEVED, data_Token)
          );
        }
        return data_Token;
      }
      tokenFunc();
      userLogout(user_Data.deviceToken, auth).then((response) => {
        if (response.data.succeeded === true) {
          remove("real_estate_user", { path: "/" });
        }
      });
    }
  }

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleInitialMobileModal = (link) => {
    let visitedLink = window.localStorage.getItem("visitedLink");
    if (visitedLink) {
      if (visitedLink.includes(link)) {
        setShowOpenAppModal(false);
      } else {
        visitedLink += link + ",";
        window.localStorage.setItem("visitedLink", visitedLink);
        setShowOpenAppModal(true);
      }
    } else {
      window.localStorage.setItem("visitedLink", link + ",");
      setShowOpenAppModal(true);
    }
  };

  console.log("isSubscribe", isSubscribe.isSubscribe);

  return (
    <React.Fragment>
      {auth &&
      auth.real_estate_user &&
      auth.real_estate_user.token &&
      auth.real_estate_user.roleId &&
      auth.real_estate_user.roleId === ROLEID.ADMIN ? (
        <AdminNav />
      ) : (
        <React.Fragment>
          {auth &&
          auth.real_estate_user &&
          auth.real_estate_user.token &&
          auth.real_estate_user.roleId ? (
            <React.Fragment>
              <Navbar
                collapseOnSelect
                fixed="top"
                expand="lg"
                className="p-0 head"
              >
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <div className="col-sm-10 col-md-10 col-lg-2 col-xl-3 header-title-div">
                  <NavLink
                    className="navbar-header-title-parent"
                    to={PATH.HOME}
                  >
                    <h3 className="navbar-header-title margin-auto px-40 ">
                      {"REDHANDED"}
                    </h3>
                  </NavLink>
                </div>
                <div className="col-sm-10 col-md-12 col-lg-10 col-xl-9 p-0">
                  <SecondTopBar />

                  <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className="justify-content-end h-55 px-40"
                  >
                    {auth.real_estate_user && auth.real_estate_user.token && (
                      <React.Fragment>
                        {!searchContainerShow ? (
                          <React.Fragment>
                            <NavLink
                              exact
                              className="nav-link"
                              to={{
                                pathname: PATH.DASHBOARD,
                              }}
                            >
                              {"DASHBOARD"}
                            </NavLink>

                            <NavLink
                              className="nav-link"
                              to={
                                auth &&
                                auth.real_estate_user &&
                                auth.real_estate_user.roleId &&
                                auth.real_estate_user.roleId ===
                                  ROLEID.LANDLORD &&
                                isSubscribe.isSubscribe == true
                                  ? {
                                      pathname: PATH.POSTPROPERTY.replace(
                                        ":type",
                                        "post"
                                      ),
                                    }
                                  : auth.real_estate_user.roleId ===
                                      ROLEID.LANDLORD &&
                                    isSubscribe.isSubscribe == false
                                  ? {
                                      pathname:
                                        PATH.LANDLORD_QUESTIONS_SUBSCRIPTION,
                                    }
                                  : {
                                      pathname: PATH.POSTPROPERTY.replace(
                                        ":type",
                                        "request"
                                      ),
                                      state: {
                                        isSave: true,
                                      },
                                    }
                              }
                              onClick={() => {
                                dispatch(getCheckIsSubscribed(auth?.real_estate_user?.userId || ""));
                              }}
                            >
                              {auth &&
                              auth.real_estate_user &&
                              auth.real_estate_user.roleId &&
                              auth.real_estate_user.roleId === ROLEID.LANDLORD
                                ? "Post A Space"
                                : "Post Requirement"}
                            </NavLink>
                            {auth &&
                            auth.real_estate_user &&
                            auth.real_estate_user.roleId &&
                            auth.real_estate_user.roleId === ROLEID.LANDLORD ? (
                              <NavLink
                                exact
                                className="nav-link"
                                to={PATH.TENANTLISTING.replace(
                                  ":search",
                                  "all"
                                )}
                              >
                                {"Browse Tenants"}
                              </NavLink>
                            ) : (
                              <NavLink
                                exact
                                className="nav-link"
                                to={PATH.BROWSELISTING.replace(":name", "all")}
                              >
                                {"View Spaces"}
                              </NavLink>
                            )}
                            {/* {auth &&
                            auth.real_estate_user &&
                            auth.real_estate_user.roleId ? (
                              <NavLink
                                exact
                                className="nav-link"
                                to={PATH.PICK_UP_SIGN}
                              >
                                {"Marketing Tools"}
                              </NavLink>
                            ) : null}
                            {auth &&
                            auth.real_estate_user &&
                            auth.real_estate_user.token &&
                            auth.real_estate_user.planId &&
                            auth.real_estate_user.planId === 5 ? (
                              <NavLink
                                exact
                                className="nav-link text-white upgrade-btn"
                                to={{
                                  pathname: PATH.PROFILE.replace(
                                    ":role",
                                    ROLE.LANDLORD
                                  ),
                                  state: {
                                    value: 1,
                                  },
                                }}
                              >
                                {"Upgrade"}
                              </NavLink>
                            ) : (
                              <NavLink
                                exact
                                className="nav-link"
                                to={PATH.MESSAGES}
                              >
                                {"Messages"}
                              </NavLink>
                            )} */}
                            <div
                              onClick={() =>
                                setSearchContainerShow(!searchContainerShow)
                              }
                              className="cursor nav-link d-flex d-lg-inline-block"
                              style={{ zIndex: 5 }}
                            >
                              <FontAwesomeIcon
                                className="red-handed-search-icon"
                                icon={faSearch}
                              />
                              <span className="d-lg-none d-inline-block">
                                {" "}
                                {"Search"}
                              </span>
                            </div>
                            <Link
                              className="cursor nav-link d-flex d-lg-inline-block position-relative"
                              style={{ zIndex: 5 }}
                              to={{
                                pathname: PATH.VIEW_CART,
                              }}
                            >
                              <FontAwesomeIcon
                                className="red-handed-search-icon"
                                icon={faShoppingCart}
                              />
                              <div className="d-flex justify-content-center align-items-center cart">
                                {cartQuantity}
                              </div>
                            </Link>
                            {auth &&
                              auth.real_estate_user &&
                              auth.real_estate_user.token && (
                                <Dropdown
                                  ref={ref}
                                  show={notifiShow}
                                  id="nav-drop-down-profile"
                                >
                                  <Dropdown.Toggle
                                    variant="none"
                                    onClick={() => {
                                      showNotification();
                                    }}
                                    bsPrefix="nav-drop-down "
                                    id="dropdown-basic"
                                  >
                                    <span className="red-handed-bell-icon">
                                      <FontAwesomeIcon
                                        className="red-handed-search-icon"
                                        icon={faBell}
                                      />
                                      {notificationState &&
                                        notificationState.unreadNotificationCount !==
                                          0 && (
                                          <span className="notification-count">
                                            {notificationState &&
                                              notificationState.unreadNotificationCount}
                                            {notificationState &&
                                              notificationState.unreadNotificationCount >
                                                9 && <span>+</span>}
                                          </span>
                                        )}
                                    </span>
                                  </Dropdown.Toggle>
                                  <NotificationListView />
                                </Dropdown>
                              )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className="col-md-4">
                              <div className="search-container-parent serch-container-z-index">
                                <ReactSearchAutocomplete
                                  className="search-bar-nav"
                                  value={searchData}
                                  items={autoSuggestion.suggestionList}
                                  onSearch={handleOnSearch}
                                  onHover={handleOnHover}
                                  onSelect={handleOnSelect}
                                  onFocus={handleOnFocus}
                                  getLocationSuggestion
                                  onChange={(e) => {
                                    setSearchData(e.target.value);
                                  }}
                                  type="text"
                                  placeholder="Enter City, State"
                                  autoFocus={true}
                                />
                                <div
                                  className="cursor search-container-child"
                                  style={{ zIndex: 5 }}
                                >
                                  <FiSearch
                                    onClick={() => {
                                      searchMe();
                                    }}
                                    className="red-handed-search-icon"
                                  />
                                  <MdCancel
                                    className="red-handed-search-icon"
                                    onClick={() =>
                                      setSearchContainerShow(
                                        !searchContainerShow
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                    {/* profile drop down */}
                    {auth.real_estate_user &&
                    auth.real_estate_user.token &&
                    auth.real_estate_user.roleId ? (
                      <Dropdown id="nav-drop-down-profile">
                        <Dropdown.Toggle
                          variant="none"
                          bsPrefix="nav-drop-down "
                          id="dropdown-basic"
                        >
                          {auth &&
                          auth.real_estate_user &&
                          auth.real_estate_user.photo ? (
                            <img
                              alt=""
                              className={`pr-im-nav ${
                                auth &&
                                auth.real_estate_user &&
                                auth.real_estate_user.token &&
                                auth.real_estate_user.planId &&
                                auth.real_estate_user.planId === 5
                                  ? "pulse"
                                  : ""
                              }`}
                              height={"35px"}
                              width={"35px"}
                              src={
                                auth.real_estate_user.photo.includes("https://")
                                  ? auth.real_estate_user.photo
                                  : AZURE_BLOB_BASE_URL +
                                    auth.real_estate_user.photo
                              }
                            />
                          ) : (
                            <FontAwesomeIcon
                              size="3x"
                              color={"#a80000"}
                              className={`${
                                auth &&
                                auth.real_estate_user &&
                                auth.real_estate_user.token &&
                                auth.real_estate_user.planId &&
                                auth.real_estate_user.planId === 5
                                  ? "pulse"
                                  : ""
                              }`}
                              icon={faUserCircle}
                            />
                          )}
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{ marginLeft: 3 }}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="navbar-profile navbar-dropdown dropdown_profile">
                          <Dropdown.Item>
                            <Link
                              className="nav-link"
                              to={PATH.PROFILE.replace(
                                ":role",
                                auth.real_estate_user.roleId === ROLEID.LANDLORD
                                  ? ROLE.LANDLORD
                                  : ROLE.TENANTS
                              )}
                            >
                              <div>{"Profile"}</div>
                            </Link>
                          </Dropdown.Item>
                          {/* {auth &&
                            auth.real_estate_user &&
                            auth.real_estate_user.token &&
                            auth.real_estate_user.planId &&
                            auth.real_estate_user.planId === 5 && (
                              <Dropdown.Item className="item_no_hover">
                                <NavLink
                                  exact
                                  className="nav-link"
                                  to={{
                                    pathname: PATH.PROFILE.replace(
                                      ":role",
                                      ROLE.LANDLORD
                                    ),
                                    state: {
                                      value: 1,
                                    },
                                  }}
                                >
                                  {"Upgrade"}
                                </NavLink>
                              </Dropdown.Item>
                            )} */}
                          <Dropdown.Item>
                            <p
                              className="sign-me-out "
                              onClick={() => {
                                logMeout();
                              }}
                            >
                              Sign Out
                            </p>
                          </Dropdown.Item>
                          {/* {auth &&
                            auth.real_estate_user &&
                            auth.real_estate_user.roleId === 2 && (
                              <React.Fragment>
                                <Dropdown.Divider />
                                <Dropdown.Item className="item_no_hover">
                                  {"Current plan"}
                                </Dropdown.Item>
                              </React.Fragment>
                            )} */}
                          {/* {auth &&
                            auth.real_estate_user &&
                            auth.real_estate_user.planId === 5 && (
                              <Dropdown.Item className="item_no_hover">
                                <Link
                                  to={{
                                    pathname: PATH.PROFILE.replace(
                                      ":role",
                                      ROLE.LANDLORD
                                    ),
                                    state: {
                                      value: 1,
                                    },
                                  }}
                                >
                                  <Button className="upgrade-button">
                                    {"Free Trial"}
                                  </Button>
                                </Link>
                              </Dropdown.Item>
                            )} */}
                          {auth &&
                            auth.real_estate_user &&
                            (auth.real_estate_user.planId === 6 ||
                              auth.real_estate_user.planId === 8) && (
                              <Dropdown.Item className="item_no_hover">
                                <Link
                                  to={{
                                    pathname: PATH.PROFILE.replace(
                                      ":role",
                                      ROLE.LANDLORD
                                    ),
                                    state: {
                                      value: 1,
                                    },
                                  }}
                                >
                                  <Button className="gold-button">Gold</Button>
                                </Link>
                              </Dropdown.Item>
                            )}
                          {auth &&
                            auth.real_estate_user &&
                            (auth.real_estate_user.planId === 7 ||
                              auth.real_estate_user.planId === 9) && (
                              <Dropdown.Item className="item_no_hover">
                                <Link
                                  to={{
                                    pathname: PATH.PROFILE.replace(
                                      ":role",
                                      ROLE.LANDLORD
                                    ),
                                    state: {
                                      value: 1,
                                    },
                                  }}
                                >
                                  <Button className="diamond-button">
                                    Diamond
                                  </Button>
                                </Link>
                              </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <div className="login-signup-btn nav-bar-authentication-btn-row">
                        <button
                          name="login"
                          onClick={() => {
                            setShow(true);
                            setText("Log In");
                          }}
                          type="button"
                        >
                          {"Log In"}
                        </button>
                        <button
                          className="ml-1 outline-red-btn"
                          name="login"
                          type="button"
                          onClick={() => {
                            setShow(true);
                            setText("Sign Up");
                          }}
                        >
                          {"Sign Up"}
                        </button>
                      </div>
                    )}
                  </Navbar.Collapse>
                </div>
              </Navbar>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Navbar
                collapseOnSelect
                expand="lg"
                className="px-4  head font-outfit"
              >
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <div className="col-sm-10 col-md-10 col-lg-4 col-xl-4 header-title-div mr-4">
                  <NavLink
                    className="navbar-header-title-parent"
                    to={PATH.HOME}
                  >
                    <h3 className="navbar-header-title margin-auto px-3 margin-logo font-normal-5vh navbar-logo-mobile-responsive">
                      {"REDHANDED"}
                    </h3>
                  </NavLink>
                </div>
                <div className="col-sm-10 col-md-12 col-lg-8 col-xl-8 p-0">
                  {/* <SecondTopBar /> */}
                  <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className="justify-content-around h-55 "
                  >
                    <NavLink
                      className="navbar-item navbar-item-text ml-5"
                      to={PATH.ABOUTUS}
                    >
                      <p
                        className="text-muted  mb-0 margin-left font-normal-2vh"
                        onClick={() => handleInitialMobileModal("About")}
                      >
                        About
                      </p>
                    </NavLink>
                    <a
                      rel="noreferrer"
                      className="navbar-item navbar-item-text"
                      target="_blank"
                      href="http://resources.redhandedco.com/"
                    >
                      <p className="text-muted  mb-0 margin-left font-normal-2vh">
                        Blog
                      </p>
                    </a>

                    <NavLink
                      className="navbar-item navbar-item-text"
                      to={PATH.OURPARTNERS}
                    >
                      <p
                        className="text-muted  mb-0 margin-left font-normal-2vh"
                        onClick={() => handleInitialMobileModal("Partners")}
                      >
                        {" "}
                        Partners
                      </p>
                    </NavLink>
                    {/* <NavLink
                      className="navbar-item navbar-item-text pr-0 mr-0"
                      to={PATH.PRICING}
                    >
                      <p
                        className="text-muted  mb-0 margin-left font-normal-2vh pr-0 mr-0"
                        onClick={() => handleInitialMobileModal("Pricing")}
                      >
                        {" "}
                        Pricing
                      </p>
                    </NavLink> */}
                    {!searchContainerShow ? (
                      <div className="login-signup-btn nav-bar-authentication-btn-row d-block d-lg-flex mr-5 pr-3">
                        <button
                          className="nav-link   mb-md-1 bg-white border-0 sign-up-login-btn font-normal-2vh mr-1"
                          onClick={() => {
                            setShow(true);
                            setText("Log In");
                          }}
                        >
                          Log In
                        </button>
                        {/* <button
                          className=" nav-link   mb-md-1 text-white sign-up-btn border-0 sign-up-login-btn font-normal-2vh "
                          onClick={() => {
                            setShow(true);
                            setText("Sign Up");
                          }}
                        >
                          Sign up
                        </button> */}
                        {/* <button className="nav-link   mb-md-1 bg-white border-0 sign-up-login-btn font-normal-2vh mr-1"
                                                            onClick={() => { setShowOpenAppModal(true); setText("Open App") }}  >
                                                            Open App
                                                        </button> */}
                      </div>
                    ) : (
                      <div className="col-md-4">
                        <div className="search-container-parent serch-container-z-index">
                          <ReactSearchAutocomplete
                            className="search-bar-nav"
                            value={searchData}
                            items={autoSuggestion.suggestionList}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            getLocationSuggestion
                            onChange={(e) => {
                              setSearchData(e.target.value);
                            }}
                            type="text"
                            placeholder="Enter City, State"
                            autoFocus={true}
                          />
                          <div
                            className="cursor search-container-child"
                            style={{ zIndex: 5 }}
                          >
                            <FiSearch
                              onClick={() => {
                                searchMe();
                              }}
                              className="red-handed-search-icon"
                            />
                            <MdCancel
                              className="red-handed-search-icon"
                              onClick={() =>
                                setSearchContainerShow(!searchContainerShow)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Navbar.Collapse>
                </div>
              </Navbar>
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      <LoginSignUpModal show={show} setShow={setShow} view={0} text={text} />

      {/* Open App Modal Starts */}
      {/* <OpenAppModal
        show={show}
        setShow={setShow}
        showOpenAppModal={showOpenAppModal}
        setShowOpenAppModal={setShowOpenAppModal}
        view={0}
        text={text}
        setText={setText}
      /> */}
      {/* Open App Modal Ends */}
    </React.Fragment>
  );
}

function SecondTopBar() {
  return (
    // <div className="hide-me-on-mob sec-top-bar border-b-0 box-shadow-none bg-transperant ">
    //     <div className="col-4 margin-auto menu-item-nav-div">
    //     </div>
    //     <div className="col-8  margin-auto menu-item-nav-div justify-content-end second-top-bar-bg-gradiant px-40 my-0">
    //         <NavLink className="navbar-link b-right" exact to={PATH.ABOUTUS}>
    //             {"About"}
    //         </NavLink>
    //         <a rel="noreferrer" className="navbar-link b-right" target="_blank" href="http://resources.redhandedco.com/">
    //             {'Blog'}
    //         </a>
    //         <Link className="navbar-link  b-right" to={PATH.OURPARTNERS}>
    //             {"Partners"}
    //         </Link>
    //         <Link className="navbar-link" to={PATH.PRICING}>
    //             {"Pricing"}
    //         </Link>
    //     </div>
    // </div>
    null
  );
}

function AdminNav() {
  const [show, setShow] = useState(false);
  const [cookies, remove] = useCookies(["real_estate_user"]);
  console.log("cookies:::", cookies);
  useEffect(() => {
    window.$(window).scroll(function () {
      var scroll = window.$(window).scrollTop();
      if (scroll >= 100) {
        window.$("#navbar-change").addClass("scrolled-navbar");
      } else {
        window.$("#navbar-change").removeClass("scrolled-navbar");
      }
    });
  }, []);
  return (
    <React.Fragment>
      <Navbar collapseOnSelect expand="lg" id="navbar-change">
        <Button
          name="menu-buttn"
          variant="link"
          bsPrefix="menu-btn"
          onClick={() => {
            setShow(!show);
          }}
        >
          <GiHamburgerMenu name="menu-buttn" />
        </Button>
        <Dropdown id="nav-drop-down-profile">
          <Dropdown.Toggle
            variant="none"
            bsPrefix="nav-drop-down"
            id="dropdown-basic"
          >
            <FontAwesomeIcon size="3x" color={"#a80000"} icon={faUserCircle} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                remove("real_estate_user", { path: "/" });
              }}
            >
              {"Sign Out"}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar>
      <Sidebar show={show} setShow={setShow} />
    </React.Fragment>
  );
}

function NotificationListView() {
  let notificationState = useSelector((state) => state.notification);
  let history = useHistory();
  let auth = useAuth();
  let dispatch = useDispatch();
  function readNotify(data) {
    dispatch(ReadNotification(data, auth));
  }
  return (
    <Dropdown.Menu className="navbar-profile navbar-dropdown">
      {notificationState &&
        notificationState.getAllNotificationLoading === true && <Loader />}
      {notificationState &&
        notificationState.getAllNotificationSuccess === true && (
          <React.Fragment>
            <div className="notification-body">
              {notificationState &&
                notificationState.getAllNotificationList &&
                notificationState.getAllNotificationList.map(
                  (notification, index) => {
                    console.log("asdas", notification);
                    return (
                      <Dropdown.Item key={index}>
                        <div
                          className="cursor d-flex"
                          style={{
                            position: "relative",
                            fontSize: 17,
                            paddingBottom: 6,
                          }}
                          onClick={() => {
                            {
                              readNotify(notification.pushNotificationId);
                              history.push(
                                notification.title === "Request"
                                  ? {
                                      pathname: PATH.PROPERTYDETAIL.replace(
                                        ":id",
                                        notification.propertyId
                                      ),
                                      state: {
                                        activeTab: 3,
                                        subTab: 1,
                                      },
                                    }
                                  : notification.title === "Connection"
                                  ? {
                                      pathname: PATH.PROPERTYDETAIL.replace(
                                        ":id",
                                        notification.propertyId
                                      ),
                                      state: {
                                        activeTab: 3,
                                        subTab: 0,
                                      },
                                    }
                                  : {
                                      pathname: PATH.MESSAGES,
                                      search: `?inboxId=${notification.inboxId}`,
                                    }
                              );
                            }
                          }}
                        >
                          <div className="cursor content-notify">
                            <div
                              className="notify-img-parent"
                              style={{
                                border: `${
                                  notification && notification.photo
                                    ? "2"
                                    : "none"
                                }`,
                              }}
                            >
                              {notification && notification.photo ? (
                                <img
                                  className="img-fluid"
                                  style={{ width: "100%", height: "100%" }}
                                  // src={ IMAGES.CHRIS}
                                  src={
                                    notification.photo
                                      ? notification.photo.includes("http")
                                        ? notification.photo
                                        : AZURE_BLOB_BASE_URL +
                                          notification.photo
                                      : createImageFromInitials(
                                          500,
                                          notification.name,
                                          getRandomColor()
                                        )
                                  }
                                  alt="user-img"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  size="3x"
                                  color={"#a80000"}
                                  icon={faUserCircle}
                                />
                              )}
                            </div>
                            <div className="notify-detail">
                              <div className="d-flex">
                                <span className="notifyName">
                                  {notification.title}
                                </span>
                                <span className="notifyStatus">
                                  <span
                                    style={{
                                      color: `${
                                        notification.isRead === false
                                          ? "#a91622"
                                          : "#797979"
                                      }`,
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      className="red-handed-read-dot-icon-seen-unseen"
                                      color={
                                        notification.isRead === false
                                          ? "#a91622"
                                          : "#797979"
                                      }
                                      icon={faCircle}
                                    />
                                  </span>
                                </span>
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  marginRight: "1rem",
                                  fontSize: "15px",
                                }}
                              >
                                {notification.body}
                              </div>
                              <div
                                className="d-flex"
                                style={{ marginRight: "1rem" }}
                              >
                                <small className="d-block ml-auto">
                                  <i>
                                    {dateTimeDifference(
                                      notification?.createdDate
                                    )}
                                  </i>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                        {index + 1 !== getAllNotificationList.length && (
                          <hr className="hr-noti" />
                        )}
                      </Dropdown.Item>
                    );
                  }
                )}
            </div>
            {notificationState &&
              notificationState.getAllNotificationLoading === false &&
              notificationState.getAllNotificationSuccess === true &&
              notificationState.getAllNotificationFailure === false &&
              notificationState.getAllNotificationList &&
              notificationState.getAllNotificationList.length <= 0 && (
                <Dropdown.Item>
                  <div className="margin-auto text-align-center">
                    {"No New Notifications"}
                  </div>
                </Dropdown.Item>
              )}
            {notificationState &&
            notificationState.getAllNotificationList &&
            notificationState.getAllNotificationList.length === 0 ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Dropdown.Item>
                <div
                  className="cursor text-center pt-2"
                  style={{ borderTop: "1px solid rgba(0,0,0,.1)" }}
                >
                  <Link to={PATH.VIEW_ALL_NOTIFICATION}>View All</Link>
                </div>
              </Dropdown.Item>
            )}
          </React.Fragment>
        )}
    </Dropdown.Menu>
  );
}
