import { MARKETING_PREFERNECE_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
    getAllLoading: false,
    getAllSuccess: false,
    getAllFailure: false,
    getAllError: null,
    getAll: [],
    addPicLoading: false,
    addPicSuccess: false,
    addPicFailure: false,
    addPicError: null,
    getDetailLoading: false,
    getDetailSuccess: false,
    getDetailFailure: false,
    getDetailError: null,
    getDetail: []
}

export const marketingPreferenceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MARKETING_PREFERNECE_REQUEST.MARKETING_DETAIL_REQUEST:
            return {
                ...state,
                getDetailLoading: true,
                getDetailSuccess: false,
                getDetailFailure: false,
                getDetailError: null,
                getDetail: []
            }
        case MARKETING_PREFERNECE_REQUEST.MARKETING_DETAIL_SUCCESS:
            return {
                ...state,
                getDetailLoading: false,
                getDetailSuccess: true,
                getDetailFailure: false,
                getDetailError: null,
                getDetail: action.payload
            }
        case MARKETING_PREFERNECE_REQUEST.MARKETING_DETAIL_FAILURE:
            return {
                ...state,
                getDetailLoading: false,
                getDetailSuccess: false,
                getDetailFailure: true,
                getDetailError: action.payload,
                getDetail: []
            }
        case MARKETING_PREFERNECE_REQUEST.ADD_BROCHURE_RESET:
            return {
                ...state,
                addPicLoading: false,
                addPicSuccess: false,
                addPicFailure: false,
                addPicError: null
            }
        case MARKETING_PREFERNECE_REQUEST.ADD_BROCHURE_REQUEST:
            return {
                ...state,
                addPicLoading: true,
                addPicSuccess: false,
                addPicFailure: false,
                addPicError: null
            }
        case MARKETING_PREFERNECE_REQUEST.ADD_BROCHURE_FAILURE:
            return {
                ...state,
                addPicLoading: false,
                addPicSuccess: false,
                addPicFailure: true,
                addPicError: action.payload
            }
        case MARKETING_PREFERNECE_REQUEST.ADD_BROCHURE_SUCCESS:
            let myList = state.getAll;
            for (let i = 0; i < myList.length; i++) {
                if (myList[i].propertyId === action.payload.propertyId) {
                    if (action.payload.isBrochure === true) {
                        myList[i].brochure = action.payload.url
                    } else {
                        myList[i].windowSignage = action.payload.url
                    }
                }
            }
            return {
                ...state,
                addPicLoading: false,
                addPicSuccess: true,
                addPicFailure: false,
                addPicError: null,
                getAll: myList
            }
        case MARKETING_PREFERNECE_REQUEST.GET_ALL_LIST_REQUEST:
            return {
                ...state,
                getAllLoading: true,
                getAllSuccess: false,
                getAllFailure: false,
                getAllError: null,
                getAll: []
            }
        case MARKETING_PREFERNECE_REQUEST.GET_ALL_LIST_SUCCESS:
            return {
                ...state,
                getAllLoading: false,
                getAllSuccess: true,
                getAllFailure: false,
                getAllError: null,
                getAll: action.payload
            }
        case MARKETING_PREFERNECE_REQUEST.GET_ALL_LIST_FAILURE:
            return {
                ...state,
                getAllLoading: false,
                getAllSuccess: false,
                getAllFailure: true,
                getAllError: action.payload
            }
        default:
            return state;
    }
}