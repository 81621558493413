import React, { useState, useEffect } from "react";
// import pro__banner from "../../assets/images/pro__banner.svg";

import onetimefee from "../../assets/images/onetimefee.svg";
import { useSelector } from "react-redux";
import { getCouponDiscountedAmount } from "../../assets";

const FeatureListing = (props) => {
  console.log(props.addOnsData);
  let couponDetails = useSelector(
    (state) => state.landLordSignUpFlow.addonsCouponDetails
  );

  useEffect(() => {
    if (
      Object.keys(couponDetails).length > 0 &&
      couponDetails.metadata &&
      couponDetails.metadata.addonId &&
      couponDetails.metadata.addonId
        .split(",")
        .includes(props.addOnsData.id.toString())
    ) {
      props.setOrdersAdded({
        ...props.ordersAdded,
        featureListing: true,
      });
    }
  }, [couponDetails]);

  return (
    <div className="row dtl__content">
      <div className="col-md-12">
        <div className="multistep__content">
          <h4>
            Our Special offers are designed to achieve your goals.
            <span>See what catches your eye!</span>
          </h4>
        </div>
      </div>

      <div className="col-md-6">
        <div className="right__section feature__listing"></div>
      </div>
      <div className="col-md-6">
        <div className="left__section">
          <div className="content__block">
            <h3>
              <span class="navbar-header-title">Featured Listing Boost</span>
            </h3>
            <ul>
              <li>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <span>
                  Featured listings will showcase on the REDHANDED home page
                </span>
              </li>
              <li>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <span>
                  Display your posting towards the top of the list when tenants
                  search for spaces that match your criteria
                </span>
              </li>
            </ul>

            <div className="row m-0">
              <div className="col-lg-6">
                <div className="price__block">
                  <span>Normal Price ${props.addOnsData.price}</span>
                  <p>
                    Price{" "}
                    <span>
                      {/* $
                      {props.addOnsData.id == couponDetails.addonId
                        ? couponDetails.addonDiscountPrice
                        : props.addOnsData.price} */}
                      $
                      {Object.keys(couponDetails).length > 0 &&
                      couponDetails.metadata &&
                      couponDetails.metadata.addonId &&
                      couponDetails.metadata.addonId
                        .split(",")
                        .includes(props.addOnsData.id.toString())
                        ? `${
                            getCouponDiscountedAmount(
                              couponDetails,
                              props.addOnsData && props.addOnsData.price
                                ? props.addOnsData.price
                                : 0
                            ).total
                          }`
                        : props.addOnsData.price}
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="onetimefee__img">
                  <img src={onetimefee} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="bottom__button">
              {!props.ordersAdded.featureListing ? (
                <button
                  class="btn btn-primary pop-up-property-detaile-btn cursor btn-red-color"
                  onClick={() => {
                    props.handleAddMyOrder();
                    props.setOrdersAdded({
                      ...props.ordersAdded,
                      featureListing: true,
                    });
                  }}
                >
                  ADD TO MY ORDER
                </button>
              ) : (
                <button
                  class="btn btn-primary pop-up-property-detaile-btn cursor btn-red-color"
                  onClick={() => {
                    props.handleRemoveMyOrder();
                    props.setOrdersAdded({
                      ...props.ordersAdded,
                      featureListing: false,
                    });
                  }}
                >
                  REMOVE FROM MY ORDER
                </button>
              )}
              <a href="#" className="linkText" onClick={props.handleNext}>
                No Thank you, I am not interested in this offer.
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureListing;
