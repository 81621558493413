import React from "react";

const NoPage = React.lazy(() => import("./NoPageFound"));
const AppSubscription = React.lazy(() => import("./AppSubscription"));
const AppPaymentCheckout = React.lazy(() =>
  import("./AppSubscription/PaymentCheckout")
);
const Home = React.lazy(() => import("./Home"));
const SignUp = React.lazy(() => import("./Signup"));
const Dashboard = React.lazy(() => import("./Dashboard"));
const Registration = React.lazy(() => import("./Registration"));
const UserVerified = React.lazy(() => import("./UserVerified"));
const Profile = React.lazy(() => import("./Profile"));
const PropertyDetail = React.lazy(() => import("./PropertyDetail"));
const Messages = React.lazy(() => import("./Messages"));
const PostProperty = React.lazy(() => import("./PostAProperty"));
const ViewProfile = React.lazy(() => import("./ViewProfile"));
const ListingDetailePage = React.lazy(() => import("./ListingDetailePage"));
const BrowseListing = React.lazy(() => import("./BrowseListing"));
const AboutUs = React.lazy(() => import("./AboutUs"));
const ForgetPassword = React.lazy(() => import("./ForgetPassword"));
const ChangePassword = React.lazy(() => import("./ChangePassword"));
const TenantListing = React.lazy(() => import("./TenantListing"));
const DonateNow = React.lazy(() => import("./DonateNow"));
const OurPartners = React.lazy(() => import("./Partners"));
const TenantDashboard = React.lazy(() => import("./TenantDashboard"));
const LandlordDashboard = React.lazy(() => import("./LandlordDashboard"));
const EditPostProperty = React.lazy(() =>
  import("./LandlordDashboard/EditAProperty")
);
const AdminLogDashboard = React.lazy(() => import("./AdminLogDashboard"));
const login = React.lazy(() => import("./Login"));
const Reports = React.lazy(() => import("./ReportsDashboard"));
const SubscriptionReports = React.lazy(() =>
  import("./ReportsDashboard/SubscriptionReoprts")
);
const NewLandlordReports = React.lazy(() =>
  import("./ReportsDashboard/NewLandlordReoprt")
);
const NewTenantsReports = React.lazy(() =>
  import("./ReportsDashboard/NewTenantsReports")
);
const WeeklyStatusReport = React.lazy(() =>
import("./ReportsDashboard/WeeklyStatusReport")
);
const TotalCountsReport = React.lazy(() =>
import("./ReportsDashboard/TotalCountsReport")
);
const Top20LandlordsReport = React.lazy(() =>
import("./ReportsDashboard/Top20LandlordsReport")
);
const Top20TenantsReport = React.lazy(() =>
import("./ReportsDashboard/Top20TenantsReport")
);
const TotalListingPerStateReport = React.lazy(() =>
import("./ReportsDashboard/TotalListingPerStateReport")
);
const TotalListingPerCategoryReport = React.lazy(() =>
import("./ReportsDashboard/TotalListingPerCategoryReport")
);
const SocialMediaMarketing = React.lazy(() => import("./SocialMediaMarketing"));
const TermsAndConditions = React.lazy(() => import("./Terms&Conditions"));
const Policy = React.lazy(() => import("./MobilePolicyView/Policy"));
const Term = React.lazy(() => import("./MobilePolicyView/TermOfUse"));
const Verification = React.lazy(() => import("./VerifyModal"));
const Settings = React.lazy(() => import("./Settings"));
const Negotiation = React.lazy(() => import("./Negottation"));
const Pricing = React.lazy(() => import("./pricing"));
const ThankYou = React.lazy(() => import("./ThankYouPage"));
const SignUpThankYou = React.lazy(() => import("./VerifyModal/ThankyouSignUp"));
const PaymentCheckout = React.lazy(() => import("./PaymentCheckout"));
const PaymentCheckoutAdmin = React.lazy(() =>
  import("./LandlordDashboard/EditProfile/PaymentCheckoutAdmin")
);
const jsonConfigComponent = React.lazy(() => import("./JsonConfig"));
const ReportedUsers = React.lazy(() => import("./ReportedUsers"));
const ReportedSpace = React.lazy(() => import("./ReportedSpaces"));
const Support = React.lazy(() => import("./Support"));
const Coupon = React.lazy(() => import("./Coupon"));
const CouponUser = React.lazy(() => import("./CouponUsers"));
const ViewAllNotification = React.lazy(() => import("./ViewAllNotification"));
const EditProfile = React.lazy(() => import("./LandlordDashboard/EditProfile"));
const MarketingPreference = React.lazy(() => import("./MarketingPreference"));
const PropertySignage = React.lazy(() => import("./PropertySignage"));
const LandingPage = React.lazy(() => import("./LandingPage"));
const PropertyDetailPage = React.lazy(() =>
  import("./ListingDetailePage/NewListingDetail")
);
const LandlordAnalytics = React.lazy(() =>
  import("./LandlordDashboard/LandlordAnalytics")
);
const SignProducts = React.lazy(() => import("./SignCart/SignProducts"));
const SignDetails = React.lazy(() => import("./SignCart/SignDetails"));
const EmailerTemplate = React.lazy(() =>
  import("./EmailerTemplate/EmailerTemplate")
);
const ViewCart = React.lazy(() => import("./ViewCart/ViewCart"));
const PickUpSign = React.lazy(() => import("./SignCart/PickUpSign"));
const SelectedSigns = React.lazy(() => import("./SignCart/SelectedSigns"));
const LandlordDescription = React.lazy(() =>
  import("./LandlordDashboard/LandlordDescription")
);
const PropertyDescriptionRequest = React.lazy(() =>
  import("./PropertyDescriptionRequest/PropertyDescriptionRequest")
);
const LandlordQuestions = React.lazy(() =>
  import("./LandLordSignUp/LandLordQuestions")
);
const LandLordQuestionsSubscription = React.lazy(() =>
  import("./LandLordSignUp/LandLordQuestionsSubscription")
);
const LeasingPlan = React.lazy(() => import("./LandLordSignUp/LeasingPlan"));
const NewCoupon = React.lazy(() => import("./Coupon/coupon"));
const TenantSignUp = React.lazy(() =>
  import("./TenantSignUpFlow/TenantSignUp")
);
const TenantLeasingPlan = React.lazy(() =>
  import("./TenantSignUpFlow/LeasingPlan")
);
const TermSheet = React.lazy(() => import("./PropertyDetail/TenantTermSheet"));
const Brochure = React.lazy(() => import("./PropertyDetail/Brochure"));
const WindowSignage = React.lazy(() =>
  import("./PropertyDetail/WindowSignage")
);
const RenewSubscription = React.lazy(() =>
  import("./LandLordSignUp/RenewSubscription")
);
const AiVirtualAssistant = React.lazy(() => import("./LandingPage/AIVirtualAssistant"));

const WEB_PAGES = {
  PROPERTYDETAILPAGE: PropertyDetailPage,
  LANDINGPAGE: LandingPage,
  PROPERTYSIGNAGE: PropertySignage,
  MARKETINGPREFERENCE: MarketingPreference,
  EDITPROFILE: EditProfile,
  PAYMENTCHECKOUTADMIN: PaymentCheckoutAdmin,
  COUPONUSER: CouponUser,
  COUPON: Coupon,
  SUPPORT: Support,
  NOPAGE: NoPage,
  HOME: Home,
  SIGNUP: SignUp,
  DASHBOARD: Dashboard,
  REGISTRATION: Registration,
  USERVERIFIED: UserVerified,
  PROFILE: Profile,
  PROPERTYDETAIL: PropertyDetail,
  MESSAGES: Messages,
  POSTPROPERTY: PostProperty,
  VIEWPROFILE: ViewProfile,
  LISTINGDETAILEPAGE: ListingDetailePage,
  BROWSELISTING: BrowseListing,
  EDITPOSTPROPERTY: EditPostProperty,
  ABOUTUS: AboutUs,
  FORGETPASSWORD: ForgetPassword,
  CHANGEPASSWORD: ChangePassword,
  TENANTLISTING: TenantListing,
  DONATENOW: DonateNow,
  OURPARTNERS: OurPartners,
  TENANTDASHBOARD: TenantDashboard,
  LANDLORDDASHBOARD: LandlordDashboard,
  ADMINLOGSDASHBOARD: AdminLogDashboard,
  LOGIN: login,
  REPORTSDASHBOARD: Reports,
  SUBSCRIPTIONREPORTS: SubscriptionReports,
  NEWLANDLORDREPORTS: NewLandlordReports,
  NEWTENANTSREPORTS: NewTenantsReports,
  WEEKLYSTATUSREPORT: WeeklyStatusReport,
  TOTALCOUNTSREPORT: TotalCountsReport,
  TOP20LANDLORDSREPORT: Top20LandlordsReport,
  TOP20TENANTSREPORT: Top20TenantsReport,
  TOTALLISTINGPERSTATEREPORT: TotalListingPerStateReport,
  TOTALLISTINGPERCATEGORYREPORT: TotalListingPerCategoryReport,
  SOCIALMEDIAMARKETING: SocialMediaMarketing,
  TERMSANDCONDITIONS: TermsAndConditions,
  POLICY: Policy,
  TERM: Term,
  VERIFICATION: Verification,
  SETTINGS: Settings,
  NEGOTIATION: Negotiation,
  PRICING: Pricing,
  THANKYOU: ThankYou,
  THANKYOUSIGNUP: SignUpThankYou,
  PAYMENTCHECKOUT: PaymentCheckout,
  JSON_CONFIG: jsonConfigComponent,
  REPORTEDUSERS: ReportedUsers,
  REPORTEDSPACE: ReportedSpace,
  APPSUBSCRIPTION: AppSubscription,
  APPPAYMENTCHECKOUT: AppPaymentCheckout,
  VIEW_ALL_NOTIFICATION: ViewAllNotification,
  LANDLORD_ANALYTICS: LandlordAnalytics,
  SIGN_PRODUCTS: SignProducts,
  SIGN_DETAILS: SignDetails,
  EMAILER_TEMPLATE: EmailerTemplate,
  VIEW_CART: ViewCart,
  PICK_UP_SIGN: PickUpSign,
  SELECTED_SIGN: SelectedSigns,
  LANDLORD_DESCRIPTION: LandlordDescription,
  PROPERTY_DESCRIPTION_REQUEST: PropertyDescriptionRequest,
  LANDLORD_QUESTIONS: LandlordQuestions,
  LANDLORD_QUESTIONS_SUBSCRIPTION: LandLordQuestionsSubscription,
  LEASING_PLAN: LeasingPlan,
  COUPON_WITH_ADDONS: NewCoupon,
  TENANT_SIGNUP: TenantSignUp,
  TENANT_LEASING_PLAN: TenantLeasingPlan,
  VIEW_TERM_SHEET: TermSheet,
  BROCHURE: Brochure,
  WINDOWSIGNAGE: WindowSignage,
  RENEW_SUBSCRIPTION: RenewSubscription,
  AI_VIRTUAL_ASSISTANT: AiVirtualAssistant
};

export { WEB_PAGES };
