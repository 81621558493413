import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Steps } from "antd";
import back__arrow from "../../assets/images/arrow__img.svg";
import ProfessionalPhotos from "./ProfessionalPhotos";
import VirtualTour from "./VirtualTour";
import WindowSignage from "./WindowSignage";
import FeatureListing from "./FeatureListing";
import SignUp from "./SignUp";
import ThankYou from "./ThankYou";
import { setPageNo } from "../../redux/actions";

const MyOrder = (props) => {
  let dispatch = useDispatch();
  let addOns = useSelector((state) => state.landLordSignUpFlow.addOns);
  let pageNo = useSelector((state) => state.landLordSignUpFlow.pageNo);
  //const [pageNo, setPageNo] = useState(10);
  const [addOnsPageNo, setAddOnsPageNo] = useState(1);

  const MyOrderComponent = () => {
    switch (pageNo) {
      case 3:
        return (
          <ProfessionalPhotos
            handleNext={handleNext}
            addOnsData={addOns[0]}
            handleAddMyOrder={() => props.handleAddMyOrder(addOns[0])}
            handleRemoveMyOrder={() => props.handleRemoveMyOrder(addOns[0])}
            ordersAdded={props.ordersAdded}
            setOrdersAdded={props.setOrdersAdded}
          />
        );
      case 4:
        return (
          <VirtualTour
            handleNext={handleNext}
            addOnsData={addOns[1]}
            handleAddMyOrder={() => props.handleAddMyOrder(addOns[1])}
            handleRemoveMyOrder={() => props.handleRemoveMyOrder(addOns[1])}
            ordersAdded={props.ordersAdded}
            setOrdersAdded={props.setOrdersAdded}
          />
        );
      // case 5:
      //   return (
      //     <WindowSignage
      //       handleNext={handleNext}
      //       addOnsData={addOns[2]}
      //       handleAddMyOrder={() => props.handleAddMyOrder(addOns[2])}
      //       handleRemoveMyOrder={() => props.handleRemoveMyOrder(addOns[2])}
      //       ordersAdded={props.ordersAdded}
      //       setOrdersAdded={props.setOrdersAdded}
      //     />
      //   );
      case 5:
        return (
          <FeatureListing
            handleNext={handleNext}
            addOnsData={addOns[2]}
            handleAddMyOrder={() => props.handleAddMyOrder(addOns[2])}
            handleRemoveMyOrder={() => props.handleRemoveMyOrder(addOns[2])}
            ordersAdded={props.ordersAdded}
            setOrdersAdded={props.setOrdersAdded}
          />
        );
      default:
        return <ThankYou />;
    }
  };

  const handleNext = () => {
    //dispatch(setPageNo(pageNo + 1));
    dispatch(setPageNo(pageNo + 1));
    setAddOnsPageNo(addOnsPageNo + 1);
  };

  return pageNo !== 6 ? (
    <div>
      <div className="row dtl__header">
        <div className="col-md-12">
          <div className="dtl__headercontent">
            <div className="dtl__logo">
              <img
                src={back__arrow}
                onClick={() => {
                  // pageNo == 10
                  //   ? props.setPageNo(props.pageNo > 1 ? props.pageNo - 1 : 0)
                  //   :
                  dispatch(setPageNo(pageNo > 1 ? pageNo - 1 : 0));
                  setAddOnsPageNo(addOnsPageNo - 1);
                }}
              />
              <h3 class="navbar-header-title">REDHANDED</h3>
            </div>

            {pageNo !== 7 ? (
              <div className="dtl__next">
                <p>
                  Add on Offer<span>{addOnsPageNo} of 3</span>
                </p>
                <button
                  class="btn btn-primary pop-up-property-detaile-btn cursor btn-red-color"
                  onClick={handleNext}
                >
                  NEXT
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div>
        <div className="multistep__blocks">
          <Steps
            progressDot
            current={pageNo<=5?1:2}
            items={[
              {
                title: "Order Submitted",
              },
              {
                title: "Special Offer",
              },
              {
                title: "Order Recipt",
              },
            ]}
          />
          
        </div>
        {MyOrderComponent()}
      </div>
    </div>
  ) : (
    <SignUp
      pageNo={pageNo}
      setPageNo={setPageNo}
      addOnsPageNo={addOnsPageNo}
      setAddOnsPageNo={setAddOnsPageNo}
      handleSubmit={props.handleSubmit}
      handleOnChange={props.handleOnChange}
      plan={props.plan}
      addOnsData={props.addOnsData}
      userDetails={props.userDetails}
    />
  );

  // <div>
  //   <h1>REDHANDED</h1>
  //   <ProfessionalPhotos />
  // </div>
};

export default MyOrder;
