import React, { useState, useEffect, useRef } from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { useForm } from "react-hook-form";
import { Link, useHistory } from 'react-router-dom';
import { BsFillEnvelopeFill, BsFillLockFill } from "react-icons/bs";
import { useCookies } from 'react-cookie';
import { login, RegistrationNewUser, SignUpSocialUser } from "../../redux/actions";
import { checkEmail, socialLinkedinUser } from "../../redux/api"
import { BiCurrentLocation } from "react-icons/bi"
import { useDispatch, useSelector } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { ErrorMessage, FieldError, Loader } from "../../assets";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa"
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import {
    PATH,
    FB_APP_ID,
    GOOGLE_CLIENT_ID,
    LINKEDIN_CLIENT_ID,
    LINKED_IN_REDIRECT_URL,
    SOCIAL_ACCOUNTS_LOGIN,
    APPLE_CLIENT_ID,
    APPLE_REDIRECT_URL
} from "../../config";
import AppleLogin from 'react-apple-login'
import {
    FormControl,
    FormGroup,
    Button,
    Modal
} from "react-bootstrap";
import {
    userLoginViaSocial,
    getAccessToken
} from "../../redux/api";
import { getToken } from "../../firebaseInit.js";
import { REGISTRATION_V2_REQUEST, request, success } from "../../redux/actions/utilities";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { parseJwt } from "../genericAction";

var iOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false;

export function OpenAppModal(props) {
    const [isScrolable, setIsScrolable] = useState(false);
    const [text, setText] = useState(props.text);
    
    const getAppFromStore = () => {    
        /// is device iOS or Android
        if (iOS) {
          /// iOS device
          if (window.innerWidth < 1025) {        
            console.log("iOS device");
            window.location.href = "https://apps.apple.com/us/app/redhanded-co/id1563207338?uo=4";
          }
        } else {
          /// Android device
          if (window.innerWidth < 1025) {             
          console.log("Android device");
          window.location.href = "https://play.google.com/store/apps/details?id=com.redhanded.app";            
          }
        }
    }
    return (
        <div id={"modal-backdrop-openApp"}>
            <Modal
                id="openApp-modal"
                className="openApp-modal-sp"
                centered="true"
                show={props.showOpenAppModal}
                backdrop={true}
                scrollable={isScrolable}
                backdropClassName={"backdropClassNameOpenApp"}
                onHide={() => props.setShowOpenAppModal(false)}
            >

                <Modal.Header className="no-border no-content-header" closeButton>
                    {""}
                </Modal.Header>
                <Modal.Body>
                    <div className="sticky-notification-appStore">
                        <div className="row">
                            <div className="col-8">
                                <div className="notification-logo-section">
                                    <div className="openApp-logo">
                                        <h6>REDHANDED</h6>
                                    </div>
                                    <div className="app-name-desc">
                                        <p className="app-name-heading">REDHANDED</p>
                                        <span>Open in the REDHANDED app</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 text-right">
                                < Button className="app-store-button" onClick={getAppFromStore}>
                                    {"OPEN"}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="main-wrapper-appStore mt-2">
                        <div>
                            <p>To enjoy a better mobile experience and real-time notifications, please download our mobile app from the app store.</p>
                        </div>
                        <div className="company-main-title">
                            <h4>
                                <strong>REDHANDED</strong>
                            </h4>
                        </div>
                        <div className="main-desc">
                            <p>Sign up to post or find your ideal commercial space.</p>
                        </div>
                        <div className="btn-section">
                            < Button className="btn-get-app" onClick={getAppFromStore}>
                                {"Get the REDHANDED app"}
                            </Button>
                        </div>
                        <div className="link-section mt-1">
                            <p>
                                <button name="login"
                                    onClick={() => {
                                        props.setShowOpenAppModal(false);
                                        props.setShow(true);
                                        props.setText("Log In");
                                    }}
                                    // onClick={() => { props.setShow(true); props.setText("Log In") }} 
                                    type="button" >
                                    {"Log In"}
                                </button>
                                <span>or</span>
                                <button
                                    name="login"
                                    type="button"
                                    onClick={() => {
                                        props.setShowOpenAppModal(false);
                                        props.setShow(true);
                                        props.setText("Sign Up");
                                    }}
                                >
                                    {"Sign Up"}
                                </button>
                                {/* <Link>Log In</Link> or <Link>Sign Up</Link> */}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* <LoginSignUpModal
                show={show}
                setShow={setShow}
                view={0}
                text={text}
            /> */}
        </div>
    )
}