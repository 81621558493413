import { RULESETS_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  photosRuleSets: [],
  spacesRequirment: [],
};

export const photoRuleSetsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RULESETS_REQUEST.GET_PHOTOS_RULE_SETS_SUCCESS:
      return {
        ...state,
        photosRuleSets: action.payload,
      };
    case RULESETS_REQUEST.GET_SPACES_REQUIREMTN_SUCCESS:
      return {
        ...state,
        spacesRequirment: action.payload,
      };
    default:
      return state;
  }
};
