import axios from "axios";
import { getCountryTimeZone } from "../../assets";
import { APP_SETTINGS } from "../../config";
export function deletePropertyById(id, auth) {
    return axios.delete(`${APP_SETTINGS.API_PATH.PROPERTYDELETE.deleteProperty}/${id}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function userLogout(token, auth) {
    return axios.post(APP_SETTINGS.API_PATH.LOGIN.logout, { deviceToken: token }, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function userLogin(userData) {
    userData = { ...userData, "timezone": getCountryTimeZone() }
    return axios.post(APP_SETTINGS.API_PATH.LOGIN.login, userData)
}
export function roleUser(userData) {
    return axios.post(APP_SETTINGS.API_PATH.RegisterV2.roleUser, userData)
}
export function socialUser(userData) {
    userData = { ...userData, "timezone": getCountryTimeZone() }
    return axios.post(APP_SETTINGS.API_PATH.RegisterV2.socialUser, userData)
}
export function socialLinkedinUser(accessToken) {
    return axios.post(`${APP_SETTINGS.API_PATH.RegisterV2.linkedInUser}`, accessToken)
}
export function userSignUp(data) {
    data = { ...data, "timezone": getCountryTimeZone() }
    return axios.post(APP_SETTINGS.API_PATH.RegisterV2.userSignUp, data,)
}
export function checkEmail(email) {
    return axios.get(`${APP_SETTINGS.API_PATH.LOGIN.checkEmail}/${email}`)
}
export function userLoginViaSocial(userData) {
    userData = { ...userData, "timezone": getCountryTimeZone(), "plateform": "web" }
    return axios.post(APP_SETTINGS.API_PATH.LOGIN.loginBySocial, userData)
}
export function updateTenantProfile(data, auth) {
    return axios.put(APP_SETTINGS.API_PATH.UPDATE_TENANT.updateTenantProfile, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function updateLandlordProfile(data, auth) {
    return axios.put(APP_SETTINGS.API_PATH.UPDATE_LANDLORD.updateLandlordProfile, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function registerTenant(tenantData) {
    tenantData = { ...tenantData, "timezone": getCountryTimeZone() }
    return axios.post(APP_SETTINGS.API_PATH.REGISTER.registerTenant, tenantData)
}
export function registerLandlord(landlordData) {
    landlordData = { ...landlordData, "timezone": getCountryTimeZone() }
    return axios.post(APP_SETTINGS.API_PATH.REGISTER.registerLandlord, landlordData)
}
export function addSubscription(subscriptionData) {
    return axios.post(APP_SETTINGS.API_PATH.SUBSCRIPTION.addSubscription, subscriptionData)
}
export function resetPassword(resetData) {
    return axios.put(APP_SETTINGS.API_PATH.RESETPASSWORD.resetPassword, resetData);
}
export function changePassword(resetData, auth) {
    return axios.put(APP_SETTINGS.API_PATH.RESETPASSWORD.changePassword, resetData, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    });
}
export function enterEmail(resetData) {
    return axios.get(APP_SETTINGS.API_PATH.RESETPASSWORD.getEmail.replace(":email", resetData));
}
export function enterCode(code, email) {
    return axios.get(APP_SETTINGS.API_PATH.RESETPASSWORD.enterCode.replace(":code", code).replace(":email", email));
}
export function registerLandlordSocial(landlordData) {
    landlordData = { ...landlordData, "timezone": getCountryTimeZone() }
    return axios.post(APP_SETTINGS.API_PATH.REGISTER.registerLandlordSocial, landlordData)
}
export function registerTenantSocial(tenantData) {
    tenantData = { ...tenantData, "timezone": getCountryTimeZone() }
    return axios.post(APP_SETTINGS.API_PATH.REGISTER.registerTenantSocial, tenantData)
}
export function updatePhoto(photo, auth) {
    return axios.put(`${APP_SETTINGS.API_PATH.UPDATE_PHOTO.updatePhoto}`, photo, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function registerLandlordByLinkedIn(landlordData) {
    landlordData = { ...landlordData, "timezone": getCountryTimeZone() }
    return axios.post(APP_SETTINGS.API_PATH.REGISTER.registerLandlordByLinkedIn, landlordData)
}
export function registerTenantByLinkedIn(tenantData) {
    tenantData = { ...tenantData, "timezone": getCountryTimeZone() }
    return axios.post(APP_SETTINGS.API_PATH.REGISTER.registerTenantByLinkedIn, tenantData)
}
export function verifyLogin(verifyData) {
    return axios.post(APP_SETTINGS.API_PATH.LOGIN.verifyEmail, verifyData)
}
export function sendSocialMediaEmail(propertyId, auth) {
    return axios.put(`${APP_SETTINGS.API_PATH.SOCIAL_MEDIA_MARKETING.sendSocialMediaEmail}/${propertyId}`, { propertyId: propertyId }, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function updateLandlordProfileByAdmin(data, auth) {
    return axios.put(`${APP_SETTINGS.API_PATH.ADMIN_SIDE.updateLandlord}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function updateProfileImageByAdmin(data, auth) {
    return axios.put(`${APP_SETTINGS.API_PATH.ADMIN_SIDE.updateProfile}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function getLandlordSubscription(userId, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.ADMIN_SIDE.getLandlordSubscription}?userId=${userId}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function cancelLandlordSubscription(userId, auth) {
    return axios.delete(`${APP_SETTINGS.API_PATH.ADMIN_SIDE.cancelLandlordSubscription}?userId=${userId}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function getLandlordCard(userId, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.ADMIN_SIDE.getLandlordCard}?userId=${userId}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function addLandlordCard(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.ADMIN_SIDE.addLandlordCard}`, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function updateLandlordSubscription(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.ADMIN_SIDE.updateSubscription, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}