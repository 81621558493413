import ProfileAvatar from "./profile-avatar.jpg";
import HandShake from "./handShake.svg";
import HandShake1 from "./handShake1.svg";
import Negotiate from "./negotiate.svg";
import TaskBoard from "./taskBoard.svg";
import UserSwap from "./userSwap.svg";
import Hover from "./hover.png";
import Normal from "./normal.png";
import AppLogo from "./logo.png";
import Assmall from "./as1x.png";
import BarStool from "./barstool.png";
import CityHarvest from "./CityHarvest.png";
import Close from "./close.svg";
import Commas from "./Commas.svg";
import Connect from "./connect.svg";
import DonateNow from "./Donate-building.png";
import Gpsmall from "./gp1x.png";
import House1 from "./1.jpeg";
import House2 from "./1.jpg";
import House3 from "./2.jpg";
import House4 from "./3.jpg";
import House5 from "./4.jpg";
import List from "./List.svg";
import MealOnWheels from "./mealonwheels.png";
import Negotitate from "./negotitate.svg";
import OurMission from "./our-mission.png";
import Quotes from "./quote.svg";
import RedHands from "./redhands.svg";
import SquareIcon from "./square-icon.svg";
import Thumbnail from "./thumbnail.jpg";
import Jennifer from "./Jenifer.png";
import Chris from "./Chris.png";
import William from "./willian.png";
import Frank from "./franku.png";
import RentSpree from "./rentSpree.png";
import RentSpreeLogo from "./rentSpreeLogo.png";
import arrowFrame from "./Frame.png";
import noImage from "./noImage.png";
import SocialMediaTwo from "./social-media.jpg";
import NavbarLogo from "./Logo.jpg";
import Rent from "./rent.jpg";
import SliderText from "./sliderText.png";
import card2Img from "./card2img.jpg";
import SocialOne from "./social-1.jpg";
import SocialTwo from "./social-2.jpg";
import SocialThree from "./social-3.jpg";
import SocialMediaOne from "./social-media-1.jpg";
import Sec3Img1 from "./sec3-img1.svg"
import Sec3Img2 from "./sec3-img2.svg"
import Sec3Img3 from "./sec3-img3.svg"
import Sec3Img4 from "./sec3-img4.svg"


export const IMAGES = {
  SOCIALMEDIAONE: SocialMediaOne,
  SEC3IMG1: Sec3Img1,
  SEC3IMG2: Sec3Img2,
  SEC3IMG3: Sec3Img3,
  SEC3IMG4: Sec3Img4,
  SOCIALTHREE: SocialThree,
  SOCIALTWO: SocialTwo,
  SOCIALONE: SocialOne,
  CARD2IMG: card2Img,
  SLIDERTEXT: SliderText,
  RENT: Rent,
  NAVBARLOGO: NavbarLogo,
  SOCIALMEDIATWO: SocialMediaTwo,
  NOIMAGE: noImage,
  ARROWFRAME: arrowFrame,
  THUMBNAIL: Thumbnail,
  AVATAR: ProfileAvatar,
  HANDSHAKE: HandShake,
  HANDSHAKE1: HandShake1,
  NEGOTIATE: Negotiate,
  TASKBOARD: TaskBoard,
  USERSWAP: UserSwap,
  COMMAS: Commas,
  HOUSE1: House1,
  HOUSE2: House2,
  HOUSE3: House3,
  HOUSE4: House4,
  HOUSE5: House5,
  HOVER: Hover,
  NORMAL: Normal,
  APPLOGO: AppLogo,
  GOOGLEPLAY: Gpsmall,
  APPLESTORE: Assmall,
  DONATENOW: DonateNow,
  OURMISSION: OurMission,
  CITYHARVEST: CityHarvest,
  MEALONWHEELS: MealOnWheels,
  BARSTOOL: BarStool,
  REDHANDS: RedHands,
  CLOSE: Close,
  NEGOTITATE: Negotitate,
  CONNECT: Connect,
  LIST: List,
  SQUAREICON: SquareIcon,
  CHRIS: Chris,
  WILLIAM: William,
  FRANK: Frank,
  JENNIFER: Jennifer,
  QUOTES: Quotes,
  RENTSPREE: RentSpree,
  RENTSPREELOGO: RentSpreeLogo,
};
