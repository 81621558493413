import { REPORTS_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  propertyReportLoading: false,
  propertyReportSuccess: false,
  propertyReportFailure: false,
  propertyReportError: null,
  propertyReport: [],
  subscriptionReportLoading: false,
  subscriptionReportSuccess: false,
  subscriptionReportFailure: false,
  subscriptionReportError: null,
  subscriptionReport: [],
  userReportLoading: false,
  userReportSuccess: false,
  userReportFailure: false,
  userReportError: null,
  userReport: [],

  mediaListLoading: false,
  mediaListSuccess: false,
  mediaListFailure: false,
  mediaListError: null,
  mediaList: [],

  subByMonthLoading: false,
  subByMonthSuccess: false,
  subByMonthFailure: false,
  subByMonthError: null,
  subByMonth: [],

  subByStateLoading: false,
  subByStateSuccess: false,
  subByStateFailure: false,
  subByStateError: null,
  subByState: [],

  subByCountLoading: false,
  subByCountSuccess: false,
  subByCountFailure: false,
  subByCountError: null,
  subByCount: [],

  userByCountLoading: false,
  userByCountSuccess: false,
  userByCountFailure: false,
  userByCountError: null,
  userByCount: [],

  userByMonthLoading: false,
  userByMonthSuccess: false,
  userByMonthFailure: false,
  userByMonthError: null,
  userByMonth: [],

  allUserLoading: false,
  allUserSuccess: false,
  allUserFailure: false,
  allUserError: null,
  allUser: [],

  allReportedUserLoading: false,
  allReportedUserSuccess: false,
  allReportedUserFailure: false,
  allReportedUserError: null,
  allReportedUser: [],

  allReportedSpaceLoading: false,
  allReportedSpaceSuccess: false,
  allReportedSpaceFailure: false,
  allReportedSpaceError: null,
  allReportedSpace: [],

  getLogsLoading: false,
  getLogsSuccess: false,
  getLogsFailure: false,
  getLoagsError: null,
  getLogs: [],

  addLogsLoading: false,
  addLogsSuccess: false,
  addLogsFailure: false,
  addLoagsError: null,
  addLogs: [],

  weeklyReportLoading: false,
  weeklyReportSuccess: false,
  weeklyReportFailure: false,
  weeklyReportError: null,
  weeklyReport: [],

  totalCountReportLoading: false,
  totalCountReportSuccess: false,
  totalCountReportFailure: false,
  totalCountReportError: null,
  totalCountReport: [],

  top20LandlordsReportLoading: false,
  top20LandlordsReportSuccess: false,
  top20LandlordsReportFailure: false,
  top20LandlordsReportError: null,
  top20LandlordsReport: [],

  top20LandlordsReportLoading: false,
  top20LandlordsReportSuccess: false,
  top20LandlordsReportFailure: false,
  top20LandlordsReportError: null,
  top20LandlordsReport: [],

  top20TenantsReportLoading: false,
  top20TenantsReportSuccess: false,
  top20TenantsReportFailure: false,
  top20TenantsReportError: null,
  top20TenantsReport: [],

  totalListingPerStateReportLoading: false,
  totalListingPerStateReportSuccess: false,
  totalListingPerStateReportFailure: false,
  totalListingPerStateReportError: null,
  totalListingPerStateReport: [],

  totalListingPerCategoryReportLoading: false,
  totalListingPerCategoryReportSuccess: false,
  totalListingPerCategoryReportFailure: false,
  totalListingPerCategoryReportError: null,
  totalListingPerCategoryReport: [],

  updateWeeklyReportLoading: false,
  updateWeeklyReportSuccess: true,
  updateWeeklyReportFailure: false,
  updateWeeklyReportError: null,
  updateWeeklyReport: null
};

export const reportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REPORTS_REQUEST.ADD_LOGS_REQUEST:
      return {
        ...state,
        addLogsLoading: true,
        addLogsSuccess: false,
        addLogsFailure: false,
        addLoagsError: null,
        addLogs: [],
      };
    case REPORTS_REQUEST.ADD_LOGS_SUCCESS:
      let allLogs = state.getLogs;
      for (let i = 0; i < allLogs.length; i++) {
        if (allLogs[i].logId === action.payload.logId) {
          allLogs[i].notes = action.payload.notes;
        }
      }
      return {
        ...state,
        addLogsLoading: false,
        addLogsSuccess: true,
        addLogsFailure: false,
        addLoagsError: null,
        addLogs: allLogs,
      };
    case REPORTS_REQUEST.ADD_LOGS_FAILURE:
      return {
        ...state,
        addLogsLoading: false,
        addLogsSuccess: false,
        addLogsFailure: true,
        addLoagsError: action.payload,
        addLogs: [],
      };
    case REPORTS_REQUEST.GET_ALL_LOGS_REQUEST:
      return {
        ...state,
        getLogsLoading: true,
        getLogsSuccess: false,
        getLogsFailure: false,
        getLoagsError: null,
        getLogs: [],
      };
    case REPORTS_REQUEST.GET_ALL_LOGS_SUCCESS:
      return {
        ...state,
        getLogsLoading: false,
        getLogsSuccess: true,
        getLogsFailure: false,
        getLoagsError: null,
        getLogs: action.payload,
      };
    case REPORTS_REQUEST.GET_ALL_LOGS_FAILURE:
      return {
        ...state,
        getLogsLoading: false,
        getLogsSuccess: false,
        getLogsFailure: true,
        getLoagsError: action.payload,
        getLogs: [],
      };
    case REPORTS_REQUEST.GET_ALL_USER_REQUEST:
      return {
        ...state,
        allUserLoading: true,
        allUserSuccess: false,
        allUserFailure: false,
        allUserError: null,
        allUser: [],
      };
    case REPORTS_REQUEST.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        allUserLoading: false,
        allUserSuccess: true,
        allUserFailure: false,
        allUserError: null,
        allUser: action.payload,
      };
    case REPORTS_REQUEST.GET_ALL_USER_FAILURE:
      return {
        ...state,
        allUserLoading: false,
        allUserSuccess: false,
        allUserFailure: true,
        allUserError: action.payload,
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_BY_MONTH_REQUEST:
      return {
        ...state,
        subByMonthLoading: true,
        subByMonthSuccess: false,
        subByMonthFailure: false,
        subByMonthError: null,
        subByMonth: [],
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_BY_MONTH_SUCCESS:
      return {
        ...state,
        subByMonthLoading: false,
        subByMonthSuccess: true,
        subByMonthFailure: false,
        subByMonthError: null,
        subByMonth: action.payload,
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_BY_MONTH_FAILURE:
      return {
        ...state,
        subByMonthLoading: false,
        subByMonthSuccess: false,
        subByMonthFailure: true,
        subByMonthError: action.payload,
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_BY_STATE_REQUEST:
      return {
        ...state,
        subByStateLoading: true,
        subByStateSuccess: false,
        subByStateFailure: false,
        subByStateError: null,
        subByState: [],
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_BY_STATE_SUCCESS:
      return {
        ...state,
        subByStateLoading: false,
        subByStateSuccess: true,
        subByStateFailure: false,
        subByStateError: null,
        subByState: action.payload,
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_BY_STATE_FAILURE:
      return {
        ...state,
        subByStateLoading: false,
        subByStateSuccess: false,
        subByStateFailure: true,
        subByStateError: action.payload,
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_BY_COUNT_REQUEST:
      return {
        ...state,
        subByCountLoading: true,
        subByCountSuccess: false,
        subByCountFailure: false,
        subByCountError: null,
        subByCount: [],
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_BY_COUNT_SUCCESS:
      return {
        ...state,
        subByCountLoading: false,
        subByCountSuccess: true,
        subByCountFailure: false,
        subByCountError: null,
        subByCount: action.payload,
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_BY_COUNT_FAILURE:
      return {
        ...state,
        subByCountLoading: false,
        subByCountSuccess: false,
        subByCountFailure: true,
        subByCountError: action.payload,
      };
    case REPORTS_REQUEST.GET_USER_BY_COUNT_REQUEST:
      return {
        ...state,
        userByCountLoading: true,
        userByCountSuccess: false,
        userByCountFailure: false,
        userByCountError: null,
        userByCount: [],
      };
    case REPORTS_REQUEST.GET_USER_BY_COUNT_SUCCESS:
      return {
        ...state,
        userByCountLoading: false,
        userByCountSuccess: true,
        userByCountFailure: false,
        userByCountError: null,
        userByCount: action.payload,
      };
    case REPORTS_REQUEST.GET_USER_BY_COUNT_FAILURE:
      return {
        ...state,
        userByCountLoading: false,
        userByCountSuccess: false,
        userByCountFailure: true,
        userByCountError: action.payload,
      };
    case REPORTS_REQUEST.GET_USER_BY_MONTH_REQUEST:
      return {
        ...state,
        userByMonthLoading: true,
        userByMonthSuccess: false,
        userByMonthFailure: false,
        userByMonthError: null,
        userByMonth: [],
      };
    case REPORTS_REQUEST.GET_USER_BY_MONTH_SUCCESS:
      return {
        ...state,
        userByMonthLoading: false,
        userByMonthSuccess: true,
        userByMonthFailure: false,
        userByMonthError: null,
        userByMonth: action.payload,
      };
    case REPORTS_REQUEST.GET_USER_BY_MONTH_FAILURE:
      return {
        ...state,
        userByMonthLoading: false,
        userByMonthSuccess: false,
        userByMonthFailure: true,
        userByMonthError: action.payload,
      };
    case REPORTS_REQUEST.GET_PROPERTY_REPORT_REQUEST:
      return {
        ...state,
        propertyReportLoading: true,
        propertyReportSuccess: false,
        propertyReportFailure: false,
        propertyReportError: null,
      };
    case REPORTS_REQUEST.GET_PROPERTY_REPORT_SUCCESS:
      return {
        ...state,
        propertyReportLoading: false,
        propertyReportSuccess: true,
        propertyReportFailure: false,
        propertyReportError: null,
        propertyReport: action.payload,
      };
    case REPORTS_REQUEST.GET_PROPERTY_REPORT_FAILURE:
      return {
        ...state,
        propertyReportLoading: false,
        propertyReportSuccess: false,
        propertyReportFailure: true,
        propertyReportError: action.payload,
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_REPORT_REQUEST:
      return {
        ...state,
        subscriptionReportLoading: true,
        subscriptionReportSuccess: false,
        subscriptionReportFailure: false,
        subscriptionReportError: null,
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_REPORT_SUCCESS:
      return {
        ...state,
        subscriptionReportLoading: false,
        subscriptionReportSuccess: true,
        subscriptionReportFailure: false,
        subscriptionReportError: null,
        subscriptionReport: action.payload,
      };
    case REPORTS_REQUEST.GET_SUBSCRIPTION_REPORT_FAILURE:
      return {
        ...state,
        subscriptionReportLoading: false,
        subscriptionReportSuccess: false,
        subscriptionReportFailure: true,
        subscriptionReportError: action.payload,
      };
    case REPORTS_REQUEST.GET_USER_REPORT_REQUEST:
      return {
        ...state,
        userReportLoading: true,
        userReportReportSuccess: false,
        userReportReportFailure: false,
        userReportError: null,
      };
    case REPORTS_REQUEST.GET_USER_REPORT_SUCCESS:
      return {
        ...state,
        userReportLoading: false,
        userReportSuccess: true,
        userReportFailure: false,
        userReportError: null,
        userReport: action.payload,
      };
    case REPORTS_REQUEST.GET_USER_REPORT_FAILURE:
      return {
        ...state,
        userReportLoading: false,
        userReportSuccess: false,
        userReportFailure: true,
        userReportError: action.payload,
      };
    case REPORTS_REQUEST.GET_MEDIA_LIST_REQUEST:
      return {
        ...state,
        mediaListLoading: true,
        mediaListSuccess: false,
        mediaListFailure: false,
        mediaListError: null,
      };
    case REPORTS_REQUEST.GET_MEDIA_LIST_SUCCESS:
      return {
        ...state,
        mediaListLoading: false,
        mediaListSuccess: true,
        mediaListFailure: false,
        mediaListError: null,
        mediaList: action.payload,
      };
    case REPORTS_REQUEST.GET_MEDIA_LIST_FAILURE:
      return {
        ...state,
        mediaListLoading: false,
        mediaListSuccess: false,
        mediaListFailure: true,
        mediaListError: action.payload,
      };
    case REPORTS_REQUEST.GET_REPORTED_SPACE_LIST_REQUEST:
      return {
        ...state,
        allReportedSpaceLoading: true,
        allReportedSpaceSuccess: false,
        allReportedSpaceFailure: false,
        allReportedSpaceError: null,
        allReportedSpace: [],
      };
    case REPORTS_REQUEST.GET_REPORTED_SPACE_LIST_SUCCESS:
      return {
        ...state,
        allReportedSpaceLoading: false,
        allReportedSpaceSuccess: true,
        allReportedSpaceFailure: false,
        allReportedSpaceError: null,
        allReportedSpace: action.payload,
      };
    case REPORTS_REQUEST.GET_REPORTED_SPACE_LIST_FAILURE:
      return {
        ...state,
        allReportedSpaceLoading: false,
        allReportedSpaceSuccess: false,
        allReportedSpaceFailure: true,
        allReportedSpaceError: action.payload,
        allReportedSpace: [],
      };
    case REPORTS_REQUEST.GET_REPORTED_USER_LIST_REQUEST:
      return {
        ...state,
        allReportedUserLoading: true,
        allReportedUserSuccess: false,
        allReportedUserFailure: false,
        allReportedUserError: null,
        allReportedUser: [],
      };
    case REPORTS_REQUEST.GET_REPORTED_USER_LIST_SUCCESS:
      return {
        ...state,
        allReportedUserLoading: false,
        allReportedUserSuccess: true,
        allReportedUserFailure: false,
        allReportedUserError: null,
        allReportedUser: action.payload,
      };
    case REPORTS_REQUEST.GET_REPORTED_USER_LIST_FAILURE:
      return {
        ...state,
        allReportedUserLoading: false,
        allReportedUserSuccess: false,
        allReportedUserFailure: true,
        allReportedUserError: action.payload,
        allReportedUser: [],
      };
    case REPORTS_REQUEST.GET_WEEKLY_REPORT_REQUEST:
      return {
        ...state,
        weeklyReportLoading: true,
        weeklyReportSuccess: false,
        weeklyReportFailure: false,
        weeklyReportError: null,
      };
    case REPORTS_REQUEST.GET_WEEKLY_REPORT_SUCCESS:
      return {
        ...state,
        weeklyReportLoading: false,
        weeklyReportSuccess: true,
        weeklyReportFailure: false,
        weeklyReportError: null,
        weeklyReport: action.payload,
      };
    case REPORTS_REQUEST.GET_WEEKLY_REPORT_FAILURE:
      return {
        ...state,
        weeklyReportLoading: false,
        weeklyReportSuccess: false,
        weeklyReportFailure: true,
        weeklyReportError: action.payload,
      };
    case REPORTS_REQUEST.GET_TOTAL_COUNT_REPORT_REQUEST:
      return {
        ...state,
        totalCountReportLoading: true,
        totalCountReportSuccess: false,
        totalCountReportFailure: false,
        totalCountReportError: null,
      };
    case REPORTS_REQUEST.GET_TOTAL_COUNT_REPORT_SUCCESS:
      return {
        ...state,
        totalCountReportLoading: false,
        totalCountReportSuccess: true,
        totalCountReportFailure: false,
        totalCountReportError: null,
        totalCountReport: action.payload,
      };
    case REPORTS_REQUEST.GET_TOTAL_COUNT_REPORT_FAILURE:
      return {
        ...state,
        totalCountReportLoading: false,
        totalCountReportSuccess: false,
        totalCountReportFailure: true,
        totalCountReportError: action.payload,
      };
    case REPORTS_REQUEST.GET_TOP_20_LANDLORDS_REPORT_REQUEST:
      return {
        ...state,
        top20LandlordsReportLoading: true,
        top20LandlordsReportSuccess: false,
        top20LandlordsReportFailure: false,
        top20LandlordsReportError: null,
      };
    case REPORTS_REQUEST.GET_TOP_20_LANDLORDS_REPORT_SUCCESS:
      return {
        ...state,
        top20LandlordsReportLoading: false,
        top20LandlordsReportSuccess: true,
        top20LandlordsReportFailure: false,
        top20LandlordsReportError: null,
        top20LandlordsReport: action.payload,
      };
    case REPORTS_REQUEST.GET_TOP_20_LANDLORDS_REPORT_FAILURE:
      return {
        ...state,
        top20LandlordsReportLoading: false,
        top20LandlordsReportSuccess: false,
        top20LandlordsReportFailure: true,
        top20LandlordsReportError: action.payload,
      };
    case REPORTS_REQUEST.GET_TOP_20_TENANTS_REPORT_REQUEST:
      return {
        ...state,
        top20TenantsReportLoading: true,
        top20TenantsReportSuccess: false,
        top20TenantsReportFailure: false,
        top20TenantsReportError: null,
      };
    case REPORTS_REQUEST.GET_TOP_20_TENANTS_REPORT_SUCCESS:
      return {
        ...state,
        top20TenantsReportLoading: false,
        top20TenantsReportSuccess: true,
        top20TenantsReportFailure: false,
        top20TenantsReportError: null,
        top20TenantsReport: action.payload,
      };
    case REPORTS_REQUEST.GET_TOP_20_TENANTS_REPORT_FAILURE:
      return {
        ...state,
        top20TenantsReportLoading: false,
        top20TenantsReportSuccess: false,
        top20TenantsReportFailure: true,
        top20TenantsReportError: action.payload,
      };
    case REPORTS_REQUEST.GET_TOTAL_LISTING_PER_STATE_REPORT_REQUEST:
      return {
        ...state,
        totalListingPerStateReportLoading: true,
        totalListingPerStateReportSuccess: false,
        totalListingPerStateReportFailure: false,
        totalListingPerStateReportError: null,
      };
    case REPORTS_REQUEST.GET_TOTAL_LISTING_PER_STATE_REPORT_SUCCESS:
      return {
        ...state,
        totalListingPerStateReportLoading: false,
        totalListingPerStateReportSuccess: true,
        totalListingPerStateReportFailure: false,
        totalListingPerStateReportError: null,
        totalListingPerStateReport: action.payload,
      };
    case REPORTS_REQUEST.GET_TOTAL_LISTING_PER_STATE_REPORT_FAILURE:
      return {
        ...state,
        totalListingPerStateReportLoading: false,
        totalListingPerStateReportSuccess: false,
        totalListingPerStateReportFailure: true,
        totalListingPerStateReportError: action.payload,
      };
    case REPORTS_REQUEST.GET_TOTAL_LISTING_PER_CATEGORY_REPORT_REQUEST:
      return {
        ...state,
        totalListingPerCategoryReportLoading: true,
        totalListingPerCategoryReportSuccess: false,
        totalListingPerCategoryReportFailure: false,
        totalListingPerCategoryReportError: null,
      };
    case REPORTS_REQUEST.GET_TOTAL_LISTING_PER_CATEGORY_REPORT_SUCCESS:
      return {
        ...state,
        totalListingPerCategoryReportLoading: false,
        totalListingPerCategoryReportSuccess: true,
        totalListingPerCategoryReportFailure: false,
        totalListingPerCategoryReportError: null,
        totalListingPerCategoryReport: action.payload,
      };
    case REPORTS_REQUEST.GET_TOTAL_LISTING_PER_CATEGORY_REPORT_FAILURE:
      return {
        ...state,
        totalListingPerCategoryReportLoading: false,
        totalListingPerCategoryReportSuccess: false,
        totalListingPerCategoryReportFailure: true,
        totalListingPerCategoryReportError: action.payload,
      };
    case REPORTS_REQUEST.UPDATE_WEEKLY_REPORT_REQUEST:
      return {
        ...state,
        updateWeeklyReportLoading: true,
        updateWeeklyReportSuccess: false,
        updateWeeklyReportFailure: false,
        updateWeeklyReportError: null,
      };
    case REPORTS_REQUEST.UPDATE_WEEKLY_REPORT_SUCCESS:
      return {
        ...state,
        updateWeeklyReportLoading: false,
        updateWeeklyReportSuccess: true,
        updateWeeklyReportFailure: false,
        updateWeeklyReportError: null,
        updateWeeklyReport: action.payload,
      };
    case REPORTS_REQUEST.UPDATE_WEEKLY_REPORT_FAILURE:
      return {
        ...state,
        updateWeeklyReportLoading: false,
        updateWeeklyReportSuccess: false,
        updateWeeklyReportFailure: true,
        updateWeeklyReportError: action.payload,
      };
      default:
      return state;
  }
};
