import axios from "axios";
import { APP_SETTINGS } from "../../config";

export function addSpaceDescription(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.POST_PROPERTY.SPACE_DESCRIPTION.addSpaceDescription, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    });
}
export function getSpaceDescriptionList(auth){
    return axios.get(APP_SETTINGS.API_PATH.POST_PROPERTY.SPACE_DESCRIPTION.getSpaceDescriptionList, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function addUpdatespacedescriptionbyadmin(data, auth){
    return axios.post(APP_SETTINGS.API_PATH.POST_PROPERTY.SPACE_DESCRIPTION.addUpdatespacedescriptionbyadmin, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function addUpdatedSpaceDescription(data, auth){
    return axios.post(APP_SETTINGS.API_PATH.POST_PROPERTY.SPACE_DESCRIPTION.addUpdatedSpaceDescription, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function getDescriptionListForLandlord(id,auth){
    let propId = `PropertyId=${id}`
    return axios.get(`${APP_SETTINGS.API_PATH.POST_PROPERTY.SPACE_DESCRIPTION.getDescriptionListForLandlord}?${propId}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}