import React, { useState, useEffect } from "react";
const Instructions = () => {

    return (
        <div className="steps__block">
            {/* <div className="main-heading-blue">
                <p>Why Brokers will fail you?</p>
            </div> */}
            <div>
                {/* <div className="landlord-quiz-checkbox">
                    <ul>
                        <li><i class="fa fa-times-circle" aria-hidden="true"></i> <span>High Commission Fees</span></li>
                        <li><i class="fa fa-times-circle" aria-hidden="true"></i> <span>They will only show you tenants they choose to show you</span></li>
                        <li><i class="fa fa-times-circle" aria-hidden="true"></i> <span>They are only human so you will wait in the queue while they are servicing other clients</span></li>
                        <li><i class="fa fa-times-circle" aria-hidden="true"></i> <span>Incented by commissions, they can get in the way of good deal making on your behalf</span></li>
                    </ul>
                </div> */}
                <div className="main-heading-blue">
                    <p>Why <span className="navbar-header-title">REDHANDED</span> assistance works</p>
                </div>
                <div className="landlord-quiz-checkbox">
                    <ul>
                        <li><i class="fa fa-check-circle" aria-hidden="true"></i><span>We use Artificial Intelligence to automate the tenant search process</span></li>
                        <li><i class="fa fa-check-circle" aria-hidden="true"></i><span>You are in full control of the leasing process and you can negotiate directly with qualified tenants</span></li>
                        <li><i class="fa fa-check-circle" aria-hidden="true"></i><span>We guide you through the process with helpful tools to market your space</span></li>
                        <li><i class="fa fa-check-circle" aria-hidden="true"></i><span>We are a fraction of the cost of brokers</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Instructions;