import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { useCookies } from 'react-cookie';
import Sidebar from "./SideBar";
import {
    Navbar,
    Dropdown,
    Button
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IMAGES } from "..";
import { PATH } from "../../config";
export function ParentComponentWithSideBar({ children, ...rest }) {
    return (
        <div className="page-height pb-1 admin-margin-left">
            {children}
        </div>
    )
}
export function ParentComponent({ children, ...rest }) {
    return (
        <>
            {children}
        </>
    )
}
export function NavBarAdmin() {
    const [show, setShow] = useState(false);
    const [cookies, remove] = useCookies(['real_estate_user']);
    console.log("cookies:::", cookies)
    return (
        <React.Fragment>
            <Navbar collapseOnSelect expand="lg" id="navbar-change" className=" admin-navbar-style">
                <Button name="menu-buttn" variant="link" bsPrefix="menu-btn" onClick={() => { setShow(!show) }} >
                    <GiHamburgerMenu name="menu-buttn" />
                </Button>
                <Dropdown id="nav-drop-down-profile" className="nav-admin-right-icon">
                    <Dropdown.Toggle
                        variant="none"
                        bsPrefix="nav-drop-down" id="dropdown-basic">
                        <FontAwesomeIcon size="3x" color={"#a80000"} icon={faUserCircle} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => { remove('real_estate_user', { path: "/" }) }}>
                            {"Sign Out"}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Navbar>
            <Sidebar
                show={show}
                setShow={setShow} />
        </React.Fragment>
    )
}
export function NavBarAdminWithoutSideBar() {
    const [cookies, remove] = useCookies(['real_estate_user']);
    console.log("cookies:::", cookies)
    return (
        <React.Fragment>
            <Navbar collapseOnSelect expand="lg" id="navbar-change" className=" admin-navbar-style without_sidebar mb-4">
                <Navbar.Brand href={PATH.DASHBOARD} className="redhanded-text-set mt-0 pl-0">
                    <div className="brand-logo">
                        <img alt="" className="img-fluid" src={IMAGES.APPLOGO} />
                    </div>
                    <h3>
                        {"REDHANDED"}
                    </h3>
                </Navbar.Brand>
                <Dropdown id="nav-drop-down-profile" className="nav-admin-right-icon">
                    <Dropdown.Toggle
                        variant="none"
                        bsPrefix="nav-drop-down" id="dropdown-basic">
                        <FontAwesomeIcon size="3x" color={"#a80000"} icon={faUserCircle} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => { remove('real_estate_user', { path: "/" }) }}>
                            {"Sign Out"}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Navbar>
        </React.Fragment>
    )
}