import { request, success, failure, RULESETS_REQUEST } from "./utilities";
import { getPhotosRuleSets, getSpacesByRequirementAPI } from "../api";

export function photosRuleSets(id) {
  return (dispatch) => {
    dispatch(request(RULESETS_REQUEST.GET_PHOTOS_RULE_SETS_REQUEST));
    getPhotosRuleSets(id).then(
      (response) => {
        debugger;
        if (response.data.propertyData) {
          dispatch(
            success(
              RULESETS_REQUEST.GET_PHOTOS_RULE_SETS_SUCCESS,
              response.data.propertyData
            )
          );
        } else {
          dispatch(
            failure(
              RULESETS_REQUEST.GET_PHOTOS_RULE_SETS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            RULESETS_REQUEST.GET_PHOTOS_RULE_SETS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function getSpacesByRequirement(intpuData) {
  return (dispatch) => {
    dispatch(request(RULESETS_REQUEST.GET_SPACES_REQUIREMTN_REQUEST));
    getSpacesByRequirementAPI(intpuData).then(
      (response) => {
        if (response.data.spaceRecords) {
          dispatch(
            success(
              RULESETS_REQUEST.GET_SPACES_REQUIREMTN_SUCCESS,
              response.data.spaceRecords
            )
          );
        } else {
          dispatch(
            failure(
              RULESETS_REQUEST.GET_SPACES_REQUIREMTN_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            RULESETS_REQUEST.GET_SPACES_REQUIREMTN_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
