import React from "react";
import {
    Form
} from "react-bootstrap";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

export class PlacesAutoCompleteBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            hover: false,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            mapCenter: {
                lat: 40.7127753,
                lng: -74.0059728
            }
        }
    };
    handleChange = address => {
        this.setState({ address });
    };
    handleSelect = address => {
        this.setState({ address });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.setState({ mapCenter: latLng });
            })
            .catch(error => console.error('Esrror', error));
    };

    render() {
        return (
            <PlacesAutocomplete
                searchOptions={{
                    componentRestrictions: { country: "us" }
                }}
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect} >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <React.Fragment>
                        <Form inline>
                            <input
                                style={{ borderColor: (this.props.errors && this.props.errors.primaryAddress) ? "#a80000" : "" }}
                                name="primaryAddress"
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input search-bar-nav form-control',
                                })} />
                        </Form>
                        <div className="autocomplete-dropdown-container">
                            {
                                loading
                                &&
                                <div>
                                    {"Loading..."}
                                </div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}>
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </React.Fragment>
                )}
            </PlacesAutocomplete>
        )
    }
}