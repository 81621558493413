import { NotificationManager } from "react-notifications";
import ProgressBar from "../../components/LandLordSignUp/CircularProgressBar";
import {
  getOptionsList,
  sendEmail,
  getPlansList,
  getAddonsList,
  registerUser,
  getOrderSummary,
  getCouponDetails,
  getCheckIsSubscribe,
  subscription,
  renewSubscription,
  getDuplicateCouponDetails,
} from "../api";
import {
  request,
  success,
  failure,
  REPORTS_REQUEST,
  LANDLORD_FLOW,
} from "./utilities";

export function getListOfOptions() {
  return (dispatch) => {
    getOptionsList().then((response) => {
      if (response.status === 200) {
        dispatch(success(LANDLORD_FLOW.GET_OPTIONS_LIST, response.data));
      }
    });
  };
}

export function sendEmailToLandlord(requestData) {
  return (dispatch) => {
    sendEmail(requestData).then(
      (response) => {
        // if (percentage == 100) {
        //if (response.data.message == "Success") {
        dispatch(success(LANDLORD_FLOW.SEND_EMAIL_RESPONSE, response.data));
        //NotificationManager.success("Email Sent Successfully");
        // } else {
        //   NotificationManager.success(response.data.message);
        // }
        // }
        // }
        // if (response.data.succeeded === true) {

        //     dispatch(success(BROWSE_LISTING.TENANT_CONNECTION_SUCCESS, response.data.data))
        //     if (moveToNext) {
        //         moveToNext();
        //     }
        // } else {
        //     dispatch(failure(BROWSE_LISTING.TENANT_CONNECTION_FAILURE, response.data.message))
        //     if (moveToNextFail) {
        //         moveToNextFail()
        //     }
        // }
      },
      (error) => {
        console.log(error);
        // dispatch(failure(BROWSE_LISTING.TENANT_CONNECTION_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
        // if (moveToNextFail) {
        //     moveToNextFail()
        // }
      }
    );
  };
}

export function getAllPlans(roleId) {
  return (dispatch) => {
    getPlansList(roleId).then((response) => {
      if (response.status === 200) {
        dispatch(success(LANDLORD_FLOW.GET_PLANS_LIST, response.data.data));
      }
    });
  };
}

export function getCheckIsSubscribed(roleId) {
  return (dispatch) => {
    getCheckIsSubscribe(roleId).then((response) => {
      if (response.status === 200) {
        dispatch(
          success(LANDLORD_FLOW.GET_SUBSCRIBE_UNSUBSCRIBE, response.data.data)
        );
      }
    });
  };
}

export function getAllAddonsList(planId) {
  return (dispatch) => {
    getAddonsList(planId).then((response) => {
      if (response.status === 200) {
        dispatch(success(LANDLORD_FLOW.GET_ADDONS_LIST, response.data.data));
      }
    });
  };
}

export function registerLandlordUser(requestData, pageNo, setCookiesforUser) {
  return (dispatch) => {
    dispatch(request(LANDLORD_FLOW.LANDLORD_SIGNUP_REQUEST));
    registerUser(requestData).then(
      (response) => {
        if (response.data.data) {
          NotificationManager.success(response.data.message);
          dispatch(
            success(LANDLORD_FLOW.LANDLORD_SIGNUP_SUCCESS, response.data.data)
          );
          dispatch(getOrderSummaryDetails(response.data.data.userId));
          dispatch(setPageNo(pageNo + 1));
          setCookiesforUser(response.data.data);
        } else {
          NotificationManager.error(response.data.message);
          dispatch(
            failure(
              LANDLORD_FLOW.LANDLORD_SIGNUP_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function getOrderSummaryDetails(userId) {
  return (dispatch) => {
    getOrderSummary(userId).then((response) => {
      if (response.status === 200) {
        dispatch(
          success(LANDLORD_FLOW.GET_ORDER_SUMMARY_DETAILS, response.data.data)
        );
      }
    });
  };
}

export function setPageNo(pageNo) {
  return (dispatch) => {
    dispatch(success(LANDLORD_FLOW.SET_PAGE_NO, pageNo));
  };
}

export function getAddonsCouponDetails(requestData) {
  return (dispatch) => {
    getCouponDetails(requestData).then((response) => {
      if (response.data.succeeded) {
        dispatch(
          success(LANDLORD_FLOW.GET_ADDONS_COUPON_DETAILS, response.data.data)
        );
      } else {
        dispatch(
          failure(LANDLORD_FLOW.SET_COUPON_VALIDATION, response.data.message)
        );
      }
    });
  };
}

export function SubsriptionForAnotherSpace(requestData, pageNo, setPageNo) {
  return (dispatch) => {
    dispatch(request(LANDLORD_FLOW.LANDLORD_SIGNUP_REQUEST));
    subscription(requestData).then(
      (response) => {
        if (response.data.data) {
          NotificationManager.success(response.data.message);
          dispatch(
            success(LANDLORD_FLOW.LANDLORD_SIGNUP_SUCCESS, response.data.data)
          );
          dispatch(getOrderSummaryDetails(response.data.data.userId));
          setPageNo(pageNo + 1);
        } else {
          NotificationManager.error(response.data.message);
          dispatch(
            failure(
              LANDLORD_FLOW.LANDLORD_SIGNUP_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function RenewSpaceSubscription(requestData, pageNo, setPageNo, redirectUrl) {
  return (dispatch) => {
    dispatch(request(LANDLORD_FLOW.LANDLORD_SIGNUP_REQUEST));
    renewSubscription(requestData).then(
      (response) => {
        if (response.data.data) {
          NotificationManager.success(response.data.message);
          dispatch(
            success(LANDLORD_FLOW.LANDLORD_SIGNUP_SUCCESS, response.data.data)
          );
          // dispatch(getOrderSummaryDetails(response.data.data.userId));
          redirectUrl();
          setPageNo(pageNo + 1);
        } else {
          NotificationManager.error(response.data.message);
          dispatch(
            failure(
              LANDLORD_FLOW.LANDLORD_SIGNUP_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function checkDuplicateCoupon(data) {
  return (dispatch) => {
    getDuplicateCouponDetails(data).then((response) => {
      if (response.data.succeeded) {
        dispatch(
          success(LANDLORD_FLOW.GET_ADDONS_COUPON_DETAILS, response.data.data)
        );
      } else {
        dispatch(
          failure(LANDLORD_FLOW.SET_COUPON_VALIDATION, response.data.message)
        );
      }
    });
  };
}