import { request, success, failure, PUSH_NOTIFICATION_REQUEST } from "./utilities";
import {
    getAllNotification,
    updateNotificationSetting,
    getPushNotificationSetting,
    getUnreadCount,
    readNotification,
} from "../api";

export function getAllNotificationList(pageData, auth) {
    return dispatch => {
        dispatch(request(PUSH_NOTIFICATION_REQUEST.GET_ALL_NOTIFICATION_REQUEST));
        getAllNotification(pageData, auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(PUSH_NOTIFICATION_REQUEST.GET_ALL_NOTIFICATION_SUCCESS, response.data.data))
                } else {
                    dispatch(failure(PUSH_NOTIFICATION_REQUEST.GET_ALL_NOTIFICATION_FAILURE, response.data.message))

                }
            },
            error => {
                dispatch(failure(PUSH_NOTIFICATION_REQUEST.GET_ALL_NOTIFICATION_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}

export function editNotificationSettings(updateSetting, auth) {
    return dispatch => {
        dispatch(request(PUSH_NOTIFICATION_REQUEST.UPDATE_NOTIFICATION_SETTING_REQUEST));
        updateNotificationSetting(updateSetting, auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(PUSH_NOTIFICATION_REQUEST.UPDATE_NOTIFICATION_SETTING_SUCCESS, updateSetting));
                    // dispatch(getNotificationSetting(auth))
                } else {
                    dispatch(failure(PUSH_NOTIFICATION_REQUEST.UPDATE_NOTIFICATION_SETTING_FAILURE, response.data.message))

                }
            },
            error => {
                dispatch(failure(PUSH_NOTIFICATION_REQUEST.UPDATE_NOTIFICATION_SETTING_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}


export function getNotificationSetting(auth) {
    return dispatch => {
        dispatch(request(PUSH_NOTIFICATION_REQUEST.GET_PUSH_NOTIFICATION_SETTING_REQUEST));
        getPushNotificationSetting(auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(PUSH_NOTIFICATION_REQUEST.GET_PUSH_NOTIFICATION_SETTING_SUCCESS, response.data.data))
                } else {
                    dispatch(failure(PUSH_NOTIFICATION_REQUEST.GET_PUSH_NOTIFICATION_SETTING_FAILURE, response.data.message))

                }
            },
            error => {
                dispatch(failure(PUSH_NOTIFICATION_REQUEST.GET_PUSH_NOTIFICATION_SETTING_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}

export function getUnreadNotificationCount(auth, logMeOut) {
    return dispatch => {
        dispatch(request(PUSH_NOTIFICATION_REQUEST.GET_UNREAD_NOTIFICATION_COUNT_REQUEST));
        getUnreadCount(auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(PUSH_NOTIFICATION_REQUEST.GET_UNREAD_NOTIFICATION_COUNT_SUCCESS, response.data.data))
                    if (response.data.isPasswordChanged === true) {
                        logMeOut();
                    }
                } else {
                    dispatch(failure(PUSH_NOTIFICATION_REQUEST.GET_UNREAD_NOTIFICATION_COUNT_FAILURE, response.data.message))
                    if (response.data.isPasswordChanged === true) {
                        logMeOut();
                    }
                }
            },
            error => {
                dispatch(failure(PUSH_NOTIFICATION_REQUEST.GET_UNREAD_NOTIFICATION_COUNT_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
                if (error.data.isPasswordChanged === true) {
                    logMeOut();
                }
            }
        )
    }
}
export function ReadNotification(id, auth) {
    return dispatch => {
        dispatch(request(PUSH_NOTIFICATION_REQUEST.READ_NOTIFICATION_REQUEST));
        readNotification(id, auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(PUSH_NOTIFICATION_REQUEST.READ_NOTIFICATION_SUCCESS, response.data.data))
                } else {
                    dispatch(failure(PUSH_NOTIFICATION_REQUEST.READ_NOTIFICATION_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(PUSH_NOTIFICATION_REQUEST.READ_NOTIFICATION_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}