import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function getRentSpree(data) {
    // ?sub=${data.sub}&email=${data.email}const params = new URLSearchParams();
    const params = new URLSearchParams();
    params.append('sub', data.sub);
    params.append('email', data.email);
    return axios.post(`${APP_SETTINGS.API_PATH.RENT_SPREE.getRentSpree}`, params, {
        headers: {
            ContentType: 'application/x-www-form-urlencoded;charset=utf-8'
        }
    })
}

export function rentSpreeVerification(data, token) {
    const params = new URLSearchParams();
    params.append('property', data.property);
    params.append('screeningOption', data.screeningOption);
    return axios.post(APP_SETTINGS.API_PATH.RENT_SPREE.rentSpree, data, {
        headers: {
            ContentType: 'application/x-www-form-urlencoded;charset=utf-8',
            Authorization: 'Bearer ' + token
        }
    })
}