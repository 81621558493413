/* eslint-disable */
import React, {
    useEffect,
    useRef,
    useLayoutEffect,
    useState
} from "react";
import Slider from "react-slick";
import {
    BsChevronCompactLeft,
    BsChevronCompactRight
} from "react-icons/bs";
import {
    getAllSuggestedPropertyList,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AZURE_BLOB_BASE_URL, PATH } from "../../config";
import { IMAGES } from "../images";
import { Truncate } from "..";
import { replaceSpace } from "../genericAction"
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { ErrorMessage, DoubleBubbleLoader } from ".";

export function MLPropertyList(props) {
    let dispatch = useDispatch();
    let auth = useAuth();
    let listing = useSelector((state) => state.listing)
    useEffect(() => {
        let data = {
            "placeName": null,
            "minSqFoot": parseInt(props.minSqFoot),
            "maxSqFoot": parseInt(props.maxSqFoot),
            "minPrice": parseInt(props.minPrice),
            "maxPrice": parseInt(props.maxPrice),
            "industryIds": props.industryIds,
            "utilities": props.utilities
        }
        dispatch(getAllSuggestedPropertyList(data, auth));
    }, [dispatch, auth])
    let ref = useRef();
    function NextClick() {
        if (ref)
            ref.current.slickNext()
    }
    function PreviousClick() {
        if (ref)
            ref.current.slickPrev()
    };
    var slider = {
        centerMode: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: false,
        speed: 500,
        adaptiveHeight: true,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,

                }
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
    let start = 25, end = 5, full = 40;
    const [width, height] = useWindowSize();
    console.log(height)
    if (width <= 1400 && width >= 1101) {
        start = 15;
        end = 10;
        full = 40;
    }
    else if (width <= 1120 && width >= 681) {
        start = 10;
        end = 5;
        full = 30;
    }
    else if (width <= 680) {
        start = 10;
        end = 5;
        full = 25;
    }
    return (
        <React.Fragment>
            {
                listing
                &&
                listing.getSuggestedListFailure === true
                &&
                listing.getSuggestedListError
                &&
                <ErrorMessage message={listing.getSuggestedListError} />
            }
            {
                listing
                &&
                listing.getSuggestedListLoading === true
                &&
                <DoubleBubbleLoader speed={5} customText={"We are looking for a great space for you."} />
            }
            {
                listing
                &&
                listing.getSuggestedListSuccess === true
                &&
                <div className="margin-auto no-padding col-11 ">
                    <BsChevronCompactLeft className="cursor"
                        onClick={() => { PreviousClick() }}
                        style={{ color: "#A19F9D", position: "absolute", top: "25%", left: "-65px", zIndex: 1, fontSize: 90 }} />
                    <div className="col-11 margin-auto max-width-100 recommended-cards-modify">
                        <Slider
                            ref={ref}
                            className="carousel"
                            {...slider}>
                            {
                                listing
                                &&
                                listing.getSuggestedList
                                &&
                                listing.getSuggestedList.map((user, index) => {
                                    return (
                                        <Link
                                            target="_blank" to={PATH.LISTINGDETAILPAGE.replace(":id", user.propertyId).replace(":address", replaceSpace(user.address))}
                                            key={index} className="carosel-card-height-home carousel-user  mb-4">
                                            {
                                                user
                                                &&
                                                user.isFeatured
                                                &&
                                                user.isFeatured === true
                                                &&
                                                <span className="is-featured">
                                                    {"Featured"}
                                                </span>
                                            }
                                            <div className="carouserl-img-parent carousel-listing">
                                                <img alt=""
                                                    className="img-fluid"
                                                    src={user.propertyImage ? user.propertyImage.includes("https://") ? user.propertyImage : AZURE_BLOB_BASE_URL + user.propertyImage : IMAGES.HOUSE1} />
                                            </div>
                                            <div className=" card-section no-margin carosel-card-height-home scroll-section" style={{ paddingTop: "2rem", paddingBottom: "0rem" }}>
                                                <h4 className=" primarry-address-listing">
                                                    {Truncate(user.address, start, end, full)}
                                                </h4>
                                                <p className=" sec-address-listing" >
                                                    {user.state}, {user.city}
                                                </p>
                                                <h6 className="cl-d-blue bold-me">
                                                    <div className="inline-block">
                                                        <img src={IMAGES.SQUAREICON} width="20" height="20" alt="icon" />
                                                        {`${user.totalSquareFoot}`}
                                                    </div>
                                                </h6>
                                            </div>
                                            <span className="d-flex justify-content-between" style={{ width: "100%", position: "absolute", bottom: 0 }}>
                                                <span className=" bold-me price-listing-slider1" style={{ color: "black", width: "50%" }}>
                                                    {`$${user.pricePerSquareFootValue * user.totalSquareFootValue}/Month`}
                                                </span>
                                                <span className=" bold-me price-listing-slider" style={{ color: "black", width: "50%" }}>
                                                    {`$ ${user.pricePerSquareFootValue} / SQ FT`}
                                                </span>
                                            </span>
                                        </Link>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                    <BsChevronCompactRight className="cursor"
                        onClick={() => { NextClick() }}
                        style={{ color: "#A19F9D", position: "absolute", top: "25%", right: "-65px", zIndex: 1, fontSize: 90 }} />
                </div>
            }
        </React.Fragment>
    )
}

export function SuggestedMLPropertyList(props) {
    let dispatch = useDispatch();
    let auth = useAuth();
    let listing = useSelector((state) => state.listing)
    useEffect(() => {
        let data = {
            "placeName": null,
            "minSqFoot": parseInt(props.minSqFoot),
            "maxSqFoot": parseInt(props.maxSqFoot),
            "minPrice": parseInt(props.minPrice),
            "maxPrice": parseInt(props.maxPrice),
            "industryIds": props.industryIds,
            "utilities": props.utilities
        }
        dispatch(getAllSuggestedPropertyList(data, auth));
    }, [dispatch, auth])
    let ref = useRef();
    function NextClick() {
        if (ref)
            ref.current.slickNext()
    }
    function PreviousClick() {
        if (ref)
            ref.current.slickPrev()
    };
    var slider = {
        centerMode: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: false,
        speed: 500,
        adaptiveHeight: true,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,

                }
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
    let start = 25, end = 5, full = 40;
    const [width, height] = useWindowSize();
    console.log(height)
    if (width <= 1400 && width >= 1101) {
        start = 15;
        end = 10;
        full = 40;
    }
    else if (width <= 1120 && width >= 681) {
        start = 10;
        end = 5;
        full = 30;
    }
    else if (width <= 680) {
        start = 10;
        end = 5;
        full = 25;
    }
    return (
        <div className="col-12" style={{ position: "relative" }}>
            {
                listing
                &&
                listing.getSuggestedListFailure === true
                &&
                listing.getSuggestedListError
                &&
                <ErrorMessage message={listing.getSuggestedListError} />
            }
            {
                listing
                &&
                listing.getSuggestedListLoading === true
                &&
                <DoubleBubbleLoader speed={5} customText={"We are looking for a great space for you."} />
            }
            <div className="mt-1 mb-1">
                {
                    listing
                    &&
                    listing.getSuggestedListSuccess === true
                    &&
                    listing.getSuggestedList
                    &&

                    listing.getSuggestedList.length > 0
                    &&
                    <div className={`ml-listing-section col-12`}>

                        <div className="headd d-flex justify-content-between col-11 margin-auto">
                            <h3>{"You maybe interested in:"}</h3>
                            <button onClick={() => { props.ViewAll() }} className="ml-view-btn">
                                View All
                            </button>
                        </div>
                        <div className="requirements col-12" style={{ position: "relative" }}>
                            {
                                listing
                                &&
                                listing.getSuggestedListSuccess === true
                                &&
                                <div className="margin-auto no-padding col-11 ">
                                    <BsChevronCompactLeft className="cursor"
                                        onClick={() => { PreviousClick() }}
                                        style={{ color: "#A19F9D", position: "absolute", top: "25%", left: "-65px", zIndex: 1, fontSize: 90 }} />
                                    <div className="col-11 margin-auto max-width-100 recommended-cards-modify">
                                        <Slider
                                            ref={ref}
                                            className="carousel"
                                            {...slider}>
                                            {
                                                listing
                                                &&
                                                listing.getSuggestedList
                                                &&
                                                listing.getSuggestedList.map((user, index) => {
                                                    return (
                                                        <Link
                                                            target="_blank" to={PATH.LISTINGDETAILPAGE.replace(":id", user.propertyId).replace(":address", replaceSpace(user.address))}
                                                            key={index} className="carosel-card-height-home carousel-user  mb-4">
                                                            {
                                                                user
                                                                &&
                                                                user.isFeatured
                                                                &&
                                                                user.isFeatured === true
                                                                &&
                                                                <span className="is-featured">
                                                                    {"Featured"}
                                                                </span>
                                                            }
                                                            <div className="carouserl-img-parent carousel-listing">
                                                                <img alt=""
                                                                    className="img-fluid"
                                                                    src={user.propertyImage ? user.propertyImage.includes("https://") ? user.propertyImage : AZURE_BLOB_BASE_URL + user.propertyImage : IMAGES.HOUSE1} />
                                                            </div>
                                                            <div className=" card-section no-margin carosel-card-height-home scroll-section" style={{ paddingTop: "2rem", paddingBottom: "0rem" }}>
                                                                <h4 className=" primarry-address-listing">
                                                                    {Truncate(user.address, start, end, full)}
                                                                </h4>
                                                                <p className=" sec-address-listing" >
                                                                    {user.state}, {user.city}
                                                                </p>
                                                                <h6 className="cl-d-blue bold-me">
                                                                    <div className="inline-block">
                                                                        <img src={IMAGES.SQUAREICON} width="20" height="20" alt="icon" />
                                                                        {`${user.totalSquareFoot}`}
                                                                    </div>
                                                                </h6>
                                                            </div>
                                                            <span className="d-flex justify-content-between" style={{ width: "100%", position: "absolute", bottom: 0 }}>
                                                                <span className=" bold-me price-listing-slider1" style={{ color: "black", width: "50%" }}>
                                                                    {`$ ${user.pricePerSquareFootValue * user.totalSquareFootValue} Monthly`}
                                                                </span>
                                                                <span className=" bold-me price-listing-slider" style={{ color: "black", width: "50%" }}>
                                                                    {`$ ${user.pricePerSquareFootValue} / SQ FT`}
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </div>
                                    <BsChevronCompactRight className="cursor"
                                        onClick={() => { NextClick() }}
                                        style={{ color: "#A19F9D", position: "absolute", top: "25%", right: "-65px", zIndex: 1, fontSize: 90 }} />
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}