import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import Checkbox from "react-custom-checkbox";
import { Dropdown } from "react-bootstrap";

export function DropDownCheckboxItem(props) {
    const [isChecked, setIsChecked] = useState(props.isCheck ? props.isCheck : false)
    return (
        <Dropdown.Item bsPrefix="dropdown-item-list"
            onClick={() => {
                setIsChecked(!isChecked);
                props.onChangeOfList(props.name)
            }}>
            <Checkbox
                checked={props.selectedList.includes(props.name) === true ? true : false}
                icon={
                    <div>
                        <FaCheck color="#255cb7" className="icon-checkbox-drop-down" size={18} />
                    </div>
                }
                borderColor="#979797"
                borderRadius={1}
                borderWidth={1}
                size={15}
                labelClassName="checkbox-class"
                label={props.name} />
        </Dropdown.Item>
    )
}

export function DropDownCheckboxItem1(props) {
    const [isChecked, setIsChecked] = useState(props.isCheck ? props.isCheck : false)
    return (
        <Dropdown.Item bsPrefix="dropdown-item-list"
            onClick={() => {
                setIsChecked(!isChecked);
                props.onChangeOfList(props)
            }}>
            <Checkbox
                checked={props.selectedList.includes(props.name.industryId) === true ? true : false}
                icon={
                    <div>
                        <FaCheck color="#255cb7" className="icon-checkbox-drop-down" size={18} />
                    </div>
                }
                borderColor="#979797"
                borderRadius={1}
                borderWidth={1}
                size={15}
                labelClassName="checkbox-class"
                label={props.name.name} />
        </Dropdown.Item>
    )
}
export function DropDownCheckboxItem2(props) {
    return (
        <Dropdown.Item bsPrefix="dropdown-item-list"
            onClick={() => {
                props.onChangeOfList(props)
            }}>
            <Checkbox
                checked={props.selectedList.includes(props.name.industryId) === true ? true : false}
                icon={
                    <div>
                        <FaCheck color="#255cb7" className="icon-checkbox-drop-down" size={18} />
                    </div>
                }
                borderColor="#979797"
                borderRadius={1}
                borderWidth={1}
                size={15}
                labelClassName="checkbox-class"
                label={props.name.value} />
        </Dropdown.Item>
    )
}