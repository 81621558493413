import React, { useState, useEffect, useRef } from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { BsFillEnvelopeFill, BsFillLockFill } from "react-icons/bs";
import { useCookies } from "react-cookie";
import {
  login,
  RegistrationNewUser,
  SignUpSocialUser,
} from "../../redux/actions";
import { checkEmail, socialLinkedinUser } from "../../redux/api";
import { BiCurrentLocation } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { ErrorMessage, FieldError, Loader } from "../../assets";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import {
  PATH,
  FB_APP_ID,
  GOOGLE_CLIENT_ID,
  LINKEDIN_CLIENT_ID,
  LINKED_IN_REDIRECT_URL,
  SOCIAL_ACCOUNTS_LOGIN,
  APPLE_CLIENT_ID,
  APPLE_REDIRECT_URL,
} from "../../config";
import AppleLogin from "react-apple-login";
import { FormControl, FormGroup, Modal } from "react-bootstrap";
import { userLoginViaSocial, getAccessToken } from "../../redux/api";
import { getToken } from "../../firebaseInit.js";
import {
  REGISTRATION_V2_REQUEST,
  request,
  success,
} from "../../redux/actions/utilities";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { parseJwt } from "../genericAction";
import loginGplus from "../../assets/images/login__gplus.svg";
import loginLinkedIn from "../../assets/images/login__linkedIn.svg";
import loginFb from "../../assets/images/login__fb.svg";
import loginApple from "../../assets/images/login__apple.svg";

export function LoginSignUpModal(props) {
  const [isScrolable, setIsScrolable] = useState(false);
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)} className="login__modal">
          <ModalBodyData />
        </Modal>
  );
}

function ModalBodyData(props) {
  const [view, setView] = useState(0);
  const [text, setText] = useState(props.text);
  const [appCode, setAppCode] = useState(null);
  const [fName, setFName] = useState(null);
  const [lName, setLName] = useState(null);
  const [email, setEmail] = useState(null);
  const [picture, setPicture] = useState(null);
  const [loginTypeId, setLoginTypeId] = useState(null);
  const [accessCode, setAccessCode] = useState(null);
  const [pnumber, setPnumber] = useState(null);
  const [noPassword, setNoPassword] = useState(false);
  let dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    if (props.view !== view) {
      setView(props.view);
    }
    if (text !== props.text) {
      setText(text);
    }
  }, [props.text, props.view]);
  // useEffect(() => {
  //   if (view === 2) {
  //     props.setIsScrolable(true);
  //   } else {
  //     props.setIsScrolable(false);
  //   }
  // }, [view, props]);
  useEffect(() => {
    dispatch(request(REGISTRATION_V2_REQUEST.USER_LOGIN_SIGNUP_RESET));
  }, []);
  return (
    <React.Fragment>
      <Modal.Header className="no-border" closeButton>
        <Modal.Title className="modal-header-style">
          <div className="generic-heading flex-center">
            {" "}
            <span /> {text}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {view === 0 && (
          <CheckForEmail
            {...props}
            noPassword={noPassword}
            setNoPassword={setNoPassword}
            setView={setView}
            setText={setText}
            email={email}
            setEmail={setEmail}
          />
        )} */}
        {/* {view === 1 && <LoginModal {...props} email={email} />} */}
        {/* {view === 2 && (
          <SignUpModal
            {...props}
            noPassword={noPassword}
            setNoPassword={setNoPassword}
            setView={setView}
            setText={setText}
            email={email}
            fName={fName}
            lName={lName}
            pnumber={pnumber}
            appCode={appCode}
            accessCode={accessCode}
            picture={picture}
            loginTypeId={loginTypeId}
            setPicture={setPicture}
            setAccessCode={setAccessCode}
            setAppCode={setAppCode}
            setPnumber={setPnumber}
            setLName={setLName}
            setFName={setFName}
            setEmail={setEmail}
            setLoginTypeId={setLoginTypeId}
          />
        )} */}
        {/* <SocialMediaLoginButtons
          {...props}
          noPassword={noPassword}
          setNoPassword={setNoPassword}
          email={email}
          fName={fName}
          lName={lName}
          pnumber={pnumber}
          appCode={appCode}
          accessCode={accessCode}
          picture={picture}
          loginTypeId={loginTypeId}
          setPicture={setPicture}
          setAccessCode={setAccessCode}
          setAppCode={setAppCode}
          setPnumber={setPnumber}
          setLName={setLName}
          setFName={setFName}
          setLoginTypeId={setLoginTypeId}
          setEmail={setEmail}
          setView={setView}
          setText={setText}
        /> */}
        {/* <p className="text-center mt-2">
          New Member?{" "}
          <Link to={PATH.LANDLORD_QUESTIONS}>
            <span className="linkText">Signup Now</span>
          </Link>
        </p> */}
      </Modal.Body>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="form__content">
          <div className="left__block">
            <h3 className="navbar-header-title text-white">REDHANDED</h3>
          </div>
          <div className="right__block">
            <div className="form__heading">
              <h3 className="navbar-header-title text-center">LOGIN</h3>
            </div>

            <div className="form__section">
              <LoginModal {...props} email={email} />
              <div className="or__connect">
                <p className="font__semibold">Or Connect With</p>
              </div>
              <SocialMediaLoginButtons
                {...props}
                noPassword={noPassword}
                setNoPassword={setNoPassword}
                email={email}
                fName={fName}
                lName={lName}
                pnumber={pnumber}
                appCode={appCode}
                accessCode={accessCode}
                picture={picture}
                loginTypeId={loginTypeId}
                setPicture={setPicture}
                setAccessCode={setAccessCode}
                setAppCode={setAppCode}
                setPnumber={setPnumber}
                setLName={setLName}
                setFName={setFName}
                setLoginTypeId={setLoginTypeId}
                setEmail={setEmail}
                setView={setView}
                setText={setText}
              />

              {/* <div className="form__social">
                <div className="row">
                  <div className="col-md-6">
                    <div className="socail__block gp">
                      <button>
                        <img src={loginGplus} />
                        <span>GOOGLE</span>
                      </button>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="socail__block fb">
                      <button>
                        <img src={loginFb} />
                        <span>FACEBOOK</span>
                      </button>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="socail__block li">
                      <button>
                        <img src={loginLinkedIn} />
                        <span>LINKEDIN</span>
                      </button>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="socail__block apple">
                      <button>
                        <img src={loginApple} />
                        <span>APPLE</span>
                      </button>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="or__connect">
                      <p className="font__semibold">
                        New Member? <span className="linkText">Signup Now</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Modal.Body>
    </React.Fragment>
  );
}

function SignUpModal(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [error, setError] = useState(null);
  console.log(loading, failure, error);
  let dispatch = useDispatch();
  let history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm();

  let location = useSelector((state) => state.locations);
  let pro = useSelector((state) => state.user);
  const password = useRef({});
  password.current = watch("password", "");

  function onSubmit(data) {
    setLoading(true);
    setFailure(false);
    setError(null);
    if (props.noPassword === true) {
      if (!props.accessCode) {
        // google or fb
        data = {
          ...data,
          loginTypeId: props && props.loginTypeId ? props.loginTypeId : null,
          socialKey: props && props.appCode ? props.appCode : null,
          photo: props && props.picture ? props.picture : null,
        };
        dispatch(SignUpSocialUser(data, setCookiesforUser));
      } else {
        //linkedin
        data = {
          ...data,
          loginTypeId: props && props.loginTypeId ? props.loginTypeId : null,
          socialKey: props && props.appCode ? props.appCode : null,
          photo: props && props.picture ? props.picture : null,
          // "accessToken": props && props.accessCode ? props.accessCode : null
        };
        dispatch(SignUpSocialUser(data, setCookiesforUser));
      }
    } else {
      let myData = {
        confirm_password: data.confirm_password,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        phoneNumber: null,
        stateId: data.stateId,
      };
      dispatch(RegistrationNewUser(myData, setCookiesforUser));
    }
  }

  function setCookiesforUser(data) {
    if (props.noPassword === true) {
      history.push({
        pathname: PATH.SIGNUP,
        state: {
          noPassword: props.noPassword,
          user: data,
        },
      });
    } else {
      history.push({
        pathname: PATH.SIGNUP,
        state: {
          noPassword: props.noPassword,
          user: data,
        },
      });
    }
  }
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row d-flex justify-content-center ">
          <div className="col-lg-10">
            <form onSubmit={handleSubmit(onSubmit)} className="form-aside">
              {pro.userSignUpFailure === true && pro.userSignUpError && (
                <ErrorMessage message={pro.userSignUpError} />
              )}
              {location &&
                location.stateLoading === true &&
                location.stateSuccess === false &&
                location.stateFailure === false && <Loader />}
              {location &&
                location.stateLoading === false &&
                location.stateSuccess === false &&
                location.stateFailure === true &&
                location.stateError && (
                  <ErrorMessage message={location.stateError} />
                )}
              {location &&
                location.stateLoading === false &&
                location.stateSuccess === true &&
                location.stateFailure === false && (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-6 pr-1">
                        <FormGroup className="input-login-parent">
                          <FormControl
                            style={{
                              borderColor:
                                errors && errors.firstName ? "#a80000" : "",
                            }}
                            className="no-border input-no-border-radius"
                            placeholder="First Name"
                            name="firstName"
                            value={props.fName}
                            onChange={(e) => {
                              props.setFName(e.target.value);
                            }}
                            type="text"
                            ref={register({ required: true })}
                          />
                        </FormGroup>
                        {errors.firstName && (
                          <FieldError message={"This Field is Required"} />
                        )}
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-6 pl-1">
                        <FormGroup className="input-login-parent">
                          <FormControl
                            style={{
                              borderColor:
                                errors && errors.lastName ? "#a80000" : "",
                            }}
                            className="no-border input-no-border-radius"
                            placeholder="Last Name"
                            name="lastName"
                            type="text"
                            value={props.lName}
                            onChange={(e) => {
                              props.setLName(e.target.value);
                            }}
                            ref={register({ required: true })}
                          />
                        </FormGroup>
                        {errors.lastName && (
                          <FieldError message={"This Field is Required"} />
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <FormGroup className="input-login-parent">
                          <div className="login-icon">
                            <BsFillEnvelopeFill />
                          </div>
                          <FormControl
                            style={{
                              borderColor:
                                errors && errors.email ? "#a80000" : "",
                            }}
                            className="no-border input-no-border-radius"
                            placeholder="Email"
                            name="email"
                            type="email"
                            value={props.email}
                            onChange={(e) => props.setEmail(props.email)}
                            ref={register({ required: true })}
                          />
                        </FormGroup>
                        {errors.email && (
                          <FieldError message={"This Field is Required"} />
                        )}
                      </div>
                    </div>

                    {props.noPassword === false && (
                      <React.Fragment>
                        <div className="row ">
                          <div className="col-12">
                            <FormGroup className="input-login-parent">
                              <div className="login-icon">
                                <BsFillLockFill />
                              </div>
                              <FormControl
                                style={{
                                  borderColor:
                                    errors && errors.password ? "#a80000" : "",
                                }}
                                className="no-border input-no-border-radius"
                                placeholder="Password"
                                name="password"
                                type={
                                  showPassword === true ? "text" : "password"
                                }
                                ref={register({
                                  required: true,
                                  pattern:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#^$!%*?&])[A-Za-z\d@#^$!%*?&]{8,}$/,
                                })}
                              />
                              <div
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                                className="cursor login-visible-invisible-icon"
                                style={{ zIndex: 5 }}
                              >
                                {showPassword === true ? (
                                  <AiOutlineEye size={20} />
                                ) : (
                                  <AiOutlineEyeInvisible size={20} />
                                )}
                              </div>
                            </FormGroup>
                            {errors.password && (
                              <FieldError
                                message={
                                  "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-12">
                            <FormGroup className="input-login-parent">
                              <div className="login-icon">
                                <BsFillLockFill />
                              </div>
                              <FormControl
                                style={{
                                  borderColor:
                                    errors && errors.confirm_password
                                      ? "#a80000"
                                      : "",
                                }}
                                className="no-border input-no-border-radius"
                                placeholder="Confirm Password"
                                name="confirm_password"
                                type={
                                  showPassword === true ? "text" : "password"
                                }
                                ref={register({
                                  validate: (value) =>
                                    value === password.current ||
                                    "The passwords do not match",
                                })}
                              />
                              <div
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                                className="cursor login-visible-invisible-icon"
                                style={{ zIndex: 5 }}
                              >
                                {showPassword === true ? (
                                  <AiOutlineEye size={20} />
                                ) : (
                                  <AiOutlineEyeInvisible size={20} />
                                )}
                              </div>
                            </FormGroup>
                            {errors.confirm_password && (
                              <FieldError
                                message={errors.confirm_password.message}
                              />
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    <div className="row">
                      <div className="col-12">
                        <FormGroup className="input-login-parent">
                          <div className="login-icon">
                            <BiCurrentLocation />
                          </div>
                          <FormControl
                            as="select"
                            className="no-border input-no-border-radius"
                            style={{
                              borderColor:
                                errors && errors.stateId ? "#a80000" : "",
                              color: "#79828a",
                            }}
                            name="stateId"
                            ref={register({ required: true })}
                          >
                            <option value="">{"State"}</option>
                            {location &&
                              location.stateList &&
                              location.stateList.map((state, index) => {
                                return (
                                  <option value={state.stateId} key={index}>
                                    {state.name}
                                  </option>
                                );
                              })}
                          </FormControl>
                        </FormGroup>
                        {errors.stateId && (
                          <FieldError
                            className="mt-1"
                            message={"This Field is Required"}
                          />
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12">
                        <span>
                          <input
                            type="checkbox"
                            value={isChecked}
                            onChange={() => {
                              setIsChecked(!isChecked);
                            }}
                          />
                          {" I accept the "}
                          <Link
                            className="click-able-color"
                            target="_blank"
                            to={{
                              pathname: PATH.TERMSANDCONDITIONS,
                              state: {
                                value: 1,
                              },
                            }}
                          >
                            {"Privacy Policy "}
                          </Link>
                          {"and"}
                          <Link
                            className="click-able-color"
                            target="_blank"
                            to={{
                              pathname: PATH.TERMSANDCONDITIONS,
                              state: {
                                value: 2,
                              },
                            }}
                          >
                            {" Terms & Conditions"}
                          </Link>
                        </span>
                      </div>
                    </div>

                    <div className="row mt-1 justify-center">
                      {pro.userSignUpLoading === true ? (
                        <Loader />
                      ) : (
                        <div className="col-12">
                          <button
                            type="submit"
                            disabled={isChecked === true ? false : true}
                            className="btn-2 btn btn-block login-signup-btn-style"
                          >
                            {"Sign Up"}
                          </button>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function CheckForEmail(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  function submitForm(data) {
    props.setEmail(data.email);
    setLoading(true);
    setError(false);
    setErrorMsg(null);
    checkEmail(data.email).then(
      (response) => {
        setLoading(false);
        if (response.data.data === true) {
          props.setView(1);
          props.setText("Log In");
          setErrorMsg(null);
          setError(false);
        } else {
          //props.setView(2);
          // props.setText("Sign Up");
          props.setNoPassword(false);
          setErrorMsg("This email id is not registered. Please Sign up");
          setError(true);
        }
      },
      (error) => {
        setLoading(false);
        setError(true);
        setErrorMsg(
          error &&
            error.response &&
            error.response.data &&
            error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    );
  }
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      {error === true && errorMsg && <ErrorMessage message={errorMsg} />}
      <div className="row mt-1">
        <div className="col-12">
          <FormGroup className="input-login-parent">
            <div className="login-icon">
              <BsFillEnvelopeFill />
            </div>
            <FormControl
              style={{ borderColor: errors && errors.email ? "#a80000" : "" }}
              className="no-border input-no-border-radius"
              placeholder="Email"
              name="email"
              type="email"
              ref={register({ required: true })}
            />
          </FormGroup>
          {errors.email && <FieldError message={"This Field is Required"} />}
        </div>
      </div>
      <div className="row justify-center">
        <div className="col-12">
          {loading === true ? (
            <Loader />
          ) : (
            <button
              type="submit"
              className="btn-2 btn btn-block login-signup-btn-style"
            >
              {"Continue"}
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

function LoginModal(props) {
  const { register, errors, handleSubmit } = useForm();
  let user_Data = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [cookies, setCookie] = useCookies(["real_estate_user"]);
  const [cookies1, setCookie1] = useCookies(["real_estate_propertyId"]);
  let dispatch = useDispatch();
  const [isTokenFound, setTokenFound] = useState(false);
  console.log("cookie", cookies);
  function onSubmit(data) {
    if (user_Data.deviceToken) {
      data = {
        ...data,
        deviceToken: user_Data.deviceToken,
        platformId: 1202,
      };
      dispatch(login(data, setCookiesforUser));
    } else {
      async function tokenFunc() {
        let data_Token = await getToken(setTokenFound);
        if (data_Token) {
          console.log("Token is", data_Token);
          dispatch(
            success(REGISTRATION_V2_REQUEST.DEVICE_TOKEN_RECIEVED, data_Token)
          );
        }
        return data_Token;
      }
      tokenFunc();
      data = {
        ...data,
        deviceToken: user_Data.deviceToken,
        platformId: 1202,
      };
      dispatch(login(data, setCookiesforUser));
    }
  }
  let history = useHistory();
  function setCookiesforUser(data) {
    setCookie("real_estate_user", data);
    setCookie1("real_estate_propertyId", data.propertyId);
    history.push(
      history?.location?.state?.from?.pathname
        ? history.location.state.from.pathname
        : PATH.DASHBOARD
    );
  }
  return (
    // <form onSubmit={handleSubmit(onSubmit)} className="form-aside">
    //   {user_Data &&
    //     user_Data.loginFailure === true &&
    //     user_Data.loginLoading === false &&
    //     user_Data.loginSuccess === false &&
    //     user_Data.loginError && <ErrorMessage message={user_Data.loginError} />}
    //   <div className="row mt-1">
    //     <div className="col-12">
    //       <FormGroup className="input-login-parent">
    //         <div className="login-icon">
    //           <BsFillEnvelopeFill />
    //         </div>
    //         <FormControl
    //           style={{ borderColor: errors && errors.email ? "#a80000" : "" }}
    //           className="no-border input-no-border-radius"
    //           placeholder="Email"
    //           name="email"
    //           type="email"
    //           value={props.email}
    //           ref={register({ required: true })}
    //         />
    //       </FormGroup>
    //       {errors.email && <FieldError message={"This Field is Required"} />}
    //     </div>
    //   </div>
    //   <div className="row ">
    //     <div className="col-12">
    //       <FormGroup className="input-login-parent">
    //         <div className="login-icon">
    //           <BsFillLockFill />
    //         </div>
    //         <FormControl
    //           style={{
    //             borderColor: errors && errors.password ? "#a80000" : "",
    //           }}
    //           className="no-border input-no-border-radius"
    //           placeholder="Password"
    //           name="password"
    //           type={showPassword === true ? "text" : "password"}
    //           ref={register({ required: true })}
    //         />
    //         <div
    //           onClick={() => {
    //             setShowPassword(!showPassword);
    //           }}
    //           className="cursor login-visible-invisible-icon"
    //           style={{ zIndex: 5 }}
    //         >
    //           {showPassword === true ? (
    //             <AiOutlineEye size={20} />
    //           ) : (
    //             <AiOutlineEyeInvisible size={20} />
    //           )}
    //         </div>
    //       </FormGroup>
    //       {errors.password && <FieldError message={"This Field is Required"} />}
    //     </div>
    //   </div>
    //   <div className="row mt-1 justify-center">
    //     <div className="col-12 ">
    //       {user_Data && user_Data.loginLoading === false && (
    //         <button
    //           type="submit"
    //           className="btn-2 btn btn-block login-signup-btn-style"
    //         >
    //           {"LOG IN"}
    //         </button>
    //       )}
    //       {user_Data && user_Data.loginLoading === true && <Loader />}
    //     </div>
    //     <span className="d-flex justify-content-end" style={{ width: "90%" }}>
    //       <Link to={PATH.FORGETPASSWORD}>{"Forgot Password?"}</Link>
    //     </span>
    //   </div>
    // </form>
    <form onSubmit={handleSubmit(onSubmit)} className="row loginForm m-0">
      {user_Data &&
        user_Data.loginFailure === true &&
        user_Data.loginLoading === false &&
        user_Data.loginSuccess === false &&
        user_Data.loginError && <ErrorMessage message={user_Data.loginError} />}

      <div className="col-md-12 p-0">
        <div class="did-floating-label-content">
          <FormGroup>
            <FormControl
              style={{ borderColor: errors && errors.email ? "#a80000" : "" }}
              className="did-floating-input"
              placeholder=" "
              name="email"
              type="email"
              value={props.email}
              ref={register({ required: true })}
            />
            {/* <input class="did-floating-input" type="email" placeholder=" " /> */}
            <label class="did-floating-label">Email</label>
          </FormGroup>
        </div>
      </div>

      {errors.email && <FieldError message={"This Field is Required"} />}

      <div className="col-md-12 p-0">
        <div class="did-floating-label-content">
          <FormGroup>
            <FormControl
              style={{
                borderColor: errors && errors.password ? "#a80000" : "",
              }}
              className="did-floating-input"
              placeholder=" "
              name="password"
              type={showPassword === true ? "text" : "password"}
              ref={register({ required: true })}
            />
            <div
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              className="cursor login-eye"
              style={{ zIndex: 5 }}
            >
              {showPassword === true ? (
                <AiOutlineEye size={20} />
              ) : (
                <AiOutlineEyeInvisible size={20} />
              )}
            </div>
            <label class="did-floating-label">Password</label>
          </FormGroup>
          {errors.password && <FieldError message={"This Field is Required"} />}
        </div>
      </div>

      <div className="col-md-12 p-0">
        <div className="forgotpw">
          {/* <div className="checkbox__block">
           <input type="checkbox" />
           <label>Remember Me</label>
         </div> */}

          <div>
            <Link to={PATH.FORGETPASSWORD}>
              <span className="linkText">Forgot Password?</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="col-md-12 p-0">
        <div className="form__button">
          {user_Data && user_Data.loginLoading === false && (
            <button type="submit" className="red__btn">
              LOGIN
            </button>
          )}
          {user_Data && user_Data.loginLoading === true && <Loader />}
        </div>
      </div>
    </form>
  );
}

function SocialMediaLoginButtons(props) {
  const [cookies, setCookie] = useCookies(["real_estate_user"]);
  const [cookies1, setCookie1] = useCookies(["real_estate_propertyId"]);
  console.log(cookies);
  let user_Data = useSelector((state) => state.user);
  let history = useHistory();
  let dispatch = useDispatch();
  const [isTokenFound, setTokenFound] = useState(false);
  const responseGoogle = (response) => {
    props.setFName(response.profileObj.givenName);
    props.setLName(response.profileObj.familyName);
    props.setAppCode(response.profileObj.googleId);
    props.setEmail(response.profileObj.email);
    props.setPicture(response.profileObj.imageUrl);
    props.setLoginTypeId(SOCIAL_ACCOUNTS_LOGIN.GOOGLE);
    if (user_Data.deviceToken) {
      let data = {
        loginTypeId: SOCIAL_ACCOUNTS_LOGIN.GOOGLE,
        socialKey: response.profileObj.googleId,
        deviceToken: user_Data.deviceToken,
        platformId: 1202,
      };
      userLoginViaSocial(data).then(
        (resp) => {
          if (resp.data.httpStatusCode === 404) {
            props.setAccessCode(null);
            props.setView(2);
            props.setText("Sign Up");
            props.setNoPassword(true);
          } else if (resp.data.succeeded === true) {
            let myData = { ...resp.data.data, isSocialLogin: true };
            setCookie("real_estate_user", myData);
            setCookie1("real_estate_propertyId", myData.propertyId);
            history.push(PATH.DASHBOARD);
          }
        },
        (error) => {
          console.log(error, "er");
        }
      );
    } else {
      async function tokenFunc() {
        let data_Token = await getToken(setTokenFound);
        if (data_Token) {
          console.log("Token is", data_Token);
          dispatch(
            success(REGISTRATION_V2_REQUEST.DEVICE_TOKEN_RECIEVED, data_Token)
          );
        }
        return data_Token;
      }
      tokenFunc();
      let data = {
        loginTypeId: SOCIAL_ACCOUNTS_LOGIN.GOOGLE,
        socialKey: response.profileObj.googleId,
        deviceToken: user_Data.deviceToken,
        platformId: 1202,
      };
      userLoginViaSocial(data).then(
        (resp) => {
          if (resp.data.httpStatusCode === 404) {
            props.setAccessCode(null);
            props.setView(2);
            props.setText("Sign Up");
            props.setNoPassword(true);
          } else if (resp.data.succeeded === true) {
            let myData = { ...resp.data.data, isSocialLogin: true };
            setCookie("real_estate_user", myData);
            setCookie1("real_estate_propertyId", myData.propertyId);
            history.push(PATH.DASHBOARD);
          }
        },
        (error) => {
          console.log(error, "er");
        }
      );
    }
  };
  const responseLinkedIn = (response) => {
    props.setAppCode(response.code);
    getAccessToken(response.code).then(
      (respon) => {
        if (respon.data) {
          props.setAccessCode(respon.data.access_token);
          if (user_Data.deviceToken) {
            let data = {
              loginTypeId: SOCIAL_ACCOUNTS_LOGIN.LINKEDIN,
              socialKey: respon.data.access_token,
              deviceToken: user_Data.deviceToken,
              platformId: 1202,
            };
            userLoginViaSocial(data).then(
              (resp) => {
                if (resp.data.httpStatusCode === 404) {
                  socialLinkedinUser({
                    accessToken: respon.data.access_token,
                  }).then((res) => {
                    props.setFName(res.data.firstName);
                    props.setLName(res.data.lastName);
                    props.setAppCode(res.data.id);
                    props.setEmail(res.data.email);
                    props.setPicture(res.data.photo);
                    props.setLoginTypeId(SOCIAL_ACCOUNTS_LOGIN.LINKEDIN);
                    props.setView(2);
                    props.setText("Sign Up");
                    props.setNoPassword(true);
                  });
                } else if (resp.data.succeeded === true) {
                  let myData = { ...resp.data.data, isSocialLogin: true };
                  setCookie("real_estate_user", myData);
                  setCookie1("real_estate_propertyId", myData.propertyId);
                  history.push(PATH.DASHBOARD);
                }
              },
              (error) => {
                console.log(error, "er");
              }
            );
          } else {
            async function tokenFunc() {
              let data_Token = await getToken(setTokenFound);
              if (data_Token) {
                console.log("Token is", data_Token);
                dispatch(
                  success(
                    REGISTRATION_V2_REQUEST.DEVICE_TOKEN_RECIEVED,
                    data_Token
                  )
                );
              }
              return data_Token;
            }
            tokenFunc();
            let data = {
              loginTypeId: SOCIAL_ACCOUNTS_LOGIN.LINKEDIN,
              socialKey: respon.data.access_token,
              deviceToken: user_Data.deviceToken,
              platformId: 1202,
            };
            userLoginViaSocial(data).then(
              (resp) => {
                if (resp.data.httpStatusCode === 404) {
                  socialLinkedinUser({
                    accessToken: respon.data.access_token,
                  }).then((res) => {
                    props.setFName(res.data.firstName);
                    props.setLName(res.data.lastName);
                    props.setAppCode(res.data.id);
                    props.setEmail(res.data.email);
                    props.setPicture(res.data.photo);
                    props.setLoginTypeId(SOCIAL_ACCOUNTS_LOGIN.LINKEDIN);
                    props.setView(2);
                    props.setText("Sign Up");
                    props.setNoPassword(true);
                  });
                } else if (resp.data.succeeded === true) {
                  let myData = { ...resp.data.data, isSocialLogin: true };
                  setCookie("real_estate_user", myData);
                  setCookie1("real_estate_propertyId", myData.propertyId);
                  history.push(PATH.DASHBOARD);
                }
              },
              (error) => {
                console.log(error, "er");
              }
            );
          }
        }
      },
      (err) => {
        console.log("error:::", err);
      }
    );
  };
  const handleFailure = (error) => {
    console.log("failure Linkedin Response:::", error);
  };
  const responseFacebook = (response) => {
    let fName = response.name.split(" ")[0];
    let lName = response.name.split(" ")[1];
    props.setFName(fName);
    props.setLName(lName);
    props.setAppCode(response.userID);
    props.setEmail(response.email);
    props.setPicture(response.picture.data.url);
    props.setLoginTypeId(SOCIAL_ACCOUNTS_LOGIN.FACEBOOK);
    if (user_Data.deviceToken) {
      let data = {
        loginTypeId: SOCIAL_ACCOUNTS_LOGIN.FACEBOOK,
        socialKey: response.userID,
        deviceToken: user_Data.deviceToken,
        platformId: 1202,
      };
      userLoginViaSocial(data).then(
        (resp) => {
          if (resp.data.httpStatusCode === 404) {
            props.setAccessCode(null);
            props.setView(2);
            props.setText("Sign Up");
            props.setNoPassword(true);
          } else if (resp.data.succeeded === true) {
            let myData = { ...resp.data.data, isSocialLogin: true };
            setCookie("real_estate_user", myData);
            setCookie1("real_estate_propertyId", myData.propertyId);
            history.push(PATH.DASHBOARD);
          }
        },
        (error) => {
          console.log(error, "er");
        }
      );
    } else {
      async function tokenFunc() {
        let data_Token = await getToken(setTokenFound);
        if (data_Token) {
          console.log("Token is", data_Token);
          dispatch(
            success(REGISTRATION_V2_REQUEST.DEVICE_TOKEN_RECIEVED, data_Token)
          );
        }
        return data_Token;
      }
      tokenFunc();
      let data = {
        loginTypeId: SOCIAL_ACCOUNTS_LOGIN.FACEBOOK,
        socialKey: response.userID,
        deviceToken: user_Data.deviceToken,
        platformId: 1202,
      };
      userLoginViaSocial(data).then(
        (resp) => {
          if (resp.data.httpStatusCode === 404) {
            props.setAccessCode(null);
            props.setView(2);
            props.setText("Sign Up");
            props.setNoPassword(true);
          } else if (resp.data.succeeded === true) {
            let myData = { ...resp.data.data, isSocialLogin: true };
            setCookie("real_estate_user", myData);
            setCookie1("real_estate_propertyId", myData.propertyId);
            history.push(PATH.DASHBOARD);
          }
        },
        (error) => {
          console.log(error, "er");
        }
      );
    }
  };
  const responseApple = (response) => {
    if (response && response.authorization) {
      let token = parseJwt(response.authorization.id_token);
      if (response && response.user) {
        let fName =
          response.user && response.user.name && response.user.name.firstName
            ? response.user.name.firstName
            : null;
        let lName =
          response.user && response.user.name && response.user.name.lastName
            ? response.user.name.lastName
            : null;
        props.setFName(fName);
        props.setLName(lName);
        props.setEmail(
          response.user && response.user.email ? response.user.email : null
        );
      } else {
        props.setFName(null);
        props.setLName(null);
        props.setEmail(null);
      }

      props.setAppCode(token.sub);
      props.setPicture(null);
      props.setLoginTypeId(SOCIAL_ACCOUNTS_LOGIN.APPLE);
      if (user_Data.deviceToken) {
        let data = {
          loginTypeId: SOCIAL_ACCOUNTS_LOGIN.APPLE,
          socialKey: token.sub,
          deviceToken: user_Data.deviceToken,
          platformId: 1202,
        };
        userLoginViaSocial(data).then(
          (resp) => {
            if (resp.data.httpStatusCode === 404) {
              props.setAccessCode(null);
              props.setView(2);
              props.setText("Sign Up");
              props.setNoPassword(true);
            } else if (resp.data.succeeded === true) {
              let myData = { ...resp.data.data, isSocialLogin: true };
              setCookie("real_estate_user", myData);
              setCookie1("real_estate_propertyId", myData.propertyId);
              history.push(PATH.DASHBOARD);
            }
          },
          (error) => {
            console.log(error, "er");
          }
        );
      } else {
        async function tokenFunc() {
          let data_Token = await getToken(setTokenFound);
          if (data_Token) {
            console.log("Token is", data_Token);
            dispatch(
              success(REGISTRATION_V2_REQUEST.DEVICE_TOKEN_RECIEVED, data_Token)
            );
          }
          return data_Token;
        }
        tokenFunc();
        let data = {
          loginTypeId: SOCIAL_ACCOUNTS_LOGIN.APPLE,
          socialKey: token.sub,
          deviceToken: user_Data.deviceToken,
          platformId: 1202,
        };
        userLoginViaSocial(data).then(
          (resp) => {
            if (resp.data.httpStatusCode === 404) {
              props.setAccessCode(null);
              props.setView(2);
              props.setText("Sign Up");
              props.setNoPassword(true);
            } else if (resp.data.succeeded === true) {
              let myData = { ...resp.data.data, isSocialLogin: true };
              setCookie("real_estate_user", myData);
              setCookie1("real_estate_propertyId", myData.propertyId);
              history.push(PATH.DASHBOARD);
            }
          },
          (error) => {
            console.log(error, "er");
          }
        );
      }
    }
  };
  return (
    <React.Fragment>
      {/* <div className="mt-1">
        <span className="flex-center">{"Or Connect With"}</span>
        <div className="flex-center">
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <button
                type="button"
                className="btn-social-size login-modal-social-fields"
                onClick={() => {
                  renderProps.onClick();
                }}
                autoload={"false"}
              >
                <FcGoogle className="logo" />
              </button>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
          />
          <FacebookLogin
            appId={FB_APP_ID}
            autoload={"false"}
            fields="name,email,picture"
            callback={responseFacebook}
            onFailure={handleFailure}
            render={(renderProps) => (
              <button
                type="button"
                onClick={renderProps.onClick}
                className="btn-social-size login-modal-social-fields"
              >
                <AiFillFacebook color={"#1877f2"} />
              </button>
            )}
          />
          <LinkedIn
            clientId={LINKEDIN_CLIENT_ID}
            onSuccess={responseLinkedIn}
            onFailure={handleFailure}
            redirectUri={LINKED_IN_REDIRECT_URL}
            scope="r_liteprofile r_emailaddress"
            state="KbJEF5ziGsWfvigGQiAQ"
            renderElement={({ onClick, disabled }) => (
              <button
                type="button"
                className="btn-social-size login-modal-social-fields"
                onClick={onClick}
                disabled={disabled}
              >
                <AiFillLinkedin color={"#0a66c2"} />
              </button>
            )}
          />
          <AppleLogin
            clientId={APPLE_CLIENT_ID}
            redirectURI={APPLE_REDIRECT_URL}
            usePopup={true}
            scope="email name"
            state="state"
            nonce="nonce"
            callback={responseApple}
            onFailure={handleFailure}
            render={(renderProps) => (
              <button
                type="button"
                className="btn-social-size login-modal-social-fields"
                onClick={() => {
                  renderProps.onClick();
                }}
                usePopup={true}
                autoload={"false"}
              >
                <FaApple className="logo" />
              </button>
            )}
          />
        </div>
      </div> */}
      <div className="form__social">
        <div className="row">
          <div className="col-md-6">
            <div className="socail__block gp">
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                render={(renderProps) => (
                  <button
                    type="button"
                    className="btn-social-size login-modal-social-fields"
                    onClick={() => {
                      renderProps.onClick();
                    }}
                    autoload={"false"}
                  >
                    <img src={loginGplus} />
                    <span>GOOGLE</span>
                  </button>
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
              />
              {/* <button>
                        <img src={loginGplus} />
                        <span>GOOGLE</span>
                      </button> */}
            </div>
          </div>

          <div className="col-md-6">
            <div className="socail__block fb">
              <FacebookLogin
                appId={FB_APP_ID}
                autoload={"false"}
                fields="name,email,picture"
                callback={responseFacebook}
                onFailure={handleFailure}
                render={(renderProps) => (
                  <button
                    type="button"
                    onClick={renderProps.onClick}
                    className="btn-social-size login-modal-social-fields"
                  >
                    <img src={loginFb} />
                    <span>FACEBOOK</span>
                  </button>
                )}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="socail__block li">
              <LinkedIn
                clientId={LINKEDIN_CLIENT_ID}
                onSuccess={responseLinkedIn}
                onFailure={handleFailure}
                redirectUri={LINKED_IN_REDIRECT_URL}
                scope="r_liteprofile r_emailaddress"
                state="KbJEF5ziGsWfvigGQiAQ"
                renderElement={({ onClick, disabled }) => (
                  <button
                    type="button"
                    className="btn-social-size login-modal-social-fields"
                    onClick={onClick}
                    disabled={disabled}
                  >
                    <img src={loginLinkedIn} />
                    <span>LINKEDIN</span>
                  </button>
                )}
              />
              {/* <button>
                <img src={loginLinkedIn} />
                <span>LINKEDIN</span>
              </button> */}
            </div>
          </div>

          <div className="col-md-6">
            <div className="socail__block apple">
              <AppleLogin
                clientId={APPLE_CLIENT_ID}
                redirectURI={APPLE_REDIRECT_URL}
                usePopup={true}
                scope="email name"
                state="state"
                nonce="nonce"
                callback={responseApple}
                onFailure={handleFailure}
                render={(renderProps) => (
                  <button
                    type="button"
                    className="btn-social-size login-modal-social-fields"
                    onClick={() => {
                      renderProps.onClick();
                    }}
                    usePopup={true}
                    autoload={"false"}
                  >
                    <img src={loginApple} />
                    <span>APPLE</span>
                  </button>
                )}
              />
            </div>
          </div>

          {/* <div className="col-md-12">
            <div className="or__connect">
              <p className="font__semibold">
                New Member?
                <Link to={PATH.LANDLORD_QUESTIONS}>
                  <span className="linkText">Signup Now</span>
                </Link>
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
}