import { COUPON_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
    couponList: [],
    couponListLoading: false,
    couponListFailure: false,
    couponListSuccess: false,
    couponListError: null,
    deleteCouponLoading: false,
    deleteCouponFailure: false,
    deleteCouponSuccess: false,
    deleteCouponError: null,
    addCouponLoading: false,
    addCouponFailure: false,
    addCouponSuccess: false,
    addCouponError: null,
    couponPlanLoading: false,
    couponPlanSuccess: false,
    couponPlanFailure: false,
    couponPlanError: null,
    couponPlan: [],
    editCouponPlanLoading: false,
    editCouponPlanSuccess: false,
    editCouponPlanFailure: false,
    editCouponPlanError: null,
    couponUsersLoading: false,
    couponUsersSuccess: false,
    couponUsersFailure: false,
    couponUsersError: null,
    couponUsers: [],
    couponsAddonsLoading: false,
    couponsAddonsSuccess: false,
    couponsAddonsFailure: false,
    couponsAddonsError: null,
    couponsAddons: [],
}

export const couponsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COUPON_REQUEST.GET_COUPON_USER_REQUEST:
            return {
                ...state,
                couponUsersLoading: true,
                couponUsersSuccess: false,
                couponUsersFailure: false,
                couponUsersError: null,
                couponUsers: []
            }
        case COUPON_REQUEST.GET_COUPON_USER_SUCCESS:
            return {
                ...state,
                couponUsersLoading: false,
                couponUsersSuccess: true,
                couponUsersFailure: false,
                couponUsersError: null,
                couponUsers: action.payload
            }
        case COUPON_REQUEST.GET_COUPON_USER_FAILURE:
            return {
                ...state,
                couponUsersLoading: false,
                couponUsersSuccess: false,
                couponUsersFailure: true,
                couponUsersError: action.payload
            }
        case COUPON_REQUEST.GET_ALL_COUPON_REQUEST:
            return {
                ...state,
                couponList: [],
                couponListLoading: true,
                couponListFailure: false,
                couponListSuccess: false,
                couponListError: null,
            }
        case COUPON_REQUEST.GET_ALL_COUPON_SUCCESS:
            return {
                ...state,
                couponList: action.payload,
                couponListLoading: false,
                couponListFailure: false,
                couponListSuccess: true,
                couponListError: null,
            }
        case COUPON_REQUEST.GET_ALL_COUPON_FAILURE:
            return {
                ...state,
                couponList: [],
                couponListLoading: false,
                couponListFailure: true,
                couponListSuccess: false,
                couponListError: action.payload,
            }
        case COUPON_REQUEST.RESET_DELETE_COUPON:
            return {
                ...state,
                deleteCouponLoading: false,
                deleteCouponFailure: false,
                deleteCouponSuccess: false,
                deleteCouponError: null,
            }
        case COUPON_REQUEST.RESET_ADD_OR_EDIT_COUPON:
            return {
                ...state,
                addCouponLoading: false,
                addCouponFailure: false,
                addCouponSuccess: false,
                addCouponError: null,
                editCouponPlanLoading: false,
                editCouponPlanSuccess: false,
                editCouponPlanFailure: false,
                editCouponPlanError: null
            }
        case COUPON_REQUEST.ADD_OR_EDIT_COUPON:
            return {
                ...state,
                deleteCouponLoading: false,
                deleteCouponFailure: false,
                deleteCouponSuccess: false,
                deleteCouponError: null,
            }
        case COUPON_REQUEST.DELETE_COUPON_REQUEST:
            return {
                ...state,
                deleteCouponLoading: true,
                deleteCouponFailure: false,
                deleteCouponSuccess: false,
                deleteCouponError: null,
            }
        case COUPON_REQUEST.DELETE_COUPON_SUCCESS:
            let myCouponList = state.couponList;
            for (let i = 0; i < myCouponList.length; i++) {
                if (myCouponList[i].id === action.payload) {
                    myCouponList.splice(i, 1)
                }
            }
            return {
                ...state,
                deleteCouponLoading: false,
                deleteCouponFailure: false,
                deleteCouponSuccess: true,
                deleteCouponError: null,
                couponList: myCouponList
            }
        case COUPON_REQUEST.DELETE_COUPON_FAILURE:
            return {
                ...state,
                deleteCouponLoading: false,
                deleteCouponFailure: true,
                deleteCouponSuccess: false,
                deleteCouponError: action.payload,
            }
        case COUPON_REQUEST.ADD_OR_UPDATE_COUPON_REQUEST:
            return {
                ...state,
                addCouponLoading: true,
                addCouponFailure: false,
                addCouponSuccess: false,
                addCouponError: null,
            }
        case COUPON_REQUEST.ADD_OR_UPDATE_COUPON_SUCCESS:

            return {
                ...state,
                addCouponLoading: false,
                addCouponFailure: false,
                addCouponSuccess: true,
                addCouponError: null
            }
        case COUPON_REQUEST.ADD_OR_UPDATE_COUPON_FAILURE:
            return {
                ...state,
                addCouponLoading: false,
                addCouponFailure: true,
                addCouponSuccess: false,
                addCouponError: action.payload,
            }
        case COUPON_REQUEST.GET_ALL_COUPON_PLAN_REQUEST:
            return {
                ...state,
                couponPlanLoading: true,
                couponPlanSuccess: false,
                couponPlanFailure: false,
                couponPlanError: null
            }
        case COUPON_REQUEST.GET_ALL_COUPON_PLAN_SUCCESS:
            return {
                ...state,
                couponPlanLoading: false,
                couponPlanSuccess: true,
                couponPlanFailure: false,
                couponPlanError: null,
                couponPlan: action.payload
            }
        case COUPON_REQUEST.GET_ALL_COUPON_PLAN_FAILURE:
            return {
                ...state,
                couponPlanLoading: false,
                couponPlanSuccess: false,
                couponPlanFailure: true,
                couponPlanError: action.payload
            }
        case COUPON_REQUEST.EDIT_COUPON_REQUEST:
            return {
                ...state,
                editCouponPlanLoading: true,
                editCouponPlanSuccess: false,
                editCouponPlanFailure: false,
                editCouponPlanError: null
            }
        case COUPON_REQUEST.EDIT_COUPON_SUCCESS:
            let allCouponList = state.couponList
            for (let i = 0; i < allCouponList.length; i++) {
                if (allCouponList[i].id === action.payload.id) {
                    allCouponList[i] = action.payload
                }
            }
            return {
                ...state,
                editCouponPlanLoading: false,
                editCouponPlanSuccess: true,
                editCouponPlanFailure: false,
                editCouponPlanError: null,
                couponList: allCouponList
            }
        case COUPON_REQUEST.EDIT_COUPON_FAILURE:
            return {
                ...state,
                editCouponPlanLoading: false,
                editCouponPlanSuccess: false,
                editCouponPlanFailure: true,
                editCouponPlanError: action.payload
            }
            case COUPON_REQUEST.GET_COUPONS_ADDONS_REQUEST:
            return {
                ...state,
                couponsAddonsLoading: true,
                couponsAddonsSuccess: false,
                couponsAddonsFailure: false,
                couponsAddonsError: null
            }
        case COUPON_REQUEST.GET_COUPONS_ADDONS_SUCCESS:
            return {
                ...state,
                couponsAddonsLoading: false,
                couponsAddonsSuccess: true,
                couponsAddonsFailure: false,
                couponsAddonsError: null,
                couponsAddons: action.payload
            }
        case COUPON_REQUEST.GET_COUPONS_ADDONS_FAILURE:
            return {
                ...state,
                couponsAddonsLoading: false,
                couponsAddonsSuccess: false,
                couponsAddonsFailure: true,
                couponsAddonsError: action.payload
            }
        default:
            return state
    }
}