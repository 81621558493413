import React, { useState } from "react";
import ReactPaginate from 'react-paginate';
export function PaginationComponent(props) {
    const [active, setActive] = useState(props && props.page ? props.page : 0)
    const handlePageClick = (data) => {
        let selected = data.selected;
        props.pageChange(selected + 1)
        setActive(selected)
    };
    return (
        <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            forcePage={active}
            breakClassName={'break-me'}
            pageCount={props.totalNumber}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'} />
    )
}