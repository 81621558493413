import { APP_SETTINGS_New } from "../../config";
import axios from "axios";

export function getPhotosRuleSets(id) {
  return axios.get(
    `${APP_SETTINGS_New.API_Path.RuleSet.getPhotosRuleSets}/` + id
  );
}
export function getSpacesByRequirementAPI(inputData) {
  return axios.post(
    `${APP_SETTINGS_New.API_Path.RuleSet.getSpacesByRequirement}`,
    inputData
  );
}
export function getSpacesAnalytics(inputData) {
  return axios.post(
    `${APP_SETTINGS_New.API_Path.RuleSet.getSpacesAnalytics}`,
    inputData
  );
}
