import React, { useState, useEffect } from "react";
import { FieldError } from "../../assets";
const LeaseSignagePlacement = (props) => {
  return (
    <div className="steps__block">
      <div className="main-heading-blue text-center">
        <p>
          <span className="navbar-header-title">REDHANDED</span> offers a
          variety of leasing sign packages for your space.
        </p>
      </div>

      <div className="landlord-quiz-checkbox text-center">
        {props.options.optionsData
          ? props.options.optionsData.map((option, index) => {
              return (
                <div>
                  <input
                    type="checkbox"
                    id={option.optionId}
                    value={option.optionId}
                    onChange={props.handleOnChange}
                    checked={option.checked}
                  />
                  <label for={option.optionId}>{option.optionText}</label>
                </div>
              );
            })
          : null}
        {props.isleaseSignage && (
          <FieldError message={"At least 1 selected field required"} />
        )}
        {/* <div><input type="checkbox" id="window" /><label for="window">I have a great window that will be seen by people walking by</label></div>
          <div><input type="checkbox" id="ExternalWall" /><label for="ExternalWall">I have an External Wall - Sign Band</label></div>
          <div><input type="checkbox" id="signpost" /><label for="signpost">I will need to hang my sign on a sign post away from my building</label></div>
          <div><input type="checkbox" id="determining" /><label for="determining">I need help determining where to hang my sign</label></div>
          <div><input type="checkbox" id="notAble"/><label for="notAble">I am not able to hang a <span className="small__text">REDHANDED</span> sign on my space</label></div> */}
      </div>
    </div>
  );
};

export default LeaseSignagePlacement;
