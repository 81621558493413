import React, { useState, useRef } from "react";
import { IMAGES } from "../images";
import Slider from "react-slick";
import { TESTIMONIAL_VIDEOS_LINKS } from "../../config";
import { AiFillCloseCircle, AiFillPlayCircle } from "react-icons/ai";
import {
    BsChevronCompactLeft,
    BsChevronCompactRight
} from "react-icons/bs";

export function Testimonial() {
    let ref = useRef();
    function NextClick() {
        if (ref)
            ref.current.slickNext()
    }
    function PreviousClick() {
        if (ref)
            ref.current.slickPrev()
    };
    var settings = {
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        speed: 500,
        adaptiveHeight: true,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <React.Fragment>
            <div className="testimonial" style={{ position: "relative" }}>
                <h5 className="generic-heading flex-center text-white">
                    <span></span>
                    {"Testimonials"}
                </h5>
                <BsChevronCompactLeft className="cursor"
                    onClick={() => { PreviousClick() }}
                    style={{ color: "#a80000", position: "absolute", top: "50%", left: "-10px", zIndex: 10, fontSize: 90 }} />
                <Slider
                    ref={ref}
                    style={{ zIndex: "4", marginBottom: 0 }}
                    className="carousel"
                    {...settings}>
                    {
                        UserList
                        &&
                        UserList.map((user, index) => {
                            return (
                                <CarouselUserList
                                    key={index}
                                    user={user}
                                />
                            )
                        })
                    }
                </Slider>
                <BsChevronCompactRight className="cursor"
                    onClick={() => { NextClick() }}
                    style={{ color: "#a80000", position: "absolute", top: "50%", right: "-10px", zIndex: 10, fontSize: 90 }} />

            </div >
        </React.Fragment>
    )
}

let UserList = [
    {
        id: 0,
        image: IMAGES.CHRIS,
        name: "Chris",
        role: "Property Manager",
        des: " REDHANDED drastically changed the way we view the leasing process.  It allows me to take full control, after all who knows our space better than us"
    },
    {
        id: 1,
        image: IMAGES.FRANK,
        name: "Frank",
        role: "Commercial Property Owner",
        des: "I was a pleasantly surprised, pleased with our results and saved a lot of money"
    },
    {
        id: 2,
        image: IMAGES.JENNIFER,
        name: "Jennifer",
        role: "Owner of a Beauty and Wellness Center",
        des: "REDHANDED gives us the ability to pursue other options on our terms and we realized that the Landlord can be a partner in our venture"
    },
    {
        id: 3,
        image: IMAGES.WILLIAM,
        name: "William",
        role: "Property Manager",
        des: "I love the transparency REDHANDED gives us.   So far it gives us hope for a new start"
    }
]

function CarouselUserList(props) {
    const [video, setVideo] = useState(false)
    return (
        <div className="carousel-user carousel-user-active small-carousel" style={{ position: "relative" }}>
            <div>
                <div onClick={() => { setVideo(!video) }} style={{ position: "absolute", right: "1rem", top: "1rem", zIndex: 555 }}>
                    {video === true ? <AiFillCloseCircle color={"#fff"} className="video-icon" /> : <AiFillPlayCircle className="video-icon" />}
                </div>
                {
                    video === false
                        ?
                        <div className="carousel-body">
                            <div className="user-pic">
                                <img alt="" src={props.user.image} />
                            </div>
                            <h4 className="text-align-center user-name">
                                {props.user.name}
                            </h4>
                            <p className="text-align-center cl-l-blue">
                                {props.user.role}
                            </p>
                            <div className="d-flex flex-center">
                                <img alt="" width="20" src={IMAGES.QUOTES} />
                            </div>
                            <p>
                                {props.user.des}
                            </p>
                        </div>
                        :
                        <div>
                            <iframe title="video" src={TESTIMONIAL_VIDEOS_LINKS[props.user.id].url}
                                height="280px"
                                width="100%"
                                id="myId"
                                className="myClassname"
                                display="initial"
                                position="relative" />
                        </div>
                }

            </div>
        </div>
    )
}