// A wrapper for <Route> that redirects to the login

import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { PATH, ROLE, ROLEID } from "../../config";
import { useAuth } from "./ProvideAuth";
import { expiryDateCheck, NavBarAdmin, NavBarAdminWithoutSideBar } from "../../assets";
import Sidebar from "../../assets/genericComponents/SideBar";
import { useHistory } from "react-router-dom";
import { Footer } from "../../components/LandingPage/Sections/Footer"
import { NavigationBar } from "../../components/LandingPage/Sections/NavigationBar"
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  let history = useHistory();
  const [show, setShow] = useState(true);
  return (
    <Route
      {...rest}
      onUpdate={() => window.scrollTo(0, 0)}
      render={({ location }) =>
        auth.real_estate_user && auth.real_estate_user.token && auth.real_estate_user.roleId ? (
          <React.Fragment>
            {
              auth.real_estate_user.roleId === ROLEID.ADMIN
                ?
                <React.Fragment>
                  {
                    history.location.pathname.includes("edit-post-property") ?
                      <div className="container-fluid admin-main-parent">
                        <NavBarAdminWithoutSideBar />
                        {children}
                      </div>
                      :
                      history.location.pathname.includes("edit-landlord") ?
                        <div className="container-fluid admin-main-parent">
                          <NavBarAdminWithoutSideBar />
                          {children}
                        </div>
                        :
                        <div className="container-fluid admin-main-parent">
                          {console.log("in console bar::::", history.location.pathname)}
                          <Sidebar
                            show={show}
                            setShow={setShow} />
                          <NavBarAdmin />
                          {children}
                        </div>
                  }
                </React.Fragment>
                :
                // (true)
                //   ?
                  <React.Fragment>
                    {
                      history.location.pathname.includes("property-signage") === false
                      &&
                      <NavigationBar />
                    }
                    <div style={{ marginTop: 60 }}>
                      {children}
                    </div>
                    {
                      history.location.pathname.includes("property-signage") === false
                      &&
                      <Footer />
                    }

                  </React.Fragment>
                  // :
                  // <Redirect
                  //   to={{
                  //     pathname: PATH.PROFILE.replace(":role", ROLE.LANDLORD),
                  //     state: {
                  //       value: 1,
                  //       expired: true,
                  //       from: location
                  //     }
                  //   }}
                  // />
            }
          </React.Fragment>

        ) : (
          auth.real_estate_user && auth.real_estate_user.token && auth.real_estate_user.roleId === null
            ?
            <Redirect
              to={{
                pathname: PATH.SIGNUP, state: { from: location },
              }}
            />
            :
            <Redirect
              to={{
                pathname: PATH.HOME,
                state: { from: location },
              }}
            />
        )
      }
    />
  );
}
export default PrivateRoute;

export function PrivateProfileRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (auth.real_estate_user && auth.real_estate_user.token && auth.real_estate_user.roleId) ? (
          <React.Fragment>
            <NavigationBar />
            {children}
            <Footer />
          </React.Fragment>

        ) : (
          <Redirect
            to={{
              pathname: PATH.HOME,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}