/* eslint-disable */
import React, { Suspense, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./Navigation/RouterConfig";
import { ProvideAuth } from "./Navigation/Auth/ProvideAuth";
import socketClient from "socket.io-client";
import { Loader, checkMobileAndTablet } from "./assets";
import { onMessageListener } from "./firebaseInit";
import { WEB_SOCKET_URL } from "./config";
import ReactNotificationComponent from "./components/Notifications/ReactNotification"
import Notifications from "./components/Notifications/Notifications"
import { useDispatch } from "react-redux";
import { REGISTRATION_V2_REQUEST, success } from "./redux/actions/utilities";
import { NotificationContainer } from "react-notifications";
var socket = socketClient(WEB_SOCKET_URL);
socket.on("connect", () => {
  console.log("signal1", socket.connected)
});
export { socket };
function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  let dispatch = useDispatch();
  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });

      dispatch(success(REGISTRATION_V2_REQUEST.NOTIFICATION_RECIEVED, payload))
    })
    .catch((err) => console.log("failed: ", err));
  console.log("my result:::", checkMobileAndTablet())
  return (
    <Suspense fallback={<Loader />}>
      <ProvideAuth>
        <BrowserRouter>
          <RouterConfig />
        </BrowserRouter>

        <ReactNotificationComponent
          setMe={setShow}
          title={notification.title}
          body={notification.body} />

        <Notifications />
        <NotificationContainer />
      </ProvideAuth>
    </Suspense>
  );
}

export default App;
