import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import appleLogo from "../../assets/images/apple-logo.svg";
import playstoreLogo from "../../assets/images/playstore-logo.svg";
import bannerMobile from "../../assets/images/bannerMobile.png";

import stepsAndriod from "../../assets/images/steps__andriod.png";
import redhandedLeftimg from "../../assets/images/redhanded__leftimg.png";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { PATH } from "../../config";
import { getCouponDiscountedAmount } from "../../assets";

const ThankYou = () => {
  let auth = useAuth();
  let history = useHistory();
  let orderSummaryDetails = useSelector(
    (state) => state.landLordSignUpFlow.orderSummaryDetails
  );
  let couponDetails = useSelector(
    (state) => state.landLordSignUpFlow.addonsCouponDetails
  );
  const [postSpace, setpostSpace] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let price = 0;
    orderSummaryDetails.addonsList &&
      orderSummaryDetails.addonsList.map((addon) => {
        if (addon.isAddonPurchased) {
          price =
            price +
            (addon.discountPrice == 0 ? addon.price : addon.discountPrice);
        }
      });
    var planPrice =
      Object.keys(couponDetails).length !== 0 &&
      couponDetails.metadata.product
        ? parseFloat(
            `${
              getCouponDiscountedAmount(
                couponDetails,
                orderSummaryDetails && orderSummaryDetails.planPrice
                  ? orderSummaryDetails.planPrice *
                      orderSummaryDetails.planInterval
                  : 0
              ).total
            }`
          )
        : orderSummaryDetails.planPrice * orderSummaryDetails.planInterval;
    setTotalPrice(planPrice + price);
  }, [orderSummaryDetails]);

  const handleSubmit = () => {
    console.log(auth.real_estate_user);
    if (auth.real_estate_user) {
      history.push(
        history?.location?.state?.from?.pathname
          ? history.location.state.from.pathname
          : postSpace == "Yes"
          ? PATH.POSTPROPERTY.replace(":type", "post")
          : PATH.DASHBOARD
      );
    }
  };

  return (
    <div className="thankyou__page">
      <div className="row">
        <div className="col-md-6">
          <div className="right__bannertext">
            <h3>
              <span className="redtextcolor font__bold">Thank you,</span> <br />{" "}
              your{" "}
              <span className="redtextcolor font__bold text-uppercase">
                REDHANDED
              </span>{" "}
              <br /> Account has been <br /> successfully created!
            </h3>
            {/* <p>
              To start using your plan, please download the{" "}
              <span className="redtextcolor font__bold text-uppercase">
                REDHANDED
              </span>{" "}
              app
            </p> */}
            {/* <div className="banner__btn">
              <button className="appleLogo">
                <img src={appleLogo} />
                <div className="text">
                  <p>Download on the</p>
                  <h5>App Store</h5>
                </div>
              </button>
              <button className="playstoreLogo">
                <img src={playstoreLogo} />
                <div className="text">
                  <p>Get it on</p>
                  <h5>Google Play</h5>
                </div>
              </button>
            </div> */}
          </div>
        </div>

        <div className="col-md-6">
          <div className="rightbanner__img">
            <img src={bannerMobile} />
          </div>
        </div>
      </div>

      <div className="row order__summary">
        <div className="col-md-12">
          <div className="text-center">
            <h3>
              Order <span className="redtextcolor font__bold">Summary</span>
            </h3>
          </div>
        </div>
        <div className="col-md-6 mx-auto">
          <div className="order__summarytable">
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <p>{orderSummaryDetails.planName} subscription</p>
                  </td>
                  <td>
                    <p className="text-right font__bold">
                      $
                      {Object.keys(couponDetails).length !== 0 &&
                      couponDetails.metadata.product
                        ? `${
                            getCouponDiscountedAmount(
                              couponDetails,
                              orderSummaryDetails &&
                                orderSummaryDetails.planPrice
                                ? orderSummaryDetails.planPrice *
                                    orderSummaryDetails.planInterval
                                : 0
                            ).total
                          }`
                        : orderSummaryDetails.planPrice *
                          orderSummaryDetails.planInterval}
                    </p>
                  </td>
                </tr>
                {orderSummaryDetails.addonsList &&
                  orderSummaryDetails.addonsList.map((addon) => {
                    return (
                      <tr>
                        <td>
                          <p>
                            {addon.addonName}{" "}
                            {/* {!addon.isAddonPurchased ? (
                              <span className="learn__more">Learn More</span>
                            ) : null} */}
                          </p>
                        </td>

                        <td>
                          <p className="text-right font__bold">
                            {addon.isAddonPurchased ? (
                              `$${
                                addon.discountPrice == 0
                                  ? addon.price
                                  : addon.discountPrice
                              }`
                            ) : (
                              <span className="not__order">Not ordered</span>
                            )}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                {/* <tr>
                  <td>
                    <p>Virtual Tour</p>
                  </td>
                  <td>
                    <p className="text-right font__bold">$75</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Window Signage{" "}
                      <span className="learn__more">Learn More</span>
                    </p>
                  </td>
                  <td>
                    <p className="text-right font__bold">
                      <span className="not__order">Not ordered</span>
                      <span className="upgrade">Upgrade</span>
                    </p>
                  </td>
                </tr> */}
                <tr>
                  <td>
                    <p className="total">Total</p>
                  </td>
                  <td>
                    <p className="total text-right">${totalPrice}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* <div className="row howtolog">
        <div className="col-md-12">
          <div className="text-center">
            <h3>
              <span className="redtextcolor font__bold">How to log</span> into
              the app
            </h3>
          </div>
        </div>
        <div className="col-md-8 mx-auto">
          <div className="tabs__section">
            <div className="tabs__list">
              <ul>
                <li className="active">Download for IOS</li>
                <li>Download for Android</li>
              </ul>
            </div>
            <div className="tabs__body">
              <div className="tabcontent1">
                <img src={stepsAndriod} className="img-fluid" />
              </div>
            </div>
          </div>

          <p className="font__bold my-5 text-center">
            If you have a mobile device you can download the{" "}
            <span className="small__text">REDHANDED</span> app for a better
            mobile experience
          </p>
        </div>
      </div> */}

      <div className="row redhanded__network">
        <div className="col-md-6">
          <div className="left__img">
            <img src={redhandedLeftimg} className="img-fluid" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="right__text">
            <h4>
              Are you ready to post your space <br /> to the REDHANDED Network?
            </h4>
            <div className="radio__block mb-1">
              <input
                type="radio"
                checked={postSpace == "Yes"}
                onChange={() => {
                  setpostSpace("Yes");
                }}
              />
              <label>Yes, I have photos of my space and ready to go</label>
            </div>
            <div className="radio__block mb-1">
              <input
                type="radio"
                checked={postSpace == "No"}
                onChange={() => {
                  setpostSpace("No");
                }}
              />
              <label>
                Not yet, I have to get some photos of my space, I will do it
                later
              </label>
            </div>

            <button
              class="btn btn-primary pop-up-property-detaile-btn cursor btn-red-color"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
