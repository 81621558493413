import React, { useState, useEffect } from "react";
// import pro__banner from "../../assets/images/pro__banner.svg";

import onetimefee from "../../assets/images/onetimefee.svg";
import { useSelector } from "react-redux";
import { getCouponDiscountedAmount } from "../../assets";

const VirtualTour = (props) => {
  let couponDetails = useSelector(
    (state) => state.landLordSignUpFlow.addonsCouponDetails
  );

  useEffect(() => {
    if (
      Object.keys(couponDetails).length > 0 &&
      couponDetails.metadata &&
      couponDetails.metadata.addonId &&
      couponDetails.metadata.addonId
        .split(",")
        .includes(props.addOnsData.id.toString())
    ) {
      props.setOrdersAdded({
        ...props.ordersAdded,
        virtualTour: true,
      });
    }
  }, [couponDetails]);

  return (
    <div className="row dtl__content">
      <div className="col-md-12">
        <div className="multistep__content">
          <h4>
            <span>You're almost there!</span> Don't miss out on these offers to
            improve your results
          </h4>
        </div>
      </div>

      <div className="col-md-6">
        <div className="right__section virtual__tour"></div>
      </div>
      <div className="col-md-6">
        <div className="left__section virtual__tour">
          <div className="content__block">
            <h3>
              <span class="navbar-header-title">Virtual Tour</span> Promotion
            </h3>
            <p>
              A Virtual Tour allows tenants to do a virtual walkthrough of your
              space.
            </p>
            <ul>
              <li>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <span>Excellent social marketing tool</span>
              </li>
              <li>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <span>
                  Helps qualify tenants to to reduce the number of physical
                  visits to your space
                </span>
              </li>
              <li>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <span>
                  Tenants can visualize the layout of your space to see if your
                  space accommodates their business requirements
                </span>
              </li>
              <li>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <span>
                  Speeds the time for connections and negotiations for your
                  space Increase search engine rankings
                </span>
              </li>
            </ul>
            <div className="row m-0">
              <div className="col-lg-6">
                <div className="price__block">
                  <span>Normal Price ${props.addOnsData.price}</span>
                  <p>
                    Price{" "}
                    <span>
                      {/* $
                      {props.addOnsData.id == couponDetails.addonId
                        ? couponDetails.addonDiscountPrice
                        : props.addOnsData.price} */}
                      $
                      {Object.keys(couponDetails).length > 0 &&
                      couponDetails.metadata &&
                      couponDetails.metadata.addonId &&
                      couponDetails.metadata.addonId
                        .split(",")
                        .includes(props.addOnsData.id.toString())
                        ? `${
                            getCouponDiscountedAmount(
                              couponDetails,
                              props.addOnsData && props.addOnsData.price
                                ? props.addOnsData.price
                                : 0
                            ).total
                          }`
                        : props.addOnsData.price}
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="onetimefee__img">
                  <img src={onetimefee} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="bottom__button">
              {!props.ordersAdded.virtualTour ? (
                <button
                  class="btn btn-primary pop-up-property-detaile-btn cursor btn-red-color"
                  onClick={() => {
                    props.handleAddMyOrder();
                    props.setOrdersAdded({
                      ...props.ordersAdded,
                      virtualTour: true,
                    });
                  }}
                >
                  ADD TO MY ORDER
                </button>
              ) : (
                <button
                  class="btn btn-primary pop-up-property-detaile-btn cursor btn-red-color"
                  onClick={() => {
                    props.handleRemoveMyOrder();
                    props.setOrdersAdded({
                      ...props.ordersAdded,
                      virtualTour: false,
                    });
                  }}
                >
                  REMOVE FROM MY ORDER
                </button>
              )}
              <a href="#" className="linkText" onClick={props.handleNext}>
                No Thank you, I am not interested in this offer.
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualTour;
