/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getToken } from "../../firebaseInit.js";
import { success, REGISTRATION_V2_REQUEST } from "../../redux/actions/utilities.js";
const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);

  console.log("Token found", isTokenFound);
  let dispatch = useDispatch();
  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        console.log("Token is", data);
        dispatch(success(REGISTRATION_V2_REQUEST.DEVICE_TOKEN_RECIEVED, data))
      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
