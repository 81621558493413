import React, { useState, useEffect } from "react";
import { ErrorMessage, FieldError, Loader } from "../../assets";
const EmailOrContactDetails = (props) => {
  return (
    <div className="steps__block">
      <div className="main-heading-blue text-center">
        <p>Please provide us your email to send your customized leasing plan</p>
      </div>

      <div>
        <div class="did-floating-label-content">
          <input
            style={{
              borderColor: props.isEmailIdValidate && !props.emailId ? "#a80000" : "",
            }}
            class="did-floating-input"
            type="email"
            placeholder=" "
            value={props.emailId}
            onChange={props.handleOnChange}
          />
          <label class="did-floating-label">Email</label>
          {props.isEmailIdValidate && !props.emailId && <FieldError message={"This Field is Required"} />}
        </div>
      </div>

      <div className="radio__block align-items-start">
        <input type="checkbox" style={{ marginTop: "6px" }} checked={true}/>
        <label>
          I agree to the <span className="linkText">Privacy policy</span> and
          receiving future information from{" "}
          <span className="small__text">REDHANDED</span>
        </label>
      </div>
    </div>
  );
};

export default EmailOrContactDetails;
