import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import {
  getStates,
  getCities,
  getAllIndustries,
  getPropertyAddress,
} from "../../redux/actions";
import { ErrorMessage, FieldError, Loader } from "../../assets";
const SpaceAddress = (props) => {
  let dispatch = useDispatch();
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [postalCode, setPostalCode] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [prAddress, setPrAddress] = useState(null);
  let stateAndCity = useSelector((state) => state.locations);
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    dispatch(getStates());
  }, [dispatch]);

  function onChangeOfState(id, success) {
    dispatch(getCities(parseInt(id), success));
  }

  const onChangeAddRemoveClass = (e) => {
    e.target.value
      ? e.currentTarget.classList.remove("select-did-floating-input-sp")
      : e.currentTarget.classList.add("select-did-floating-input-sp");
  };

  return (
    <div className="steps__block">
      <div className="main-heading-blue text-center">
        <p>What is the address of your space?</p>
      </div>

      <div>
        <FormGroup>
          <div class="did-floating-label-content">
            <PlacesAutoCompleteBar
              errors={errors}
              setLatitude={setLatitude}
              setLongitude={setLongitude}
              postalCode={props.postalCode}
              setPostalCode={props.setPostalCode}
              state={stateAndCity}
              city={props.city}
              setCity={props.setCity}
              prAddress={prAddress}
              setPrAddress={setPrAddress}
              onChangeOfState={onChangeOfState}
              setState={props.setState}
              register={register({ required: true })}
              label="Address 1"
              setAddress1={props.setAddress1}
              address1={props.address1}
              isAddressValidate={props.isAddressValidate}
            />
            {props.isAddressValidate && !props.address1 && (
              <FieldError message={"This Field is Required"} />
            )}
          </div>
          <div class="did-floating-label-content">
            <FormControl
              // style={{
              //   borderColor: props.isAddressValidate && !props.address2 ? "#a80000" : "",
              // }}
              className="did-floating-input"
              name="secondaryAddress"
              placeholder=" "
              ref={register}
              value={props.address2}
              onChange={(e) => {
                props.setAddress2(e.target.value);
              }}
            />
            <FormLabel className="input-label-form label-title did-floating-label">
              {"Address 2"}
            </FormLabel>
            {/* {props.isAddressValidate && !props.address2 && (
              <FieldError message={"This Field is Required"} />
            )} */}
          </div>
          <div class="did-floating-label-content">
            <FormControl
              as="select"
              style={{ borderColor: errors && errors.stateId ? "#a80000" : "" }}
              className="did-floating-input"
              placeholder=" "
              name="stateId"
              ref={register({ required: true })}
              value={props.state}
              onChange={(e) => {
                props.setState(e.target.value);
                onChangeOfState(e.target.value);
                //onChangeAddRemoveClass(e);
              }}
            >
              <option value="">{"Select"}</option>
              {stateAndCity &&
                stateAndCity.stateList &&
                stateAndCity.stateList.map((state, index) => {
                  return (
                    <option value={state.stateId} key={index}>
                      {state.name}
                    </option>
                  );
                })}
            </FormControl>
            <FormLabel className="input-label-form label-title did-floating-label">
              {"State"}
            </FormLabel>
            {props.isAddressValidate && !props.state && (
              <FieldError message={"This Field is Required"} />
            )}
          </div>
          <div class="did-floating-label-content">
            <FormControl
              as="select"
              disabled={!stateAndCity.citiesSuccess}
              style={{ borderColor: errors && errors.cityId ? "#a80000" : "" }}
              className="did-floating-input"
              name="cityId"
              value={props.city}
              onChange={(e) => {
                props.setCity(e.target.value);
                //onChangeAddRemoveClass(e);
              }}
              ref={register({ required: true })}
            >
              <option value="">{"Select"}</option>
              {stateAndCity &&
                stateAndCity.citiesList &&
                stateAndCity.citiesList.map((city, index) => {
                  return (
                    <option value={city.cityId} key={index}>
                      {city.name}
                    </option>
                  );
                })}
            </FormControl>
            <FormLabel className="input-label-form label-title did-floating-label">
              {"City"}
            </FormLabel>
            {props.isAddressValidate && !props.city && (
              <FieldError message={"This Field is Required"} />
            )}
          </div>
          <div class="did-floating-label-content ">
            <FormControl
              style={{
                borderColor: errors && errors.secondaryAddress ? "#a80000" : "",
              }}
              className="did-floating-input"
              name="postalCode"
              placeholder=" "
              ref={register}
              value={props.postalCode}
              onChange={(e) => {
                console.log(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(e.target.value));
                  props.setPostalCode(e.target.value);
              }}
            />
            <FormLabel className="input-label-form label-title did-floating-label">
              {"Zip Code"}
            </FormLabel>
            {props.isAddressValidate && !props.postalCode && (
              <FieldError message={"This Field is Required"} />
            )}
            {props.postalCode && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(props.postalCode) && (
              <FieldError message={"Invalid Zip Code"} />
            )}
          </div>
        </FormGroup>
      </div>
    </div>
  );
};

export default SpaceAddress;

class PlacesAutoCompleteBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.value,
      hover: false,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapCenter: {
        lat: 40.7127753,
        lng: -74.0059728,
      },
      city: null,
    };
  }
  handleChange = (address) => {
    this.props.setAddress1(address);
  };

  findCity = () => {
    if (this.state.city) {
      for (let i = 0; i < this.props.state.citiesList.length; i++) {
        if (this.state.city === this.props.state.citiesList[i].name) {
          this.props.setCity(this.props.state.citiesList[i].cityId);
        }
      }
    }
  };
  handleSelect = (address, placeId) => {
    this.props.setAddress1(address);
    this.props.setPrAddress(address);
    geocodeByPlaceId(placeId).then((result) => {
      const { long_name: postalCode = "" } =
        result[0].address_components.find((c) =>
          c.types.includes("postal_code")
        ) || {};
      if (this.props.setPostalCode) {
        this.props.setPostalCode(postalCode);
      }
      const { long_name: state = "" } =
        result[0].address_components.find((c) =>
          c.types.includes("administrative_area_level_1")
        ) || {};
      const { long_name: city = "" } =
        result[0].address_components.find((c) =>
          c.types.includes("locality")
        ) || {};
      this.setState({ city: city });
      for (let i = 0; i < this.props.state.stateList.length; i++) {
        if (state === this.props.state.stateList[i].name) {
          this.props.setState(this.props.state.stateList[i].stateId);
          this.props.onChangeOfState(
            this.props.state.stateList[i].stateId,
            this.findCity
          );
        }
      }
    });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.props.setLatitude(latLng.lat);
        this.props.setLongitude(latLng.lng);
        this.setState({ mapCenter: latLng });
      })
      .catch((error) => console.error("Esrror", error));
  };
  changeOfAddress() {}
  render() {
    return (
      <PlacesAutocomplete
        value={this.props.address1}
        searchOptions={{
          componentRestrictions: { country: "us" },
        }}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <React.Fragment>
              <Form inline>
                <input
                  style={{
                    borderColor: this.props.isAddressValidate && !this.props.address1 ? "#a80000" : "",
                  }}
                  name="primaryAddress"
                  value={this.props.address1}
                  onChange={() => {
                    this.changeOfAddress();
                  }}
                  {...getInputProps({
                    className:
                      "location-search-input w-100 form-control did-floating-input",
                  })}
                  ref={this.props.register}
                  placeholder=" "
                />
                <FormLabel className="input-label-form label-title did-floating-label">
                  {this.props.label}
                </FormLabel>
              </Form>
              <div className="autocomplete-dropdown-container">
                {loading && <div>{"Loading..."}</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}
