import { APP_SETTINGS_New } from "../../config";
import axios from "axios";

export function getTextbyOpenAIEdit(inputData) {
  debugger;
  return axios.post(
    `${APP_SETTINGS_New.API_Path.AI.getTextbyOpenAIEdit}`,
    inputData
  );
}
export function getTextbyOpenAICompletion(inputData) {
  return axios.post(
    `${APP_SETTINGS_New.API_Path.AI.getTextbyOpenAICompletionAPI}`,
    inputData
  );
}
export function getTextByDeepAI(inputData) {
  return axios.post(
    `${APP_SETTINGS_New.API_Path.AI.getTextByDeepAI}`,
    inputData
  );
}
export function getTextByCortex(inputData) {
  return axios.post(
    `${APP_SETTINGS_New.API_Path.AI.getTextByCortex}`,
    inputData
  );
}
export function getTextbyChatGPT(inputData) {
  debugger;
  return axios.post(
    `${APP_SETTINGS_New.API_Path.AI.getTextbyChatGPT}`,
    inputData
  );
}
