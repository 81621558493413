import { PUSH_NOTIFICATION_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
    getAllNotificationLoading: false,
    getAllNotificationSuccess: false,
    getAllNotificationFailure: false,
    getAllNotificationError: null,
    getAllNotificationList: [],
    notificationListCount: 0,

    updateNotificationSettingLoading: false,
    updateNotificationSettingSuccess: false,
    updateNotificationSettingFailure: false,
    updateNotificationSettingError: null,

    getNotificationSettingLoading: false,
    getNotificationSettingSuccess: false,
    getNotificationSettingFailure: false,
    getNotificationSettingError: null,
    getNotificationSetting: [],

    getUnreadNotificationCountLoading: false,
    getUnreadNotificationCountSuccess: false,
    getUnreadNotificationCountFailure: false,
    getUnreadNotificationCountError: null,
    unreadNotificationCount: 0,

    readNotificationLoading: false,
    readNotificationSuccess: false,
    readNotificationFailure: false,
    readNotificationError: null,
    readNotification: [],
}
// unreadCount

export const PushNotificationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PUSH_NOTIFICATION_REQUEST.GET_ALL_NOTIFICATION_REQUEST:
            return {
                ...state,
                getAllNotificationLoading: true,
                getAllNotificationSuccess: false,
                getAllNotificationFailure: false,
                getAllNotificationError: null,
                getAllNotificationList: [],
                notificationListCount: 0,
            }
        case PUSH_NOTIFICATION_REQUEST.GET_ALL_NOTIFICATION_SUCCESS:
            console.log("action.payload", action.payload)
            return {
                ...state,
                getAllNotificationLoading: false,
                getAllNotificationSuccess: true,
                getAllNotificationFailure: false,
                getAllNotificationError: null,
                getAllNotificationList: action.payload,
                notificationListCount: action.payload[0]?.totalRecord
            }
        case PUSH_NOTIFICATION_REQUEST.GET_ALL_NOTIFICATION_FAILURE:
            return {
                ...state,
                getAllNotificationLoading: false,
                getAllNotificationSuccess: false,
                getAllNotificationFailure: true,
                getAllNotificationError: action.payload,
                notificationListCount: 0
            }
        case PUSH_NOTIFICATION_REQUEST.UPDATE_NOTIFICATION_SETTING_REQUEST:
            return {
                ...state,
                updateNotificationSettingLoading: true,
                updateNotificationSettingSuccess: false,
                updateNotificationSettingFailure: false,
                updateNotificationSettingError: null,

            }
        case PUSH_NOTIFICATION_REQUEST.UPDATE_NOTIFICATION_SETTING_SUCCESS:
            return {
                ...state,
                updateNotificationSettingLoading: false,
                updateNotificationSettingSuccess: true,
                updateNotificationSettingFailure: false,
                updateNotificationSettingError: null,
                getNotificationSetting: action.payload
            }
        case PUSH_NOTIFICATION_REQUEST.UPDATE_NOTIFICATION_SETTING_FAILURE:
            return {
                ...state,
                updateNotificationSettingLoading: false,
                updateNotificationSettingSuccess: false,
                updateNotificationSettingFailure: true,
                updateNotificationSettingError: action.payload
            }
        case PUSH_NOTIFICATION_REQUEST.GET_PUSH_NOTIFICATION_SETTING_REQUEST:
            return {
                ...state,
                getNotificationSettingLoading: true,
                getNotificationSettingSuccess: false,
                getNotificationSettingFailure: false,
                getNotificationSettingError: null,
                getNotificationSetting: []

            }
        case PUSH_NOTIFICATION_REQUEST.GET_PUSH_NOTIFICATION_SETTING_SUCCESS:
            return {
                ...state,
                getNotificationSettingLoading: false,
                getNotificationSettingSuccess: true,
                getNotificationSettingFailure: false,
                getNotificationSettingError: null,
                getNotificationSetting: action.payload
            }
        case PUSH_NOTIFICATION_REQUEST.GET_PUSH_NOTIFICATION_SETTING_FAILURE:
            return {
                ...state,
                getNotificationSettingLoading: false,
                getNotificationSettingSuccess: false,
                getNotificationSettingFailure: true,
                getNotificationSettingError: action.payload,
            }
        case PUSH_NOTIFICATION_REQUEST.GET_UNREAD_NOTIFICATION_COUNT_REQUEST:
            return {
                ...state,
                getUnreadNotificationCountLoading: true,
                getUnreadNotificationCountSuccess: false,
                getUnreadNotificationCountFailure: false,
                getUnreadNotificationCountError: null,
                // unreadNotificationCount: 0

            }
        case PUSH_NOTIFICATION_REQUEST.GET_UNREAD_NOTIFICATION_COUNT_SUCCESS:
            return {
                ...state,
                getUnreadNotificationCountLoading: false,
                getUnreadNotificationCountSuccess: true,
                getUnreadNotificationCountFailure: false,
                getUnreadNotificationCountError: null,
                unreadNotificationCount: action.payload.unreadCount
            }
        case PUSH_NOTIFICATION_REQUEST.GET_UNREAD_NOTIFICATION_COUNT_FAILURE:
            return {
                ...state,
                getUnreadNotificationCountLoading: false,
                getUnreadNotificationCountSuccess: false,
                getUnreadNotificationCountFailure: true,
                getUnreadNotificationCountError: action.payload
            }
        case PUSH_NOTIFICATION_REQUEST.READ_NOTIFICATION_REQUEST:
            return {
                ...state,
                readNotificationLoading: true,
                readNotificationSuccess: false,
                readNotificationFailure: false,
                readNotificationError: null,
                readNotification: []

            }
        case PUSH_NOTIFICATION_REQUEST.READ_NOTIFICATION_SUCCESS:
            return {
                ...state,
                readNotificationLoading: false,
                readNotificationSuccess: true,
                readNotificationFailure: false,
                readNotificationError: null,
                readNotification: action.payload
            }
        case PUSH_NOTIFICATION_REQUEST.READ_NOTIFICATION_FAILURE:
            return {
                ...state,
                readNotificationLoading: false,
                readNotificationSuccess: false,
                readNotificationFailure: true,
                readNotificationError: action.payload,
            }
        default:
            return state;
    }
}