import { request, success, failure, PRODUCT_LIST } from './utilities';
import { getProductList } from '../api';

export function getProductListAction() {
    return dispatch => {
        //dispatch((PRODUCT_LIST.GET_PRODUCT_LIST));
        getProductList().then(
            (response) => {
                if(response.status === 200) {
                    dispatch(success(PRODUCT_LIST.GET_PRODUCT_LIST, response.data.productList))
                }
            }
        )
    };
}

export function setSelectedProductsAction(item) {
    return dispatch => {
        if(item.length !== 0) {
            dispatch(success(PRODUCT_LIST.SELECT_PRODUCT, item));
        }
    }
}

export function removeFromCart(key) {
    return dispatch => {
       // if(key.length) {
            dispatch(success(PRODUCT_LIST.REMOVE_FROM_CART, key));
        //}
    }
}
