import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import '../styles/table.css'

const GenericTable = (props) => {

    return (
        <BootstrapTable
            {...props}
            classes={`${props.className ? props.className + "redesign-table table-responsive-xl " : "redesign-table table-responsive-xl  "}`}
            bodyClasses="custom-table-body"
            noDataIndication={<div className="text-center p-3">{props.noDataIndication}</div>}
            hover
        />
    )
}

GenericTable.defaultProps = {
    noDataIndication: <h3> No Records found</h3>

}

export default GenericTable


