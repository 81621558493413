import React, { useState, useEffect } from "react";
import { FieldError } from "../../assets";
const SpaceTimeframe = (props) => {
  return (
    <div className="steps__block text-center">
      <div className="main-heading-blue">
        <p>When will your space be available?</p>
      </div>

      <div className="landlord-quiz-checkbox">
        {props.options.optionsData
          ? props.options.optionsData.map((option, index) => {
              return (
                <div>
                  <input
                    type="checkbox"
                    id={option.optionId}
                    value={option.optionId}
                    onChange={props.handleOnChange}
                    checked={option.checked}
                  />
                  <label for={option.optionId}>
                    <i aria-hidden="true"></i>
                    {option.optionText}
                  </label>
                </div>
              );
            })
          : null}
        {props.isSpaceAvailability && (
          <FieldError message={"At least 1 selected field required"} />
        )}
        {/* <div><input type="checkbox" id="immediately" /><label for="immediately"><i aria-hidden="true"></i>Immediately</label></div>
                <div><input type="checkbox" id="sixMonths" /><label for="sixMonths"> <i aria-hidden="true"></i>3-6 months</label></div>
                <div><input type="checkbox" id="twelveMonths" /><label for="twelveMonths"> <i aria-hidden="true"></i>6-12 months</label></div> */}
      </div>
    </div>
  );
};

export default SpaceTimeframe;
