import React  from "react";
import { Button } from "react-bootstrap";

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    bsPrefix="input-group border rounded p-2 custom-toggle-button"
    variant="light"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    {children}
  </Button>
));
