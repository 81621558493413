import axios from "axios";
import { APP_SETTINGS } from "../../config";

export function getAllCouponPlan() {
    return axios.get(APP_SETTINGS.API_PATH.COUPON.getAllCouponPlan)
}
export function getAllCoupon() {
    return axios.get(`${APP_SETTINGS.API_PATH.COUPON.getAllCoupon}`)
}

export function deleteCoupon(couponId, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.COUPON.deleteCoupon}?stripeCouponId=${couponId}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function addOrUpdate(couponData, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.COUPON.addOrUpdate}`, couponData, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function editCoupon(couponData, auth) {
    return axios.post(APP_SETTINGS.API_PATH.COUPON.editCoupon, couponData, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function getByCouponId(id) {
    return axios.get(`${APP_SETTINGS.API_PATH.COUPON.getByCouponId}?id=${id}`)
}
export function getCouponUsers(couponId){
    if(couponId){
    return axios.get(`${APP_SETTINGS.API_PATH.COUPON.getCouponUsers}?id=${couponId}`)
}
else{
    return axios.get(`${APP_SETTINGS.API_PATH.COUPON.getCouponUsers}`)
}
}