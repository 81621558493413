import { IMAGES } from '../images';
import React from "react";
import ReactTooltip from "react-tooltip";
export function HowItWorks() {
    return (
        <div className="how-it-works mb-3" style={{ paddingTop: "40px" }}>
            <h5 className="generic-heading flex-center" style={{marginTop:"2rem",marginBottom:"3rem"}}>
                <span />  {"How It Works"}
            </h5>
            <br></br>
            <div className="d-flex text-white flex-xs-column" >
                <div className="flex-1 bg-l-blue">
                    <div className="work-div" data-tip data-for="registerTip1">
                        <img src={IMAGES.LIST} width="36" height="36" alt="List" />
                        <h6 className="text-uppercase">
                            List your space.
                        </h6>
                        <span>
                            01
                        </span>
                    </div>
                    <ReactTooltip id="registerTip1" place="top" type="dark" effect="float">
                        {"List your space/requirements"}
                    </ReactTooltip>
                </div>
                <div className="flex-1 bg-d-blue">
                    <div className="work-div" data-tip data-for="registerTip2">
                        <img src={IMAGES.CONNECT} width="36" height="36" alt="List" />

                        <h6 className="text-uppercase">
                            Connect.
                        </h6>
                        <span>
                            02
                        </span>
                    </div>
                    <ReactTooltip id="registerTip2" place="top" type="dark" effect="float">
                        {"Connect with landlord/tenant"}
                    </ReactTooltip>
                </div>
                <div className="flex-1 bg-l-blue">
                    <div className="work-div" data-tip data-for="registerTip3">
                        <img src={IMAGES.NEGOTITATE} width="36" height="36" alt="List" />

                        <h6 className="text-uppercase">
                            Negotiate.
                        </h6>
                        <span>
                            03
                        </span>
                    </div>
                    <ReactTooltip id="registerTip3" place="top" type="dark" effect="float">
                        {"Negotiate your deal"}
                    </ReactTooltip>
                </div>
                <div className="flex-1 bg-d-blue">
                    <div className="work-div" data-tip data-for="registerTip4">
                        <img src={IMAGES.CLOSE} width="36" height="36" alt="List" />

                        <h6 className="text-uppercase">
                            Close.
                        </h6>
                        <span>
                            04
                        </span>
                    </div>
                    <ReactTooltip id="registerTip4" place="top" type="dark" effect="float">
                        {"Close the deal"}
                    </ReactTooltip>
                </div>
            </div>
        </div>
    )
}