import { BROWSE_LISTING } from "../actions/utilities";

const INITIAL_STATE = {
    getListLoading: false,
    getListFailure: false,
    getListSuccess: false,
    getListError: null,
    getList: [],

    getTenantsLoading: false,
    getTenantsFailure: false,
    getTenantsSuccess: false,
    getTenantsError: null,
    getTenants: [],

    sendRequestLoading: false,
    sendRequestSuccess: false,
    sendRequestFailure: false,
    sendRequestError: null,

    suggestionList: [],
    suggestionLoading: false,
    suggestionSuccess: false,

    tenantDetailLoading: false,
    tenantDetailSuccess: false,
    tenantDetailFailure: false,
    tenantDetailError: null,
    tenantDetail: [],

    getSuggestedListLoading: false,
    getSuggestedListFailure: false,
    getSuggestedListSuccess: false,
    getSuggestedListError: null,
    getSuggestedList: [],

    

}

export const ListingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BROWSE_LISTING.GET_TENANT_DETAIL_REQUEST:
            return {
                ...state,
                tenantDetailLoading: true,
                tenantDetailSuccess: false,
                tenantDetailFailure: false,
                tenantDetailError: null,
                tenantDetail: []
            }
        case BROWSE_LISTING.GET_TENANT_DETAIL_SUCCESS:
            return {
                ...state,
                tenantDetailLoading: false,
                tenantDetailSuccess: true,
                tenantDetailFailure: false,
                tenantDetailError: null,
                tenantDetail: action.payload
            }
        case BROWSE_LISTING.GET_TENANT_DETAIL_FAILURE:
            return {
                ...state,
                tenantDetailLoading: false,
                tenantDetailSuccess: false,
                tenantDetailFailure: true,
                tenantDetailError: action.payload
            }
        case BROWSE_LISTING.GET_SUGGESTION_LOADING:
            return {
                ...state,
                suggestionLoading: true,
                suggestionSuccess: false,

            }
        case BROWSE_LISTING.GET_SUGGESTION_SUCCESS:
            return {
                ...state,
                suggestionLoading: false,
                suggestionSuccess: true,
                suggestionList: action.payload
            }
        case BROWSE_LISTING.TENANT_CONNECTION_RESET:
            return {
                ...state,
                sendRequestLoading: false,
                sendRequestSuccess: false,
                sendRequestFailure: false,
                sendRequestError: null,
            }
        case BROWSE_LISTING.TENANT_CONNECTION_REQUEST:
            return {
                ...state,
                sendRequestLoading: true,
                sendRequestSuccess: false,
                sendRequestFailure: false,
                sendRequestError: null,
            }
        case BROWSE_LISTING.TENANT_CONNECTION_SUCCESS:
            return {
                ...state,
                sendRequestLoading: false,
                sendRequestSuccess: true,
                sendRequestFailure: false,
                sendRequestError: null,
            }
        case BROWSE_LISTING.TENANT_CONNECTION_FAILURE:
            return {
                ...state,
                sendRequestLoading: false,
                sendRequestSuccess: false,
                sendRequestFailure: true,
                sendRequestError: action.payload,
            }
        
        
        
        case BROWSE_LISTING.GET_TENANTS_REQUEST:
            return {
                ...state,
                getTenantsLoading: true,
                getTenantsFailure: false,
                getTenantsSuccess: false,
                getTenantsError: null,
                getTenants: []
            }
        case BROWSE_LISTING.GET_TENANTS_SUCCESS:
            return {
                ...state,
                getTenantsLoading: false,
                getTenantsFailure: false,
                getTenantsSuccess: true,
                getTenantsError: null,
                getTenants: action.payload
            }
        case BROWSE_LISTING.GET_TENANTS_FAILURE:
            return {
                ...state,
                getTenantsLoading: false,
                getTenantsFailure: true,
                getTenantsSuccess: false,
                getTenantsError: action.payload
            }
        case BROWSE_LISTING.GET_LIST_REQUEST:
            return {
                ...state,
                getListLoading: true,
                getListFailure: false,
                getListSuccess: false,
                getListError: null
            }
        case BROWSE_LISTING.GET_LIST_SUCCESS:
            let myList = []
            if (action.payload.pageNumber > 1) {
                // myList = state.getList;
                for (let i = 0; i<action.payload.res.length; i++) {
                    myList.push(action.payload.res[i])
                }

            }
            else {
                myList = action.payload.res;
            }
            return {
                ...state,
                getListLoading: false,
                getListFailure: false,
                getListSuccess: true,
                getListError: null,
                getList: myList
            }
        case BROWSE_LISTING.GET_LIST_FAILURE:
            return {
                ...state,
                getListLoading: false,
                getListFailure: true,
                getListSuccess: false,
                getListError: action.payload
            }
        case BROWSE_LISTING.GET_SUGGESTED_LIST_REQUEST:
            return {
                ...state,
                getSuggestedListLoading: true,
                getSuggestedListFailure: false,
                getSuggestedListSuccess: false,
                getSuggestedListError: null,
                getSuggestedList: [],
            }
        case BROWSE_LISTING.GET_SUGGESTED_LIST_SUCCESS:
            return {
                ...state,
                getSuggestedListLoading: false,
                getSuggestedListFailure: false,
                getSuggestedListSuccess: true,
                getSuggestedListError: null,
                getSuggestedList: action.payload,
            }
        case BROWSE_LISTING.GET_SUGGESTED_LIST_FAILURE:
            return {
                ...state,
                getSuggestedListLoading: false,
                getSuggestedListFailure: false,
                getSuggestedListSuccess: true,
                getSuggestedListError: action.payload,
                getSuggestedList: [],
            }
        default:
            return state;
    }
}