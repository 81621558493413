import { AI_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  aiTextEditore: [],
  aiTextCompletion: [],
  aiTextDeep: [],
  aiTextCortex: [],
};
export const aiText = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AI_REQUEST.GET_AI_TEXT_EDITOR_SUCCESS:
      return {
        ...state,
        aiTextEditore: action.payload,
      };
    case AI_REQUEST.GET_AI_TEXT_COMPLETION_SUCCESS:
      return {
        ...state,
        aiTextCompletion: action.payload,
      };
    case AI_REQUEST.GET_AI_TEXT_DEEP_SUCCESS:
      return {
        ...state,
        aiTextDeep: action.payload,
      };
    case AI_REQUEST.GET_AI_TEXT_CORTEX_SUCCESS:
      return {
        ...state,
        aiTextDeep: action.payload,
      };
    default:
      return state;
  }
};
