import React from "react";
import { useHistory } from "react-router-dom";
import { PATH } from "../../config";

export function NotAuthorisedUser() {
    let history = useHistory();
    return (
        <div>
            {history.push(PATH.DASHBOARD)}
        </div>
    )
}