import { combineReducers } from "redux";
import { ListingReducer } from "./browseLisiting";
import { BusinessProfileReducer } from "./bussinessProfileReducer";
import { LookUpReducer } from "./lookUpReducer";
import { postProertyReducer } from "./postProperty";
import { requirementReducer } from "./requirementReducer";
import { reviewPropertyReducer } from "./reviewPropertyReducer";
import { stateAndCityReducer } from "./stateAndCityReducer";
import { userReducer } from "./userReducer";
import { reportReducer } from "./reportReducer";
import { industryReducer } from "./industryReducer";
import { propertiesReducer } from "./propertyReducer";
import { landingPageReducer } from "./landingPage";
import { connectionReducer } from "./requestReducer";
import { chatReducer } from "./chatReducer";
import { rentSpreeReducer } from "./rentSpree";
import { couponReducer } from "./couponReducer";
import { couponsReducer } from "./couponsReducer";
import { PushNotificationReducer } from "./pushNotificationReducer";
import { marketingPreferenceReducer } from "./marketingPreferenceReducer";
import { photoRuleSetsReducer } from "./ruleSets";
import { aiText } from "./aiReducers";
import { productListReducer } from "./productListReducer";
import { landLordSignUpFlowReducer } from "./landLordSignUpFlowReducer";
import { tenantSignUpFlowReducer } from "./tenantSignUpFlowReducer";
import { termSheetReducer } from "./termSheetReducer";

export const rootReducer = combineReducers({
  user: userReducer,
  locations: stateAndCityReducer,
  lookUps: LookUpReducer,
  businessProfile: BusinessProfileReducer,
  requirement: requirementReducer,
  reviewProperty: reviewPropertyReducer,
  postProperty: postProertyReducer,
  listing: ListingReducer,
  reports: reportReducer,
  industry: industryReducer,
  properties: propertiesReducer,
  landingPage: landingPageReducer,
  connection: connectionReducer,
  chat: chatReducer,
  rentSpree: rentSpreeReducer,
  coupon: couponReducer,
  coupons: couponsReducer,
  notification: PushNotificationReducer,
  marketingPreference: marketingPreferenceReducer,
  photosRuleSets: photoRuleSetsReducer,
  aiText: aiText,
  productList: productListReducer,
  landLordSignUpFlow: landLordSignUpFlowReducer,
  tenantSignUpFlow: tenantSignUpFlowReducer,
  termSheet: termSheetReducer,
});
