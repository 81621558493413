import React, {
  useEffect,
  useRef,
  useLayoutEffect,
  useState
} from "react";
import Slider from "react-slick";
import {
  BsChevronCompactLeft,
  BsChevronCompactRight
} from "react-icons/bs";
import {
  allPropertisLis,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AZURE_BLOB_BASE_URL, PATH } from "../../config";
import { IMAGES } from "../images";
import { Truncate } from "..";
import { perSqftToMonthly, replaceSpace } from "../genericAction"

export function ListingSlider() {
  return (
    <section>
      <div className="requirements col-12" style={{ position: "relative" }}>
        <h2 className="text-align-center mb-2 listing-heding generic-heading flex-center">
          <span />  {"Browse Spaces"}
        </h2>
        <PropertyList />
      </div>
    </section>
  );

}
function PropertyList() {
  let dispatch = useDispatch();
  let landingPage = useSelector((state) => state.landingPage)
  useEffect(() => {
    dispatch(allPropertisLis());
  }, [dispatch])
  let ref = useRef();
  function NextClick() {
    if (ref)
      ref.current.slickNext()
  }
  function PreviousClick() {
    if (ref)
      ref.current.slickPrev()
  };
  var slider = {
    centerMode: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    speed: 500,
    adaptiveHeight: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,

        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  let start = 15, end = 5, full = 50;
  const [width, height] = useWindowSize();
  console.log(height)
  if (width <= 1400 && width >= 1101) {
    start = 15;
    end = 10;
    full = 40;
  }
  else if (width <= 1100 && width >= 681) {
    start = 10;
    end = 5;
    full = 30;
  }
  else if (width <= 680) {
    start = 10;
    end = 5;
    full = 25;
  }
  return (
    <div className="margin-auto no-padding col-11 ">
      <BsChevronCompactLeft className="cursor"
        onClick={() => { PreviousClick() }}
        style={{ color: "#A19F9D", position: "absolute", top: "25%", left: "-65px", zIndex: 1, fontSize: 90 }} />
      <div className="col-11 margin-auto max-width-100 recommended-cards-modify">
        <Slider
          ref={ref}
          className="carousel"
          {...slider}>
          {
            landingPage
            &&
            landingPage.property
            &&
            landingPage.property.map((user, index) => {

              return (
                <Link
                  to={PATH.LISTINGDETAILPAGE.replace(":id", user.propertyId).replace(":address", replaceSpace(user.primaryAddress))}
                  key={index} className="carosel-card-height-home carousel-user  mb-4">
                  {
                    user
                    &&
                    user.isFeatured
                    &&
                    user.isFeatured === true
                    &&
                    <span className="is-featured">
                      {"Featured"}
                    </span>
                  }
                  <div className="carouserl-img-parent carousel-listing">
                    <img alt=""
                      className="img-fluid"
                      src={user.propertyImage ? user.propertyImage.includes("https://") ? user.propertyImage : AZURE_BLOB_BASE_URL + user.propertyImage : IMAGES.HOUSE1} />
                  </div>
                  <div className=" card-section no-margin carosel-card-height-home scroll-section" style={{ paddingTop: "2rem", paddingBottom: "0rem" }}>
                    <h4 className=" primarry-address-listing">
                      {Truncate(user.primaryAddress, start, end, full)}
                    </h4>
                    <p className=" sec-address-listing" >
                      {user.state}, {user.city}
                    </p>
                    <h6 className="cl-d-blue bold-me">
                      <div className="inline-block">
                        <img src={IMAGES.SQUAREICON} width="20" height="20" alt="icon" />
                        {`${user.sqFootage} SQ FT`}
                      </div>
                    </h6>

                  </div>
                  <span className="d-flex justify-content-between" style={{ width: "100%", position: "absolute", bottom: 0 }}>
                    <span className=" bold-me price-listing-slider1" style={{ color: "black", width: "50%" }}>
                      {`$${perSqftToMonthly(user.maxPSF, user.sqFootage)}/Month`}
                    </span>
                    <span className=" bold-me price-listing-slider" style={{ color: "black", width: "50%" }}>
                      {`$ ${user.maxPSF} / SQ FT`}
                    </span>
                  </span>
                </Link>
              )
            })
          }
        </Slider>
      </div>
      <BsChevronCompactRight className="cursor"
        onClick={() => { NextClick() }}
        style={{ color: "#A19F9D", position: "absolute", top: "25%", right: "-65px", zIndex: 1, fontSize: 90 }} />
    </div>

  )
}