import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import { useHistory } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { NotificationManager } from "react-notifications";
import { PATH } from "../../config";
const CircularProgressBar = (props) => {
  let response = useSelector(
    (state) => state.landLordSignUpFlow.sentEmailResponse
  );
  const [percentage, setPercentage] = useState(0);
  let history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1);
      } else if (response && percentage == 100) {
        console.log(response);
        if (response.message == "Success") {
          NotificationManager.success("Email Sent Successfully");
          history.push(PATH.HOME);
        } else {
          NotificationManager.error(response.message);
        }
        props.setPageNo(props.pageNo - 1);
      }
    }, 50);
  }, [percentage]);

  return (
    <div className="steps__block">
      <div className="circular__progessbar">
        <CircularProgressbar
          background={true}
          backgroundPadding={25}
          value={percentage}
          text={`${percentage}%`}
        />
        <p className="loading__text">
          We are creating your Leasing Plan, <br /> please wait...
        </p>
      </div>
    </div>
  );
};

export default CircularProgressBar;
