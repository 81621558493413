import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function reportUser(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.REPORT.reportUser, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function reportSpace(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.REPORT.reportSpace, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function reportSpaceList(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.REPORT.reportSpaceList, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function reportUserList(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.REPORT.reportUserList, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function statusUser(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.REPORT.statusUser, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function landlordUserDelete(data, auth) {
    return axios.put(APP_SETTINGS.API_PATH.REPORT.landlordUserDelete.replace(":userId", data.userId), { userId: data.userId }, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function statusSpace(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.REPORT.statusSpace, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

