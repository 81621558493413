import axios from "axios";
import { APP_SETTINGS } from "../../config";

export function getAllNotification(pageData, auth) {
    return axios.post(APP_SETTINGS.API_PATH.PUSH_NOTIFICATION.getAllNotification, pageData, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function updateNotificationSetting(updateSetting, auth) {
    return axios.post(APP_SETTINGS.API_PATH.PUSH_NOTIFICATION.updateNotificationSetting, updateSetting, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}


export function getPushNotificationSetting(auth) {
    return axios.get(APP_SETTINGS.API_PATH.PUSH_NOTIFICATION.getPushNotificationSetting, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}


export function getUnreadCount(auth) {
    return axios.get(APP_SETTINGS.API_PATH.PUSH_NOTIFICATION.getUnreadCount, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function readNotification(id, auth) {
    return axios.get(APP_SETTINGS.API_PATH.PUSH_NOTIFICATION.readNotification.replace(":id", id), {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}