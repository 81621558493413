import { POST_PROPERTY } from "../actions/utilities";

const INITIAL_STATE = {
    addFileLoading: false,
    addFileSuccess: false,
    addFileFailure: false,
    addFileError: null,

    addVideoLoading: false,
    addVideoSuccess: false,
    addVideoFailure: false,
    addVideoError: null,

    getAllFilesLoading: false,
    getAllFilesSuccess: false,
    getAllFilesFailure: false,
    getAllFilesError: null,
    files: [],
    videoFile: [],
    getPropertyDetailLoading: false,
    getPropertyDetailSuccess: false,
    getPropertyDetailFailure: false,
    getPropertyDetailError: null,
    propertyDetail: [],

}

export const postProertyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case POST_PROPERTY.ADD_PROPERTY_FILE_REQUEST:
            return {
                ...state,
                addFileLoading: true,
                addFileFailure: false,
                addFileSuccess: false,
                addFileError: null
            }
        case POST_PROPERTY.ADD_PROPERTY_FILE_SUCCESS:
            //let addImage = state.files;
            let addImage = action.payload;
            //addImage.push(action.payload)
            return {
                ...state,
                addFileLoading: false,
                addFileFailure: false,
                addFileSuccess: true,
                addFileError: null,
                files: addImage
            }
        case POST_PROPERTY.ADD_PROPERTY_FILE_FAILURE:
            return {
                ...state,
                addFileLoading: false,
                addFileFailure: true,
                addFileSuccess: false,
                addFileError: action.payload
            }

        // For Video

        case POST_PROPERTY.ADD_PROPERTY_VIDEO_REQUEST:
            return {
                ...state,
                addVideoLoading: true,
                addVideoFailure: false,
                addVideoSuccess: false,
                addVideoError: null
            }
        case POST_PROPERTY.ADD_PROPERTY_VIDEO_SUCCESS:
            //let addImage = state.files;
            let addVideo = action.payload;
            //addImage.push(action.payload)
            return {
                ...state,
                addVideoLoading: false,
                addVideoFailure: false,
                addVideoSuccess: true,
                addVideoError: null,
                videoFile: addVideo
            }
        case POST_PROPERTY.ADD_PROPERTY_VIDEO_FAILURE:
            return {
                ...state,
                addVideoLoading: false,
                addVideoFailure: true,
                addVideoSuccess: false,
                addVideoError: action.payload
            }

        
        case POST_PROPERTY.REMOVE_PROPERTY_FILE_REQUEST:
            return {
                ...state,
                addFileLoading: true,
                addFileSuccess: false,
                addFileFailure: false,
                addFileError: null
            }
        case POST_PROPERTY.REMOVE_PROPERTY_FILE_SUCCESS:
            let imgList = state.files;
            for (let i = 0; i < imgList.length; i++) {
                if (imgList[i].propertyFileId === action.payload) {
                    imgList.splice(i, 1);
                }
            }
            return {
                ...state,
                addFileLoading: false,
                addFileSuccess: true,
                addFileFailure: false,
                addFileError: null,
                files: imgList
            }
        case POST_PROPERTY.REMOVE_PROPERTY_FILE_FAILURE:
            return {
                ...state,
                addFileLoading: false,
                addFileSuccess: false,
                addFileFailure: true,
                addFileError: action.payload
            }
        case POST_PROPERTY.GET_ALL_PROPERTY_FILES_REQUEST:
            return {
                ...state,
                getAllFilesLoading: true,
                getAllFilesSuccess: false,
                getAllFilesFailure: false,
                getAllFilesError: null
            }
        case POST_PROPERTY.GET_ALL_PROPERTY_FILES_SUCCESS:
            let revisedActionPayload = [];
            for(let i=0; i<action.payload.length; i++) {
                if(action.payload[i].propertyFileTypeId === 500) {
                    revisedActionPayload.push(action.payload[i]);
                }
            }
            return {
                ...state,
                getAllFilesLoading: false,
                getAllFilesSuccess: true,
                getAllFilesFailure: false,
                getAllFilesError: null,
                files: revisedActionPayload
            }
        case POST_PROPERTY.GET_ALL_PROPERTY_FILES_FAILURE:
            return {
                ...state,
                getAllFilesLoading: false,
                getAllFilesSuccess: false,
                getAllFilesFailure: true,
                getAllFilesError: action.payload
            }
        case POST_PROPERTY.GET_PROPERTY_DETAIL_REQUEST:
            return {
                ...state,
                getPropertyDetailLoading: true,
                getPropertyDetailSuccess: false,
                getPropertyDetailFailure: false,
                getPropertyDetailError: null
            }
        case POST_PROPERTY.GET_PROPERTY_DETAIL_SUCCESS:
            let nameArr = []
            let finalData = []
            if (action && action.payload && action.payload.utilities) {
                nameArr = action.payload.utilities.split(',');
                finalData = {
                    askingType: action && action.payload && action.payload.askingType ? action.payload.askingType : null,
                    askingTypeId: action && action.payload && action.payload.askingTypeId ? action.payload.askingTypeId : null,
                    leaseTerm: action && action.payload && action.payload.leaseTerm ? action.payload.leaseTerm : null,
                    maxPSF: action && action.payload && action.payload.maxPSF ? action.payload.maxPSF : null,
                    minPSF: action && action.payload && action.payload.minPSF ? action.payload.minPSF : null,
                    propertyId: action && action.payload && action.payload.propertyId ? action.payload.propertyId : null,
                    sqFootage: action && action.payload && action.payload.sqFootage ? action.payload.sqFootage : null,
                    utilities: nameArr
                }
            } else {
                finalData = {
                    askingType: action && action.payload && action.payload.askingType ? action.payload.askingType : null,
                    askingTypeId: action && action.payload && action.payload.askingTypeId ? action.payload.askingTypeId : null,
                    leaseTerm: action && action.payload && action.payload.leaseTerm ? action.payload.leaseTerm : null,
                    maxPSF: action && action.payload && action.payload.maxPSF ? action.payload.maxPSF : null,
                    minPSF: action && action.payload && action.payload.minPSF ? action.payload.minPSF : null,
                    propertyId: action && action.payload && action.payload.propertyId ? action.payload.propertyId : null,
                    sqFootage: action && action.payload && action.payload.sqFootage ? action.payload.sqFootage : null,
                    utilities: []
                }
            }

            return {
                ...state,
                getPropertyDetailLoading: false,
                getPropertyDetailSuccess: true,
                getPropertyDetailFailure: false,
                getPropertyDetailError: null,
                propertyDetail: finalData
            }
        case POST_PROPERTY.GET_PROPERTY_DETAIL_FAILURE:
            return {
                ...state,
                getPropertyDetailLoading: false,
                getPropertyDetailSuccess: false,
                getPropertyDetailFailure: true,
                getPropertyDetailError: action.payload,
            }
        case POST_PROPERTY.ADD_PROPERTYDETAIL_SUCCESS:
            var utli = state.propertyDetail.utilities
            utli.push(action.payload);
            return {
                ...state,
                propertyDetail: {
                    ...state.propertyDetail,
                    utilities: utli
                }
            }
        case POST_PROPERTY.REMOVE_PROPERTYDETAIL_SUCCESS:
            var utli_ = state.propertyDetail.utilities;
            for (let i = 0; i < utli_.length; i++) {
                if (utli_[i] === action.payload) {
                    utli_.splice(i, 1)
                }
            }
            return {
                ...state,
                propertyDetail: {
                    ...state.propertyDetail,
                    utilities: utli_
                }
            }
        default:
            return state;

    }
}