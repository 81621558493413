import { RENT_SPREE_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
    getTokenLoading: false,
    getTokenSuccess: false,
    getTokenFailure: false,
    getToken: []
}


export const rentSpreeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RENT_SPREE_REQUEST.GET_RENT_SPREE_REQUEST:
            return {
                ...state,
                getTokenLoading: true,
                getTokenSuccess: false,
                getTokenFailure: false,
                getToken: []
            }
        case RENT_SPREE_REQUEST.GET_RENT_SPREE_SUCCESS:
            return {
                ...state,
                getTokenLoading: true,
                getTokenSuccess: true,
                getTokenFailure: false,
                getToken: action.payload
            }
        case RENT_SPREE_REQUEST.GET_RENT_SPREE_FAILURE:
            return {
                ...state,
                getTokenLoading: false,
                getTokenSuccess: false,
                getTokenFailure: true,
                getToken: []
            }
            case RENT_SPREE_REQUEST.RENT_SPREE_REQUEST:
            return {
                ...state,
                getTokenLoading: true,
                getTokenSuccess: false,
                getTokenFailure: false,
            }
        case RENT_SPREE_REQUEST.RENT_SPREE_SUCCESS:
            return {
                ...state,
                getTokenLoading: false,
                getTokenSuccess: true,
                getTokenFailure: false,
            }
        case RENT_SPREE_REQUEST.RENT_SPREE_FAILURE:
            return {
                ...state,
                getTokenLoading: false,
                getTokenSuccess: false,
                getTokenFailure: true,
            }
            default:
                return state;
    }
}