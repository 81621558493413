import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPFqhrGkKKbDsTPS2nbqBX5i23FHFd1YM",
  authDomain: "redhandedapp.firebaseapp.com",
  projectId: "redhandedapp",
  storageBucket: "redhandedapp.appspot.com",
  messagingSenderId: "577035897758",
  appId: "1:577035897758:web:3dd136a26f0f285987a48f",
  measurementId: "G-VLHJBQFZ73"
};
let messaging = null;
firebase.initializeApp(firebaseConfig);
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}
// const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;
export const getToken = async (setTokenFound) => {
  let currentToken = "";
  if (firebase.messaging.isSupported()) {
    try {
      currentToken = await messaging.getToken({ vapidKey: publicKey });
      if (currentToken) {
        setTokenFound(true);
      } else {
        setTokenFound(false);
      }
    } catch (error) {
      console.log("An error occurred while retrieving token. ", error);
    }

    return currentToken;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (firebase.messaging.isSupported()) {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    }
  });
