import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import LeasingChallenges from "./LeasingChallenges";
import SpaceMarketed from "./SpaceMarketed";
import LeaseSignagePlacement from "./LeaseSignagePlacement";
import Instructions from "./Instructions";
import SpaceAddress from "./SpaceAddress";

import back__arrow from "../../assets/images/arrow__img.svg";
import SpaceTimeframe from "./SpaceTimeframe";
import EmailOrContactDetails from "./EmailOrContactDetails";
import MyOrder from "./MyOrder";
import { getListOfOptions, sendEmailToLandlord } from "../../redux/actions";
import _ from "lodash";
import CircularProgressBar from "./CircularProgressBar";
import { PATH } from "../../config";

export default function LandLordQuestions() {
  let dispatch = useDispatch();
  let spaceOptions = useSelector(
    (state) => state.landLordSignUpFlow.spaceOptions
  );
  const [countOfProgess, setCountOfProgess] = useState(14.3);
  const [pageNo, setPageNo] = useState(0);
  const [buttonText, setbuttonText] = useState("NEXT");
  const [leasingChallenges, setLeasingChallenges] = useState({});
  const [leaseSignage, setLeaseSignage] = useState({});
  const [spaceAvailability, setSpaceAvailability] = useState({});
  const [spaceMarketed, setSpaceMarketed] = useState({});
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [isAddressValidate, setIsAddressValidate] = useState(false);
  const [retailClass, setRetailClass] = useState("d-none");
  const [emailId, setEmailId] = useState("");
  const [isEmailIdValidate, setIsEmailIdValidate] = useState(false);
  const [isleasingChallengeValidate, setIsLeasingChallengeValidate] = useState(false);
  const [isleaseSignage, setIsLeaseSignage] = useState(false);
  const [isSpaceMarketed, setIsSpaceMarketed] = useState(false);
  const [isSpaceAvailability, setIsSpaceAvailability] = useState(false);
  let history = useHistory();

  useEffect(() => {
    dispatch(getListOfOptions());
  }, [dispatch]);

  useEffect(() => {
    setLeasingChallenges(
      spaceOptions.data
        ? {
            pageId: spaceOptions.data[0].pageId,
            optionsData: spaceOptions.data[0].optionsData.map((option) => {
              return { ...option, checked: false };
            }),
          }
        : {}
    );
    setSpaceMarketed(
      spaceOptions.data
        ? {
            pageId: spaceOptions.data[1].pageId,
            optionsData: spaceOptions.data[1].optionsData.map((option) => {
              return {
                ...option,
                checked: false,
                subCategoryData: option.subCategoryData
                  ? option.subCategoryData.map((subOption) => {
                      return { ...subOption, checked: false };
                    })
                  : option.subCategoryData,
              };
            }),
          }
        : {}
    );
    setLeaseSignage(
      spaceOptions.data
        ? {
            pageId: spaceOptions.data[2].pageId,
            optionsData: spaceOptions.data[2].optionsData.map((option) => {
              return { ...option, checked: false };
            }),
          }
        : {}
    );
    setSpaceAvailability(
      spaceOptions.data
        ? {
            pageId: spaceOptions.data[3].pageId,
            optionsData: spaceOptions.data[3].optionsData.map((option) => {
              return { ...option, checked: false };
            }),
          }
        : {}
    );
  }, [spaceOptions]);

  useEffect(() => {
    console.log(pageNo);
    if (pageNo == 6) setbuttonText("SEND EMAIL");
    // else if (pageNo == 9) setbuttonText("SUBMIT SECURE PAYMENT");
    else setbuttonText("NEXT");
  }, [pageNo]);

  const conditionalComponent = () => {
    switch (pageNo) {
      case 0:
        return (
          <LeasingChallenges
            options={leasingChallenges ? leasingChallenges : null}
            handleOnChange={(e) => {
              handleOnChange(e, leasingChallenges, "Challenges leasing");
            }}
            isleasingChallengeValidate={isleasingChallengeValidate}
          />
        );
      case 1:
        return (
          <SpaceMarketed
            options={spaceMarketed ? spaceMarketed : null}
            handleOnChange={(e) => {
              handleOnChange(e, spaceMarketed, "Space Marketed");
            }}
            handleOnChangeSubOption={(e) => {
              handleOnChangeSubOption(e, spaceMarketed, "Space Marketed");
            }}
            retailClass={retailClass}
            isSpaceMarketed={isSpaceMarketed}
          />
        );
      case 2:
        return (
          <LeaseSignagePlacement
            options={leaseSignage ? leaseSignage : null}
            handleOnChange={(e) => {
              handleOnChange(e, leaseSignage, "Lease signage");
            }}
            isleaseSignage={isleaseSignage}
          />
        );
      case 3:
        return <Instructions />;
      case 4:
        return (
          <SpaceAddress
            address1={address1}
            setAddress1={setAddress1}
            address2={address2}
            setAddress2={setAddress2}
            state={state}
            setState={setState}
            city={city}
            setCity={setCity}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            isAddressValidate={isAddressValidate}
          />
        );
      case 5:
        return (
          <SpaceTimeframe
            options={spaceAvailability ? spaceAvailability : null}
            handleOnChange={(e) => {
              handleOnChange(e, spaceAvailability, "Space Availability");
            }}
            isSpaceAvailability={isSpaceAvailability}
          />
        );
      case 6:
        return (
          <EmailOrContactDetails
            handleOnChange={(e) => {
              setEmailId(e.target.value);
            }}
            emailId={emailId}
            isEmailIdValidate={isEmailIdValidate}
          />
        );
      default:
        return <CircularProgressBar pageNo={pageNo} setPageNo={setPageNo} />;
    }
  };

  const getPageOptions = () => {
    let optionData = [];
    const leasingChallengeOptionData = leasingChallenges.optionsData.filter(
      (option) => {
        return option.checked == true;
      }
    );
    const leaseSignageOptionData = leaseSignage.optionsData.filter((option) => {
      return option.checked == true;
    });
    const spaceAvailabilityOptionData = spaceAvailability.optionsData.filter(
      (option) => {
        return option.checked == true;
      }
    );

    const spaceMarketedOptionData = spaceMarketed.optionsData
      .map((option) => ({
        ...option,
        subCategoryData: option.subCategoryData
          ? option.subCategoryData.filter((subOption) => {
              return subOption.checked == true;
            })
          : option.subCategoryData,
      }))
      .filter((option) => {
        return option.checked == true;
      });

    if (leasingChallengeOptionData.length > 0)
      optionData.push({
        PageId: leasingChallenges.pageId,
        OptionsData: leasingChallengeOptionData.map((option) => {
          return {
            OptionId: option.optionId,
            OptionText: option.optionText,
          };
        }),
      });
    if (spaceMarketedOptionData.length > 0)
      optionData.push({
        PageId: spaceMarketed.pageId,
        OptionsData: spaceMarketedOptionData.map((option) => {
          return {
            OptionId: option.optionId,
            OptionText: option.optionText,
            SubCategoryData: option.subCategoryData
              ? option.subCategoryData.map((subOption) => {
                  return {
                    OptionId: subOption.optionId,
                    OptionText: subOption.optionText,
                  };
                })
              : option.subCategoryData,
          };
        }),
      });
    if (leaseSignageOptionData.length > 0)
      optionData.push({
        PageId: leaseSignage.pageId,
        OptionsData: leaseSignageOptionData.map((option) => {
          return {
            OptionId: option.optionId,
            OptionText: option.optionText,
          };
        }),
      });
    if (spaceAvailabilityOptionData.length > 0)
      optionData.push({
        PageId: spaceAvailability.pageId,
        OptionsData: spaceAvailabilityOptionData.map((option) => {
          return {
            OptionId: option.optionId,
            OptionText: option.optionText,
          };
        }),
      });

    return optionData;
  };

  const handleSaveNext = () => {
    const leasingChallengeOptionData = leasingChallenges.optionsData.filter(
      (option) => {
        return option.checked == true;
      }
    );
    const leaseSignageOptionData = leaseSignage.optionsData.filter((option) => {
      return option.checked == true;
    });
    const spaceAvailabilityOptionData = spaceAvailability.optionsData.filter(
      (option) => {
        return option.checked == true;
      }
    );
    const spaceMarketedOptionData = spaceMarketed.optionsData
      .map((option) => ({
        ...option,
        subCategoryData: option.subCategoryData
          ? option.subCategoryData.filter((subOption) => {
              return subOption.checked == true;
            })
          : option.subCategoryData,
      }))
      .filter((option) => {
        return option.checked == true;
      });
    console.log(leasingChallengeOptionData);
    if(leasingChallengeOptionData.length == 0){
      setIsLeasingChallengeValidate(true);
    }
    else if(pageNo == 1 && spaceMarketedOptionData.length == 0){
      setIsSpaceMarketed(true);
    }
    else if(pageNo == 2 && leaseSignageOptionData.length == 0){
      setIsLeaseSignage(true);
    }else if (pageNo == 4 && (!address1 || !state || !city || !postalCode || !/^\d{5}(-\d{4})?$/.test(postalCode))) {
      setIsAddressValidate(true);
    }else if (pageNo == 5 && spaceAvailabilityOptionData.length == 0) {
      setIsSpaceAvailability(true);
    } else {
      setIsLeasingChallengeValidate(false);
      setIsSpaceMarketed(false);
      setIsLeaseSignage(false);
      setIsSpaceAvailability(false);
      setIsAddressValidate(false);
      setPageNo(pageNo < 6 ? pageNo + 1 : pageNo);
      setCountOfProgess((oldProgress) => {
        return Math.min(oldProgress + 14.3, 100);
      });
    }
    
    if (pageNo == 6 && emailId) {
      setIsEmailIdValidate(false);

      const optionData = getPageOptions();

      console.log(optionData);
      const payload = {
        EmailId: emailId,
        Role: "LandLord",
        PageOptionsData: optionData,
        SpaceAddressData: {
          PrimaryAddress: address1,
          SecondaryAddress: address2,
          CountryId: 3956,
          StateId: state,
          CityId: city,
          ZipCode: postalCode,
        },
      };
      dispatch(sendEmailToLandlord(payload));
      setPageNo(pageNo + 1);
    } else if (pageNo == 6 && !emailId) {
      setIsEmailIdValidate(true);
    }
  };

  const handleOnChange = (e, options, page) => {
    const { value, checked } = e.target;

    if (page == "Challenges leasing") {
      setLeasingChallenges({
        pageId: options.pageId,
        optionsData: options.optionsData.map((option) => {
          return {
            ...option,
            checked: option.optionId == value ? checked : option.checked,
          };
        }),
      });
    } else if (page == "Lease signage") {
      setLeaseSignage({
        pageId: options.pageId,
        optionsData: options.optionsData.map((option) => {
          return {
            ...option,
            checked: option.optionId == value ? checked : option.checked,
          };
        }),
      });
    } else if (page == "Space Marketed") {
      const data = options.optionsData.filter((option) => {
        return option.optionId == value;
      });
      if (data[0].optionText == "Retail") {
        checked ? setRetailClass("d-block") : setRetailClass("d-none");
      } else {
        setRetailClass("d-none");
      }
      setSpaceMarketed({
        pageId: options.pageId,
        optionsData: options.optionsData.map((option) => {
          return {
            ...option,
            checked: option.optionId == value ? checked : false,
          };
        }),
      });
    } else if (page == "Space Availability") {
      setSpaceAvailability({
        pageId: options.pageId,
        optionsData: options.optionsData.map((option) => {
          return {
            ...option,
            checked: option.optionId == value ? checked : false,
          };
        }),
      });
    }
  };

  const handleOnChangeSubOption = (e, options, page) => {
    const { value, checked } = e.target;

    setSpaceMarketed({
      pageId: options.pageId,
      optionsData: options.optionsData.map((option) => {
        return {
          ...option,
          checked: option.optionId == value ? checked : option.checked,
          subCategoryData: option.subCategoryData
            ? option.subCategoryData.map((subOption) => {
                return {
                  ...subOption,
                  checked:
                    subOption.optionId == value ? checked : subOption.checked,
                };
              })
            : option.subCategoryData,
        };
      }),
    });
  };

  return (
    <React.Fragment>
      {pageNo !== (10 || 11 || 12 || 13) ? (
        <div className="signup-section modal__bg">
          {/* <ToastContainer position="top-end" className="p-3">
        <Toast>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto"></strong>
            <small className="text-muted"></small>
          </Toast.Header>
          <Toast.Body>See? Just like this.</Toast.Body>
        </Toast>
      </ToastContainer> */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="modal__screen">
                  {pageNo !== 7 ? (
                    <div className="modal__header">
                      <div className="header__arrow">
                        <img
                          src={back__arrow}
                          onClick={() => {
                            //dispatch(setPageNo(pageNo > 1 ? pageNo - 1 : 0));
                            setPageNo(
                              pageNo > 0 ? pageNo - 1 : (history.push(PATH.HOME))
                            );
                            setCountOfProgess((oldProgress) => {
                              return pageNo > 1
                                ? Math.min(oldProgress - 14.3, 100)
                                : 14.3;
                            });
                          }}
                        />
                      </div>
                      <div className="header__logo">
                        <h3 className="navbar-header-title">REDHANDED</h3>
                      </div>
                      <div className="steps__count">
                        <p>0{pageNo + 1}/7</p>
                      </div>
                    </div>
                  ) : null}

                  <div className="modal__body">
                    <div className="modal__content">
                      <div className="progress__counter">
                        <ProgressBar now={countOfProgess} />
                      </div>
                      {conditionalComponent()}
                    </div>
                    {pageNo !== 7 ? (
                      <div className="modal__footer">
                        <div className="next__btn text-center">
                          <button
                            className="btn btn-primary pop-up-property-detaile-btn cursor btn-red-color"
                            onClick={handleSaveNext}
                          >
                            {buttonText}
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <MyOrder pageNo={pageNo} setPageNo={setPageNo} />
      )}
    </React.Fragment>
  );
}
