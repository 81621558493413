import axios from "axios";
import { APP_SETTINGS } from "../../config";

export function getSuggestedList(listData, auth) {
    let finalData = {
        "cityId": listData.cityId ? listData.cityId : null,
        "placeName": listData.placeName ? listData.placeName : null,
        "minSqFoot": listData.minSqFoot ? listData.minSqFoot : null,
        "maxSqFoot": listData.maxSqFoot ? listData.maxSqFoot : null,
        "minPrice": listData.minPrice ? listData.minPrice : null,
        "maxPrice": listData.maxPrice ? listData.maxPrice : null,
        "industryIds": listData.industryIds ? listData.industryIds : [],
        "utilities": listData.utilities ? listData.utilities : [],
        "pageSize": listData.pageSize ? listData.pageSize : 20,
        "pageNumber": listData.pageNumber ? listData.pageNumber : 1,
        "sortingTypeId": listData.sortingTypeId ? listData.sortingTypeId : 0,
    }
    return axios.post(`${APP_SETTINGS.API_PATH.BROWSE_LISTING.getSuggestedList}`, finalData, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function getAllLists(listData, auth) {
    let finalData = {
        "cityId": listData.cityId ? listData.cityId : null,
        "placeName": listData.placeName ? listData.placeName : null,
        "minSqFoot": listData.minSqFoot ? listData.minSqFoot : null,
        "maxSqFoot": listData.maxSqFoot ? listData.maxSqFoot : null,
        "minPrice": listData.minPrice ? listData.minPrice : null,
        "maxPrice": listData.maxPrice ? listData.maxPrice : null,
        "industryIds": listData.industryIds ? listData.industryIds : [],
        "utilities": listData.utilities ? listData.utilities : [],
        "pageSize": listData.pageSize ? listData.pageSize : 20,
        "pageNumber": listData.pageNumber ? listData.pageNumber : 1,
        "sortingTypeId": listData.sortingTypeId ? listData.sortingTypeId : 0,
    }
    return axios.post(`${APP_SETTINGS.API_PATH.BROWSE_LISTING.getAllLists}`, finalData, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function getAllLists_data(listData) {
    let finalData = {
        "cityId": listData.cityId ? listData.cityId : null,
        "placeName": listData.placeName ? listData.placeName : null,
        "minSqFoot": listData.minSqFoot ? listData.minSqFoot : null,
        "maxSqFoot": listData.maxSqFoot ? listData.maxSqFoot : null,
        "minPrice": listData.minPrice ? listData.minPrice : null,
        "maxPrice": listData.maxPrice ? listData.maxPrice : null,
        "industryIds": listData.industryIds ? listData.industryIds : [],
        "utilities": listData.utilities ? listData.utilities : [],
        "pageSize": listData.pageSize ? listData.pageSize : 20,
        "pageNumber": listData.pageNumber ? listData.pageNumber : 1,
        "sortingTypeId": listData.sortingTypeId ? listData.sortingTypeId : 0,
        "propertyOwnerId": listData.propertyOwnerId ? listData.propertyOwnerId : 0,
    }
    return axios.post(`${APP_SETTINGS.API_PATH.BROWSE_LISTING.getAllLists}`, finalData)
}
export function getAllTenants(tenantData, auth) {
    let finalData = {
        "cityId": tenantData.cityId ? tenantData.cityId : null,
        "propertyId": tenantData.propertyId ? tenantData.propertyId : null,
        "placeName": tenantData.placeName ? tenantData.placeName : null,
        "minSqFoot": tenantData.minSqFoot ? tenantData.minSqFoot : null,
        "maxSqFoot": tenantData.maxSqFoot ? tenantData.maxSqFoot : null,
        "minPrice": tenantData.minPrice ? tenantData.minPrice : null,
        "maxPrice": tenantData.maxPrice ? tenantData.maxPrice : null,
        "industryIds": tenantData.industryIds ? tenantData.industryIds : null,
        "utilities": tenantData.utilities ? tenantData.utilities : [],
    }
    return axios.post(`${APP_SETTINGS.API_PATH.BROWSE_TENANTS.getAllTenants}`, finalData, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function BaseTermSheet(payload, auth) {
    
    return axios.post(`${APP_SETTINGS.API_PATH.BASE_TERM_SHEET.BaseTermSheet}`, payload, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function autoSuggest(query) {
    return axios.get(`${APP_SETTINGS.API_PATH.AUTOSUGGEST.autoSuggest}/${query}`)
}
export function moreDetail(tenantId, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.BROWSE_TENANTS.moreDetail}?tenantUserId=${tenantId}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}