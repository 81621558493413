import React, { useState, useEffect, useRef } from "react";
import { Divider, Steps } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useForm } from "react-hook-form";
import back__arrow from "../../assets/images/arrow__img.svg";
import { FieldError, Loader, getCouponDiscountedAmount } from "../../assets";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { setPageNo } from "../../redux/actions";

export default function SignUp(props) {
  let dispatch = useDispatch();
  //let pageNo = useSelector((state) => state.landLordSignUpFlow.pageNo);
  let location = useSelector((state) => state.locations);
  let userData = useSelector((state) => state.landLordSignUpFlow);
  const { search } = useLocation();
  const emailId = new URLSearchParams(search).get("email");
  const roleId = new URLSearchParams(search).get("role");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm();
  const Password = useRef({});
  Password.current = watch("password", "");
  const [totalPrice, setTotalPrice] = useState(0);
  let couponDetails = useSelector(
    (state) => state.landLordSignUpFlow.addonsCouponDetails
  );
  console.log(props.addOnsData);
  console.log(couponDetails);

  useEffect(() => {
    let price = 0;
    props &&
      props.addOnsData &&
      props.addOnsData.map((addon) => {
        if (addon.isAddonPurchased) {
          price =
            price + addon.discountPrice;
        }
      });
      
      var planPrice =
      Object.keys(couponDetails).length !== 0 && couponDetails.metadata.product
        ? parseFloat(
            `${
              getCouponDiscountedAmount(
                couponDetails,
                props.plan && props.plan.amount
                  ? props.plan.amount * props.plan.intervalCount
                  : 0
              ).total
            }`
          )
        : props.plan.amount * props.plan.intervalCount;
    setTotalPrice(planPrice + price);
  }, [props.addOnsData]);

  const onSubmit = () => {
    // props.setPageNo(props.pageNo + 1);
    if (
      /^[a-zA-Z ]*$/.test(props.userDetails.firstName) &&
      /^[a-zA-Z ]*$/.test(props.userDetails.lastName)
    )
      props.handleSubmit();
  };

  return (
    <React.Fragment>
      <div className="signup-section modal__bg onemorestep">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmit)} className="form-aside">
                <div className="modal__screen">
                  <div className="modal__header">
                    <div className="header__arrow">
                      <img
                        src={back__arrow}
                        onClick={() => {
                          dispatch(
                            setPageNo(
                              userData.pageNo > 1 ? userData.pageNo - 1 : 0
                            )
                          );
                          props.setAddOnsPageNo(props.addOnsPageNo - 1);
                        }}
                      />
                    </div>
                    {/* <div className="header__logo">
                      <h3 className="navbar-header-title">REDHANDED</h3>
                    </div>
                    <div className="steps__count">
                      <p>{pageNo + 1}/10</p>
                    </div> */}
                  </div>

                  <div className="modal__body">
                    <div className="modal__content">
                      <div
                        className="steps__block"
                        style={{ maxHeight: "32rem" }}
                      >
                        <div className="multistep__blocks">
                          <Steps
                            progressDot
                            current={2}
                            items={[
                              {
                                title: "Order Submitted",
                              },
                              {
                                title: "Special Offer",
                              },
                              {
                                title: "Order Recipt",
                              },
                            ]}
                          />
                          {/* <Divider /> */}
                        </div>
                        <div className="text-center">
                          <h3>
                            Just{" "}
                            <span
                              className="redtextcolor"
                              style={{ fontWeight: "bold" }}
                            >
                              one more
                            </span>{" "}
                            step...
                          </h3>
                          <p>
                            Use your email and create a password to access your{" "}
                            <span className="small__text">REDHANDED</span> app{" "}
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup className="did-floating-label-content mt-1">
                              <FormControl
                                style={{
                                  borderColor:
                                    errors && errors.firstName ? "#a80000" : "",
                                }}
                                className="did-floating-input"
                                type="text"
                                name="firstName"
                                //value={props.userDetails.firstName}
                                placeholder=" "
                                onChange={props.handleOnChange}
                                ref={register({ required: true })}
                              />
                              <FormLabel className="did-floating-label">
                                {"First Name"}
                              </FormLabel>
                            </FormGroup>

                            {errors.firstName && (
                              <FieldError message={"This Field is Required"} />
                            )}
                            {props.userDetails.firstName &&
                              !/^[a-zA-Z ]*$/.test(
                                props.userDetails.firstName
                              ) && (
                                <FieldError
                                  message={"Please enter valid first name"}
                                />
                              )}
                            <FormGroup className="did-floating-label-content mt-1">
                              <FormControl
                                style={{
                                  borderColor:
                                    errors && errors.lastName ? "#a80000" : "",
                                }}
                                className="did-floating-input"
                                placeholder=" "
                                name="lastName"
                                type="text"
                                //value={props.userDetails.lastName}
                                onChange={props.handleOnChange}
                                ref={register({ required: true })}
                              />
                              <FormLabel className="did-floating-label">
                                {"Last Name"}
                              </FormLabel>
                            </FormGroup>
                            {errors.lastName && (
                              <FieldError message={"This Field is Required"} />
                            )}
                            {props.userDetails.lastName &&
                              !/^[a-zA-Z ]*$/.test(
                                props.userDetails.lastName
                              ) && (
                                <FieldError
                                  message={"Please enter valid last name"}
                                />
                              )}
                            <div class="did-floating-label-content">
                              <input
                                class="did-floating-input"
                                type="text"
                                placeholder=" "
                                value={emailId}
                                disabled={true}
                              />
                              <label class="did-floating-label">
                                E-mail Address
                              </label>
                            </div>

                            <div class="did-floating-label-content">
                              <input
                                class="did-floating-input"
                                type="text"
                                placeholder=" "
                                value={emailId}
                                disabled={true}
                              />
                              <label class="did-floating-label">
                                Confirm email
                              </label>
                            </div>
                            <FormGroup className="did-floating-label-content">
                              <FormControl
                                style={{
                                  borderColor:
                                    errors && errors.password ? "#a80000" : "",
                                }}
                                className="did-floating-input"
                                placeholder=" "
                                name="password"
                                type={
                                  showPassword === true ? "text" : "password"
                                }
                                onChange={props.handleOnChange}
                                ref={register({
                                  required: true,
                                  pattern:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#^$!%*?&])[A-Za-z\d@#^$!%*?&]{8,}$/,
                                })}
                              />
                              <div
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                                className="cursor login-visible-invisible-icon"
                                style={{ zIndex: 5 }}
                              >
                                {showPassword === true ? (
                                  <AiOutlineEye size={20} />
                                ) : (
                                  <AiOutlineEyeInvisible size={20} />
                                )}
                              </div>
                              <FormLabel className="did-floating-label">
                                {"Password"}
                              </FormLabel>
                            </FormGroup>
                            {errors.password && (
                              <FieldError
                                message={
                                  "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                                }
                              />
                            )}
                            <FormGroup className="did-floating-label-content">
                              <FormControl
                                style={{
                                  borderColor:
                                    errors && errors.confirm_password
                                      ? "#a80000"
                                      : "",
                                }}
                                className="did-floating-input"
                                placeholder=" "
                                name="confirm_password"
                                type={
                                  showConfirmPassword === true ? "text" : "password"
                                }
                                onChange={props.handleOnChange}
                                ref={register({
                                  validate: (value) =>
                                    value === Password.current ||
                                    "The passwords do not match",
                                })}
                              />
                              <div
                                onClick={() => {
                                  setShowConfirmPassword(!showConfirmPassword);
                                }}
                                className="cursor login-visible-invisible-icon"
                                style={{ zIndex: 5 }}
                              >
                                {showConfirmPassword === true ? (
                                  <AiOutlineEye size={20} />
                                ) : (
                                  <AiOutlineEyeInvisible size={20} />
                                )}
                              </div>
                              <FormLabel className="did-floating-label">
                                {"Confirm Password"}
                              </FormLabel>
                            </FormGroup>
                            {errors.confirm_password && (
                              <FieldError
                                message={errors.confirm_password.message}
                              />
                            )}
                            <FormGroup className="did-floating-label-content">
                              <FormControl
                                as="select"
                                className="did-floating-input"
                                style={{
                                  borderColor:
                                    errors && errors.stateId ? "#a80000" : "",
                                  color: "#79828a",
                                }}
                                name="stateId"
                                onChange={props.handleOnChange}
                                ref={register({ required: true })}
                              >
                                <option value="">{"State"}</option>
                                {location &&
                                  location.stateList &&
                                  location.stateList.map((state, index) => {
                                    return (
                                      <option value={state.stateId} key={index}>
                                        {state.name}
                                      </option>
                                    );
                                  })}
                              </FormControl>
                            </FormGroup>
                            {errors.stateId && (
                              <FieldError
                                className="mt-1"
                                message={"This Field is Required"}
                              />
                            )}
                          </div>
                          <div className="col-md-6">
                            <div class="row order__summary p-0 bg-transparent">
                              <div class="col-md-12">
                                <div class="">
                                  <h6 className="redtextcolor">
                                    Order{" "}
                                    <span class="font__bold">Summary</span>
                                  </h6>
                                </div>
                              </div>
                              <div class="col-md-12 mx-auto">
                                <div class="order__summarytable">
                                  <table class="table">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <p>
                                            {props &&
                                              props.plan &&
                                              props.plan.name}
                                          </p>
                                        </td>
                                        <td>
                                          <p class="text-right font__bold">
                                            $
                                            {Object.keys(couponDetails)
                                              .length !== 0 &&
                                            couponDetails.metadata.product
                                              ? `${
                                                  getCouponDiscountedAmount(
                                                    couponDetails,
                                                    props.plan &&
                                                      props.plan.amount
                                                      ? props.plan.amount *
                                                          props.plan
                                                            .intervalCount
                                                      : 0
                                                  ).total
                                                }`
                                              : props.plan.amount *
                                                props.plan.intervalCount}
                                          </p>
                                        </td>
                                      </tr>
                                      {props &&
                                        props.addOnsData &&
                                        props.addOnsData.map((addon) => {
                                          return (
                                            <tr>
                                              <td>
                                                <p>{addon.addon_Name}</p>
                                              </td>
                                              <td>
                                                <p class="text-right font__bold">
                                                  $
                                                  {addon.discountPrice}
                                                </p>
                                              </td>
                                            </tr>
                                          );
                                        })}

                                      <tr>
                                        <td>
                                          <p class="total">Total</p>
                                        </td>
                                        <td>
                                          <p class="total text-right">
                                            $
                                            {totalPrice}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  {userData.signUpLoading == true ? (
                                    <Loader />
                                  ) : (
                                    <div className="next__btn text-center">
                                      <button
                                        className="btn btn-primary pop-up-property-detaile-btn cursor btn-red-color w-100"
                                        type="submit"
                                      >
                                        Save & Continue
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="modal__footer">
                      {userData.signUpLoading == true ? (
                        <Loader />
                      ) : (
                        <div className="next__btn text-center">
                          <button
                            className="btn btn-primary pop-up-property-detaile-btn cursor btn-red-color"
                            type="submit"
                          >
                            Save & Continue
                          </button>
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
