import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsPinterest } from "react-icons/bs";
import { CgFacebook } from "react-icons/cg";
import { RiInstagramLine } from "react-icons/ri";
import { PATH } from "../../../config";
export const Footer = (props) => {
  return (
    <footer className=" footer-div font-outfit height-15vh footer-elemnt" >
      <Container className="h-100">
        <div className="d-flex align-items-center justify-content-center h-100 ">
          <div className="d-flex flex-column  footer-margin">
            <h1 className="footer-icon py-0 my-0 font-outfit ">REDHANDED</h1>
            <p className="text-white p-0 m-0 font-outfit footer-p ">&copy; 2021 REDHANDED</p>
          </div>
          <div className="footer-flex mx-0 px-0  footer-margin">
            <Link to={PATH.ABOUTUS} className="text-white footer-link font-2vh font-outfit footer-link   text-nowrap footer-link-margin">
              About Us
            </Link>
            <a rel="noreferrer" className="text-white footer-link font-2vh font-outfit  footer-link-margin text-nowrap" target="_blank" href="http://resources.redhandedco.com/">
              {'Blog'}
            </a>
            <Link to={PATH.OURPARTNERS} className="text-white footer-link font-2vh font-outfit  footer-link-margin text-nowrap">
              Partners
            </Link>
            {/* <Link to={PATH.PRICING} className="text-white footer-link font-2vh font-outfit  footer-link-margin text-nowrap">
              Pricing
            </Link> */}
            <Link to="#" className="text-white footer-link font-2vh font-outfit  footer-link-margin text-nowrap">
              Contact Us{" "}
            </Link>
            <Link to={PATH.TERMSANDCONDITIONS} className="text-white footer-link font-2vh font-outfit  footer-link-margin text-nowrap">
              Terms & Conditions
            </Link>
            <Link to={PATH.TERMSANDCONDITIONS} className="text-white footer-link font-2vh font-outfit  footer-link-margin text-nowrap" >
              Privacy Policy
            </Link>
          </div>

          <div className="d-flex ms-2  ml-1 footer-margin">
            <a href="https://www.facebook.com/RedHanded-Co-100875638804653/?ref=page_internal"
              rel="noreferrer"
              target="_blank" className="cursor ">
              <CgFacebook className="icon-color " />
            </a>
            <a href="https://www.instagram.com/redhandedco/"
              rel="noreferrer"
              target="_blank" className="cursor ">
              <RiInstagramLine className="mx-2 icon-color" />
            </a>
            <a href="https://www.pinterest.com/helloredhanded"
              rel="noreferrer"
              target="_blank" className="cursor ">
              <BsPinterest className="icon-color-2 " />
            </a>
          </div>
        </div>
      </Container>
    </footer >
  );
};