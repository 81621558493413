// A wrapper for <Route> that redirects to the login

import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { PATH, ROLEID } from "../../config";
import { useAuth } from "./ProvideAuth";
import { NavBarAdminWithoutSideBar } from "../../assets";
import { NavigationBar } from "../../components/LandingPage/Sections/NavigationBar"
import { Footer } from "../../components/LandingPage/Sections/Footer"
// screen if you're not yet authenticated.
function PublicRoute({ children, ...rest }) {

    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                (auth.real_estate_user && auth.real_estate_user.token && auth.real_estate_user.roleId) ? (
                    <Redirect
                        to={{
                            pathname: rest?.location?.state?.from?.pathname ? rest.location.state.from.pathname : PATH.DASHBOARD,
                            state: { from: location },
                        }}
                    />
                ) : (
                    <div className="main-parent container-fluid no-padding no-margin">
                        <NavigationBar />
                        {children}
                        <Footer />
                    </div>
                )
            }
        />
    );
}
export default PublicRoute;

export function ModerateRoute({ children, ...rest }) {
    let history = useHistory();
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                (auth.real_estate_user && auth.real_estate_user.token && auth.real_estate_user.roleId) ? (
                    <React.Fragment>
                        {
                            auth.real_estate_user && auth.real_estate_user.token
                                &&
                                auth.real_estate_user.roleId === ROLEID.ADMIN
                                ?
                                <React.Fragment>
                                    {
                                        history.location.pathname.includes("Listing") ?
                                            <div className="admin-main-parent">
                                                <NavBarAdminWithoutSideBar />
                                                {children}
                                            </div>
                                            :
                                            history.location.pathname.includes("/checkout") ?
                                                <div className="admin-main-parent">
                                                    <NavBarAdminWithoutSideBar />
                                                    {children}
                                                </div>
                                                :
                                                history.location.pathname.includes("/upgraded") ?
                                                    <div className="admin-main-parent">
                                                        <NavBarAdminWithoutSideBar />
                                                        {children}
                                                    </div>
                                                    :
                                                    <Redirect
                                                        to={{
                                                            pathname: rest?.location?.state?.from?.pathname ? rest.location.state.from.pathname : PATH.DASHBOARD,
                                                            state: { from: location },
                                                        }}
                                                    />
                                    }
                                </React.Fragment>
                                :

                                <div className="main-parent">
                                    {
                                        rest.path !== PATH.POLICY
                                        &&
                                        rest.path !== PATH.TERMOFUSE
                                        &&

                                        history.location.pathname.includes("property-signage") === false
                                        &&
                                        <NavigationBar />
                                    }
                                    {children}
                                    {
                                        rest.path !== PATH.POLICY
                                        &&
                                        rest.path !== PATH.TERMOFUSE
                                        &&
                                        rest.path !== PATH.HOME
                                        &&
                                        history.location.pathname.includes("property-signage") === false
                                        &&
                                        <Footer />
                                    }
                                </div>
                        }
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {
                            auth.real_estate_user && auth.real_estate_user.token && auth.real_estate_user.roleId &&
                                auth.real_estate_user.roleId === ROLEID.ADMIN
                                ?
                                <React.Fragment>
                                    {
                                        history.location.pathname.includes("Listing") ?
                                            <div className="admin-main-parent">
                                                <NavBarAdminWithoutSideBar />
                                                {children}
                                            </div>
                                            :
                                            <Redirect
                                                to={{
                                                    pathname: rest?.location?.state?.from?.pathname ? rest.location.state.from.pathname : PATH.DASHBOARD,
                                                    state: { from: location },
                                                }}
                                            />
                                    }
                                </React.Fragment>
                                :
                                <div className="main-parent">
                                    {
                                        rest.path !== PATH.POLICY
                                        &&
                                        rest.path !== PATH.TERMOFUSE
                                        &&
                                        rest.path !== PATH.HOME
                                        &&
                                        history.location.pathname.includes("property-signage") === false
                                        &&
                                        <NavigationBar />
                                    }
                                    {children}
                                    {
                                        rest.path !== PATH.POLICY
                                        &&
                                        rest.path !== PATH.TERMOFUSE
                                        &&
                                        rest.path !== PATH.HOME
                                        &&
                                        history.location.pathname.includes("property-signage") === false
                                        &&
                                        <Footer />
                                    }
                                </div>
                        }
                    </React.Fragment>
                )
            }
        />
    )
}
export function ConfigRoute({ children, ...rest }) {

    return (
        <Route
            {...rest}
            render={({ location }) =>


                <React.Fragment>
                    {children}
                </React.Fragment>


            }
        />
    )
}

export function PublicProfileRoute({ children, ...rest }) {

    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                (auth.real_estate_user && auth.real_estate_user.token) ? (
                    <React.Fragment>
                        <NavigationBar />
                        {children}
                        <Footer />
                    </React.Fragment>
                ) : (
                    <Redirect
                        to={{
                            pathname: rest?.location?.state?.from?.pathname ? rest.location.state.from.pathname : PATH.DASHBOARD,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}