import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { FieldError } from "./ErrorMessage"
const ToFromDropdown = (props) => {
  function onChangeOfValue(event) {
    if (event.target.name === "min") {
      if (props.changeInMin) {
        props.changeInMin(event.target.value)
      }
    }
    else if (event.target.name === "max") {
      if (props.changeInMax) {
        props.changeInMax(event.target.value)
      }
    }
  }
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  useEffect(() => {
    setMinValue(props.minValue)
    setMaxValue(props.maxValue)
  }, [props])
  return (
    <div>
      <p className="dropdown-title no-margin">
        {props.title}
      </p>
      <div className="d-flex custom-tofrom-dropdown">
        <InputGroup style={{ padding: 0 }}>
          <FormControl
            className="to-from-input"
            placeholder={props.to}
            name="min"
            min={0}
            value={minValue}
            onChange={(e) => { onChangeOfValue(e) }}
            type="number" />
        </InputGroup>
        <span>
          {" to "}
        </span>
        <InputGroup style={{ padding: 0 }}>
          <FormControl
            className="to-from-input"
            placeholder={props.from}
            value={props.maxValue ? props.maxValue : null}
            name="max"
            min={0}
            onChange={(e) => { onChangeOfValue(e) }}
            type="number" />
        </InputGroup>
      </div>
      {
        ((maxValue - minValue) < 0)
        &&
        <FieldError message={"Max value must be greater than Min Value"} />
      }

    </div>
  );
};
export { ToFromDropdown };
