import { USER_REQUEST, REGISTRATION_V2_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  loginLoading: false,
  loginSuccess: false,
  loginFailure: false,
  loginError: null,
  user: null,
  roleId: null,

  updateTenantLoading: false,
  updateTenantSuccess: false,
  updateTenantFailure: false,
  updateTenantError: null,

  statLoading: false,
  statSuccess: false,
  statFailure: false,
  statError: null,
  stats: [],

  profileLoading: false,
  profileSuccess: false,
  profileFailure: false,
  profileError: null,
  profile: [],

  getUpdateTenantLoading: false,
  getUpdateTenantSuccess: false,
  getUpdateTenantFailure: false,
  getUpdateTenantError: null,
  getUpdateTenant: [],

  getusercodeLoading: false,
  getusercodeSuccess: false,
  getusercodeFailure: false,
  getusercodeError: null,
  getusercode: [],

  getuseremailLoading: false,
  getuseremailSuccess: false,
  getuseremailFailure: false,
  getuseremailError: null,
  getuseremail: [],

  resetpasswordLoading: false,
  resetpasswordSuccess: false,
  resetpasswordFailure: false,
  resetpasswordError: null,
  resetpassword: [],

  verifyLoading: false,
  verifySuccess: false,
  verifyFailure: false,
  verify: [],
  verifyError: null,
  userSignUpLoading: false,
  userSignUpSuccess: false,
  userSignUpFailure: false,
  userSignUpError: null,
  userSignUpList: [],

  roleUserLoading: false,
  roleUserSuccess: false,
  roleUserFailure: false,
  roleUserError: null,
  roleUserList: [],

  socialUserLoading: false,
  socialUserSuccess: false,
  socialUserFailure: false,
  socialUserError: null,
  socialUserList: [],
  deviceToken: null,
  notificationList: [],
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_REQUEST.SELECT_ROLE_ID:
      return {
        ...state,
        roleId: action.payload,
      };
    case USER_REQUEST.REMOVE_ROLE_ID:
      return {
        ...state,
        roleId: null,
      };
    case REGISTRATION_V2_REQUEST.USER_LOGIN_SIGNUP_RESET:
      return {
        ...state,
        socialUserLoading: false,
        socialUserSuccess: false,
        socialUserFailure: false,
        socialUserError: null,
        userSignUpLoading: false,
        userSignUpSuccess: false,
        userSignUpFailure: false,
        userSignUpError: null,
        loginLoading: false,
        loginSuccess: false,
        loginFailure: false,
        loginError: null,
      };
    case REGISTRATION_V2_REQUEST.NOTIFICATION_RESET:
      return {
        ...state,
        notificationList: [],
      };
    case REGISTRATION_V2_REQUEST.DEVICE_TOKEN_RECIEVED:
      return {
        ...state,
        deviceToken: action.payload,
      };
    case REGISTRATION_V2_REQUEST.NOTIFICATION_RECIEVED:
      let myNotification = state.notificationList;
      console.log(
        "in reducer:::",
        myNotification.includes(action.payload.fcmMessageId)
      );
      if (myNotification.includes(action.payload.fcmMessageId)) {
      } else {
        myNotification.push(action.payload.fcmMessageId);
      }
      return {
        ...state,
        notificationList: myNotification,
      };
    case REGISTRATION_V2_REQUEST.USER_SIGN_UP_REQUEST:
      return {
        ...state,
        userSignUpLoading: true,
        userSignUpSuccess: false,
        userSignUpFailure: false,
        userSignUpError: null,
        userSignUpList: [],
      };
    case REGISTRATION_V2_REQUEST.USER_SIGN_UP_SUCCESS:
      return {
        ...state,
        userSignUpLoading: false,
        userSignUpSuccess: true,
        userSignUpFailure: false,
        userSignUpError: null,
        userSignUpList: action.payload,
      };
    case REGISTRATION_V2_REQUEST.USER_SIGN_UP_FAILURE:
      return {
        ...state,
        userSignUpLoading: false,
        userSignUpSuccess: false,
        userSignUpFailure: true,
        userSignUpError: action.payload,
      };
    case REGISTRATION_V2_REQUEST.ROLE_USER_REQUEST:
      return {
        ...state,
        roleUserLoading: true,
        roleUserSuccess: false,
        roleUserFailure: false,
        roleUserError: null,
        roleUserList: [],
      };
    case REGISTRATION_V2_REQUEST.ROLE_USER_SUCCESS:
      return {
        ...state,
        roleUserLoading: false,
        roleUserSuccess: true,
        roleUserFailure: false,
        roleUserError: null,
        roleUserList: action.payload,
      };
    case REGISTRATION_V2_REQUEST.ROLE_USER_FAILURE:
      return {
        ...state,
        roleUserLoading: false,
        roleUserSuccess: false,
        roleUserFailure: true,
        roleUserError: action.payload,
      };
    case REGISTRATION_V2_REQUEST.SOCIAL_USER_REQUEST:
      return {
        ...state,
        socialUserLoading: true,
        socialUserSuccess: false,
        socialUserFailure: false,
        socialUserError: null,
        socialUserList: [],
        userSignUpLoading: true,
        userSignUpSuccess: false,
        userSignUpFailure: false,
        userSignUpError: null,
        userSignUpList: [],
      };
    case REGISTRATION_V2_REQUEST.SOCIAL_USER_SUCCESS:
      return {
        ...state,
        socialUserLoading: false,
        socialUserSuccess: true,
        socialUserFailure: false,
        socialUserError: null,
        socialUserList: action.payload,
        userSignUpLoading: false,
        userSignUpSuccess: true,
        userSignUpFailure: false,
        userSignUpError: null,
        userSignUpList: action.payload,
      };
    case REGISTRATION_V2_REQUEST.SOCIAL_USER_FAILURE:
      return {
        ...state,
        socialUserLoading: false,
        socialUserSuccess: false,
        socialUserFailure: true,
        socialUserError: action.payload,
        userSignUpLoading: false,
        userSignUpSuccess: false,
        userSignUpFailure: true,
        userSignUpError: action.payload,
      };
    case USER_REQUEST.VERIFY_EMAIL_RESET:
      return {
        ...state,
        verifyLoading: false,
        verifySuccess: false,
        verifyFailure: false,
        verifyError: null,
      };
    case USER_REQUEST.VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        verifyLoading: true,
        verifySuccess: false,
        verifyFailure: false,
        verifyError: null,
      };
    case USER_REQUEST.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyLoading: false,
        verifySuccess: true,
        verifyFailure: false,
        verify: action.payload,
        verifyError: null,
      };
    case USER_REQUEST.VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        verifyLoading: false,
        verifySuccess: false,
        verifyFailure: true,
        verifyError: action.payload,
      };
    case USER_REQUEST.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        profileLoading: true,
        profileSuccess: false,
        profileFailure: false,
        profileError: null,
        profile: [],
      };
    case USER_REQUEST.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        profileSuccess: true,
        profileFailure: false,
        profileError: null,
        profile: action.payload,
      };
    case USER_REQUEST.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        profileLoading: false,
        profileSuccess: false,
        profileFailure: true,
        profileError: action.payload,
      };
    case USER_REQUEST.GET_UPDATE_TENANT_REQUEST:
      return {
        ...state,
        getUpdateTenantLoading: true,
        getUpdateTenantSuccess: false,
        getUpdateTenantFailure: false,
        getUpdateTenantError: null,
        getUpdateTenant: [],
      };
    case USER_REQUEST.GET_UPDATE_TENANT_RESET:
      return {
        ...state,
        getUpdateTenantLoading: false,
        getUpdateTenantSuccess: false,
        getUpdateTenantFailure: false,
        getUpdateTenantError: null,
        getUpdateTenant: [],
      };
    case USER_REQUEST.GET_UPDATE_TENANT_SUCCESS:
      return {
        ...state,
        getUpdateTenantLoading: false,
        getUpdateTenantSuccess: true,
        getUpdateTenantFailure: false,
        getUpdateTenantError: null,
        getUpdateTenant: action.payload,
      };
    case USER_REQUEST.GET_UPDATE_TENANT_FAILURE:
      return {
        ...state,
        getUpdateTenantLoading: false,
        getUpdateTenantSuccess: false,
        getUpdateTenantFailure: true,
        getUpdateTenantError: action.payload,
      };
    case USER_REQUEST.GET_USER_CODE_REQUEST:
      return {
        ...state,
        getusercodeLoading: true,
        getusercodeSuccess: false,
        getusercodeFailure: false,
        getusercodeError: null,
        getusercode: [],
      };
    case USER_REQUEST.GET_USER_CODE_SUCCESS:
      return {
        ...state,
        getusercodeLoading: false,
        getusercodeSuccess: true,
        getusercodeFailure: false,
        getusercodeError: null,
        getusercode: action.payload,
      };
    case USER_REQUEST.GET_USER_CODE_FAILURE:
      return {
        ...state,
        getusercodeLoading: false,
        getusercodeSuccess: false,
        getusercodeFailure: true,
        getusercodeError: action.payload,
      };
    case USER_REQUEST.GET_USER_CODE_RESET:
      return {
        ...state,
        getusercodeLoading: false,
        getusercodeSuccess: false,
        getusercodeFailure: false,
        getusercodeError: null,
        getusercode: [],
      };
    case USER_REQUEST.GET_USER_EMAIL_REQUEST:
      return {
        ...state,
        getuseremailLoading: true,
        getuseremailSuccess: false,
        getuseremailFailure: false,
        getuseremailError: null,
        getuseremail: [],
      };
    case USER_REQUEST.GET_USER_EMAIL_SUCCESS:
      return {
        ...state,
        getuseremailLoading: false,
        getuseremailSuccess: true,
        getuseremailFailure: false,
        getuseremailError: null,
        getuseremail: action.payload,
      };
    case USER_REQUEST.GET_USER_EMAIL_FAILURE:
      return {
        ...state,
        getuseremailLoading: false,
        getuseremailSuccess: false,
        getuseremailFailure: true,
        getuseremailError: action.payload,
      };
    case USER_REQUEST.GET_USER_EMAIL_RESET:
      return {
        ...state,
        getuseremailLoading: false,
        getuseremailSuccess: false,
        getuseremailFailure: false,
        getuseremailError: null,
        getuseremail: [],
      };
    case USER_REQUEST.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetpasswordLoading: true,
        resetpasswordSuccess: false,
        resetpasswordFailure: false,
        resetpasswordError: null,
        resetpassword: [],
      };
    case USER_REQUEST.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetpasswordLoading: false,
        resetpasswordSuccess: true,
        resetpasswordFailure: false,
        resetpasswordError: null,
        resetpassword: action.payload,
      };
    case USER_REQUEST.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetpasswordLoading: false,
        resetpasswordSuccess: false,
        resetpasswordFailure: true,
        resetpasswordError: action.payload,
      };
    case USER_REQUEST.GET_STAT_REQUEST:
      return {
        ...state,
        statLoading: true,
        statSuccess: false,
        statFailure: false,
        statError: null,
      };
    case USER_REQUEST.GET_STAT_SUCCESS:
      return {
        ...state,
        statLoading: false,
        statSuccess: true,
        statFailure: false,
        statError: null,
        stats: action.payload,
      };
    case USER_REQUEST.GET_STAT_FAILURE:
      return {
        ...state,
        statLoading: false,
        statSuccess: false,
        statFailure: true,
        statError: action.payload,
      };
    case USER_REQUEST.USER_SIGNIN_RESET:
      return {
        ...state,
        loginLoading: false,
        loginSuccess: false,
        loginFailure: false,
        loginError: null,
      };
    case USER_REQUEST.USER_SIGNIN_REQUEST:
      return {
        ...state,
        loginLoading: true,
        loginSuccess: false,
        loginFailure: false,
        loginError: null,
      };
    case USER_REQUEST.USER_SIGNIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        loginSuccess: false,
        loginFailure: true,
        loginError: action.payload,
      };
    case USER_REQUEST.USER_SIGNIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        loginSuccess: true,
        loginFailure: false,
        loginError: null,
        user: action.payload,
      };
    case USER_REQUEST.UPDATE_TENANT_PROFILE_REQUEST:
      return {
        ...state,
        updateTenantLoading: true,
        updateTenantSuccess: false,
        updateTenantFailure: false,
        updateTenantError: null,
      };
    case USER_REQUEST.UPDATE_TENANT_PROFILE_SUCCESS:
      return {
        ...state,
        updateTenantLoading: false,
        updateTenantSuccess: true,
        updateTenantFailure: false,
        updateTenantError: null,
      };
    case USER_REQUEST.UPDATE_TENANT_PROFILE_FAILURE:
      return {
        ...state,
        updateTenantLoading: false,
        updateTenantSuccess: false,
        updateTenantFailure: true,
        updateTenantError: action.payload,
      };
    default:
      return state;
  }
};
