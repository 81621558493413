import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function getOptionsList() {
  return axios.get(APP_SETTINGS.API_PATH.LANDLORD_FLOW.getOptions);
}

export function sendEmail(data) {
  return axios.post(APP_SETTINGS.API_PATH.LANDLORD_FLOW.sendEmail, data);
}

export function getPlansList(roleId) {
  return axios.get(`${APP_SETTINGS.API_PATH.LANDLORD_FLOW.getPlans}${roleId}`);
}
export function getCheckIsSubscribe(roleId) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.LANDLORD_FLOW.checkIsSubscribed}?userId=${roleId}`
  );
}

export function getAddonsList(planId) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.LANDLORD_FLOW.getAddons}?planId=${planId}`
  );
}

export function registerUser(requestData) {
  return axios.post(
    APP_SETTINGS.API_PATH.LANDLORD_FLOW.registerUser,
    requestData
  );
}

export function getOrderSummary(userId) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.LANDLORD_FLOW.getOrderDetails}?UserId=${userId}`
  );
}

export function getCouponDetails(requestData) {
  return axios.post(
    `${APP_SETTINGS.API_PATH.COUPONS.getApplyCouponDetails}`,
    requestData
  );
}

export function subscription(requestData) {
  return axios.post(
    APP_SETTINGS.API_PATH.LANDLORD_FLOW.subsriptionForAnotherSpace,
    requestData
  );
}

export function renewSubscription(requestData) {
  return axios.post(
    APP_SETTINGS.API_PATH.LANDLORD_FLOW.renewSubscription,
    requestData
  );
}

export function getDuplicateCouponDetails(data) {
  return axios.post(
    `${APP_SETTINGS.API_PATH.COUPONS.checkCouponByUserId}`, data
  );
}
