import axios from "axios";
import { APP_SETTINGS } from "../../config";

export function getAllCouponsPlan() {
    return axios.get(APP_SETTINGS.API_PATH.COUPONS.getAllCouponPlan)
}

export function getAllCouponsAddons() {
    return axios.get(APP_SETTINGS.API_PATH.COUPONS.getAllCouponsAddons)
}

export function getAllCoupons() {
    return axios.get(`${APP_SETTINGS.API_PATH.COUPONS.getAllCouponDetails}`)
}

export function deleteCoupons(couponId, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.COUPONS.deleteCoupon}?stripeCouponId=${couponId}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function saveCouponDetails(couponData, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.COUPONS.addOrUpdate}`, couponData, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function editCoupon(couponData, auth) {
    return axios.post(APP_SETTINGS.API_PATH.COUPONS.editCoupon, couponData, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function getByCouponId(id) {
    return axios.get(`${APP_SETTINGS.API_PATH.COUPONS.getByCouponId}?id=${id}`)
}
export function getCouponUsers(couponId){
    if(couponId){
    return axios.get(`${APP_SETTINGS.API_PATH.COUPONS.getCouponUsers}?id=${couponId}`)
}
else{
    return axios.get(`${APP_SETTINGS.API_PATH.COUPONS.getCouponUsers}`)
}
}