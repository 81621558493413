import { PRODUCT_LIST } from "../actions/utilities";

const INITIAL_STATE = {
    numberCart: 0,
    products: [],
    selectedProducts: [],
    totalPrice: 0
};

export function productListReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case PRODUCT_LIST.GET_PRODUCT_LIST:
            return {
                ...state,
                products: action.payload
            }
        case PRODUCT_LIST.SELECT_PRODUCT: 
            let check = false;
            if(state.selectedProducts.length === 0) {
               check = true;
            }else { 
                state.selectedProducts.map((item,key) => {
                    if(item.productId !== action.payload.productId) {
                       check = true;
                    }else {
                        state.selectedProducts[key].quantity++;
                        check = true;
                    }
                })
            }
            if(check){
                return {
                    ...state,
                    numberCart: state.numberCart+1,
                    selectedProducts: [ ...state.selectedProducts, action.payload]
                }
            }
        case PRODUCT_LIST.REMOVE_FROM_CART: 
            let quantity = state.selectedProducts[action.payload].quantity;
            return {
                ...state,
                numberCart: state.numberCart - quantity,
                selectedProducts: state.selectedProducts.filter(item => {
                    return item.productId !== state.selectedProducts[action.payload].productId
                })
            }
        default:
            return state;
    }
}
