import React from "react";
import { Modal } from "react-bootstrap"
export function SuccessModal(props) {
    return (
        <Modal show={props.popUpShow}
            centered
            onHide={() => { props.setPopUpShow(false) }}>
            <Modal.Header closeButton>
                <h2 className="generic-heading flex-center">
                    <span /> {"Success"}
                </h2>
            </Modal.Header>
            <Modal.Body>
                <p className="black-bold discard-msg">
                    {props.textMsg}
                </p>
                <div className="text-align-center modal-footer-cus">
                    <span onClick={() => { props.setPopUpShow(false) }}
                        className="pop-up-property-detaile-btn cursor btn-blue-color yes-no-btn">
                        {"Ok"}
                    </span>

                </div>
            </Modal.Body>
        </Modal>
    )
}