/* eslint-disable */
import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { WEB_PAGES } from "../components";
import { PATH, ROLEID } from "../config";
import PrivateRoute, { PrivateProfileRoute } from "./Auth/PrivateRoute";
import PublicRoute, {
  ConfigRoute,
  PublicProfileRoute,
  ModerateRoute,
} from "./Auth/PublicRoute";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { useLocation } from "react-router-dom";
import { useAuth } from "./Auth/ProvideAuth";
import { useDispatch } from "react-redux";
import { getUnreadNotificationCount } from "../redux/actions";
import { REGISTRATION_V2_REQUEST, request } from "../redux/actions/utilities";
import { useCookies } from "react-cookie";
import { userLogout } from "../redux/api";
import LandLordQuestions from "../components/LandLordSignUp/LandLordQuestions";
export const RouterConfig = () => {
  const { pathname } = useLocation();
  const [cookies, remove] = useCookies(["real_estate_user"]);
  let dispatch = useDispatch();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   dispatch(request(REGISTRATION_V2_REQUEST.NOTIFICATION_RESET));
  //   if (pathname !== PATH.TERMOFUSE && pathname !== PATH.POLICY) {
  //     if (window.$("#takkkk").length <= 0) {
  //       window.$("#root").after(`<script id="takkkk" type="text/javascript">
  //     var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  //     (function(){
  //     var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  //     s1.async=true;
  //     s1.src='https://embed.tawk.to/60a50ba1b1d5182476ba651b/1f62bas08';
  //     s1.charset='UTF-8';
  //     s1.setAttribute('crossorigin','*');
  //     s0.parentNode.insertBefore(s1,s0);
  //     })();
  //     </script>`);
  //     }
  //   } else {
  //     window.$("#takkkk").remove();
  //   }
  // }, [pathname]);
  let auth = useAuth();
  useEffect(() => {
    // if (true) {
    //   window.location.replace("instagram://");
    //   setTimeout(() => {
    //     window.location.replace(
    //       "https://apps.apple.com/us/app/instagram/id389801252"
    //     );
    //   }, 10000);
    // } else {
    //   window.location.replace("https://instagram.com");
    // }
  }, []);
  useEffect(() => {
    console.log("here::", auth);
    const interval = setInterval(() => {
      if (
        auth &&
        auth.real_estate_user &&
        auth.real_estate_user.roleId &&
        auth.real_estate_user.roleId !== ROLEID.ADMIN
      ) {
        //dispatch(getUnreadNotificationCount(auth,logMeout))
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  function logMeout() {
    userLogout(null, auth).then((response) => {
      if (response.data.succeeded === true) {
        remove("real_estate_user", { path: "/" });
      }
    });
  }
  return (
    <div>
      <Switch>
        {/* List all public routes here */}
        <PublicRoute exact path={PATH.LINKEDIN_REDIRECT}>
          <LinkedInPopUp />
        </PublicRoute>
        <PublicRoute exact path={PATH.APPLE_REDIRECT}>
          <WEB_PAGES.HOME />
        </PublicRoute>
        <PublicRoute exact path={PATH.LOGIN}>
          <WEB_PAGES.LOGIN />
        </PublicRoute>
        <PublicRoute exact path={PATH.VERIFICATION}>
          <WEB_PAGES.VERIFICATION />
        </PublicRoute>
        {/* <PublicRoute exact path={PATH.LANDLORD_QUESTIONS}>
          <LandLordQuestions />
        </PublicRoute> */}
        {/* <PublicRoute exact path={PATH.LANDLORD_ANALYTICS}>
          <WEB_PAGES.LANDLORD_ANALYTICS />
        </PublicRoute> */}
        <PrivateRoute exact path={PATH.TENANTLISTING}>
          <WEB_PAGES.TENANTLISTING />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TENANTDASHBOARD}>
          <WEB_PAGES.TENANTDASHBOARD />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.LANDLORDDASHBOARD}>
          <WEB_PAGES.LANDLORDDASHBOARD />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.EDITPOSTPROPERTY}>
          <WEB_PAGES.EDITPOSTPROPERTY />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMINLOGSDASHBOARD}>
          <WEB_PAGES.ADMINLOGSDASHBOARD />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.REPORTSDASHBOARD}>
          <WEB_PAGES.REPORTSDASHBOARD />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.SUBSCRIPTIONREPORT}>
          <WEB_PAGES.SUBSCRIPTIONREPORTS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TENANTSREPORTS}>
          <WEB_PAGES.NEWTENANTSREPORTS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.LANDLORDREPORTS}>
          <WEB_PAGES.NEWLANDLORDREPORTS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.WEEKLYSTATUSREPORTS}>
          <WEB_PAGES.WEEKLYSTATUSREPORT />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TOTALCOUNTSREPORT}>
          <WEB_PAGES.TOTALCOUNTSREPORT />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TOP20LANDLORDSREPORT}>
          <WEB_PAGES.TOP20LANDLORDSREPORT />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TOP20TENANTSREPORT}>
          <WEB_PAGES.TOP20TENANTSREPORT />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TOTALLISTINGPERSTATEREPORT}>
          <WEB_PAGES.TOTALLISTINGPERSTATEREPORT />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TOTALLISTINGPERCATEGORYREPORT}>
          <WEB_PAGES.TOTALLISTINGPERCATEGORYREPORT />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.SOCIALMEDIAMARKETING}>
          <WEB_PAGES.SOCIALMEDIAMARKETING />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.DASHBOARD}>
          <WEB_PAGES.DASHBOARD />
        </PrivateRoute>
        <PublicProfileRoute exact path={PATH.PROFILE}>
          <WEB_PAGES.PROFILE />
        </PublicProfileRoute>
        <PrivateRoute exact path={PATH.COUPON}>
          <WEB_PAGES.COUPON />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.COUPON_WITH_ADDONS}>
          <WEB_PAGES.COUPON_WITH_ADDONS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.LANDLORD_ANALYTICS}>
          <WEB_PAGES.LANDLORD_ANALYTICS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.LANDLORD_DESCRIPTION}>
          <WEB_PAGES.LANDLORD_DESCRIPTION />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.PICK_UP_SIGN}>
          <WEB_PAGES.PICK_UP_SIGN />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.SELECTED_SIGN}>
          <WEB_PAGES.SELECTED_SIGN />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.SIGN_PRODUCTS}>
          <WEB_PAGES.SIGN_PRODUCTS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.SIGN_DETAILS}>
          <WEB_PAGES.SIGN_DETAILS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.EMAILER_TEMPLATE}>
          <WEB_PAGES.EMAILER_TEMPLATE />
        </PrivateRoute>
        <ModerateRoute exact path={PATH.THANKYOU}>
          <WEB_PAGES.THANKYOU />
        </ModerateRoute>
        <PrivateRoute exact path={PATH.PROPERTYDETAIL}>
          <WEB_PAGES.PROPERTYDETAIL />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.SETTINGS}>
          <WEB_PAGES.SETTINGS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.MESSAGES}>
          <WEB_PAGES.MESSAGES />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.POSTPROPERTY}>
          <WEB_PAGES.POSTPROPERTY />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.VIEWPROFILE}>
          <WEB_PAGES.VIEWPROFILE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.NEGOTIATION}>
          <WEB_PAGES.NEGOTIATION />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.COUPONUSER}>
          <WEB_PAGES.COUPONUSER />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.MARKETINGPREFERENCE}>
          <WEB_PAGES.MARKETINGPREFERENCE />
        </PrivateRoute>
        <ModerateRoute exact path={PATH.PAYMENTCHECKOUT}>
          <WEB_PAGES.PAYMENTCHECKOUT />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.PAYMENTCHECKOUTADMIN}>
          <WEB_PAGES.PAYMENTCHECKOUTADMIN />
        </ModerateRoute>
        <PrivateRoute exact path={PATH.REPORTEDUSER}>
          <WEB_PAGES.REPORTEDUSERS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.EDITPROFILE}>
          <WEB_PAGES.EDITPROFILE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.REPORTEDSPACE}>
          <WEB_PAGES.REPORTEDSPACE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.VIEW_ALL_NOTIFICATION}>
          <WEB_PAGES.VIEW_ALL_NOTIFICATION />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.PROPERTYSIGNAGE}>
          <WEB_PAGES.PROPERTYSIGNAGE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.VIEW_CART}>
          <WEB_PAGES.VIEW_CART />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.PROPERTY_DESCRIPTION_REQUEST}>
          <WEB_PAGES.PROPERTY_DESCRIPTION_REQUEST />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.AI_VIRTUAL_ASSISTANT}>
          <WEB_PAGES.AI_VIRTUAL_ASSISTANT />
        </PrivateRoute>
        <ModerateRoute exact path={PATH.THANKYOUSIGNUP}>
          <WEB_PAGES.THANKYOUSIGNUP />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.SUBSCRIPTION}>
          <WEB_PAGES.SIGNUP />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.SUPPORT}>
          <WEB_PAGES.SUPPORT />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.TERMSANDCONDITIONS}>
          <WEB_PAGES.TERMSANDCONDITIONS />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.SIGNUP}>
          <WEB_PAGES.REGISTRATION />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.USERVERIFIED}>
          <WEB_PAGES.USERVERIFIED />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.ABOUTUS}>
          <WEB_PAGES.ABOUTUS />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.BROWSELISTING}>
          <WEB_PAGES.BROWSELISTING />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.POLICY}>
          <WEB_PAGES.POLICY />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.TERMOFUSE}>
          <WEB_PAGES.TERM />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.FORGETPASSWORD}>
          <WEB_PAGES.FORGETPASSWORD />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.CHANGEPASSWORD}>
          <WEB_PAGES.CHANGEPASSWORD />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.LISTINGDETAILPAGE}>
          <WEB_PAGES.PROPERTYDETAILPAGE />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.VIEW_TERM_SHEET}>
          <WEB_PAGES.VIEW_TERM_SHEET />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.PROPERTY_DETIAL_PAGE}>
          <WEB_PAGES.PROPERTYDETAILPAGE />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.PRICING}>
          <WEB_PAGES.PRICING />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.HOME}>
          <WEB_PAGES.LANDINGPAGE />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.DONATENOW}>
          <WEB_PAGES.DONATENOW />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.OURPARTNERS}>
          <WEB_PAGES.OURPARTNERS />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.BROWSELISTINGENERIC}>
          <WEB_PAGES.BROWSELISTING />
        </ModerateRoute>
        <ModerateRoute exact path={PATH.LANDING_PAGE}>
          <WEB_PAGES.LANDINGPAGE />
        </ModerateRoute>
        <ConfigRoute exact path={PATH.JSON_CONFIG}>
          <WEB_PAGES.JSON_CONFIG />
        </ConfigRoute>
        <ConfigRoute exact path={PATH.LANDLORD_QUESTIONS}>
          <WEB_PAGES.LANDLORD_QUESTIONS />
        </ConfigRoute>
        {/* Subscription Questions */}
        <ConfigRoute exact path={PATH.LANDLORD_QUESTIONS_SUBSCRIPTION}>
          <WEB_PAGES.LANDLORD_QUESTIONS_SUBSCRIPTION />
        </ConfigRoute>
        {/* End */}
        <ConfigRoute exact path={PATH.LEASING_PLAN}>
          <WEB_PAGES.LEASING_PLAN />
        </ConfigRoute>
        <ConfigRoute exact path={PATH.TENANT_SIGNUP}>
          <WEB_PAGES.TENANT_SIGNUP />
        </ConfigRoute>
        <ConfigRoute exact path={PATH.TENANT_LEASING_PLAN}>
          <WEB_PAGES.TENANT_LEASING_PLAN />
        </ConfigRoute>
        <ConfigRoute exact path={PATH.WINDOWSIGNAGE}>
          <WEB_PAGES.WINDOWSIGNAGE />
        </ConfigRoute>
        <ConfigRoute exact path={PATH.RENEW_SUBSCRIPTION}>
          <WEB_PAGES.RENEW_SUBSCRIPTION />
        </ConfigRoute>

        <Route
          path={PATH.AppPaymentCheckout}
          component={WEB_PAGES.APPPAYMENTCHECKOUT}
        />
        <Route
          path={PATH.APPSUBSCRIPTION}
          component={WEB_PAGES.APPSUBSCRIPTION}
        />
        <Route path={PATH.NOPAGE} component={WEB_PAGES.NOPAGE} />
        {/* <Route path={PATH.LANDLORD_ANALYTICS} component={WEB_PAGES.LANDLORD_ANALYTICS} /> */}
      </Switch>
    </div>
  );
};
