import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const ReactNotificationComponent = ({ title, body }) => {

  let user = useSelector((state) => state.user)
  useEffect(() => {
    if (user.notificationList.length > 0) {
      toast(<Display />);
    }
  }, [user])
  useEffect(() => {
    const interval = setInterval(() => {
      toast.dismiss();
    }, 3000);

    return () => clearInterval(interval);
  }, [])
  function Display() {
    return (
      <div className="notify-popup">
        <h4>{title}</h4>
        <p>{body}</p>
      </div>
    );
  }

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

ReactNotificationComponent.defaultProps = {
  title: "This is title",
  body: "Some body",
};

ReactNotificationComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

export default ReactNotificationComponent;
