import {
  getPropertyReport,
  getSubscriptionReport,
  getUserReport,
  subscriptionByMonth,
  subscriptionByState,
  subscriptionByCount,
  userByCount,
  userByMonth,
  getMediaList,
  getUserList,
  reportSpaceList,
  reportUserList,
  getAdminLog,
  addAdminLog,
  getAllMarketingPreference,
  addBrochure,
  getMarketingDetailByUUID,
  getWeeklyReport,
  totalCountReport,
  top20LandlordsReport,
  top20TenantsReport,
  totalListingPerStateReport,
  totalListingPerCategoryReport,
  getWeeklyReport2,
  weeklyReport,
} from "../api";
import {
  request,
  success,
  failure,
  REPORTS_REQUEST,
  MARKETING_PREFERNECE_REQUEST,
} from "./utilities";

export function propertyReport(id) {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_PROPERTY_REPORT_REQUEST));
    getPropertyReport(id).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_PROPERTY_REPORT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_PROPERTY_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_PROPERTY_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function subscriptionReport(id, pageNumber) {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_SUBSCRIPTION_REPORT_REQUEST));
    getSubscriptionReport(id, pageNumber).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_SUBSCRIPTION_REPORT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_SUBSCRIPTION_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_SUBSCRIPTION_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function userReport(id) {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_USER_REPORT_REQUEST));
    getUserReport(id).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(REPORTS_REQUEST.GET_USER_REPORT_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_USER_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_USER_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function mediaList(auth) {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_MEDIA_LIST_REQUEST));
    getMediaList(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(REPORTS_REQUEST.GET_MEDIA_LIST_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_MEDIA_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_MEDIA_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function getListOfUser(data) {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_ALL_USER_REQUEST));
    getUserList(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(REPORTS_REQUEST.GET_ALL_USER_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(REPORTS_REQUEST.GET_ALL_USER_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_ALL_USER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function byMonthSubscription() {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_SUBSCRIPTION_BY_MONTH_REQUEST));
    subscriptionByMonth().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_SUBSCRIPTION_BY_MONTH_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_SUBSCRIPTION_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_SUBSCRIPTION_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function byStateSubscription() {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_SUBSCRIPTION_BY_STATE_REQUEST));
    subscriptionByState().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_SUBSCRIPTION_BY_STATE_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_SUBSCRIPTION_BY_STATE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_SUBSCRIPTION_BY_STATE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function byCountSubscription() {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_SUBSCRIPTION_BY_COUNT_REQUEST));
    subscriptionByCount().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_SUBSCRIPTION_BY_COUNT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_SUBSCRIPTION_BY_COUNT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_SUBSCRIPTION_BY_COUNT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function byCountUser() {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_USER_BY_COUNT_REQUEST));
    userByCount().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_USER_BY_COUNT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_USER_BY_COUNT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_USER_BY_COUNT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function byMonthUser() {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_USER_BY_MONTH_REQUEST));
    userByMonth().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_USER_BY_MONTH_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_USER_BY_MONTH_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_USER_BY_MONTH_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function reportedSpaceList(data, auth) {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_REPORTED_SPACE_LIST_REQUEST));
    reportSpaceList(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_REPORTED_SPACE_LIST_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_REPORTED_SPACE_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_REPORTED_SPACE_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function reportedUserList(data, auth) {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_REPORTED_USER_LIST_REQUEST));
    reportUserList(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_REPORTED_USER_LIST_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_REPORTED_USER_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_REPORTED_SPACE_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getAdminLogList(pageSize, pageNumber, auth) {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_ALL_LOGS_REQUEST));
    getAdminLog(pageSize, pageNumber, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(REPORTS_REQUEST.GET_ALL_LOGS_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(REPORTS_REQUEST.GET_ALL_LOGS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_ALL_LOGS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function addAdminLogToList(logData, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.ADD_LOGS_REQUEST));
    addAdminLog(logData, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(REPORTS_REQUEST.ADD_LOGS_SUCCESS, logData));
          if (moveToNext) {
            moveToNext(false);
          }
        } else {
          dispatch(
            failure(REPORTS_REQUEST.ADD_LOGS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.ADD_LOGS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getAllMarketingPreferenceList(data, auth) {
  return (dispatch) => {
    dispatch(request(MARKETING_PREFERNECE_REQUEST.GET_ALL_LIST_REQUEST));
    getAllMarketingPreference(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              MARKETING_PREFERNECE_REQUEST.GET_ALL_LIST_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              MARKETING_PREFERNECE_REQUEST.GET_ALL_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MARKETING_PREFERNECE_REQUEST.GET_ALL_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function addBrochureToMarketingPreference(
  data,
  auth,
  successMove,
  failMove,
  url
) {
  return (dispatch) => {
    dispatch(request(MARKETING_PREFERNECE_REQUEST.ADD_BROCHURE_REQUEST));
    addBrochure(data, auth).then(
      (response) => {
        console.log("adoadd", response);
        if (response.data.succeeded === true) {
          dispatch(
            success(MARKETING_PREFERNECE_REQUEST.ADD_BROCHURE_SUCCESS, data)
          );
          if (successMove) {
            successMove(url);
          }
        } else {
          dispatch(
            failure(
              MARKETING_PREFERNECE_REQUEST.ADD_BROCHURE_FAILURE,
              response.data.message
            )
          );
          if (failMove) {
            failMove();
          }
        }
      },
      (error) => {
        dispatch(
          failure(
            MARKETING_PREFERNECE_REQUEST.ADD_BROCHURE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
        if (failMove) {
          failMove();
        }
      }
    );
  };
}
export function getMarketingDetail(uuid, auth) {
  return (dispatch) => {
    dispatch(request(MARKETING_PREFERNECE_REQUEST.MARKETING_DETAIL_REQUEST));
    getMarketingDetailByUUID(uuid, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          console.log("here is response:::", response);
          dispatch(
            success(
              MARKETING_PREFERNECE_REQUEST.MARKETING_DETAIL_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              MARKETING_PREFERNECE_REQUEST.MARKETING_DETAIL_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MARKETING_PREFERNECE_REQUEST.MARKETING_DETAIL_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function weeklyStatusReport(weeklyDate) {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_WEEKLY_REPORT_REQUEST));
    getWeeklyReport(weeklyDate).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_WEEKLY_REPORT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_WEEKLY_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_WEEKLY_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function weeklyStatusReport2() {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_WEEKLY_REPORT_REQUEST));
    getWeeklyReport2().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_WEEKLY_REPORT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_WEEKLY_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_WEEKLY_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getTotalCountReport() {
  console.log("djewoij");
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_TOTAL_COUNT_REPORT_REQUEST));
    totalCountReport().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_TOTAL_COUNT_REPORT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_TOTAL_COUNT_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_TOTAL_COUNT_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getTop20LandlordsReport() {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_TOP_20_LANDLORDS_REPORT_REQUEST));
    top20LandlordsReport().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_TOP_20_LANDLORDS_REPORT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_TOP_20_LANDLORDS_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_TOP_20_LANDLORDS_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getTop20TenantsReport() {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.GET_TOP_20_TENANTS_REPORT_REQUEST));
    top20TenantsReport().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_TOP_20_TENANTS_REPORT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_TOP_20_TENANTS_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_TOP_20_TENANTS_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getTotalListingPerStateReport() {
  return (dispatch) => {
    dispatch(
      request(REPORTS_REQUEST.GET_TOTAL_LISTING_PER_STATE_REPORT_REQUEST)
    );
    totalListingPerStateReport().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_TOTAL_LISTING_PER_STATE_REPORT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_TOTAL_LISTING_PER_STATE_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_TOTAL_LISTING_PER_STATE_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getTotalListingPerCategoryReport() {
  return (dispatch) => {
    dispatch(
      request(REPORTS_REQUEST.GET_TOTAL_LISTING_PER_CATEGORY_REPORT_REQUEST)
    );
    totalListingPerCategoryReport().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.GET_TOTAL_LISTING_PER_CATEGORY_REPORT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.GET_TOTAL_LISTING_PER_CATEGORY_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.GET_TOTAL_LISTING_PER_CATEGORY_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function updataWeeklyReport(data) {
  return (dispatch) => {
    dispatch(request(REPORTS_REQUEST.UPDATE_WEEKLY_REPORT_REQUEST));
    weeklyReport(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              REPORTS_REQUEST.UPDATE_WEEKLY_REPORT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              REPORTS_REQUEST.UPDATE_WEEKLY_REPORT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            REPORTS_REQUEST.UPDATE_WEEKLY_REPORT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
