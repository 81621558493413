import { request, success, failure, RENT_SPREE_REQUEST } from './utilities';
import { getRentSpree, rentSpreeVerification } from '../api';

export function getrentSpreeToken(data, SuccessRequest) {
    return dispatch => {
        dispatch(request(RENT_SPREE_REQUEST.GET_RENT_SPREE_REQUEST));
        getRentSpree(data).then(
            response => {
                if (response.status === 200) {
                    dispatch(success(RENT_SPREE_REQUEST.GET_RENT_SPREE_SUCCESS, response.data.data))
                    if (SuccessRequest) {
                        SuccessRequest(response.data.data);
                    }
                }
                else {
                    dispatch(failure(RENT_SPREE_REQUEST.GET_RENT_SPREE_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(RENT_SPREE_REQUEST.GET_RENT_SPREE_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    };
}

export function userRentSpree(data, token, moveToNext) {
    return dispatch => {
        dispatch(request(RENT_SPREE_REQUEST.RENT_SPREE_REQUEST));
        rentSpreeVerification(data, token).then(
            response => {
                if (response.status === 200) {
                    dispatch(success(RENT_SPREE_REQUEST.RENT_SPREE_SUCCESS, response.data.data))
                    moveToNext(response.data.applyLink.fullLink)
                }
                else {
                    dispatch(failure(RENT_SPREE_REQUEST.RENT_SPREE_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(RENT_SPREE_REQUEST.RENT_SPREE_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    };
}
