import { autoSuggest, getAllLists, getAllLists_data, getAllTenants, moreDetail, sendRequest, getSuggestedList, BaseTermSheet } from "../api"
import { BROWSE_LISTING, request, success, failure, TERM_SHEET } from "./utilities"

export function getAllPropertyList(listData, auth, moveToNext) {
    return dispatch => {
        dispatch(request(BROWSE_LISTING.GET_LIST_REQUEST));
        if (auth) {
            getAllLists(listData, auth).then(
                response => {
                    if (response.data.succeeded === true) {
                        dispatch(success(BROWSE_LISTING.GET_LIST_SUCCESS, { res: response.data.data, pageNumber: listData.pageNumber }))
                        if (moveToNext) {
                            if (response.data.data && response.data.data.length <= 0 && listData.pageNumber === 1) {
                                moveToNext(true, null)
                            }
                            else if (!response.data.data && listData.pageNumber === 1) {
                                moveToNext(true, null)
                            } else {
                                moveToNext(false, response.data.data)
                            }
                        }
                    }
                    else {
                        dispatch(failure(BROWSE_LISTING.GET_LIST_FAILURE, response.data.message))
                    }
                },
                error => {
                    dispatch(failure(BROWSE_LISTING.GET_LIST_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
                }
            )
        }
        else {
            getAllLists_data(listData).then(
                response => {
                    if (response.data.succeeded === true) {
                        dispatch(success(BROWSE_LISTING.GET_LIST_SUCCESS, { res: response.data.data, pageNumber: listData.pageNumber }))
                    }
                    else {
                        dispatch(failure(BROWSE_LISTING.GET_LIST_FAILURE, response.data.message))
                    }
                },
                error => {
                    dispatch(failure(BROWSE_LISTING.GET_LIST_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
                }
            )
        }
    }
}
export function getAllSuggestedPropertyList(listData, auth) {
    return dispatch => {
        dispatch(request(BROWSE_LISTING.GET_SUGGESTED_LIST_REQUEST));
        getSuggestedList(listData, auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(BROWSE_LISTING.GET_SUGGESTED_LIST_SUCCESS, response.data.data))
                }
                else {
                    dispatch(failure(BROWSE_LISTING.GET_SUGGESTED_LIST_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(BROWSE_LISTING.GET_SUGGESTED_LIST_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )

    }
}
export function getAllTenantList(listData, auth) {
    return dispatch => {
        dispatch(request(BROWSE_LISTING.GET_TENANTS_REQUEST));
        getAllTenants(listData, auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(BROWSE_LISTING.GET_TENANTS_SUCCESS, response.data.data))
                }
                else {
                    dispatch(failure(BROWSE_LISTING.GET_TENANTS_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(BROWSE_LISTING.GET_TENANTS_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}


export function sendRequestToTenant(requestData, auth, moveToNext, moveToNextFail) {
    return dispatch => {
        dispatch(request(BROWSE_LISTING.TENANT_CONNECTION_REQUEST));
        sendRequest(requestData, auth).then(
            response => {
                if (response.data.succeeded === true) {

                    dispatch(success(BROWSE_LISTING.TENANT_CONNECTION_SUCCESS, response.data.data))
                    if (moveToNext) {
                        moveToNext();
                    }
                } else {
                    dispatch(failure(BROWSE_LISTING.TENANT_CONNECTION_FAILURE, response.data.message))
                    if (moveToNextFail) {
                        moveToNextFail()
                    }
                }
            },
            error => {
                dispatch(failure(BROWSE_LISTING.TENANT_CONNECTION_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
                if (moveToNextFail) {
                    moveToNextFail()
                }
            }
        )
    };
}

export function getLocationSuggestion(query) {
    return dispatch => {
        dispatch(request(BROWSE_LISTING.GET_SUGGESTION_LOADING))
        autoSuggest(query).then(
            response => {
                if (response.data.succeeded === true)
                    dispatch(success(BROWSE_LISTING.GET_SUGGESTION_SUCCESS, response.data.data))
            },
            error => {
                dispatch(failure(BROWSE_LISTING.GET_SUGGESTION_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}

export function moreTenantDetail(tenantId, auth) {
    return dispatch => {
        dispatch(request(BROWSE_LISTING.GET_TENANT_DETAIL_REQUEST))
        moreDetail(tenantId, auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(BROWSE_LISTING.GET_TENANT_DETAIL_SUCCESS, response.data.data))
                }
                else {
                    dispatch(failure(BROWSE_LISTING.GET_TENANT_DETAIL_FAILURE, response.data.message))

                }
            },
            error => {
                dispatch(failure(BROWSE_LISTING.GET_TENANT_DETAIL_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}