import moment from "moment";

function getTodayDate() {
    var today = new Date();
    return today;
}
function convertTZ(date) {
    let tzString = getCountryTimeZone();
    let finalData = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
    return finalData.toString().split("(")[1].split(")")[0]
}

function getPreviousDate(date, interval) {
    const toDate = new Date(date);
    const fromDate = new Date(toDate);
    fromDate.setDate(fromDate.getDate() - interval);
    return fromDate;
}
function getNextDate(date, interval) {
    const toDate = new Date(date);
    const fromDate = new Date(toDate);
    fromDate.setDate(fromDate.getDate() + interval);
    return fromDate;
}
function monthOfYearAsString(monthIndex) {
    return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][monthIndex];
}
function dayOfWeekAsString(dayIndex) {
    return ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"][dayIndex];
}
function fullMonthOfYearAsString(monthIndex) {
    return ["January", "Feburary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][monthIndex];
}
function getDateInFormat(date) {
    var dateToChange = new Date(date);
    var month = dateToChange.getMonth();
    var date_ = dateToChange.getDate();
    var year = dateToChange.getFullYear();
    return `${month + 1} / ${date_} / ${year}`;
}

function getDateInDashFormat(date) {
    var dateToChange = new Date(date);
    var month = dateToChange.getMonth();
    var date_ = dateToChange.getDate();
    var year = dateToChange.getFullYear();
    return `${date_}-${month + 1}-${year}`;
}
function convertBase64(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload =  () => {
           resolve(fileReader.result);
           console.log("-----------Result------------",fileReader.result)
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
function getInitialName(name) {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;
    return initials.toUpperCase();
};
function createImageFromInitials(size, name, color) {
    if (name == null) return;
    name = getInitialName(name)
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size
    context.fillStyle = "#ffffff"
    context.fillRect(0, 0, size, size)
    context.fillStyle = `${color}50`
    context.fillRect(0, 0, size, size)
    context.fillStyle = color;
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.font = `${size / 2}px Roboto`
    context.fillText(name, (size / 2), (size / 2))
    return canvas.toDataURL()
};
function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
function listOfNextYears(number) {
    let expYearArray = [];
    for (let i = 0; i <= number; i++) {
        expYearArray.push((new Date().getFullYear() + (i)))
    }
    return expYearArray;
}
function listOfMonths() {
    return [
        {
            id: 0,
            value: 1,
            month: "January"
        },
        {
            id: 1,
            value: 2,
            month: "Feburary"
        },
        {
            id: 2,
            value: 3,
            month: "March"
        },
        {
            id: 3,
            value: 4,
            month: "April"
        },
        {
            id: 4,
            value: 5,
            month: "May"
        },
        {
            id: 5,
            value: 6,
            month: "June"
        },
        {
            id: 6,
            value: 7,
            month: "July"
        },
        {
            id: 7,
            value: 8,
            month: "August"
        },
        {
            id: 8,
            value: 9,
            month: "September"
        },
        {
            id: 9,
            value: 10,
            month: "October"
        },
        {
            id: 10,
            value: 11,
            month: "November"
        },
        {
            id: 11,
            value: 12,
            month: "December"
        }
    ]
}
function expiryDateCheck(expiryDate) {
    var GivenDate = expiryDate;
    var CurrentDate = new Date();
    GivenDate = new Date(GivenDate);
    if (GivenDate > CurrentDate) {
        return true
    } else {
        return false
    }
}
function dateDifference1(start, end) {
    var endDate = new Date(end)
    var startDate = new Date(start);
    if (startDate > endDate) {
        return true
    } else {
        return false
    }
}
function date_diff_indays(date2) {
    let dt1 = new Date();
    let dt2 = new Date(date2);
    let final = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24))
    if (final < 0) {
        final = final * -1
    }
    return final;
}
function dateDifference(createdDate) {
    var date1 = new Date(createdDate);
    var date2 = new Date();
    var diffDays = date2.getTime() - date1.getTime();
    if (diffDays < 0) {
        diffDays = diffDays * -1
    }
    return diffDays
}
function date_diff_in_days(start, end) {
    let dt1 = new Date(start);
    let dt2 = new Date(end);
    let final = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24))

    return final;
}
function getCountryTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}
function allMonthList() {
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
}
function subscriptionData(subscription, data) {
    let monthList = allMonthList()
    let dataArray = [];
    let value = 0;
    switch (subscription) {
        case "Monthly":
            while (value <= 11) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].month === monthList[value]) {
                        dataArray.push(data[i].monthlyCount)
                    }
                }
                value++;
            }
            return dataArray;
        case "3 Months":
            while (value <= 11) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].month === monthList[value]) {
                        dataArray.push(data[i].threeMonthsCount)
                    }
                }
                value++;
            }
            return dataArray;
        case "6 Months":
            while (value <= 11) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].month === monthList[value]) {
                        dataArray.push(data[i].sixMonthsCount)
                    }
                }
                value++;
            }
            return dataArray;
        default:
            dataArray = [];
    }
}
function landlordTenantByMonthData(role, data) {
    let monthList = allMonthList()
    let dataArray = [];
    let value = 0;
    switch (role) {
        case "landlord":
            while (value <= 11) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].month === monthList[value]) {
                        dataArray.push(data[i].landlordCount)
                    }
                }
                value++;
            }
            return dataArray;
        case "tenant":
            while (value <= 11) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].month === monthList[value]) {
                        dataArray.push(data[i].tenantCount)
                    }
                }
                value++;
            }
            return dataArray;
        default:
            dataArray = [];
    }
}
function dataByState(data) {
    let stateData = []
    for (let i = 0; i < data.length; i++) {
        stateData.push({
            name: data[i].state,
            y: data[i].userCount
        });
    }
    return stateData;
}
function alertDialog(msg, yesFunc, params) {
    return (
        window.confirm(msg) && yesFunc(params)
    )
}
function Truncate(text, startChars, endChars, maxLength) {
    if (text && text.length > maxLength) {
        var start = text.substring(0, startChars);
        var end = text.substring(text.length - endChars, text.length);
        return start + ' ' + end;
    }
    return text;
}
function replaceSpace(text) {
    var i = 0, length = text?.length;
    for (i; i < length; i++) {
        text = text.replace(" ", "-");
        text = text.replace(",", "");
    }
    return text;
}
function replaceDash(text) {
    var i = 0, length = text?.length;
    for (i; i < length; i++) {
        text = text.replace("-", " ");
    }
    return text;
}
function replacePercent20(text) {
    var i = 0, length = text.length;
    for (i; i < length; i++) {
        text = text.replace("%20", " ");
    }
    return text;
}
const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};
function getDateInFormatWithMonthName(date) {
    var dateToChange = new Date(date);
    var month = dateToChange.getMonth();
    var date_ = dateToChange.getDate();
    var year = dateToChange.getFullYear();
    return `${date_}/${monthOfYearAsString(month)}/${year}`;
}
function getCouponDiscountedAmount(couponDiscount, total) {
    if (couponDiscount) {
        if (couponDiscount.amountOff) {
            return { discount: couponDiscount.amountOff/100, total: (total - couponDiscount.amountOff/100) >= 0 ? (total - couponDiscount.amountOff/100) : 0 }
        }
        else {
            let finalAmount = ((couponDiscount.percentOff / 100) * total)
            return { discount: finalAmount, total: (total - finalAmount) >= 0 ? (total - finalAmount) : 0 }
        }
    }
}
function getValidityDate(plan, appliedDate, validity) {
    if (validity === "once") {
        return 0
    }
    else if (validity === "forever") {
        return "Forever"
    }
    else {
        let planValidity = plan === "GOLD" ? 50 : 75
        let couponValidityInDays = validity * 30;
        var todayDate = new Date();
        var date_diff = date_diff_in_days(appliedDate, todayDate)
        var allDiff = couponValidityInDays / planValidity;
        var final = Math.ceil(allDiff) - (date_diff + 1);
        return final <= 0 ? 0 : final;
    }
}

function getSinceTime(date) {
    var formatedDate = new Date(date);
    let dateObject = formatedDate.toLocaleTimeString();

    // const seconds = Math.floor((Date.now() - dateObject.getTime()) / 1000);
    // const interval = intervals.find(i => i.seconds < seconds);
    // const count = Math.floor(seconds / interval.seconds);
    // return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
    return dateObject;
}

function dateTimeDifference(date) {
    var formatDate = moment(date).format();
    var CurrentDate = new Date();
    var formatCurrentDate = moment(CurrentDate).format();
    if (formatDate < formatCurrentDate) {
        return (moment(date).format('L'))
    }
    else if (formatDate === CurrentDate) {
        return (moment(date).startOf('hour').fromNow())
    }
}
function monthlyToPerSqft(sqft, price) {
    let value = (price * 12) / sqft;
    return Math.round(value.toFixed(2));
}
function perSqftToMonthly(price, sqft) {
    if (price !== "" && sqft !== "") {
        let final = ((price * sqft) / 12).toFixed(2);
        let finalInt = parseInt(final);
        if (final > finalInt) {
            return Math.round(((price * sqft) / 12).toFixed(2));
        }
        else {
            return Math.round(finalInt)
        }
    }
    else {
        return ""
    }
}
function changeInSqft(area, price, setPerSQFT, setMonthly) {
    if (price) {
        setMonthly(price !== "" ? perSqftToMonthly(price, area) : "");
    }
} function monthlyToPerSqftAddEdit(sqft, price) {
    let value = (price * 12) / sqft;
    return value.toFixed(2);
}
function perSqftToMonthlyAddEdit(price, sqft) {
    if (price !== "" && sqft !== "") {
        let final = ((price * sqft) / 12).toFixed(2);
        let finalInt = parseInt(final);
        if (final > finalInt) {
            return ((price * sqft) / 12).toFixed(2);
        }
        else {
            return finalInt
        }
    }
    else {
        return ""
    }
}

function getFileExtension(file) {
    let lastIndexOfDot = file.lastIndexOf('.');
    let ext = file.slice(lastIndexOfDot + 1, file.length);
    console.log("ext:::", ext)
    return ext;
}
function viewImage(url) {
    var url2;
    var fileExtension = url.substr((url.lastIndexOf('.') + 1));
    if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {
        var image = new Image();
        image.src = url;
        ////$("#imgTag").attr("src", url);
        //$(".imageViewrBody").html("")
        //$(".imageViewrBody").html(image.outerHTML)
        //$("#imageViewer").modal("show");
        var w = window.open("");
        w.document.write(image.outerHTML);
        w.stop()

    } else if (fileExtension === "pdf") {
        url2 = "https://docs.google.com/viewer?url=" + url;
        window.open(url2, '_blank')
    } else if (fileExtension === "doc" || fileExtension === "docx") {
        url2 = "https://docs.google.com/viewer?url=" + url;
        window.open(url2, "Image Viewer", '_blank')
    } else if (fileExtension === "ppt") {
        url2 = "https://docs.google.com/viewer?url=" + url;
        window.open(url2, "Image Viewer", '_blank')
    } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        url2 = "https://docs.google.com/viewer?url=" + url;
        window.open(url2, "Image Viewer", '_blank')
    } else if (fileExtension === "txt") {
        url2 = "https://docs.google.com/viewer?url=" + url;
        window.open(url2, "Image Viewer", '_blank')
    }

    //window.open(url);
}

function wordsLengthCheck(text) {
    let words = text.split(" ")
    return words.length <= 700
}
function checkMobileAndTablet() {
    var MobileDetect = require('mobile-detect')

    let type = new MobileDetect(window.navigator.userAgent)

    if (type.os() === "iOS") {
        return "Download for iOS";
    } else if (type.os() === "AndroidOS") {
        return "Download for Android";
    } else if (type.os() === "BlackBerryOS") {
        return "Download for Blackberry";
    } else if (type.os() === "WindowsOS") {
        return "Download for Windows";
    } else if (type.os() === "MacOS") {
        return "Download for Mac";
    } else {
        return "Download on your device";
    }

}

export {
    checkMobileAndTablet,
    wordsLengthCheck,
    viewImage,
    getFileExtension,
    monthlyToPerSqftAddEdit,
    perSqftToMonthlyAddEdit,
    changeInSqft,
    monthlyToPerSqft,
    perSqftToMonthly,
    convertTZ,
    date_diff_in_days,
    getValidityDate,
    getCouponDiscountedAmount,
    parseJwt,
    replaceDash,
    replaceSpace,
    replacePercent20,
    getTodayDate,
    getPreviousDate,
    getNextDate,
    monthOfYearAsString,
    dayOfWeekAsString,
    getInitialName,
    getDateInFormat,
    dateTimeDifference,
    getDateInDashFormat,
    convertBase64,
    createImageFromInitials,
    getRandomColor,
    listOfNextYears,
    listOfMonths,
    expiryDateCheck,
    fullMonthOfYearAsString,
    dateDifference,
    getCountryTimeZone,
    subscriptionData,
    allMonthList,
    landlordTenantByMonthData,
    dataByState,
    alertDialog,
    date_diff_indays,
    Truncate,
    dateDifference1,
    getDateInFormatWithMonthName,
    getSinceTime
}