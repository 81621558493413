function request(actionType) {
  return {
    type: actionType,
  };
}
function success(actionType, response) {
  return {
    type: actionType,
    payload: response,
  };
}
function failure(actionType, error) {
  return {
    type: actionType,
    payload: error,
  };
}
const USER_REQUEST = {
  VERIFY_EMAIL_RESET: "VERIFY_EMAIL_RESET",
  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_FAILURE: "VERIFY_EMAIL_FAILURE",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  USER_SIGNIN_RESET: "USER_SIGNIN_RESET",
  USER_SIGNIN_REQUEST: "USER_SIGNIN_REQUEST",
  USER_SIGNIN_SUCCESS: "USER_SIGNIN_SUCCESS",
  USER_SIGNIN_FAILURE: "USER_SIGNIN_FAILURE",
  UPDATE_TENANT_PROFILE_REQUEST: "UPDATE_TENANT_PROFILE_REQUEST",
  UPDATE_TENANT_PROFILE_SUCCESS: "UPDATE_TENANT_PROFILE_SUCCESS",
  UPDATE_TENANT_PROFILE_FAILURE: "UPDATE_TENANT_PROFILE_FAILURE",
  UPDATE_LANDLORD_PROFILE_REQUEST: "UPDATE_LANDLORD_PROFILE_REQUEST",
  UPDATE_LANDLORD_PROFILE_SUCCESS: "UPDATE_LANDLORD_PROFILE_SUCCESS",
  UPDATE_LANDLORD_PROFILE_FAILURE: "UPDATE_LANDLORD_PROFILE_FAILURE",
  SELECT_ROLE_ID: "SELECT_ROLE_ID",
  REMOVE_ROLE_ID: "REMOVE_ROLE_ID",

  GET_STAT_REQUEST: "GET_STAT_REQUEST",
  GET_STAT_SUCCESS: "GET_STAT_SUCCESS",
  GET_STAT_FAILURE: "GET_STAT_FAILURE",

  GET_USER_PROFILE_REQUEST: "GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAILURE: "GET_USER_PROFILE_FAILURE",

  GET_UPDATE_TENANT_REQUEST: "GET_UPDATE_TENANT_REQUEST",
  GET_UPDATE_TENANT_SUCCESS: "GET_UPDATE_TENANT_SUCCESS",
  GET_UPDATE_TENANT_FAILURE: "GET_UPDATE_TENANT_FAILURE",
  GET_UPDATE_TENANT_RESET: "GET_UPDATE_TENANT_RESET",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  GET_USER_EMAIL_REQUEST: "GET_USER_EMAIL_REQUEST",
  GET_USER_EMAIL_SUCCESS: "GET_USER_EMAIL_SUCCESS",
  GET_USER_EMAIL_FAILURE: "GET_USER_EMAIL_FAILURE",
  GET_USER_EMAIL_RESET: "GET_USER_EMAIL_RESET",

  GET_USER_CODE_REQUEST: "GET_USER_CODE_REQUEST",
  GET_USER_CODE_SUCCESS: "GET_USER_CODE_SUCCESS",
  GET_USER_CODE_FAILURE: "GET_USER_CODE_FAILURE",
  GET_USER_CODE_RESET: "GET_USER_CODE_RESET",
  LANDLORD_UPDATE_BY_ADMIN_REQUEST: "LANDLORD_UPDATE_BY_ADMIN_REQUEST",
  LANDLORD_UPDATE_BY_ADMIN_SUCCESS: "LANDLORD_UPDATE_BY_ADMIN_SUCCESS",
  LANDLORD_UPDATE_BY_ADMIN_FAILURE: "LANDLORD_UPDATE_BY_ADMIN_FAILURE",
};
const RENT_SPREE_REQUEST = {
  GET_RENT_SPREE_REQUEST: "GET_RENT_SPREE_REQUEST",
  GET_RENT_SPREE_SUCCESS: "GET_RENT_SPREE_SUCCESS",
  GET_RENT_SPREE_FAILURE: "GET_RENT_SPREE_FAILURE",

  RENT_SPREE_REQUEST: "RENT_SPREE_REQUEST",
  RENT_SPREE_SUCCESS: "RENT_SPREE_SUCCESS",
  RENT_SPREE_FAILURE: "RENT_SPREE_FAILURE",
};

const LOACTION_REQUEST = {
  GET_STATES_REQUEST: "GET_STATES_REQUEST",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_STATES_FAILURE: "GET_STATES_FAILURE",
  GET_CITIES_REQUEST: "GET_CITIES_REQUEST",
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
  GET_CITIES_FAILURE: "GET_CITIES_FAILURE",
  ADD_LOCATION_REQUEST: "ADD_LOCATION_REQUEST",
  ADD_LOCATION_SUCCESS: "ADD_LOCATION_SUCCESS",
  ADD_LOCATION_FAILURE: "ADD_LOCATION_FAILURE",
  GET_LOCATION_REQUEST: "GET_LOCATION_REQUEST",
  GET_LOCATION_SUCCESS: "GET_LOCATION_SUCCESS",
  GET_LOCATION_FAILURE: "GET_LOCATION_FAILURE",
};
const BUSSINESS_PROFILE = {
  GET_BUSSINESS_PROFILE_REQUEST: "GET_BUSSINESS_PROFILE_REQUEST",
  GET_BUSSINESS_PROFILE_SUCCESS: "GET_BUSSINESS_PROFILE_SUCCESS",
  GET_BUSSINESS_PROFILE_FAILURE: "GET_BUSSINESS_PROFILE_FAILURE",
  ADD_BUSSINESS_PROFILE_REQUEST: "ADD_BUSSINESS_PROFILE_REQUEST",
  ADD_BUSSINESS_PROFILE_SUCCESS: "ADD_BUSSINESS_PROFILE_SUCCESS",
  ADD_BUSSINESS_PROFILE_FAILURE: "ADD_BUSSINESS_PROFILE_FAILURE",
};
const LOOKUP_REQUEST = {
  GET_ALL_LOOKUP_REQUEST: "GET_ALL_LOOKUP_REQUEST",
  GET_ALL_LOOKUP_SUCCESS: "GET_ALL_LOOKUP_SUCCESS",
  GET_ALL_LOOKUP_FAILURE: "GET_ALL_LOOKUP_FAILURE",
  GET_ALL_LOOKUP_KEYS_REQUEST: "GET_ALL_LOOKUP_KEYS_REQUEST",
  GET_ALL_LOOKUP_KEYS_SUCCESS: "GET_ALL_LOOKUP_KEYS_SUCCESS",
  GET_ALL_LOOKUP_KEYS_FAILURE: "GET_ALL_LOOKUP_KEYS_FAILURE",
  GET_LOOKUP_BY_TYPE_REQUEST: "GET_LOOKUP_BY_TYPE_REQUEST",
  GET_LOOKUP_BY_TYPE_SUCCESS: "GET_LOOKUP_BY_TYPE_SUCCESS",
  GET_LOOKUP_BY_TYPE_FAILURE: "GET_LOOKUP_BY_TYPE_FAILURE",
};
const REQUIREMENT_PROPERTY_REQUEST = {
  GET_REQUIREMENT_REQUEST: "GET_REQUIREMENT_REQUEST",
  GET_REQUIREMENT_SUCCESS: "GET_REQUIREMENT_SUCCESS",
  GET_REQUIREMENT_FAILURE: "GET_REQUIREMENT_FAILURE",
  ADD_REQUIREMENT_REQUEST: "ADD_REQUIREMENT_REQUEST",
  ADD_REQUIREMENT_SUCCESS: "ADD_REQUIREMENT_SUCCESS",
  ADD_REQUIREMENT_FAILURE: "ADD_REQUIREMENT_FAILURE",
  EDIT_REQUIREMENT_REQUEST: "EDIT_REQUIREMENT_REQUEST",
  EDIT_REQUIREMENT_SUCCESS: "EDIT_REQUIREMENT_SUCCESS",
  EDIT_REQUIREMENT_FAILURE: "EDIT_REQUIREMENT_FAILURE",
  ADD_REQUIREMENTDETAIL_SUCCESS: "ADD_REQUIREMENTDETAIL_SUCCESS",
  REMOVE_REQUIREMENT_SUCCESS: "REMOVE_REQUIREMENT_SUCCESS",
};
const REVIEW_PROPERTY = {
  GET_TENANT_REVIEW_LOADING: "GET_TENANT_REVIEW_LOADING",
  GET_TENANT_REVIEW_SUCCESS: "GET_TENANT_REVIEW_SUCCESS",
  GET_TENANT_REVIEW_FAILURE: "GET_TENANT_REVIEW_FAILURE",
  GET_LANDLORD_REVIEW_LOADING: "GET_LANDLORD_REVIEW_LOADING",
  GET_LANDLORD_REVIEW_SUCCESS: "GET_LANDLORD_REVIEW_SUCCESS",
  GET_LANDLORD_REVIEW_FAILURE: "GET_LANDLORD_REVIEW_FAILURE",
};
const POST_PROPERTY = {
  RESET_ADDRESS: "RESET_ADDRESS",

  ADD_PROPERTY_FILE_REQUEST: "ADD_PROPERTY_FILE_REQUEST",
  ADD_PROPERTY_FILE_FAILURE: "ADD_PROPERTY_FILE_FAILURE",
  ADD_PROPERTY_FILE_SUCCESS: "ADD_PROPERTY_FILE_SUCCESS",

  ADD_PROPERTY_VIDEO_REQUEST: "ADD_PROPERTY_VIDEO_REQUEST",
  ADD_PROPERTY_VIDEO_FAILURE: "ADD_PROPERTY_VIDEO_FAILURE",
  ADD_PROPERTY_VIDEO_SUCCESS: "ADD_PROPERTY_VIDEO_SUCCESS",

  REMOVE_PROPERTY_FILE_REQUEST: "REMOVE_PROPERTY_FILE_REQUEST",
  REMOVE_PROPERTY_FILE_FAILURE: "REMOVE_PROPERTY_FILE_FAILURE",
  REMOVE_PROPERTY_FILE_SUCCESS: "REMOVE_PROPERTY_FILE_SUCCESS",

  GET_PROPERTY_FILE_BY_ID_REQUEST: "GET_PROPERTY_FILE_BY_ID_REQUEST",
  GET_PROPERTY_FILE_BY_ID_SUCCESS: "GET_PROPERTY_FILE_BY_ID_SUCCESS",
  GET_PROPERTY_FILE_BY_ID_FAILURE: "GET_PROPERTY_FILE_BY_ID_FAILURE",

  GET_ALL_PROPERTY_FILES_REQUEST: "GET_ALL_PROPERTY_FILES_REQUEST",
  GET_ALL_PROPERTY_FILES_SUCCESS: "GET_ALL_PROPERTY_FILES_SUCCESS",
  GET_ALL_PROPERTY_FILES_FAILURE: "GET_ALL_PROPERTY_FILES_FAILURE",

  GET_PROPERTY_DETAIL_REQUEST: "GET_PROPERTY_DETAIL_REQUEST",
  GET_PROPERTY_DETAIL_SUCCESS: "GET_PROPERTY_DETAIL_SUCCESS",
  GET_PROPERTY_DETAIL_FAILURE: "GET_PROPERTY_DETAIL_FAILURE",

  ADD_PROPERTYDETAIL_SUCCESS: "ADD_PROPERTYDETAIL_SUCCESS",
  REMOVE_PROPERTYDETAIL_SUCCESS: "REMOVE_PROPERTYDETAIL_SUCCESS",

  GET_ADDRESS_LOADING: "GET_ADDRESS_LOADING",
  GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
  GET_ADDRESS_FAILURE: "GET_ADDRESS_FAILURE",
};
const BROWSE_LISTING = {
  GET_LIST_REQUEST: "GET_LIST_REQUEST",
  GET_LIST_FAILURE: "GET_LIST_FAILURE",
  GET_LIST_SUCCESS: "GET_LIST_SUCCESS",

  GET_SUGGESTED_LIST_REQUEST: "GET_SUGGESTED_LIST_REQUEST",
  GET_SUGGESTED_LIST_FAILURE: "GET_SUGGESTED_LIST_FAILURE",
  GET_SUGGESTED_LIST_SUCCESS: "GET_SUGGESTED_LIST_SUCCESS",
  GET_SUGGESTED_LIST_RESET: "GET_SUGGESTED_LIST_RESET",

  GET_TENANTS_REQUEST: "GET_TENANTS_REQUEST",
  GET_TENANTS_FAILURE: "GET_TENANTS_FAILURE",
  GET_TENANTS_SUCCESS: "GET_TENANTS_SUCCESS",

  TENANT_CONNECTION_REQUEST: "TENANT_CONNECTION_REQUEST",
  TENANT_CONNECTION_SUCCESS: "TENANT_CONNECTION_SUCCESS",
  TENANT_CONNECTION_FAILURE: "TENANT_CONNECTION_FAILURE",
  TENANT_CONNECTION_RESET: "TENANT_CONNECTION_RESET",

  GET_SUGGESTION_LOADING: "GET_SUGGESTION_LOADING",
  GET_SUGGESTION_SUCCESS: "GET_SUGGESTION_SUCCESS",
  GET_SUGGESTION_FAILURE: "GET_SUGGESTION_FAILURE",

  GET_TENANT_DETAIL_REQUEST: "GET_TENANT_DETAIL_REQUEST",
  GET_TENANT_DETAIL_SUCCESS: "GET_TENANT_DETAIL_SUCCESS",
  GET_TENANT_DETAIL_FAILURE: "GET_TENANT_DETAIL_FAILURE",
};

const CONNECTION_REQUEST = {
  GET_ALL_CONNECTION_REQUEST: "GET_ALL_CONNECTION_REQUEST",
  GET_ALL_CONNECTION_SUCCESS: "GET_ALL_CONNECTION_SUCCESS",
  GET_ALL_CONNECTION_FAILURE: "GET_ALL_CONNECTION_FAILURE",

  ADD_REMOVE_TO_FAVOURITE_CONNECTION_REQUEST:
    "ADD_REMOVE_TO_FAVOURITE_CONNECTION_REQUEST",
  ADD_REMOVE_TO_FAVOURITE_CONNECTION_SUCCESS:
    "ADD_REMOVE_TO_FAVOURITE_CONNECTION_SUCCESS",
  ADD_REMOVE_TO_FAVOURITE_CONNECTION_FAILURE:
    "ADD_REMOVE_TO_FAVOURITE_CONNECTION_FAILURE",

  REMOVE_CONNECTION_REQUEST: "REMOVE_CONNECTION_REQUEST",
  REMOVE_CONNECTION_SUCCESS: "REMOVE_CONNECTION_SUCCESS",
  REMOVE_CONNECTION_FAILURE: "REMOVE_CONNECTION_FAILURE",

  SEND_CONNECTION_REQUEST: "SEND_CONNECTION_REQUEST",
  SEND_CONNECTION_SUCCESS: "SEND_CONNECTION_SUCCESS",
  SEND_CONNECTION_FAILURE: "SEND_CONNECTION_FAILURE",

  ACCEPT_CONNECTION_REQUEST: "ACCEPT_CONNECTION_REQUEST",
  ACCEPT_CONNECTION_SUCCESS: "ACCEPT_CONNECTION_SUCCESS",
  ACCEPT_CONNECTION_FAILURE: "ACCEPT_CONNECTION_FAILURE",
  REQUEST_RESET: "REQUEST_RESET",
  REJECT_CONNECTION_REQUEST: "REJECT_CONNECTION_REQUEST",
  REJECT_CONNECTION_SUCCESS: "REJECT_CONNECTION_SUCCESS",
  REJECT_CONNECTION_FAILURE: "REJECT_CONNECTION_FAILURE",
};
const CHAT_REQUEST = {
  JOIN_CHAT_REQUEST: "JOIN_CHAT_REQUEST",
  JOIN_CHAT_SUCCESS: "JOIN_CHAT_SUCCESS",
  JOIN_CHAT_FAILURE: "JOIN_CHAT_FAILURE",

  ALL_CHAT_REQUEST: "ALL_CHAT_REQUEST",
  ALL_CHAT_SUCCESS: "ALL_CHAT_SUCCESS",
  ALL_CHAT_FAILURE: "ALL_CHAT_FAILURE",

  USER_CHAT_REQUEST: "USER_CHAT_REQUEST",
  USER_CHAT_SUCCESS: "USER_CHAT_SUCCESS",
  USER_CHAT_FAILURE: "USER_CHAT_FAILURE",

  SEND_CHAT_REQUEST: "SEND_CHAT_REQUEST",
  SEND_CHAT_SUCCESS: "SEND_CHAT_SUCCESS",
  SEND_CHAT_FAILURE: "SEND_CHAT_FAILURE",

  FAV_UNFAV_REQUEST: "FAV_UNFAV_REQUEST",
  FAV_UNFAV_SUCCESS: "FAV_UNFAV_SUCCESS",
  FAV_UNFAV_FAILURE: "FAV_UNFAV_FAILURE",

  SEND_OR_RECIEVE_MESSAGE: "SEND_OR_RECIEVE_MESSAGE",

  SELECT_CHAT_LOCATION: "SELECT_CHAT_LOCATION",
};
const REPORTS_REQUEST = {
  GET_PROPERTY_REPORT_REQUEST: "GET_PROPERTY_REPORT_REQUEST",
  GET_PROPERTY_REPORT_SUCCESS: "GET_PROPERTY_REPORT_SUCCESS",
  GET_PROPERTY_REPORT_FAILURE: "GET_PROPERTY_REPORT_FAILURE",

  GET_SUBSCRIPTION_REPORT_REQUEST: "GET_SUBSCRIPTION_REPORT_REQUEST",
  GET_SUBSCRIPTION_REPORT_SUCCESS: "GET_SUBSCRIPTION_REPORT_SUCCESS",
  GET_SUBSCRIPTION_REPORT_FAILURE: "GET_SUBSCRIPTION_REPORT_FAILURE",

  GET_USER_REPORT_REQUEST: "GET_USER_REPORT_REQUEST",
  GET_USER_REPORT_SUCCESS: "GET_USER_REPORT_SUCCESS",
  GET_USER_REPORT_FAILURE: "GET_USER_REPORT_FAILURE",

  GET_MEDIA_LIST_REQUEST: "GET_MEDIA_LIST_REQUEST",
  GET_MEDIA_LIST_SUCCESS: "GET_MEDIA_LIST_SUCCESS",
  GET_MEDIA_LIST_FAILURE: "GET_MEDIA_LIST_FAILURE",

  GET_SUBSCRIPTION_BY_MONTH_REQUEST: "GET_SUBSCRIPTION_BY_MONTH_REQUEST",
  GET_SUBSCRIPTION_BY_MONTH_SUCCESS: "GET_SUBSCRIPTION_BY_MONTH_SUCCESS",
  GET_SUBSCRIPTION_BY_MONTH_FAILURE: "GET_SUBSCRIPTION_BY_MONTH_FAILURE",

  GET_SUBSCRIPTION_BY_STATE_REQUEST: "GET_SUBSCRIPTION_BY_STATE_REQUEST",
  GET_SUBSCRIPTION_BY_STATE_SUCCESS: "GET_SUBSCRIPTION_BY_STATE_SUCCESS",
  GET_SUBSCRIPTION_BY_STATE_FAILURE: "GET_SUBSCRIPTION_BY_STATE_FAILURE",

  GET_SUBSCRIPTION_BY_COUNT_REQUEST: "GET_SUBSCRIPTION_BY_COUNT_REQUEST",
  GET_SUBSCRIPTION_BY_COUNT_SUCCESS: "GET_SUBSCRIPTION_BY_COUNT_SUCCESS",
  GET_SUBSCRIPTION_BY_COUNT_FAILURE: "GET_SUBSCRIPTION_BY_COUNT_FAILURE",

  GET_USER_BY_COUNT_REQUEST: "GET_USER_BY_COUNT_REQUEST",
  GET_USER_BY_COUNT_SUCCESS: "GET_USER_BY_COUNT_SUCCESS",
  GET_USER_BY_COUNT_FAILURE: "GET_USER_BY_COUNT_FAILURE",

  GET_USER_BY_MONTH_REQUEST: "GET_USER_BY_MONTH_REQUEST",
  GET_USER_BY_MONTH_SUCCESS: "GET_USER_BY_MONTH_SUCCESS",
  GET_USER_BY_MONTH_FAILURE: "GET_USER_BY_MONTH_FAILURE",

  GET_ALL_USER_REQUEST: "GET_ALL_USER_REQUEST",
  GET_ALL_USER_SUCCESS: "GET_ALL_USER_SUCCESS",
  GET_ALL_USER_FAILURE: "GET_ALL_USER_FAILURE",

  GET_REPORTED_SPACE_LIST_REQUEST: "GET_REPORTED_SPACE_LIST_REQUEST",
  GET_REPORTED_SPACE_LIST_SUCCESS: "GET_REPORTED_SPACE_LIST_SUCCESS",
  GET_REPORTED_SPACE_LIST_FAILURE: "GET_REPORTED_SPACE_LIST_FAILURE",

  GET_REPORTED_USER_LIST_REQUEST: "GET_REPORTED_USER_LIST_REQUEST",
  GET_REPORTED_USER_LIST_SUCCESS: "GET_REPORTED_USER_LIST_SUCCESS",
  GET_REPORTED_USER_LIST_FAILURE: "GET_REPORTED_USER_LIST_FAILURE",

  GET_ALL_LOGS_REQUEST: "GET_ALL_LOGS_REQUEST",
  GET_ALL_LOGS_SUCCESS: "GET_ALL_LOGS_SUCCESS",
  GET_ALL_LOGS_FAILURE: "GET_ALL_LOGS_FAILURE",

  ADD_LOGS_REQUEST: "ADD_LOGS_REQUEST",
  ADD_LOGS_SUCCESS: "ADD_LOGS_SUCCESS",
  ADD_LOGS_FAILURE: "ADD_LOGS_FAILURE",

  GET_WEEKLY_REPORT_REQUEST: "GET_WEEKLY_REPORT_REQUEST",
  GET_WEEKLY_REPORT_SUCCESS: "GET_WEEKLY_REPORT_SUCCESS",
  GET_WEEKLY_REPORT_FAILURE: "GET_WEEKLY_REPORT_FAILURE",

  GET_TOTAL_COUNT_REPORT_REQUEST: "GET_TOTAL_COUNT_REPORT_REQUEST",
  GET_TOTAL_COUNT_REPORT_SUCCESS: "GET_TOTAL_COUNT_REPORT_SUCCESS",
  GET_TOTAL_COUNT_REPORT_FAILURE: "GET_TOTAL_COUNT_REPORT_FAILURE",

  GET_TOP_20_LANDLORDS_REPORT_REQUEST: "GET_TOP_20_LANDLORDS_REPORT_REQUEST",
  GET_TOP_20_LANDLORDS_REPORT_SUCCESS: "GET_TOP_20_LANDLORDS_REPORT_SUCCESS",
  GET_TOP_20_LANDLORDS_REPORT_FAILURE: "GET_TOP_20_LANDLORDS_REPORT_FAILURE",

  GET_TOP_20_TENANTS_REPORT_REQUEST: "GET_TOP_20_TENANTS_REPORT_REQUEST",
  GET_TOP_20_TENANTS_REPORT_SUCCESS: "GET_TOP_20_TENANTS_REPORT_SUCCESS",
  GET_TOP_20_TENANTS_REPORT_FAILURE: "GET_TOP_20_TENANTS_REPORT_FAILURE",

  GET_TOTAL_LISTING_PER_STATE_REPORT_REQUEST: "GET_TOTAL_LISTING_PER_STATE_REPORT_REQUEST",
  GET_TOTAL_LISTING_PER_STATE_REPORT_SUCCESS: "GET_TOTAL_LISTING_PER_STATE_REPORT_SUCCESS",
  GET_TOTAL_LISTING_PER_STATE_REPORT_FAILURE: "GET_TOTAL_LISTING_PER_STATE_REPORT_FAILURE",

  GET_TOTAL_LISTING_PER_CATEGORY_REPORT_REQUEST: "GET_TOTAL_LISTING_PER_CATEGORY_REPORT_REQUEST",
  GET_TOTAL_LISTING_PER_CATEGORY_REPORT_SUCCESS: "GET_TOTAL_LISTING_PER_CATEGORY_REPORT_SUCCESS",
  GET_TOTAL_LISTING_PER_CATEGORY_REPORT_FAILURE: "GET_TOTAL_LISTING_PER_CATEGORY_REPORT_FAILURE",

  UPDATE_WEEKLY_REPORT_REQUEST: "UPDATE_WEEKLY_REPORT_REQUEST",
  UPDATE_WEEKLY_REPORT_SUCCESS: "UPDATE_WEEKLY_REPORT_SUCCESS",
  UPDATE_WEEKLY_REPORT_FAILURE: "UPDATE_WEEKLY_REPORT_FAILURE"
};
const INDUSTRY_REQUEST = {
  GET_INDUSTRY_REQUEST: "GET_INDUSTRY_REQUEST",
  GET_INDUSTRY_SUCCESS: "GET_INDUSTRY_SUCCESS",
  GET_INDUSTRY_FAILURE: "GET_INDUSTRY_FAILURE",

  ADD_INDUSTRY_REQUEST: "ADD_INDUSTRY_REQUEST",
  ADD_INDUSTRY_SUCCESS: "ADD_INDUSTRY_SUCCESS",
  ADD_INDUSTRY_FAILURE: "ADD_INDUSTRY_FAILURE",

  ADD_OR_REMOVE_INDUSTRY: "ADD_OR_REMOVE_INDUSTRY",
};
const PROPERTIES_REQUEST = {
  GET_ALL_PROPERTIES_REQUEST: "GET_ALL_PROPERTIES_REQUEST",
  GET_ALL_PROPERTIES_SUCCESS: "GET_ALL_PROPERTIES_SUCCESS",
  GET_ALL_PROPERTIES_FAILURE: "GET_ALL_PROPERTIES_FAILURE",

  RECORD_DEAL_REQUEST: "RECORD_DEAL_REQUEST",
  RECORD_DEAL_SUCCESS: "RECORD_DEAL_SUCCESS",
  RECORD_DEAL_FAILURE: "RECORD_DEAL_FAILURE",
  RECORD_DEAL_RESET: "RECORD_DEAL_RESET",
  GET_PROPERTY_BY_ID_REQUEST: "GET_PROPERTY_BY_ID_REQUEST",
  GET_PROPERTY_BY_ID_SUCCESS: "GET_PROPERTY_BY_ID_SUCCESS",
  GET_PROPERTY_BY_ID_FAILURE: "GET_PROPERTY_BY_ID_FAILURE",

  CHANGE_MEDIA_REQUEST: "CHANGE_MEDIA_REQUEST",
  CHANGE_MEDIA_SUCCESS: "CHANGE_MEDIA_SUCCESS",
  CHANGE_MEDIA_FAILURE: "CHANGE_MEDIA_FAILURE",

  GET_ANALYTICS_REQUEST: "GET_ANALYTICS_REQUEST",
  GET_ANALYTICS_SUCCESS: "GET_ANALYTICS_SUCCESS",
  GET_ANALYTICS_FAILURE: "GET_ANALYTICS_FAILURE",

  GET_TERM_SHEET_REQUEST: "GET_TERM_SHEET_REQUEST",
  GET_TERM_SHEET_SUCCESS: "GET_TERM_SHEET_SUCCESS",
  GET_TERM_SHEET_FAILURE: "GET_TERM_SHEET_FAILURE",
};
const LANDING_PAGE_REQUEST = {
  ALL_REQUIREMENT_REQUEST: "ALL_REQUIREMENT_REQUEST",
  ALL_REQUIREMENT_SUCCESS: "ALL_REQUIREMENT_SUCCESS",
  ALL_REQUIREMENT_FAILURE: "ALL_REQUIREMENT_FAILURE",

  ALL_PROPERTY_REQUEST: "ALL_PROPERTY_REQUEST",
  ALL_PROPERTY_SUCCESS: "ALL_PROPERTY_SUCCESS",
  ALL_PROPERTY_FAILURE: "ALL_PROPERTY_FAILURE",

  CHECK_SPACE_EXIST_REQUEST: "CHECK_SPACE_EXIST_REQUEST",
  CHECK_SPACE_EXIST_SUCCESS: "CHECK_SPACE_EXIST_SUCCESS",
  CHECK_SPACE_EXIST_FAILURE: "CHECK_SPACE_EXIST_FAILURE",
};
const REGISTRATION_V2_REQUEST = {
  USER_LOGIN_SIGNUP_RESET: "USER_LOGIN_SIGNUP_RESET",

  USER_SIGN_UP_REQUEST: "USER_SIGN_UP_REQUEST",
  USER_SIGN_UP_SUCCESS: "USER_SIGN_UP_SUCCESS",
  USER_SIGN_UP_FAILURE: "USER_SIGN_UP_FAILURE",

  ROLE_USER_REQUEST: "ROLE_USER_REQUEST",
  ROLE_USER_SUCCESS: "ROLE_USER_SUCCESS",
  ROLE_USER_FAILURE: "ROLE_USER_FAILURE",

  SOCIAL_USER_REQUEST: "SOCIAL_USER_REQUEST",
  SOCIAL_USER_SUCCESS: "SOCIAL_USER_SUCCESS",
  SOCIAL_USER_FAILURE: "SOCIAL_USER_FAILURE",
  NOTIFICATION_RESET: "NOTIFICATION_RESET",
  NOTIFICATION_RECIEVED: "NOTIFICATION_RECIEVED",
  DEVICE_TOKEN_RECIEVED: "DEVICE_TOKEN_RECIEVED",
};

const COUPON_REQUEST = {
  GET_ALL_COUPON_PLAN_REQUEST: "GET_ALL_COUPON_PLAN_REQUEST",
  GET_ALL_COUPON_PLAN_SUCCESS: "GET_ALL_COUPON_PLAN_SUCCESS",
  GET_ALL_COUPON_PLAN_FAILURE: "GET_ALL_COUPON_PLAN_FAILURE",
  GET_ALL_COUPON_REQUEST: "GET_ALL_COUPON_REQUEST",
  GET_ALL_COUPON_SUCCESS: "GET_ALL_COUPON_SUCCESS",
  GET_ALL_COUPON_FAILURE: "GET_ALL_COUPON_FAILURE",
  DELETE_COUPON_REQUEST: "DELETE_COUPON_REQUEST",
  DELETE_COUPON_SUCCESS: "DELETE_COUPON_SUCCESS",
  DELETE_COUPON_FAILURE: "DELETE_COUPON_FAILURE",
  RESET_DELETE_COUPON: "RESET_DELETE_COUPON",
  EDIT_COUPON_REQUEST: "EDIT_COUPON_REQUEST",
  EDIT_COUPON_SUCCESS: "EDIT_COUPON_SUCCESS",
  EDIT_COUPON_FAILURE: "EDIT_COUPON_FAILURE",
  RESET_EDIT_COUPON: "RESET_EDIT_COUPON",
  RESET_ADD_OR_EDIT_COUPON: " RESET_ADD_OR_EDIT_COUPON",
  ADD_OR_UPDATE_COUPON_REQUEST: "ADD_OR_UPDATE_COUPON_REQUEST",
  ADD_OR_UPDATE_COUPON_SUCCESS: "ADD_OR_UPDATE_COUPON_SUCCESS",
  ADD_OR_UPDATE_COUPON_FAILURE: "ADD_OR_UPDATE_COUPON_FAILURE",
  GET_COUPON_USER_REQUEST: "GET_COUPON_USER_REQUEST",
  GET_COUPON_USER_SUCCESS: "GET_COUPON_USER_SUCCESS",
  GET_COUPON_USER_FAILURE: "GET_COUPON_USER_FAILURE",
  GET_COUPONS_ADDONS_REQUEST: "GET_COUPONS_ADDONS_REQUEST",
  GET_COUPONS_ADDONS_SUCCESS: "GET_COUPONS_ADDONS_SUCCESS",
  GET_COUPONS_ADDONS_FAILURE: "GET_COUPONS_ADDONS_FAILURE",
};

const PUSH_NOTIFICATION_REQUEST = {
  GET_ALL_NOTIFICATION_REQUEST: "GET_ALL_NOTIFICATION_REQUEST",
  GET_ALL_NOTIFICATION_SUCCESS: "GET_ALL_NOTIFICATION_SUCCESS",
  GET_ALL_NOTIFICATION_FAILURE: "GET_ALL_NOTIFICATION_FAILURE",

  UPDATE_NOTIFICATION_SETTING_REQUEST: "UPDATE_NOTIFICATION_SETTING_REQUEST",
  UPDATE_NOTIFICATION_SETTING_SUCCESS: "UPDATE_NOTIFICATION_SETTING_SUCCESS",
  UPDATE_NOTIFICATION_SETTING_FAILURE: "UPDATE_NOTIFICATION_SETTING_FAILURE",

  GET_PUSH_NOTIFICATION_SETTING_REQUEST:
    "GET_PUSH_NOTIFICATION_SETTING_REQUEST",
  GET_PUSH_NOTIFICATION_SETTING_SUCCESS:
    "GET_PUSH_NOTIFICATION_SETTING_SUCCESS",
  GET_PUSH_NOTIFICATION_SETTING_FAILURE:
    "GET_PUSH_NOTIFICATION_SETTING_FAILURE",

  GET_UNREAD_NOTIFICATION_COUNT_REQUEST:
    "GET_UNREAD_NOTIFICATION_COUNT_REQUEST",
  GET_UNREAD_NOTIFICATION_COUNT_SUCCESS:
    "GET_UNREAD_NOTIFICATION_COUNT_SUCCESS",
  GET_UNREAD_NOTIFICATION_COUNT_FAILURE:
    "GET_UNREAD_NOTIFICATION_COUNT_FAILURE",

  READ_NOTIFICATION_REQUEST: "READ_NOTIFICATION_REQUEST",
  READ_NOTIFICATION_SUCCESS: "READ_NOTIFICATION_SUCCESS",
  READ_NOTIFICATION_FAILURE: "READ_NOTIFICATION_FAILURE",
};
const MARKETING_PREFERNECE_REQUEST = {
  GET_ALL_LIST_REQUEST: "GET_ALL_LIST_REQUEST",
  GET_ALL_LIST_SUCCESS: "GET_ALL_LIST_SUCCESS",
  GET_ALL_LIST_FAILURE: "GET_ALL_LIST_FAILURE",
  ADD_BROCHURE_RESET: "ADD_BROCHURE_RESET",
  ADD_BROCHURE_REQUEST: "ADD_BROCHURE_REQUEST",
  ADD_BROCHURE_SUCCESS: "ADD_BROCHURE_SUCCESS",
  ADD_BROCHURE_FAILURE: "ADD_BROCHURE_FAILURE",
  MARKETING_DETAIL_REQUEST: "MARKETING_DETAIL_REQUEST",
  MARKETING_DETAIL_SUCCESS: "MARKETING_DETAIL_SUCCESS",
  MARKETING_DETAIL_FAILURE: "MARKETING_DETAIL_FAILURE",
};

const RULESETS_REQUEST = {
  GET_PHOTOS_RULE_SETS_REQUEST: "GET_PHOTOS_RULE_SETS_REQUEST",
  GET_PHOTOS_RULE_SETS_SUCCESS: "GET_PHOTOS_RULE_SETS_SUCCESS",
  GET_PHOTOS_RULE_SETS_FAILURE: "GET_PHOTOS_RULE_SETS_FAILURE",
  GET_SPACES_REQUIREMTN_REQUEST: "GET_SPACES_REQUIREMTN_REQUEST",
  GET_SPACES_REQUIREMTN_SUCCESS: "GET_SPACES_REQUIREMTN_SUCCESS",
  GET_SPACES_REQUIREMTN_FAILURE: "GET_SPACES_REQUIREMTN_FAILURE",
};
const AI_REQUEST = {
  GET_AI_TEXT_EDITOR_REQUEST: "GET_AI_TEXT_EDITOR_REQUEST",
  GET_AI_TEXT_EDITOR_SUCCESS: "GET_AI_TEXT_EDITOR_SUCCESS",
  GET_AI_TEXT_EDITOR_FAILURE: "GET_AI_TEXT_EDITOR_FAILURE",

  GET_AI_TEXT_COMPLETION_REQUEST: "GET_AI_TEXT_EDITOR_REQUEST",
  GET_AI_TEXT_COMPLETION_SUCCESS: "GET_AI_TEXT_COMPLETION_SUCCESS",
  GET_AI_TEXT_COMPLETION_FAILURE: "GET_AI_TEXT_EDITOR_FAILURE",

  GET_AI_TEXT_DEEP_REQUEST: "GET_AI_TEXT_DEEP_REQUEST",
  GET_AI_TEXT_DEEP_SUCCESS: "GET_AI_TEXT_DEEP_SUCCESS",
  GET_AI_TEXT_DEEP_FAILURE: "GET_AI_TEXT_DEEP_FAILURE",

  GET_AI_TEXT_CORTEX_REQUEST: "GET_AI_TEXT_CORTEX_REQUEST",
  GET_AI_TEXT_CORTEX_SUCCESS: "GET_AI_TEXT_CORTEX_SUCCESS",
  GET_AI_CHAT_GPT_REQUEST: "GET_AI_CHAT_GPT_REQUEST",
};

const PRODUCT_LIST = {
  GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
  SELECT_PRODUCT: "SELECT_PRODUCT",
  SETQUANTITY: "SETQUANTITY",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  GET_TOTAL_PRICE: "GET_TOTAL_PRICE",
};

const LANDLORD_FLOW = {
  GET_OPTIONS_LIST: "GET_OPTIONS_LIST",
  GET_PLANS_LIST: "GET_PLANS_LIST",
  GET_SUBSCRIBE_UNSUBSCRIBE: "GET_SUBSCRIBE_UNSUBSCRIBE",
  GET_ADDONS_LIST: "GET_ADDONS_LIST",
  SEND_EMAIL_RESPONSE: "SEND_EMAIL_RESPONSE",
  GET_ORDER_SUMMARY_DETAILS: "GET_ORDER_SUMMARY_DETAILS",
  SET_PAGE_NO: "SET_PAGE_NO",
  GET_ADDONS_COUPON_DETAILS: "GET_ADDONS_COUPON_DETAILS",
  SET_COUPON_VALIDATION: "SET_COUPON_VALIDATION",
  LANDLORD_SIGNUP_REQUEST: "LANDLORD_SIGNUP_REQUEST",
  LANDLORD_SIGNUP_SUCCESS: "LANDLORD_SIGNUP_SUCCESS",
  LANDLORD_SIGNUP_FAILURE: "LANDLORD_SIGNUP_FAILURE",
};

const TENANT_FLOW = {
  SET_EMAIL_ID: "SET_EMAIL_ID",
  SET_LOCATION_DATA: "SET_LOCATION_DATA",
  SET_BUSINESS_PROFILE_DATA: "SET_BUSINESS_PROFILE_DATA",
  SET_REQUIREMENT_DATA: "SET_REQUIREMENT_DATA",
  SEND_EMAIL_RESPONSE: "SEND_EMAIL_RESPONSE",
  TENANT_SIGNUP_REQUEST: "TENANT_SIGNUP_REQUEST",
  TENANT_SIGNUP_SUCCESS: "TENANT_SIGNUP_SUCCESS",
  TENANT_SIGNUP_FAILURE: "TENANT_SIGNUP_FAILURE",
};

const TERM_SHEET = {
  GET_TERM_SHEET_REQUEST: "GET_TERM_SHEET_REQUEST",
  GET_TERM_SHEET_SUCCESS: "GET_TERM_SHEET_SUCCESS",
  GET_TERM_SHEET_FAILURE: "GET_TERM_SHEET_FAILURE",

  // for base Term sheet 
  GET_BASE_TERM_SHEET_REQUEST : "GET_BASE_TERM_SHEET_REQUEST",
  GET_BASE_TERM_SHEET_SUCCESS : "GET_BASE_TERM_SHEET_SUCCESS",
  GET_BASE_TERM_SHEET_FAILURE : "GET_BASE_TERM_SHEET_FAILURE",

  UPDATE_BASE_TERM_SHEET_REQUEST : "UPDATE_BASE_TERM_SHEET_REQUEST",
  UPDATE_BASE_TERM_SHEET_SUCCESS : "UPDATE_BASE_TERM_SHEET_SUCCESS",
  UPDATE_BASE_TERM_SHEET_FAILURE : "UPDATE_BASE_TERM_SHEET_FAILURE",

  DELETE_BASE_TERM_SHEET_REQUEST : "DELETE_BASE_TERM_SHEET_REQUEST",
  DELETE_BASE_TERM_SHEET_SUCCESS : "DELETE_BASE_TERM_SHEET_SUCCESS",
  DELETE_BASE_TERM_SHEET_FAILURE : "DELETE_BASE_TERM_SHEET_FAILURE",

  // end 

  ADD_TERM_SHEET_REQUEST: "ADD_TERM_SHEET_REQUEST",
  ADD_TERM_SHEET_SUCCESS: "ADD_TERM_SHEET_SUCCESS",
  ADD_TERM_SHEET_FAILURE: "ADD_TERM_SHEET_FAILURE",

  UPDATE_TERM_SHEET_REQUEST: "UPDATE_TERM_SHEET_REQUEST",
  UPDATE_TERM_SHEET_SUCCESS: "UPDATE_TERM_SHEET_SUCCESS",
  UPDATE_TERM_SHEET_FAILURE: "UPDATE_TERM_SHEET_FAILURE",

  DELETE_TERM_SHEET_REQUEST: "DELETE_TERM_SHEET_REQUEST",
  DELETE_TERM_SHEET_SUCCESS: "DELETE_TERM_SHEET_SUCCESS",
  DELETE_TERM_SHEET_FAILURE: "DELETE_TERM_SHEET_FAILURE",

  LIKE_DISLIKE_TERM_SHEET_SUCCESS: "LIKE_DISLIKE_TERM_SHEET_SUCCESS",
  LIKE_DISLIKE_TERM_SHEET_FAILURE: "LIKE_DISLIKE_TERM_SHEET_FAILURE",
  LIKE_DISLIKE_TERM_SHEET_REQUEST: "LIKE_DISLIKE_TERM_SHEET_REQUEST",

  SPACE_NEGOTIATION_QUESTIONS_SUCCESS: "SPACE_NEGOTIATION_QUESTIONS_SUCCESS",
  SPACE_NEGOTIATION_QUESTIONS_FAILURE: "SPACE_NEGOTIATION_QUESTIONS_FAILURE",
  SPACE_NEGOTIATION_QUESTIONS_REQUEST: "SPACE_NEGOTIATION_QUESTIONS_REQUEST",

  SEND_CHAT_REQUEST: "SEND_CHAT_REQUEST",
  SEND_CHAT_SUCCESS: "SEND_CHAT_SUCCESS",
  SEND_CHAT_FAILURE: "SEND_CHAT_FAILURE",
  SEND_OR_RECIEVE_MESSAGE: "SEND_OR_RECIEVE_MESSAGE",

  ALL_CHAT_REQUEST: "ALL_CHAT_REQUEST",
  ALL_CHAT_SUCCESS: "ALL_CHAT_SUCCESS",
  ALL_CHAT_FAILURE: "ALL_CHAT_FAILURE",

  // SEND_MESSAGE_REQUEST: "SEND_MESSAGE_REQUEST",
  // SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  // SEND_MESSAGE_FAILURE: "SEND_MESSAGE_FAILURE",

  // ALL_MESSAGE_REQUEST: "ALL_MESSAGE_REQUEST",
  // ALL_MESSAGE_SUCCESS: "ALL_MESSAGE_SUCCESS",
  // ALL_MESSAGE_FAILURE: "ALL_MESSAGE_FAILURE",
};

export {
  request,
  success,
  failure,
  MARKETING_PREFERNECE_REQUEST,
  USER_REQUEST,
  LOACTION_REQUEST,
  BUSSINESS_PROFILE,
  LOOKUP_REQUEST,
  REQUIREMENT_PROPERTY_REQUEST,
  REVIEW_PROPERTY,
  POST_PROPERTY,
  BROWSE_LISTING,
  CONNECTION_REQUEST,
  REPORTS_REQUEST,
  PROPERTIES_REQUEST,
  INDUSTRY_REQUEST,
  LANDING_PAGE_REQUEST,
  CHAT_REQUEST,
  RENT_SPREE_REQUEST,
  REGISTRATION_V2_REQUEST,
  COUPON_REQUEST,
  PUSH_NOTIFICATION_REQUEST,
  RULESETS_REQUEST,
  AI_REQUEST,
  PRODUCT_LIST,
  LANDLORD_FLOW,
  TENANT_FLOW,
  TERM_SHEET,
};
