import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function getPropertyReport(propertyId) {
    return axios.post(`${APP_SETTINGS.API_PATH.REPORTS.getPropertyReport}`, propertyId)
}
export function getSubscriptionReport(subscriptionId, pageNumber) {
    return axios.post(`${APP_SETTINGS.API_PATH.REPORTS.getSubscriptionReport}`, {
        "filterBy": subscriptionId,
        "pageSize": 20,
        "pageNumber": pageNumber ? pageNumber : 1
    })
}
export function getUserReport(roleId) {
    return axios.post(`${APP_SETTINGS.API_PATH.REPORTS.getUserReport}`, roleId)
}

export function getMediaList(auth) {
    return axios.get(APP_SETTINGS.API_PATH.SOCIAL_MEDIA_MARKETING.getList, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function getUserList(userData) {
    return axios.post(APP_SETTINGS.API_PATH.REPORTS.getUsers, userData)
}

export function subscriptionByMonth() {
    return axios.get(APP_SETTINGS.API_PATH.ADMIN_SIDE.subscriptionByMonth)
}

export function subscriptionByState() {
    return axios.get(APP_SETTINGS.API_PATH.ADMIN_SIDE.subscriptionByState)
}

export function subscriptionByCount() {
    return axios.get(APP_SETTINGS.API_PATH.ADMIN_SIDE.subscriptionByCount)
}

export function userByCount() {
    return axios.get(APP_SETTINGS.API_PATH.ADMIN_SIDE.userByCount)
}

export function userByMonth() {
    return axios.get(APP_SETTINGS.API_PATH.ADMIN_SIDE.userByMonth)
}
export function getAdminLog(pageSize, pageNumber, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.ADMIN_SIDE.getAdminLog}?PageSize=${pageSize}&PageNumber=${pageNumber}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function addAdminLog(logData, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.ADMIN_SIDE.addAdminLog}`, logData, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function getAllMarketingPreference(data, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.ADMIN_SIDE.getAllMarketingPreference}?PageNumber=${data.pageNumber}&PageSize=20&name=${data.name}&email=${data.email}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function addBrochure(data, auth) {
    return axios.post(APP_SETTINGS.API_PATH.ADMIN_SIDE.addBrochure, data, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function resendEmail(propertyId, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.ADMIN_SIDE.resendEmail}?propertyId=${propertyId}`, { propertyId: propertyId }, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function getMarketingDetailByUUID(uuid, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.ADMIN_SIDE.getMarketingDetailByUUID}?uuid=${uuid}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}

export function getWeeklyReport(weeklyDate) {
    return axios.post(`${APP_SETTINGS.API_PATH.REPORTS.getWeeklyReport}?weeklyDate=${weeklyDate}`)
}

export function getWeeklyReport2() {
    return axios.post(`${APP_SETTINGS.API_PATH.REPORTS.getAdminWeeklyReport}`)
}

export function totalCountReport() {
    return axios.get(`${APP_SETTINGS.API_PATH.REPORTS.getTotalCountReport}`)
}

export function top20LandlordsReport() {
    return axios.get(`${APP_SETTINGS.API_PATH.REPORTS.getTop20LandlordsReport}`)
}

export function top20TenantsReport() {
    return axios.get(`${APP_SETTINGS.API_PATH.REPORTS.getTop20TenantReport}`)
}

export function totalListingPerStateReport() {
    return axios.get(`${APP_SETTINGS.API_PATH.REPORTS.getTotalListingPerStateReport}`)
}

export function totalListingPerCategoryReport() {
    return axios.get(`${APP_SETTINGS.API_PATH.REPORTS.getTopSpacePerCategoryReport}`)
}

export function weeklyReport(request) {
    return axios.post(`${APP_SETTINGS.API_PATH.REPORTS.updataWeeklyReport}`, request)
}