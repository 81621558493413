import 'bootstrap/dist/css/bootstrap.min.css';
import './loader';
import './assets/styles/index.css';
import './assets/styles/new-home.css';
import './assets/styles/mediaquery.css';
import './assets/styles/doubleBubbleLoader.css'
import './index.css';
import "./assets/styles/fahad.css";
import "./assets/styles/mobileResponsive.css";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/Font/AvenirNext-Bold.ttf";
import "./assets/Font/AvenirNext-DemiBold.ttf";
import "./assets/Font/Montserrat-Regular.ttf";
import "./assets/Font/Montserrat-Medium.ttf";
import "./assets/Font/Montserrat-Bold.ttf";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "react-notifications/lib/notifications.css";
// import 'sweetalert2/src/sweetalert2.scss'
import App from './App';
import { CookiesProvider } from 'react-cookie';
import { Provider } from "react-redux";
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';

// import "node_modules/video-react/dist/video-react.css"; 
// if (process.env.NODE_ENV === 'production') {
//   console.log = function () { };
// }
ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
