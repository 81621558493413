import { failure, request, success, TERM_SHEET } from "./utilities";
import {
  getTermSheet,
  addTermSheet,
  editTermSheet,
  deleteTerm,
  likeDislikeTerm,
  spaceNegotiationQuestions,
  sendComment,
  getComment,
  getMessage,
  sendMessage,
  addGlobalTermSheet,
  BaseTermSheet,
  editBaseTermSheet,
  deleteBaseTerm,
} from "../api";

export function getTermSheetList(data, auth) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.GET_TERM_SHEET_REQUEST));
    getTermSheet(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(TERM_SHEET.GET_TERM_SHEET_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(TERM_SHEET.GET_TERM_SHEET_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.GET_TERM_SHEET_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getBaseTermSheet(listData, auth) {
  return dispatch => {
      dispatch(request(TERM_SHEET.GET_BASE_TERM_SHEET_REQUEST));
      BaseTermSheet(listData, auth).then(
          response => {
              if (response.data.succeeded === true) {
                  dispatch(success(TERM_SHEET.GET_BASE_TERM_SHEET_SUCCESS, response.data.data))
              }
              else {
                  dispatch(failure(TERM_SHEET.GET_BASE_TERM_SHEET_FAILURE, response.data.message))
              }
          },
          error => {
              dispatch(failure(TERM_SHEET.GET_BASE_TERM_SHEET_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
          }
      )
  }
}

export function saveTermSheet(data, auth, setAddTermPopUpShow, setLoading) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.ADD_TERM_SHEET_REQUEST));
    addTermSheet(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(TERM_SHEET.ADD_TERM_SHEET_SUCCESS, response.data.data)
          );
          const request = {
            propertyId: data.propertyId,
            tenantId: data.tenantId,
          };

          dispatch(getTermSheetList(request, auth));
          if (setAddTermPopUpShow) {
            setAddTermPopUpShow(false);
          }
        } else {
          dispatch(
            failure(TERM_SHEET.ADD_TERM_SHEET_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.ADD_TERM_SHEET_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function updateTermSheet(data, auth, setEditTermPopUpShow) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.UPDATE_TERM_SHEET_REQUEST));
    editTermSheet(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(TERM_SHEET.UPDATE_TERM_SHEET_SUCCESS, response.data.data)
          );
          const request = {
            propertyId: data.propertyId,
            tenantId: data.tenantId,
          }; 
          dispatch(getTermSheetList(request, auth));
          if (setEditTermPopUpShow) {
            setEditTermPopUpShow(false);
          }
        } else {
          dispatch(
            failure(TERM_SHEET.UPDATE_TERM_SHEET_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.UPDATE_TERM_SHEET_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function updateBaseTermSheet(data, auth, setEditModalVisible) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.UPDATE_BASE_TERM_SHEET_REQUEST));
    editBaseTermSheet(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(TERM_SHEET.UPDATE_BASE_TERM_SHEET_SUCCESS, response.data.data)
          );
          const request = {
            propertyId: data.propertyId,
            userId: data.userId,
          }; 
          dispatch(getBaseTermSheet(request, auth));
          if (setEditModalVisible) {
            setEditModalVisible(false);
          }
        } else {
          dispatch(
            failure(TERM_SHEET.UPDATE_BASE_TERM_SHEET_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.UPDATE_BASE_TERM_SHEET_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function deleteTermFromSheet(data, auth, setDeletePopUpShow) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.DELETE_TERM_SHEET_REQUEST));
    deleteTerm(data.termId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(TERM_SHEET.DELETE_TERM_SHEET_SUCCESS, data.termId));
          const request = {
            propertyId: data.propertyId,
            tenantId: data.tenantId,
          };
          dispatch(getTermSheetList(request, auth));
          if (setDeletePopUpShow) {
            setDeletePopUpShow(false);
          }
        } else {
          dispatch(
            failure(TERM_SHEET.DELETE_TERM_SHEET_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.DELETE_TERM_SHEET_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function deleteBaseTermSheet(data, auth, setDeleteModalVisible) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.DELETE_BASE_TERM_SHEET_REQUEST));
    deleteBaseTerm(data.termId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(TERM_SHEET.DELETE_BASE_TERM_SHEET_SUCCESS, data.termId));
          const request = {
            propertyId: data.propertyId,
            userId: data.userId,
          }; 
          dispatch(getBaseTermSheet(request, auth));
          if (setDeleteModalVisible) {
            setDeleteModalVisible(false);
          }
        } else {
          dispatch(
            failure(TERM_SHEET.DELETE_BASE_TERM_SHEET_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.DELETE_BASE_TERM_SHEET_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function updateLikeDislikeTerm(data, auth, setDislikePopUpShow) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.LIKE_DISLIKE_TERM_SHEET_REQUEST));
    likeDislikeTerm(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              TERM_SHEET.LIKE_DISLIKE_TERM_SHEET_SUCCESS,
              response.data.data
            )
          );
          const request = {
            propertyId: data.propertyId,
            tenantId: data.tenantId,
          };
          dispatch(getTermSheetList(request, auth));
          if (setDislikePopUpShow) {
            setDislikePopUpShow(false);
          }
        } else {
          dispatch(
            failure(
              TERM_SHEET.LIKE_DISLIKE_TERM_SHEET_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.LIKE_DISLIKE_TERM_SHEET_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function updateSpaceNegotiationQuestions(data, auth, setPopUpShow) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.SPACE_NEGOTIATION_QUESTIONS_REQUEST));
    spaceNegotiationQuestions(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              TERM_SHEET.SPACE_NEGOTIATION_QUESTIONS_SUCCESS,
              response.data.data
            )
          );
          const request = {
            propertyId: data.propertyId,
            tenantId: data.tenantId,
          };
          dispatch(getTermSheetList(request, auth));
          if (setPopUpShow) {
            setPopUpShow(false);
          }
        } else {
          dispatch(
            failure(
              TERM_SHEET.SPACE_NEGOTIATION_QUESTIONS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.SPACE_NEGOTIATION_QUESTIONS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function sendTermSheetComment(data, auth) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.SEND_CHAT_REQUEST));
    sendComment(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(TERM_SHEET.SEND_CHAT_SUCCESS, response.data.data));
          // if (setDislikePopUpShow) {
          //   setDislikePopUpShow(false);
          // }
          dispatch(success(TERM_SHEET.SEND_OR_RECIEVE_MESSAGE, response.data.data))
        } else {
          dispatch(
            failure(TERM_SHEET.SEND_CHAT_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.SEND_CHAT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getTermSheetComment(termId, auth) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.ALL_CHAT_REQUEST));
    getComment(termId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(TERM_SHEET.ALL_CHAT_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(TERM_SHEET.ALL_CHAT_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.ALL_CHAT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function sendUserComment(data, auth) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.SEND_CHAT_REQUEST));
    sendMessage(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(TERM_SHEET.SEND_CHAT_SUCCESS, response.data.data));
          // if (setDislikePopUpShow) {
          //   setDislikePopUpShow(false);
          // }
          dispatch(success(TERM_SHEET.SEND_OR_RECIEVE_MESSAGE, response.data.data))
        } else {
          dispatch(
            failure(TERM_SHEET.SEND_CHAT_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.SEND_CHAT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getUserComment(data, auth) {
  return (dispatch) => {
    dispatch(request(TERM_SHEET.ALL_CHAT_REQUEST));
    getMessage(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(TERM_SHEET.ALL_CHAT_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(TERM_SHEET.ALL_CHAT_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.ALL_CHAT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function saveGlobalTermSheet(data, auth, setLoading, setTenantListshow) {
  return (dispatch) => {
    setLoading(true);
    dispatch(request(TERM_SHEET.ADD_TERM_SHEET_REQUEST));
    addGlobalTermSheet(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(TERM_SHEET.ADD_TERM_SHEET_SUCCESS, response.data.data)
          );
          const request = {
            propertyId: data.propertyId,
            tenantId: data.tenantId,
          };

          dispatch(getTermSheetList(request, auth));
        } else {
          dispatch(
            failure(TERM_SHEET.ADD_TERM_SHEET_FAILURE, response.data.message)
          );
        }
        setLoading(false);
        setTenantListshow(false);
      },
      (error) => {
        dispatch(
          failure(
            TERM_SHEET.ADD_TERM_SHEET_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
        setLoading(false);
        setTenantListshow(false);
      }
    );
  };
}
