import { request, success, failure, AI_REQUEST } from "./utilities";
import { getTextbyOpenAIEdit, getTextbyOpenAICompletion, getTextByDeepAI, getTextByCortex, getTextbyChatGPT } from "../api";

export const getTextbyOpenAIEditAPI = (inputData) => async (dispatch) => {
  dispatch(request(AI_REQUEST.GET_AI_TEXT_EDITOR_REQUEST));
  const response = getTextbyOpenAIEdit(inputData);
  debugger;
  return Promise.resolve(response);
}; 
export const getTextbyOpenAICompletionAPI = (inputData) => async (dispatch) => {
  dispatch(request(AI_REQUEST.GET_AI_TEXT_COMPLETION_REQUEST));
  const response = getTextbyOpenAICompletion(inputData);
  return Promise.resolve(response);
}
export const getTextByDeepAIAPI = (inputData) => async (dispatch) => {
  dispatch(request(AI_REQUEST.GET_AI_TEXT_DEEP_REQUEST));
  const response = getTextByDeepAI(inputData);
  return Promise.resolve(response);
}
export const getTextByCortexAPI = (inputData) => async (dispatch) => {
  dispatch(request(AI_REQUEST.GET_AI_TEXT_CORTEX_REQUEST));
  const response = getTextByCortex(inputData);
  return Promise.resolve(response);
}
export const getTextbyChatGPTAPI = (inputData) => async (dispatch) => {
  dispatch(request(AI_REQUEST.GET_AI_CHAT_GPT_REQUEST));
  const response = getTextbyChatGPT(inputData);
  debugger;
  return Promise.resolve(response);
};
