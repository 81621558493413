// import { IMAGES } from "./assets";

const ERROR = {
  SYSTEM_ERROR: "System error. Please try again later!",
};
const GOOGLE_MAP_KEY = "AIzaSyBPFqhrGkKKbDsTPS2nbqBX5i23FHFd1YM";
const PATH = {
  PROPERTYSIGNAGE: "/property-signage/:uuid/:propertyId",
  EDITPROFILE: "/edit-landlord-:mode/:id",
  COUPON: "/coupon",
  COUPONUSER: "/coupon-users",
  APPSUBSCRIPTION: "/update-subscription",
  AppPaymentCheckout: "/:plan/:planId/payment-checkout",
  NOPAGE: "*",
  SUPPORT: "/support",
  HOME: "/",
  DASHBOARD: "/dashboard",
  PRICING: "/pricing",
  SIGNUP: "/sign-up",
  LOGIN: "/login",
  SUBSCRIPTION: "/:role/signing-up",
  USERVERIFIED: "/:role/verified",
  PROFILE: "/:role/profile",
  PROPERTYDETAIL: "/property-detail/:id",
  MESSAGES: "/messages",
  POSTPROPERTY: "/:type-property",
  VIEWPROFILE: "/view-profile/:id",
  LISTINGDETAILPAGE: "/Listing/:address/:id",
  BROWSELISTING: "/search/commercial-real-estate/:name",
  BROWSELISTINGENERIC: "/listing?state=:state&city=:city",
  TENANTLISTING: "/tenants",
  ABOUTUS: "/about-us",
  FORGETPASSWORD: "/forget-password",
  CHANGEPASSWORD: "/change-password",
  DONATENOW: "/donate-now",
  OURPARTNERS: "/our-partners",
  TENANTDASHBOARD: "/all-tenants",
  LANDLORDDASHBOARD: "/all-landlords",
  ADMINLOGSDASHBOARD: "/all-adminLogs",
  MARKETINGPREFERENCE: "/marketing-preference",
  LINKEDIN_REDIRECT: "/linkedin/redirect",
  APPLE_REDIRECT: "/apple/redirect",
  REPORTSDASHBOARD: "/reports/properties",
  SUBSCRIPTIONREPORT: "/reports/subscriptions",
  TENANTSREPORTS: "/reports/tenants",
  LANDLORDREPORTS: "/reports/landlords",
  WEEKLYSTATUSREPORTS: "/reports/weeklystatus",
  TOTALCOUNTSREPORT: "/reports/totalcount",
  TOP20LANDLORDSREPORT: "/reports/top20landlord",
  TOP20TENANTSREPORT: "/reports/top20tenant",
  TOTALLISTINGPERSTATEREPORT: "/reports/totallisting",
  TOTALLISTINGPERCATEGORYREPORT: "/reports/totallistingpercategory",
  SOCIALMEDIAMARKETING: "/social-media-marketing",
  TERMSANDCONDITIONS: "/Terms&Conditions",
  POLICY: "/Terms&Conditions/Policy",
  TERMOFUSE: "/Terms&Conditions/Terms-of-use",
  VERIFICATION: "/verify/email=:email&token=:token&roleId=:roleId",
  SETTINGS: "/settings",
  NEGOTIATION: "/negotiation",
  THANKYOU: "/:plan/upgraded",
  THANKYOUSIGNUP: "/thankyou-:role",
  PAYMENTCHECKOUT: "/:plan/:planId/checkout",
  PAYMENTCHECKOUTADMIN: "/userId=:userId/:plan/:planId/checkout",
  JSON_CONFIG: "/apple-app-site-association",
  REPORTEDUSER: "/reported-users",
  REPORTEDSPACE: "/reported-spaces",
  EDITPOSTPROPERTY: "/edit-post-property/:id",
  VIEW_ALL_NOTIFICATION: "/all-notification",
  LANDING_PAGE: "/LandingPage",
  PROPERTY_DETIAL_PAGE: "/property-detail/:address/:id",
  LANDLORD_ANALYTICS: "/landlord-analytics",
  SIGN_PRODUCTS: "/sign-products",
  SIGN_DETAILS: "/sign-details",
  EMAILER_TEMPLATE: "/emailer-template",
  VIEW_CART: "/view-cart",
  PICK_UP_SIGN: "/pick-up-sign",
  SELECTED_SIGN: "/selected-sign",
  LANDLORD_DESCRIPTION: "/landlord-description",
  PROPERTY_DESCRIPTION_REQUEST: "/property-description-request",
  LANDLORD_QUESTIONS: "/landlordSignUp",
  LANDLORD_QUESTIONS_SUBSCRIPTION: "/landlordSignUpSubscription",
  LEASING_PLAN: "/leasing-plan",
  COUPON_WITH_ADDONS: "/coupons",
  TENANT_SIGNUP: "/tenantSignUp",
  TENANT_LEASING_PLAN: "/tenant-leasing-plan",
  VIEW_TERM_SHEET: "/termsheet/:id",
  BROCHURE: "/brochure",
  WINDOWSIGNAGE: "/window-signage",
  RENEW_SUBSCRIPTION: "/renewSubscription/:id",
  AI_VIRTUAL_ASSISTANT: "/ai-virtual-assistant/:address"
};
const POLICY_TERMS = {
  POLICY: "Privacy",
  TERMOFUSE: "Terms-of-use",
};
//const baseUrl = "https://redhanded-dev-be.azurewebsites.net/api/";
// const baseUrl = "https://redhandedapi.azurewebsites.net/api/";      //02/08
const baseUrl = "https://redhanded-stage-api.azurewebsites.net/api/";      //staging
const baseUrl_New = "https://redhanded-dev-api.brainvire.net/api/";
// const baseUrl = "https://redhanded-dev-api.brainvire.net/api/";
// const baseUrl = "https://localhost:44396/api/"
const APP_SETTINGS_New = {
  API_Path: {
    RuleSet: {
      getPhotosRuleSets:
        baseUrl_New + "ruleset/getLandlordDashboardAnalyticsData",
      getSpacesByRequirement: baseUrl_New + "ruleset/getSpacesByRequirement",
      getSpacesAnalytics: baseUrl + "ruleset/getLandlordSpaceAnalyticsData",
    },
    AI: {
      getTextbyOpenAIEdit: baseUrl_New + "ai/getTextbyOpenAIEditAPI",
      getTextbyOpenAICompletionAPI:
        baseUrl_New + "ai/getTextbyOpenAICompletionAPI",
      getTextByDeepAI: baseUrl_New + "ai/getTextByDeepAI",
      getTextByCortex: baseUrl_New + "ai/getTextByCortexAPI",
      getTextbyChatGPT: baseUrl + "ai/getChatGPTAPI",
    },
  },
};
const APP_SETTINGS = {
  API_PATH: {
    ADMIN_SIDE: {
      getAdminLog: baseUrl + "adminLog/getAll",
      addAdminLog: baseUrl + "adminLog/post",
      subscriptionByMonth: baseUrl + "subscription/month",
      subscriptionByState: baseUrl + "subscription/state",
      subscriptionByCount: baseUrl + "subscription/count",
      userByCount: baseUrl + "user/count",
      userByMonth: baseUrl + "user/month",
      updateLandlord: baseUrl + "landLord/v2/put",
      updateProfile: baseUrl + "user/v2/updatePhoto",
      getLandlordSubscription: baseUrl + "v2/subscription/get",
      getLandlordCard: baseUrl + "v2/userCard/get",
      addLandlordCard: baseUrl + "v2/userCard/post",
      updateSubscription: baseUrl + "v2/subscription/update",
      cancelLandlordSubscription: baseUrl + "v2/subscription/cancel",
      addBrochure: baseUrl + "marketingPreference/postBrochureOrSignage",
      getAllMarketingPreference: baseUrl + "marketingPreference/getAll",
      resendEmail: baseUrl + "marketingPreference/resendMarketingEmail",
      getMarketingDetailByUUID:
        baseUrl + "marketingPreference/getMarketingPreferenceTemplateDetails",
    },
    ANALYTICS: {
      getanalytics: baseUrl + "property",
    },
    AUTOSUGGEST: {
      autoSuggest: baseUrl + "city/getCitiesByFilter",
    },
    AZURE_BLOB: {
      AZURE_BLOB: baseUrl + "azure/blob/post",
      AZURE_BLOB_VIDEO: baseUrl + "azure/blob/upload",
      AZURE_BLOB_WITH_EXTENSION: baseUrl + "azure/blob/v2/post",
    },
    BROWSE_LISTING: {
      getAllLists: baseUrl + "property/getProperties",
      getSuggestedList: baseUrl + "property/getSuggestedProperties",
    },
    BROWSE_TENANTS: {
      getAllTenants: baseUrl + "requirement/browseTenants",
      moreDetail: baseUrl + "requirement/getPropertiesByTenant",
    },
    BASE_TERM_SHEET:{
      BaseTermSheet: baseUrl + "termSheet/getBaseTermSheet",
      updateBaseTermSheet: baseUrl + "termSheet/updateBaseTermSheet",
      deleteBaseTermSheet: baseUrl + "termSheet/deleteBaseTerm",
    },
    Chat: {
      joinChat: baseUrl + "chat/join",
      getAllChat: baseUrl + "chat/getAll",
      getUserChat: baseUrl + "chat/inbox",
      sendMessage: baseUrl + "chat/message/send",
      addRemoveToFavourite: baseUrl + "chat/inbox",
    },
    CONNECTIONS: {
      getAllTenantConnection: baseUrl + "connection/getAll",
      addRemoveToFavouriteTenantConnection:
        baseUrl + "connection/change/favoriteStatus",
      removeTenantConnection: baseUrl + "connection/cancel",
    },
    COUPON: {
      getAllCouponPlan: baseUrl + "coupon/get/plans",
      getAllCoupon: baseUrl + "coupon/getAll",
      deleteCoupon: baseUrl + "coupon/delete",
      addOrUpdate: baseUrl + "coupon/save",
      editCoupon: baseUrl + "coupon/update",
      getByCouponId: baseUrl + "coupon/getByCouponId",
      getCouponUsers: baseUrl + "coupon/getCouponUsers",
    },
    COUPONS: {
      getAllCouponPlan: baseUrl + "coupon/getCouponsPlan",
      getAllCouponsAddons: baseUrl + "coupon/getCouponsAddonsData",
      getAllCouponDetails: baseUrl + "coupon/getAllCouponDetails",
      deleteCoupon: baseUrl + "coupon/delete",
      addOrUpdate: baseUrl + "coupon/saveCouponDetails",
      editCoupon: baseUrl + "coupon/update",
      getByCouponId: baseUrl + "coupon/getByCouponId",
      getCouponUsers: baseUrl + "coupon/getCouponUsers",
      checkCouponByUserId: baseUrl + "coupon/checkCouponByUserId",
      getApplyCouponDetails: baseUrl + "coupon/getApplyCouponDetails",
    },
    INDUSTRY: {
      getAllIndustry: baseUrl + "industry/getAll",
    },
    PHOTOSLISTING: {
      getPhotosListing: baseUrl + "ruleset/getPhotosRuleSets/",
    },
    LANDING_PAGE: {
      getRequirements: baseUrl + "landingPage/getRequirements",
      getProperties: baseUrl + "landingPage/getProperties",
      checkAddressExist: baseUrl + "landingPage/checkAddressExist",
    },
    LOCATION: {
      getAllStates: baseUrl + "state/getAll/country/",
      getAllCities: baseUrl + "city/getAll/state/",
      addLocation: baseUrl + "location/post",
      getLocation: baseUrl + "location/get",
    },
    LOGIN: {
      checkEmail: baseUrl + "login/check",
      login: baseUrl + "login/post",
      loginBySocial: baseUrl + "login/social",
      verifyEmail: baseUrl + "login/email/verify",
      logout: baseUrl + "login/logout",
    },
    LOOKUP: {
      getAllLookup: baseUrl + "lookup/getAll",
      getLookupByType: baseUrl + "lookup/getAll/",
      getAllLookUpKeys: baseUrl + "lookup/getKeys",
    },
    PLAN: {
      getPlans: baseUrl + "plan/getAll/",
    },
    POST_PROPERTY: {
      ADDRESS: {
        addAddress: baseUrl + "property/post",
        getAddressById: baseUrl + "property/get/",
      },
      PROPERTY_DETAIL: {
        addPropertyDetail: baseUrl + "propertyDetail/post",
        getPropertyDetailById: baseUrl + "propertyDetail/get/",
      },
      DESCRIPTION: {
        addDescription: baseUrl + "propertyDescription/post", //propertyDescription/post - 0509 api changed
        getDescriptionById: baseUrl + "propertyDescription/get/",
      },
      SPACE_DESCRIPTION: {
        addSpaceDescription:
          baseUrl + "propertyDescription/postspacedescription",
        getSpaceDescriptionList:
          baseUrl + "propertyDescription/getspacedescriptionlist",
        addUpdatespacedescriptionbyadmin:
          baseUrl + "propertyDescription/updatespacedescriptionbyadmin",
        addUpdatedSpaceDescription: baseUrl + "propertyDescription/post",
        getDescriptionListForLandlord:
          baseUrl + "propertyDescription/getspacedescriptionforlandlord",
      },
      PROPERTY_REVIEW: {
        getPropertyById: baseUrl + "property/get/details/",
      },
      ALL_PROPERTIES: {
        getAllProperties: baseUrl + "property/get/details",
      },
      PROPERTY_FILES: {
        addPropertyFile: baseUrl + "propertyFile/post",
        getAllPropertyFiles: baseUrl + "propertyFile/getAll",
        removePropertyFile: baseUrl + "propertyFile/delete",
        getPropertyFileById: baseUrl + "propertyFile/get",
        addRange: baseUrl + "propertyFile/addRange",
        uploadVideo:  baseUrl + "propertyFile/uploadVirtualTourVideo",
        removeVideo : baseUrl + "propertyFile/removeVideo",
      },
      MARKETING_PREFERENCE: {
        getPropertyMarketingPreference: baseUrl + "marketingPreference/get",
        addPropertyMarketingPreference: baseUrl + "marketingPreference/post",
      },
    },
    PROPERTYDELETE: {
      deleteProperty: baseUrl + "property/delete",
    },
    PRODUCTLIST: {
      getProductList: baseUrl + "products/getproductslist",
    },
    PUSH_NOTIFICATION: {
      getAllNotification: baseUrl + "/pushNotification/getAll",
      updateNotificationSetting: baseUrl + "/pushNotification/updateSettings",
      getPushNotificationSetting: baseUrl + "/pushNotification/getSettings",
      getUnreadCount: baseUrl + "/pushNotification/getUnreadCount",
      readNotification: baseUrl + "pushNotification/read/:id",
    },
    RECORD_DEAL: {
      recordDeal: baseUrl + "property/recordDeal/",
    },
    REGISTER: {
      registerTenant: baseUrl + "register/tenant",
      registerLandlord: baseUrl + "register/landLord",
      registerLandlordSocial: baseUrl + "register/social/landLord",
      registerTenantSocial: baseUrl + "register/social/tenant",
      registerLandlordByLinkedIn: baseUrl + "register/social/linkedInLandLord",
      registerTenantByLinkedIn: baseUrl + "register/social/linkedInTenant",
    },
    RegisterV2: {
      userSignUp: baseUrl + "register/v2/post",
      roleUser: baseUrl + "register/v2/role/post",
      socialUser: baseUrl + "register/v2/social/post",
      linkedInUser: baseUrl + "register/v2/social/getLinkedInProfile",
    },
    RENT_SPREE: {
      getRentSpree: "https://rhnode.azurewebsites.net/rentsPree",
      rentSpree:
        "https://api.rentspree.com/partners/v2/tenant-screening/with-property/link",
    },
    REPORT: {
      reportUser: baseUrl + "user/report",
      reportSpace: baseUrl + "property/report",
      reportSpaceList: baseUrl + "ReportProperty/getReportPropertyList",
      reportUserList: baseUrl + "ReportUser/getReportUserList",
      statusUser: baseUrl + "ReportUser/userAction",
      landlordUserDelete: baseUrl + "user/delete/:userId",
      statusSpace: baseUrl + "ReportProperty/propertyAction",
    },
    REPORTS: {
      getPropertyReport: baseUrl + "property/getPropertyReport",
      getSubscriptionReport: baseUrl + "subscription/getSubscriptionReport",
      getUserReport: baseUrl + "user/getNewUsersReport",
      getUsers: baseUrl + "user/getUsersByRole",
      getWeeklyReport: baseUrl + "reports/getWeeklyReport",
      getAdminWeeklyReport: baseUrl + "reports/getAdminWeeklyReport",
      getTotalCountReport: baseUrl + "reports/getTotalCountReport",
      getTop20LandlordsReport: baseUrl + "reports/getTop20LandlordsReport",
      getTop20TenantReport: baseUrl + "reports/getTop20TenantReport",
      getTotalListingPerStateReport: baseUrl + "reports/getTotalListingPerStateReport",
      getTopSpacePerCategoryReport: baseUrl + "reports/getTopSpacePerCategoryReport",
      updataWeeklyReport: baseUrl + "register/v2/updataWeeklyReport",
    },
    REQUEST: {
      sendRequest: baseUrl + "request/post",
      getAllRequest: baseUrl + "request/getAll",
      acceptRequest: baseUrl + "request/accept",
      rejectRequest: baseUrl + "request/reject",
    },
    REQUEST_PROPERTY: {
      bussinessProfile: baseUrl + "businessProfile/post",
      getBussinessProfile: baseUrl + "businessProfile/get",
    },
    REQUIREMENT_PROPERTY: {
      addRequirement: baseUrl + "requirement/post",
      getRequirement: baseUrl + "requirement/get",
      updateRequirement: baseUrl + "requirement/put",
    },
    RESETPASSWORD: {
      getEmail: baseUrl + "forgetPassword/checkEmail/:email",
      enterCode: baseUrl + "forgetPassword/checkCode/:code/email/:email",
      resetPassword: baseUrl + "forgetPassword/put",
      changePassword: baseUrl + "resetPassword/put",
    },
    REVIEW_PROPERTY: {
      getTenantReviewProperty: baseUrl + "tenant/details",
    },
    SOCIAL_MEDIA_MARKETING: {
      getList: baseUrl + "socialMediaMarketing/getAll",
      changePropertyMediaStatus:
        baseUrl + "property/changeSocialMediaMarketingStatus",
      sendSocialMediaEmail: baseUrl + "socialMediaMarketing/sendEmail",
      getSocialThumbnail: baseUrl + "property/getSocialThumbnail",
    },
    SUBSCRIPTION: {
      addSubscription: baseUrl + "subscription/post",
      changeSubscription: baseUrl + "subscription/update",
      cancelSubscription: baseUrl + "subscription/cancel",
      suspendUser: baseUrl + "user/ChangeSuspendStatus",
      getSubscription: baseUrl + "subscription/get",
    },
    UPDATE_LANDLORD: {
      updateLandlordProfile: baseUrl + "landLord/put",
    },
    UPDATE_PHOTO: {
      updatePhoto: baseUrl + "user/updatePhoto",
    },
    UPDATE_TENANT: {
      updateTenantProfile: baseUrl + "tenant/put",
    },
    USER_CARD: {
      getCard: baseUrl + "userCard/get",
      addCard: baseUrl + "userCard/post",
    },
    USER_PROFILE: {
      getTenantProfile: baseUrl + "tenant/getTenantProfile",
      getLandlordProfile: baseUrl + "landLord/getLandLordProfile",
      getUpdateTenant: baseUrl + "tenant/UpdateTenantStatus",
    },
    USER_STATS: {
      landlordStats: baseUrl + "landLord/getLandLordDashboardStats",
      tenantStats: baseUrl + "tenant/getTenantDashboardStats",
    },
    LANDLORD_FLOW: {
      getOptions: baseUrl + "register/v2/getSpaceOptions",
      sendEmail: baseUrl + "register/v2/spaceOptions/post",
      getPlans: baseUrl + "plan/getAllPlansForLandlord/",
      getAddons: baseUrl + "plan/getAddonsList",
      registerUser: baseUrl + "register/v2/user/post",
      getOrderDetails: baseUrl + "register/v2/getOrderSummaryDetails",
      getCouponDetails: baseUrl + "register/v2/getAddonsCouponDetails",
      checkIsSubscribed: baseUrl + "property/checkIsSubscribed",
      subsriptionForAnotherSpace: baseUrl + "register/v2/subsriptionForAnotherSpace/post",
      renewSubscription: baseUrl + "register/v2/renewSubscription/post"
    },
    TENANT_FLOW: {
      sendEmail: baseUrl + "register/v2/tenantRequirement/post",
      registerUser: baseUrl + "register/v2/tenantUser/post",
    },
    TERM_SHEET: {
      getTermSheetDetails: baseUrl + "termSheet/get",
      saveTermSheet: baseUrl + "termSheet/save",
      updateTermSheet: baseUrl + "termSheet/update",
      deleteTermSheet: baseUrl + "termSheet/delete",
      likeDislikeTerm: baseUrl + "termSheet/likeDislikeTerm",
      spaceNegotiationQuestions:
        baseUrl + "termSheet/spaceNegotiationQuestions",
      sendComment: baseUrl + "termSheet/comment/send",
      getComment: baseUrl + "termSheet/getComments",
      sendMessage: baseUrl + "termSheet/userComment/send",
      getMessage: baseUrl + "termSheet/getUserComments",
      saveGlobalTermSheet: baseUrl + "termSheet/saveGlobalTermSheet"
    },
  },
};
const TESTIMONIAL_VIDEOS_LINKS = [
  {
    id: 0,
    url: "https://redhandedstorage.blob.core.windows.net/assets/videos/RedHanded_Chris%20Property%20Manager_v2.mp4",
    name: "Chris",
    title: "Property Manager",
    img: "./assets/images/chris.jpg",
  },
  {
    id: 1,
    url: "https://redhandedstorage.blob.core.windows.net/assets/videos/RedHanded_Frank%20Landlord_v2.mp4",
    name: "Frank",
    title: "Commercial Property Owner",
    img: "./assets/images/frank.png",
  },
  {
    id: 2,
    url: "https://redhandedstorage.blob.core.windows.net/assets/videos/RedHanded_Jennifer%20Tenant%20(beauty)_v2.mp4",
    name: "Jennifer",
    title: "Owner of a Beauty & Wellness Center",
    img: "./assets/images/jennifer.jpg",
  },
  {
    id: 3,
    url: "https://redhandedstorage.blob.core.windows.net/assets/videos/RedHanded_William%20Tenant%20(Bar)_v2.mp4",
    name: "William",
    title: "Bar Owner",
    img: "./assets/images/william.jpg",
  },
];

const STATE_VALUES = [
  {
    Alabama: "AL",
    Alaska: "AK",
    Arizona: "AZ",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    "District Of Columbia": "DC",
    Florida: "FL",
    Georgia: "GA",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
  },
];
const ROLE = {
  TENANTS: "tenant",
  LANDLORD: "landlord",
  ADMIN: "admin",
};
const ROLEID = {
  TENANTS: 3,
  LANDLORD: 2,
  ADMIN: 1,
};
const LOOKUP_TYPE_KEYS = {
  ROLES: "Roles",
  LOGINTYPES: "LoginTypes",
  BUSINESSPROFILETYPES: "BusinessProfileTypes",
  BUSINESSPERIODS: "BusinessPeriods",
  PROPERTYFILETYPES: "PropertyFileTypes",
};
const AZURE_STORAGE = {
  NAME: "redhandedstorage",
  KEY: "ZqMPurEUUl4NhVsS1EbnUvZLnlpdqyhgYRhSB/4LQKW08dbak/FP4xMRsbD2hDm74iVTvgYp6WwF1WA3T2vLog==",
  CONNECTION_STRING:
    "DefaultEndpointsProtocol=https;AccountName=redhandedstorage;AccountKey=zQsd8+pDDJXegdgdWUGdl9l12kTYs2Ll/tenwXkjI1PJXXGjWBVhEwYSl++vsjGPMBQHFoVvLaCwmfiTVWS24w==;EndpointSuffix=core.windows.net",
};
const PICTURES_LOOKUP = {
  FLOOR_PLAN: 501,
  PROPERTY_PLAN: 500,
};
const SOCIAL_ACCOUNTS_LOGIN = {
  CREDENTIAL: 100,
  FACEBOOK: 101,
  GOOGLE: 102,
  LINKEDIN: 103,
  APPLE: 104,
};
const REPORT_STATUS = {
  REPORT: 1000,
  CLEAR: 1001,
  WARNING: 1002,
  DELETE: 1003,
};
const COMMUNICATION_PREFERENCE_LOOKUP = [
  {
    lookupId: 600,
    type: "CommunicationPreferenceTypes",
    text: "ByEmail",
    value: "By Email",
    order: 1,
  },
  {
    lookupId: 601,
    type: "CommunicationPreferenceTypes",
    text: "ByText",
    value: "By Text",
    order: 2,
  },
  {
    lookupId: 602,
    type: "CommunicationPreferenceTypes",
    text: "ByPhone",
    value: "By Phone",
    order: 3,
  },
];
const ASKING_TYPE_LOOKUP = [
  {
    lookupId: 400,
    type: "AskingTypes",
    text: "Rent",
    value: "Rent",
    order: 1,
  },
  {
    lookupId: 401,
    type: "AskingTypes",
    text: "Negotiable",
    value: "Negotiable",
    order: 2,
  },
];
const PROPERTY_REPORT_LOOKUP = [
  {
    lookupId: 700,
    type: "PropertyReports",
    text: "PropertiesWithNoConnections",
    value: "Properties With No Connections",
    order: 1,
  },
  {
    lookupId: 701,
    type: "PropertyReports",
    text: "PropertiesHavingConnectionbutNoMessages",
    value: "Properties Having Connection but No Messages",
    order: 2,
  },
  {
    lookupId: 702,
    type: "PropertyReports",
    text: "PropertiesWithoutbothConnection&Messages",
    value: "Properties Without both Connection & Messages",
    order: 3,
  },
];
const UTILITY_LOOKUP = [
  {
    lookupId: 800,
    type: "UtilityTypes",
    text: "Gas",
    value: "Gas",
    order: 1,
  },
  {
    lookupId: 802,
    type: "UtilityTypes",
    text: "Water",
    value: "Water",
    order: 2,
  },
  {
    lookupId: 803,
    type: "UtilityTypes",
    text: "Electricity",
    value: "Electricity",
    order: 3,
  },
  {
    lookupId: 804,
    type: "UtilityTypes",
    text: "Parking",
    value: "Parking",
    order: 4,
  },
  {
    lookupId: 805,
    type: "UtilityTypes",
    text: "Ventilation",
    value: "Ventilation",
    order: 5,
  },
  {
    lookupId: 813,
    type: "UtilityTypes",
    text: "AirConditioning",
    value: "Air Conditioning",
    order: 13,
  },
  {
    lookupId: 814,
    type: "UtilityTypes",
    text: "Sprinkler",
    value: "Sprinkler",
    order: 14,
  },
  {
    lookupId: 815,
    type: "UtilityTypes",
    text: "RoofAccess",
    value: "Roof Access",
    order: 15,
  },
];
const STATE_CODE = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District Of Columbia": "DC",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};
let COUPON_PLANS = [
  {
    product: "prod_KhdwRJctwYpsJz",
    name: "GOLD",
  },
  {
    product: "prod_Jqr9Js1qWiDjRI",
    name: "DIAMOND",
  },
];
let COUPON_TYPE = [
  {
    name: "Forever",
    value: "forever",
  },
  {
    name: "Once",
    value: "once",
  },
  {
    name: "Multiple Months",
    value: "repeating",
  },
];
const AZURE_BLOB_BASE_URL =
  "https://redhandedstorage.blob.core.windows.net:443/assets";
const FB_APP_ID = "479983409985904";
const GOOGLE_CLIENT_ID =
  "577035897758-11379smrm9vpob03gc1c3a9f57h5i2e9.apps.googleusercontent.com";
const LINKEDIN_CLIENT_ID = "7812rkm33ow0hc";
const LINKED_IN_REDIRECT_URL =
  process.env.NODE_ENV === "production"
    ? "https://redhandedco.com/linkedin/redirect"
    : "http://localhost:3000/linkedin/redirect";
const LINKED_IN_SECRET_ID = "ITTvQwXiZXAvLAr8";
const LINKED_IN_EMBEDED_URL =
  process.env.NODE_ENV === "production"
    ? "https%3A%2F%2Fredhandedco.com%2Flinkedin%2Fredirect"
    : "http%3A%2F%2Flocalhost%3A3000%2Flinkedin%2Fredirect";
const WEB_BASE_URL = "https://redhandedco.com"; // Production URL
//const WEB_BASE_URL = "https://redhanded-stage-ui.azurewebsites.net" // Stage URL
// const WEB_BASE_URL = "https://redhandedco-dev.brainvire.net" // Dev URL
const WEB_BASE_URL_TO_SHARE = "https://spaces.redhandedco.com/Property/Index/";
const WEB_SOCKET_URL = "https://stealthsocket.ghostlayers.com:3000/chat";
const APPLE_CLIENT_ID = "com.Redhanded.RedhandedApp.web";
const APPLE_REDIRECT_URL =
  process.env.NODE_ENV === "production"
    ? "https://redhandedco.com/apple/redirect"
    : "https://redhanded.azurewebsites.net/apple/redirect";
const QUESTIONS = {
  TENANT: [
    {
      id: 1,
      question: "Who is responsible for spaces insurance?",
      ans: "Answer of Question # 1",
      isEnabled: true,
      hasResponse: true,
      toolTip:
        "It is important you discuss insurance coverage and requirements. This ensures that your business is covered in the event of an emergency. Generally, landlords carry a comprehensive policy that covers liability for common areas of the building such as lobbies, stairways, elevators, etc. You as a tenant most likely carry the liability coverage that protects the landlord against any claims that arise from your business’ operations. (Example: a customer gets hurt in your store.) ",
    },
    {
      id: 2,
      question: "Who else can lease in this property?",
      isEnabled: true,
      hasResponse: false,
      toolTip:
        "You may want to think twice if you are looking at a space that has a business who competes with yours in the same building or a neighboring space. It is important to ask the landlord on what future businesses can move in. Additionally, you should negotiate limits on what type of companies you want to move in next door to you in the future. You can look into securing a non-compete clause as part of your lease that ensures no similar businesses can open in the same building or center that you are operating in. Example: If you owned and operated a luncheonette, you wouldn’t want a coffee shop or bakery opening directly next door as this would directly compete with your business model)",
    },
    {
      id: 3,
      question: "Is the space modifiable?",
      ans: "Answer of Question # 3",
      isEnabled: true,
      hasResponse: true,
      toolTip:
        "It is not common that you find a commercial space that meets the needs of your business. Usually, you will end up needing to renovate the space to fit your business needs. It is important to ask the landlord if it is acceptable to renovate or build out the space. If yes, you should negotiate on concessions (Example: Money towards construction and or free rent). Head over to RedHanded’s library of contractors to find a company that can help build your dream to reality! ",
    },
    {
      id: 4,
      question: "Under what circumstances can the lease be terminated?",
      isEnabled: true,
      hasResponse: false,
      toolTip:
        "In the uncertain times we live in today, it is important to have confidence in the terms of your lease. You should know how long the lease is for and understand the circumstances the lease is to be terminated. For example, if your business changes or an external factor occurs in which your business suffers, causing your business to drop, is there a way to leave your lease early? It is beneficial to negotiate a break clause into your lease that allows termination, giving some security that you will not be stuck in a lease incase of an uncontrollable factor. This will protect you against the unexpected. Be on the lookout for a landlord who is unwilling to negotiate the term of the lease. Often, this is a sign of a landlord who is not pleasant to work with, leading to a difficult relationship. ",
    },
    {
      id: 5,
      question: "Strong regulations that will conflict with business model?",
      ans: "Answer of Question # 1",
      isEnabled: true,
      hasResponse: true,
    },
    {
      id: 6,
      question: "Who will handle the insurance cost?",
      isEnabled: false,
      hasResponse: false,
    },
    {
      id: 7,
      question: "Is the price negotiable?",
      isEnabled: false,
      hasResponse: false,
    },
  ],
};
const SPACES = [
  {
    id: 1,
    //  picture: IMAGES.HOUSE2,
    name: "2 Park Pl, New York, NY 10007",
    psf: 450,
    sqft: 1450,
  },
  {
    id: 2,
    // picture: IMAGES.HOUSE3,
    name: "12 E 63rd St, New York, NY 10065",
    psf: 250,
    sqft: 1550,
  },
  {
    id: 3,
    //  picture: IMAGES.HOUSE4,
    name: "8044 Langdale St, Queens, NY 11040",
    psf: 350,
    sqft: 1550,
  },
  {
    id: 4,
    //  picture: IMAGES.HOUSE5,
    name: "20 W 53rd St, New York, NY 10019",
    psf: 450,
    sqft: 1750,
  },
];
const PROPERTY_REPORTING_REASON = [
  "Over-posting",
  "Conflict of interest",
  "Spam",
  "Fraud",
  "Incorrect information about Space",
  "Contains illegal content or activity",
  "Violation of someone's privacy",
  "Misinformation or abuse",
  "Goes against my beliefs, values or politics",
  "Sexually explicit content",
];
const USER_REPORTING_REASON = [
  "Uncivil, rude or vulgar",
  "Discrimination",
  "Personal dispute",
  "Incorrect information about Space",
  "Fake Profile",
];
const SORTINGTYPE = [
  {
    lookupId: 1100,
    type: "SortingTypes",
    text: "Featured",
    value: "Featured",
    order: 1,
  },
  {
    lookupId: 1101,
    type: "SortingTypes",
    text: "Newest",
    value: "Newest",
    order: 2,
  },
  // {
  //     "lookupId": 1102,
  //     "type": "SortingTypes",
  //     "text": "Utilities",
  //     "value": "Utilities",
  //     "order": 3
  // },
  // {
  //     "lookupId": 1103,
  //     "type": "SortingTypes",
  //     "text": "Industry",
  //     "value": "Industry",
  //     "order": 4
  // },
  {
    lookupId: 1104,
    type: "SortingTypes",
    text: "SQFT (low - high)",
    value: "SQFT (low - high)",
    order: 5,
  },
  {
    lookupId: 1105,
    type: "SortingTypes",
    text: "SQFT (high - low)",
    value: "SQFT (high - low)",
    order: 6,
  },
];
const MARKETING_PHONE_HELP_OPTION = [
  {
    id: 0,
    text: "Text",
  },
  {
    id: 1,
    text: "Call",
  },
  {
    id: 2,
    text: "I Don't Want Help",
  },
];
const CUSTOM_SIGNAGE_OPTION = [
  {
    id: 0,
    text: "POST The Sign For ME",
  },
  {
    id: 1,
    text: "Mail The Sign To Me",
  },
];
const ADVERTISE_OPTION = [
  {
    id: 0,
    text: "Yes",
  },
  {
    id: 1,
    text: "No",
  },
];
const POST_CONTENT_OPTION = [
  {
    id: 0,
    text: "Yes",
  },
  {
    id: 1,
    text: "No",
  },
  {
    id: 2,
    text: "Let Me Know First",
  },
];
const PAGINATION_PAGE_SIZE = 10;
const PropertyList = [
  {
    id: 0,
    picture: "static/media/post-kit-1.fdc70a26.jpg",
    name: "151 West 2 , USA",
    location: "New York, Brooklyn",
    sqft: "7000",
    price: "28583",
  },
  {
    id: 1,
    picture: "static/media/post-kit-2.248d9f83.jpg",
    name: "160 West 2 , USA",
    location: "New York, Brooklyn",
    sqft: "5000",
    price: "28570",
  },
  {
    id: 2,
    picture: "static/media/post-kit-3.22e1ba42.jpg",
    name: "158 West 2 , USA",
    location: "New York, Brooklyn",
    sqft: "9000",
    price: "28610",
  },
  {
    id: 3,
    picture: "static/media/post-kit-4.903bd386.jpg",
    name: "158 West 2 , USA",
    location: "New York, Brooklyn",
    sqft: "7000",
    price: "28583",
  },
  {
    id: 4,
    picture: "static/media/post-kit-5.d33225b3.jpg",
    name: "149 West 2 , USA",
    location: "New York, Brooklyn",
    sqft: "3000",
    price: "2851",
  },
  {
    id: 5,
    picture: "static/media/post-kit-6.85cbf3b9.jpg",
    name: "158 West 2 , USA",
    location: "New York, Brooklyn",
    sqft: "4000",
    price: "2861",
  },
  {
    id: 6,
    picture: "static/media/post-kit-7.e1d97623.jpg",
    name: "157 West 2 , USA",
    location: "New York, Brooklyn",
    sqft: "8000",
    price: "28612",
  },
  {
    id: 7,
    picture: "static/media/post-kit-8.ea995527.jpg",
    name: "157 West 2 , USA",
    location: "New York, Brooklyn",
    sqft: "9000",
    price: "28987",
  },
];
const TERM_SHEET_CONTENT = [
  {
    title: "PREMISES",
    description: "xxx, Brooklyn, NY 11215"
  },
  {
    title: "TENANT",
    description: "New entity to be formed"
  },
  {
    title: "SIZE",
    description: "Approximately 1,300 Square Feet - Ground Floor, Approximately 500 Square Feet - Mezzanine, Approximately 300 Square Feet - Basement"
  },
  {
    title: "USE",
    description: "High end wine shop"
  },
  {
    title: "POSSESSION",
    description: "Upon mutual execution and delivery of lease documents, and substantial completion of Landlord's Work"
  },
  {
    title: "TERM",
    description: "Ten (10) years with a five (5) year option to renew"
  },
  {
    title: "BASE RENT",
    description: "Starting annual base rent of $62,400 with 3% annual increases"
  },
  {
    title: "OPTION RENT",
    description: "The greater of Fair Market Value or 103% of the then escalated rent; with continuing 3% annual increases"
  },
  {
    title: "RENT COMMENCEMENT",
    description: "Four (4) months from Possession"
  },
  {
    title: "SECURITY",
    description: "-TBD upon review of financials -Personal Good Guy Guarantee by principal of Tenant"
  },
  {
    title: "REAL ESTATE TAXES",
    description: "Tenant to pay its proportionate share of real estate tax increases over a 2015/2016 base year."
  },
  {
    title: "LANDLORD'S WORK",
    description: "-All utilities to be stubbed to the premises for Tenant's distribution -Landlord to cover cost of HVAC equipment, but not installation -Otherwise the space is being delivered 'As Is'"
  },
  {
    title: "TENANT'S WORK",
    description: "Tenant shall design and construct all Tenant's improvements at the " +
    "premises necessary to prepare the premises for occupancy, and shall "+
    "outfit the premises for the standard operation of a wine and liquor retail "+
    "store. Tenant, at its sole cost and expense, shall be responsible for "+
    "obtaining all governmental permits, approvals, and sign-offs for "+
    "Tenant's Work and all permits and approvals required for the operation "+
    "of the premises."
  },
  {
    title: "UTILITIES",
    description: "Paid by Tenant. Directly metered."
  },
  {
    title: "SIGNAGE",
    description: "Subject to Landlord approval. Signage exhibit to be attached to lease."
  }
]
export {
  POST_CONTENT_OPTION,
  ADVERTISE_OPTION,
  CUSTOM_SIGNAGE_OPTION,
  MARKETING_PHONE_HELP_OPTION,
  WEB_BASE_URL_TO_SHARE,
  SORTINGTYPE,
  USER_REPORTING_REASON,
  PROPERTY_REPORTING_REASON,
  ERROR,
  GOOGLE_MAP_KEY,
  PATH,
  APP_SETTINGS,
  ROLE,
  ROLEID,
  LOOKUP_TYPE_KEYS,
  AZURE_STORAGE,
  FB_APP_ID,
  GOOGLE_CLIENT_ID,
  LINKEDIN_CLIENT_ID,
  LINKED_IN_REDIRECT_URL,
  PICTURES_LOOKUP,
  SOCIAL_ACCOUNTS_LOGIN,
  LINKED_IN_SECRET_ID,
  LINKED_IN_EMBEDED_URL,
  COMMUNICATION_PREFERENCE_LOOKUP,
  ASKING_TYPE_LOOKUP,
  TESTIMONIAL_VIDEOS_LINKS,
  PROPERTY_REPORT_LOOKUP,
  UTILITY_LOOKUP,
  AZURE_BLOB_BASE_URL,
  WEB_BASE_URL,
  POLICY_TERMS,
  WEB_SOCKET_URL,
  QUESTIONS,
  SPACES,
  STATE_VALUES,
  STATE_CODE,
  REPORT_STATUS,
  APPLE_CLIENT_ID,
  APPLE_REDIRECT_URL,
  COUPON_PLANS,
  COUPON_TYPE,
  PAGINATION_PAGE_SIZE,
  APP_SETTINGS_New,
  PropertyList,
  TERM_SHEET_CONTENT
};
