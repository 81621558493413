import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FieldError } from "../../assets";
const LeasingChallenges = (props) => {
  const [countOfProgess, setCountOfProgess] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountOfProgess((oldProgress) => {
        if (100 == oldProgress) return 0;
        return Math.min(oldProgress + Math.random() * 10, 100);
      });
    }, 499);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="steps__block">
      {/* <div>
          <h4>React-Bootstrap ProgressBar Component</h4>
          Current Progress is: {parseInt(countOfProgess)} %
          <ProgressBar now={countOfProgess} />
        </div> */}
      <div className="main-heading-blue text-center">
        <p>Choose the challenges you are having leasing your space</p>
      </div>
      <div>
        <p className="text-center font-weight-bold">
          Select areas you need us to help with
        </p>
      </div>
      <div className="landlord-quiz-checkbox">
        {props.options.optionsData
          ? props.options.optionsData.map((option, index) => {
              return (
                <div>
                  <input
                    type="checkbox"
                    id={option.optionId}
                    value={option.optionId}
                    onChange={props.handleOnChange}
                    checked={option.checked}
                  />
                  <label for={option.optionId}>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    {option.optionText}
                  </label>
                </div>
              );
            })
          : null}
        {props.isleasingChallengeValidate && (
          <FieldError message={"At least 1 selected field required"} />
        )}
        {/* <div><input type="checkbox" id="brokerFees"/><label for="brokerFees"><i class="fa fa-plus" aria-hidden="true"></i> Broker Fees are too high</label></div>
          <div><input type="checkbox" id="marketSpace"/><label for="marketSpace"> <i class="fa fa-plus" aria-hidden="true"></i> I don't know how to Market my space</label></div>
          <div><input type="checkbox" id="findTenants"/><label for="findTenants"> <i class="fa fa-plus" aria-hidden="true"></i> I don't have the time to find tenants myself</label></div>
          <div><input type="checkbox" id="qualifiedTenants"/><label for="qualifiedTenants"> <i class="fa fa-plus" aria-hidden="true"></i>I don't have connections to qualified tenants</label></div>
          <div><input type="checkbox" id="negotiatingDeal"/><label for="negotiatingDeal"> <i class="fa fa-plus" aria-hidden="true"></i> I need help negotiating a good deal for my space</label></div> */}
      </div>
    </div>
  );
};

export default LeasingChallenges;
