import { COUPON_REQUEST, failure, request, success } from "./utilities";
import { deleteCoupon, getAllCoupon, addOrUpdate, getAllCouponPlan, editCoupon,getCouponUsers } from "../api";

export function getCouponPlan() {
    return (dispatch) => {
        dispatch(request(COUPON_REQUEST.GET_ALL_COUPON_PLAN_REQUEST))
        getAllCouponPlan().then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(COUPON_REQUEST.GET_ALL_COUPON_PLAN_SUCCESS, response.data.data))
                }
                else {
                    dispatch(failure(COUPON_REQUEST.GET_ALL_COUPON_PLAN_FAILURE, response.data.message))
                }
            }, error => {
                dispatch(failure(COUPON_REQUEST.GET_ALL_COUPON_PLAN_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}

export function getAllCouponList() {
    return (dispatch) => {
        dispatch(request(COUPON_REQUEST.GET_ALL_COUPON_REQUEST))
        getAllCoupon().then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(COUPON_REQUEST.GET_ALL_COUPON_SUCCESS, response.data.data))
                }
                else {
                    dispatch(failure(COUPON_REQUEST.GET_ALL_COUPON_FAILURE, response.data.message))
                }
            }, error => {
                dispatch(failure(COUPON_REQUEST.GET_ALL_COUPON_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}

export function addOrUpdateCoupon(data, auth, moveToNext) {
    return (dispatch) => {
        dispatch(request(COUPON_REQUEST.ADD_OR_UPDATE_COUPON_REQUEST))
        addOrUpdate(data, auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(COUPON_REQUEST.ADD_OR_UPDATE_COUPON_SUCCESS, response.data.data))
                    dispatch(getAllCouponList())
                    if (moveToNext) {
                        moveToNext()
                    }
                } else {
                    dispatch(failure(COUPON_REQUEST.ADD_OR_UPDATE_COUPON_FAILURE, response.data.message))
                }
            }
            , error => {
                dispatch(failure(COUPON_REQUEST.ADD_OR_UPDATE_COUPON_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}

export function deleteCouponFromList(couponId, auth, moveToNext) {
    return (dispatch) => {
        dispatch(request(COUPON_REQUEST.DELETE_COUPON_REQUEST))
        deleteCoupon(couponId, auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(COUPON_REQUEST.DELETE_COUPON_SUCCESS, couponId))
                    if (moveToNext) {
                        moveToNext()
                    }
                }
                else {
                    dispatch(failure(COUPON_REQUEST.DELETE_COUPON_FAILURE, response.data.message))
                }
            }, error => {
                dispatch(failure(COUPON_REQUEST.DELETE_COUPON_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}

export function editCouponFromList(couponData, auth, moveToNext) {
    return (dispatch) => {
        dispatch(request(COUPON_REQUEST.EDIT_COUPON_REQUEST))
        editCoupon(couponData, auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(COUPON_REQUEST.EDIT_COUPON_SUCCESS, response.data.data))
                    if (moveToNext) {
                        moveToNext()
                    }
                }
                else {
                    dispatch(failure(COUPON_REQUEST.EDIT_COUPON_FAILURE, response.data.message))
                }
            }, error => {
                dispatch(failure(COUPON_REQUEST.EDIT_COUPON_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}
export function getCouponUsersList(couponId) {
    return (dispatch) => {
        dispatch(request(COUPON_REQUEST.GET_COUPON_USER_REQUEST))
        getCouponUsers(couponId).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(COUPON_REQUEST.GET_COUPON_USER_SUCCESS, response.data.data))   
                }
                else {
                    dispatch(failure(COUPON_REQUEST.GET_COUPON_USER_FAILURE, response.data.message))
                }
            }, error => {
                dispatch(failure(COUPON_REQUEST.GET_COUPON_USER_FAILURE, (error && error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message)))
            }
        )
    }
}