import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FieldError } from "../../assets";
const SpaceMarketed = (props) => {
  return (
    <div className="steps__block">
      <div className="main-heading-blue text-center">
        <p>Tell us about your space</p>
      </div>
      <div className="landlord-quiz-checkbox technical__skills text-center">
        {props.options.optionsData
          ? props.options.optionsData.map((option, index) => {
              return (
                <>
                  <div>
                    <input
                      type="checkbox"
                      id={option.optionId}
                      value={option.optionId}
                      checked={option.checked}
                      onChange={props.handleOnChange}
                    />
                    <label for={option.optionId}>
                      <i aria-hidden="true"></i>
                      {option.optionText}
                    </label>
                  </div>
                  <div className={`retailer__childs ${props.retailClass}`}>
                    {option.subCategoryData
                      ? option.subCategoryData.map((subOption, index) => {
                          return (
                            <div>
                              <input
                                type="checkbox"
                                id={subOption.optionId}
                                value={subOption.optionId}
                                checked={subOption.checked}
                                onChange={props.handleOnChangeSubOption}
                              />
                              <label for={subOption.optionId}>
                                <i aria-hidden="true"></i>
                                {subOption.optionText}
                              </label>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </>
              );
              {
                /* <div>
          <input type="checkbox" id="retail" />
          <label for="retail">
            <i aria-hidden="true"></i>Retail
          </label>
        </div> */
              }
            })
          : null}

        {props.isSpaceMarketed && (
          <FieldError message={"At least 1 selected field required"} />
        )}
        {/* <div>
          <input type="checkbox" id="office" />
          <label for="office">
            <i aria-hidden="true"></i>Office
          </label>
        </div>
        <div>
          <input type="checkbox" id="industrial" />
          <label for="industrial">
            <i aria-hidden="true"></i>Industrial / Warehouse
          </label>
        </div> */}
      </div>
    </div>
  );
};

export default SpaceMarketed;
