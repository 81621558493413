import React from "react";
import { AiOutlineDashboard, AiOutlinePropertySafety } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { GoReport, GoDash } from "react-icons/go";
import { PATH } from "../../config";
import { IoTicketOutline } from "react-icons/io5"
import { FaUserAltSlash, FaUserTag } from "react-icons/fa"
export const SidebarData = [
    {
        title: "Dashboard",
        path: PATH.DASHBOARD,
        icon: <AiOutlineDashboard />
    },
    {
        title: "Reports",
        path: "#",
        icon: <GoReport />,
        subNav: [
            {
                title: "Space",
                path: PATH.REPORTSDASHBOARD,
                icon: <GoDash />
            },
            {
                title: "Subscriptions",
                path: PATH.SUBSCRIPTIONREPORT,
                icon: <GoDash />
            },
            {
                title: "New Landlords",
                path: PATH.LANDLORDREPORTS,
                icon: <GoDash />
            },
            {
                title: "New Tenants",
                path: PATH.TENANTSREPORTS,
                icon: <GoDash />
            },
            {
                title: "Weekly Status Report",
                path: PATH.WEEKLYSTATUSREPORTS,
                icon: <GoDash />
            },
            {
                title: "Total Count Report",
                path: PATH.TOTALCOUNTSREPORT,
                icon: <GoDash />
            },
            {
                title: "Top 20 Landlords Report",
                path: PATH.TOP20LANDLORDSREPORT,
                icon: <GoDash />
            },
            {
                title: "Top 20 Tenants Report",
                path: PATH.TOP20TENANTSREPORT,
                icon: <GoDash />
            },
            {
                title: "Total Listing Per State",
                path: PATH.TOTALLISTINGPERSTATEREPORT,
                icon: <GoDash />
            },
            {
                title: "Total Listing Per Category",
                path: PATH.TOTALLISTINGPERCATEGORYREPORT,
                icon: <GoDash />
            },
        ]
    },
    {
        title: "Social Media Marketing",
        path: PATH.SOCIALMEDIAMARKETING,
        icon: <BsFillPersonFill />
    },
    {
        title: "Reported Users",
        path: PATH.REPORTEDUSER,
        icon: <FaUserAltSlash />
    },
    {
        title: "Reported Space",
        path: PATH.REPORTEDSPACE,
        icon: <AiOutlinePropertySafety />
    },
    // {
    //     title: "Coupon",
    //     path: PATH.COUPON,
    //     icon: <IoTicketOutline />
    // },
    {
        title: "Coupon (With Addons)",
        path: PATH.COUPON_WITH_ADDONS,
        icon: <IoTicketOutline />
    },
    {
        title: "Coupon User",
        path: PATH.COUPONUSER,
        icon: <FaUserTag />
    },
    {
        title: "Customer Support",
        path: "#",
        icon: <FaUserTag />,
        subNav: [
            {
                title: "Tenants",
                path: PATH.TENANTDASHBOARD,
                icon: <GoDash />
            },
            {
                title: "Landlord",
                path: PATH.LANDLORDDASHBOARD,
                icon: <GoDash />
            },
            {
                title: "Admin Logs",
                path: PATH.ADMINLOGSDASHBOARD,
                icon: <GoDash />
            },
            {
                title: "Marketing Preference",
                path: PATH.MARKETINGPREFERENCE,
                icon: <GoDash />
            },
        ]
    },
    {
        title: "AI Description",
        path: PATH.PROPERTY_DESCRIPTION_REQUEST,
        icon: <FaUserTag />
    },
]
