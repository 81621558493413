import { TENANT_FLOW } from "../actions/utilities";

const INITIAL_STATE = {
  emailId: "",
  location: { countryId: 231, stateId: "", cityId: "" },
  businessProfile: {
    industryId: "",
    name: "",
    businessPeriodTypeId: "",
    businessPeriod: null,
    websiteURL: "",
    numberOfLocations: null,
  },
  requirement: {
    minSqFtRange: 1,
    maxSqFtRange: null,
    minSqFtBudget: 1,
    maxSqFtBudget: null,
    utilities: "",
  },
  sentEmailResponse: {},
  userData: {},
  signUpLoading: false,
};
export const tenantSignUpFlowReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TENANT_FLOW.SET_EMAIL_ID:
      return {
        ...state,
        emailId: action.payload,
      };
    case TENANT_FLOW.SET_LOCATION_DATA:
      return {
        ...state,
        location: action.payload,
      };
    case TENANT_FLOW.SET_BUSINESS_PROFILE_DATA:
      return {
        ...state,
        businessProfile: action.payload,
      };
    case TENANT_FLOW.SET_REQUIREMENT_DATA:
      return {
        ...state,
        requirement: action.payload,
      };
    case TENANT_FLOW.SEND_EMAIL_RESPONSE:
      return {
        ...state,
        sentEmailResponse: action.payload,
      };
    case TENANT_FLOW.TENANT_SIGNUP_REQUEST:
      return {
        ...state,
        signUpLoading: true,
      };
    case TENANT_FLOW.TENANT_SIGNUP_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        signUpLoading: false,
      };
    case TENANT_FLOW.TENANT_SIGNUP_FAILURE:
      return {
        ...state,
        signUpLoading: false,
      };
    default:
      return state;
  }
};
