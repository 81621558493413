import { NotificationManager } from "react-notifications";
import {
  sendEmailToTenantUser,
  singUpTenantUser
} from "../api";
import {
  request,
  success,
  failure,
  REPORTS_REQUEST,
  TENANT_FLOW,
} from "./utilities";

export function setEmailId(data) {
  console.log(data);
  return (dispatch) => {
    dispatch(success(TENANT_FLOW.SET_EMAIL_ID, data));
  };
}

export function setLocationData(data) {
  return (dispatch) => {
    console.log(data);
    dispatch(success(TENANT_FLOW.SET_LOCATION_DATA, data));
  };
}
export function setBusinessProfileData(data) {
  return (dispatch) => {
    dispatch(success(TENANT_FLOW.SET_BUSINESS_PROFILE_DATA, data));
  };
}
export function setRequirementData(data) {
  return (dispatch) => {
    dispatch(success(TENANT_FLOW.SET_REQUIREMENT_DATA, data));
  };
}

export function sendEmailToTenant(requestData) {
  return (dispatch) => {
    sendEmailToTenantUser(requestData).then(
      (response) => {
        dispatch(success(TENANT_FLOW.SEND_EMAIL_RESPONSE, response.data));
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function registerTenantUser(requestData, setCookiesforUser) {
  return (dispatch) => {
    dispatch(request(TENANT_FLOW.TENANT_SIGNUP_REQUEST));
    singUpTenantUser(requestData).then(
      (response) => {
        if (response.data.data) {
          dispatch(
            success(TENANT_FLOW.TENANT_SIGNUP_SUCCESS, response.data.data)
          );
          setCookiesforUser(response.data.data);
          NotificationManager.success(response.data.message);
        }else{
          NotificationManager.error(response.data.message);
          dispatch(
            failure(TENANT_FLOW.TENANT_SIGNUP_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
}
