import { LANDLORD_FLOW } from "../actions/utilities";

const INITIAL_STATE = {
  spaceOptions: [],
  plans: [],
  addOns: [],
  sentEmailResponse: {},
  orderSummaryDetails: {},
  pageNo: 0,
  addonsCouponDetails: {},
  couponValidation: null,
  userData: {},
  signUpLoading: false,
  isSubscribe: false,
};
export const landLordSignUpFlowReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LANDLORD_FLOW.GET_OPTIONS_LIST:
      return {
        ...state,
        spaceOptions: action.payload,
      };
    case LANDLORD_FLOW.SEND_EMAIL_RESPONSE:
      return {
        ...state,
        sentEmailResponse: action.payload,
      };
    case LANDLORD_FLOW.GET_PLANS_LIST:
      return {
        ...state,
        plans: action.payload,
      };
    case LANDLORD_FLOW.GET_SUBSCRIBE_UNSUBSCRIBE:
      return {
        ...state,
        isSubscribe: action.payload,
      };
    case LANDLORD_FLOW.GET_ADDONS_LIST:
      return {
        ...state,
        addOns: action.payload,
      };
    case LANDLORD_FLOW.LANDLORD_SIGNUP_REQUEST:
      return {
        ...state,
        signUpLoading: true,
      };
    case LANDLORD_FLOW.LANDLORD_SIGNUP_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        signUpLoading: false,
      };
    case LANDLORD_FLOW.LANDLORD_SIGNUP_FAILURE:
      return {
        ...state,
        signUpLoading: false,
      };
    case LANDLORD_FLOW.GET_ORDER_SUMMARY_DETAILS:
      return {
        ...state,
        orderSummaryDetails: action.payload,
      };
    case LANDLORD_FLOW.SET_PAGE_NO:
      return {
        ...state,
        pageNo: action.payload,
      };
    case LANDLORD_FLOW.GET_ADDONS_COUPON_DETAILS:
      return {
        ...state,
        addonsCouponDetails: action.payload,
        couponValidation: null,
      };
    case LANDLORD_FLOW.SET_COUPON_VALIDATION:
      return {
        ...state,
        couponValidation: action.payload,
      };
    default:
      return state;
  }
};
