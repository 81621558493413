import { COUPON_REQUEST, TERM_SHEET } from "../actions/utilities";

const INITIAL_STATE = {
  termSheetList: [],
  termSheetListLoading: false,
  termSheetListFailure: false,
  termSheetListSuccess: false,
  termSheetListError: null,

  deleteTermSheetLoading: false,
  deleteTermSheetFailure: false,
  deleteTermSheetSuccess: false,
  deleteTermSheetError: null,

  addTermSheetLoading: false,
  addTermSheetFailure: false,
  addTermSheetSuccess: false,
  addTermSheetError: null,

  updateTermSheetLoading: false,
  updateTermSheetSuccess: false,
  updateTermSheetFailure: false,
  updateTermSheetError: null,

  likeDislikeTermSheetLoading: false,
  likeDislikeTermSheetSuccess: false,
  likeDislikeTermSheetFailure: false,
  likeDislikeTermSheetError: null,

  questionsTermSheetLoading: false,
  questionsTermSheetFailure: false,
  questionsTermSheetSuccess: false,
  questionsTermSheetError: null,

  sendChatLoading: false,
  sendChatSuccess: false,
  sendChatFailure: false,
  sendChatError: null,

  // for base term sheet

  getBaseTermSheetLoading: true,
  getBaseTermSheetFailure: false,
  getBaseTermSheetSuccess: false,
  getBaseTermSheetError: null,

  updateBaseTermSheetLoading: true,
  updateBaseTermSheetFailure: false,
  updateBaseTermSheetSuccess: false,
  updateBaseTermSheetError: null,

  deleteBaseTermSheetLoading: false,
  deleteBaseTermSheetFailure: false,
  deleteBaseTermSheetSuccess: false,
  deleteBaseTermSheetError: null,

  BaseTermSheet: [],
};

export const termSheetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // for Base Term Sheet
    case TERM_SHEET.GET_BASE_TERM_SHEET_REQUEST:
      return {
        ...state,
        getBaseTermSheetLoading: true,
        getBaseTermSheetFailure: false,
        getBaseTermSheetSuccess: false,
        getBaseTermSheetError: null,
        BaseTermSheet: [],
      };
    case TERM_SHEET.GET_BASE_TERM_SHEET_SUCCESS:
      return {
        ...state,
        getBaseTermSheetLoading: false,
        getBaseTermSheetFailure: false,
        getBaseTermSheetSuccess: true,
        getBaseTermSheetError: null,
        BaseTermSheet: action.payload,
      };
    case TERM_SHEET.GET_BASE_TERM_SHEET_FAILURE:
      return {
        ...state,
        getBaseTermSheetLoading: false,
        getBaseTermSheetFailure: true,
        getBaseTermSheetSuccess: false,
        getBaseTermSheetError: action.payload,
      };

    case TERM_SHEET.UPDATE_BASE_TERM_SHEET_REQUEST:
      return {
        ...state,

        updateBaseTermSheetLoading: true,
        updateBaseTermSheetFailure: false,
        updateBaseTermSheetSuccess: false,
        updateBaseTermSheetError: null,
      };
    case TERM_SHEET.UPDATE_BASE_TERM_SHEET_SUCCESS:
      return {
        ...state,
        updateBaseTermSheetLoading: false,
        updateBaseTermSheetSuccess: true,
        updateBaseTermSheetFailure: false,
        updateBaseTermSheetError: null,
      };
    case TERM_SHEET.UPDATE_BASE_TERM_SHEET_FAILURE:
      return {
        ...state,
        updateBaseTermSheetLoading: false,
        updateBaseTermSheetSuccess: false,
        updateBaseTermSheetFailure: true,
        updateBaseTermSheetError: action.payload,
      };

    case TERM_SHEET.DELETE_BASE_TERM_SHEET_REQUEST:
      return {
        ...state,
        deleteBaseTermSheetLoading: true,
        deleteBaseTermSheetFailure: false,
        deleteBaseTermSheetSuccess: false,
        deleteBaseTermSheetError: null,
      };
    case TERM_SHEET.DELETE_BASE_TERM_SHEET_SUCCESS:
      return {
        ...state,
        deleteBaseTermSheetLoading: false,
        deleteBaseTermSheetFailure: false,
        deleteBaseTermSheetSuccess: true,
        deleteBaseTermSheetError: null,
      };
    case TERM_SHEET.DELETE_BASE_TERM_SHEET_FAILURE:
      return {
        ...state,
        deleteBaseTermSheetLoading: false,
        deleteBaseTermSheetFailure: true,
        deleteBaseTermSheetSuccess: false,
        deleteBaseTermSheetError: action.payload,
      };

    // End

    case TERM_SHEET.GET_TERM_SHEET_REQUEST:
      return {
        ...state,
        termSheetList: [],
        termSheetListLoading: true,
        termSheetListFailure: false,
        termSheetListSuccess: false,
        termSheetListError: null,
      };
    case TERM_SHEET.GET_TERM_SHEET_SUCCESS:
      return {
        ...state,
        termSheetList: action.payload,
        termSheetListLoading: false,
        termSheetListFailure: false,
        termSheetListSuccess: true,
        termSheetListError: null,
      };
    case TERM_SHEET.GET_TERM_SHEET_FAILURE:
      return {
        ...state,
        termSheetList: [],
        termSheetListLoading: false,
        termSheetListFailure: true,
        termSheetListSuccess: false,
        termSheetListError: action.payload,
      };
    case TERM_SHEET.DELETE_TERM_SHEET_REQUEST:
      return {
        ...state,
        deleteTermSheetLoading: true,
        deleteTermSheetFailure: false,
        deleteTermSheetSuccess: false,
        deleteTermSheetError: null,
      };
    case TERM_SHEET.DELETE_TERM_SHEET_SUCCESS:
      // let myCouponList = state.couponList;
      // for (let i = 0; i < myCouponList.length; i++) {
      //     if (myCouponList[i].id === action.payload) {
      //         myCouponList.splice(i, 1)
      //     }
      // }
      return {
        ...state,
        deleteTermSheetLoading: false,
        deleteTermSheetFailure: false,
        deleteTermSheetSuccess: true,
        deleteTermSheetError: null,
      };
    case TERM_SHEET.DELETE_TERM_SHEET_FAILURE:
      return {
        ...state,
        deleteTermSheetLoading: false,
        deleteTermSheetFailure: true,
        deleteTermSheetSuccess: false,
        deleteTermSheetError: action.payload,
      };
    case TERM_SHEET.ADD_TERM_SHEET_REQUEST:
      return {
        ...state,
        addTermSheetLoading: true,
        addTermSheetFailure: false,
        addTermSheetSuccess: false,
        addTermSheetError: null,
      };
    case TERM_SHEET.ADD_TERM_SHEET_SUCCESS:
      return {
        ...state,
        addTermSheetLoading: false,
        addTermSheetFailure: false,
        addTermSheetSuccess: true,
        addTermSheetError: null,
      };
    case TERM_SHEET.ADD_TERM_SHEET_FAILURE:
      return {
        ...state,
        addTermSheetLoading: false,
        addTermSheetFailure: true,
        addTermSheetSuccess: false,
        addTermSheetError: action.payload,
      };
    case TERM_SHEET.UPDATE_TERM_SHEET_REQUEST:
      return {
        ...state,
        updateTermSheetLoading: true,
        updateTermSheetSuccess: false,
        updateTermSheetFailure: false,
        updateTermSheetError: null,
      };
    case TERM_SHEET.UPDATE_TERM_SHEET_SUCCESS:
      return {
        ...state,
        updateTermSheetLoading: false,
        updateTermSheetSuccess: true,
        updateTermSheetFailure: false,
        updateTermSheetError: null,
      };
    case TERM_SHEET.UPDATE_TERM_SHEET_FAILURE:
      return {
        ...state,
        updateTermSheetLoading: false,
        updateTermSheetSuccess: false,
        updateTermSheetFailure: true,
        updateTermSheetError: action.payload,
      };
    case TERM_SHEET.LIKE_DISLIKE_TERM_SHEET_REQUEST:
      return {
        ...state,
        likeDislikeTermSheetLoading: true,
        likeDislikeTermSheetSuccess: false,
        likeDislikeTermSheetFailure: false,
        likeDislikeTermSheetError: null,
      };
    case TERM_SHEET.LIKE_DISLIKE_TERM_SHEET_SUCCESS:
      return {
        ...state,
        likeDislikeTermSheetLoading: false,
        likeDislikeTermSheetSuccess: true,
        likeDislikeTermSheetFailure: false,
        likeDislikeTermSheetError: null,
      };
    case TERM_SHEET.LIKE_DISLIKE_TERM_SHEET_FAILURE:
      return {
        ...state,
        likeDislikeTermSheetLoading: false,
        likeDislikeTermSheetSuccess: false,
        likeDislikeTermSheetFailure: true,
        likeDislikeTermSheetError: action.payload,
      };
    case TERM_SHEET.SPACE_NEGOTIATION_QUESTIONS_REQUEST:
      return {
        ...state,
        questionsTermSheetLoading: true,
        questionsTermSheetFailure: false,
        questionsTermSheetSuccess: false,
        questionsTermSheetError: null,
      };
    case TERM_SHEET.SPACE_NEGOTIATION_QUESTIONS_SUCCESS:
      return {
        ...state,
        questionsTermSheetLoading: false,
        questionsTermSheetFailure: false,
        questionsTermSheetSuccess: true,
        questionsTermSheetError: null,
      };
    case TERM_SHEET.SPACE_NEGOTIATION_QUESTIONS_FAILURE:
      return {
        ...state,
        questionsTermSheetLoading: false,
        questionsTermSheetFailure: true,
        questionsTermSheetSuccess: false,
        questionsTermSheetError: action.payload,
      };
    case TERM_SHEET.SEND_CHAT_REQUEST:
      return {
        ...state,
        sendChatLoading: true,
        sendChatSuccess: false,
        sendChatFailure: false,
        sendChatError: null,
      };
    case TERM_SHEET.SEND_CHAT_SUCCESS:
      return {
        ...state,
        sendChatLoading: false,
        sendChatSuccess: true,
        sendChatFailure: false,
        sendChatError: null,
        // joinChat: action.payload,
      };
    case TERM_SHEET.SEND_CHAT_FAILURE:
      return {
        ...state,
        sendChatLoading: false,
        sendChatSuccess: false,
        sendChatFailure: true,
        sendChatError: action.payload,
      };
    case TERM_SHEET.SEND_OR_RECIEVE_MESSAGE:
      let newMessage = state.allChat;
      let already = false;
      for (let i = 0; i < newMessage.length; i++) {
        if (newMessage[i].messageId === action.payload.messageId) {
          already = true;
        }
      }
      if (already === false) {
        newMessage.push(action.payload);
      }
      return {
        ...state,
        allChat: newMessage,
      };
    case TERM_SHEET.ALL_CHAT_REQUEST:
      return {
        ...state,
        allChatLoading: true,
        allChatSuccess: false,
        allChatFailure: false,
        allChatError: null,
        allChat: [],
      };
    case TERM_SHEET.ALL_CHAT_SUCCESS:
      return {
        ...state,
        allChatLoading: false,
        allChatSuccess: true,
        allChatFailure: false,
        allChatError: null,
        allChat: action.payload,
      };
    case TERM_SHEET.ALL_CHAT_FAILURE:
      return {
        ...state,
        allChatLoading: false,
        allChatSuccess: false,
        allChatFailure: true,
        allChatError: action.payload,
      };

    default:
      return state;
  }
};
