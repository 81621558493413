import React, { useState, useEffect } from "react";
// import pro__banner from "../../assets/images/pro__banner.svg";

import onetimefee from "../../assets/images/onetimefee.svg";
import { useSelector } from "react-redux";
import { getCouponDiscountedAmount } from "../../assets";

const ProfessionalPhotos = (props) => {
  let couponDetails = useSelector(
    (state) => state.landLordSignUpFlow.addonsCouponDetails
  );

  useEffect(() => {
    if (
      Object.keys(couponDetails).length > 0 &&
      couponDetails.metadata &&
      couponDetails.metadata.addonId &&
      couponDetails.metadata.addonId
        .split(",")
        .includes(props.addOnsData.id.toString())
    ) {
      props.setOrdersAdded({
        ...props.ordersAdded,
        professionalPhotos: true,
      });
    }
  }, [couponDetails]);

  return (
    <div className="row dtl__content">
      <div className="col-md-12">
        <div className="multistep__content">
          <h4>
            <span>Wait!</span> Your Order is not Complete
          </h4>
        </div>
      </div>

      <div className="col-md-6">
        <div className="left__section">
          <div className="content__block">
            <h3>
              Add <span class="navbar-header-title">Professional Photos</span>{" "}
              of your space to attract Tenants
            </h3>
            <ul>
              <li>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <span>Photographer will come to your site</span>
              </li>
              <li>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <span>
                  10 professional photos including inside and outside shots
                </span>
              </li>
              <li>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <span>
                  Upload photos to{" "}
                  <span className="small__text">REDHANDED</span> or use with any
                  of your promotional needs
                </span>
              </li>
              <li>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <span>
                  Our records show spaces with high quality photos attract more
                  views and connections to your space
                </span>
              </li>
            </ul>
            <div className="row m-0">
              <div className="col-lg-6">
                <div className="price__block">
                  <span>Normal Price ${props.addOnsData.price}</span>
                  <p>
                    Price{" "}
                    <span>
                      {/* $
                      {props.addOnsData.id == couponDetails.addonId
                        ? couponDetails.addonDiscountPrice
                        : props.addOnsData.price} */}
                      $
                      {Object.keys(couponDetails).length > 0 &&
                      couponDetails.metadata &&
                      couponDetails.metadata.addonId &&
                      couponDetails.metadata.addonId
                        .split(",")
                        .includes(props.addOnsData.id.toString())
                        ? `${
                            getCouponDiscountedAmount(
                              couponDetails,
                              props.addOnsData && props.addOnsData.price
                                ? props.addOnsData.price
                                : 0
                            ).total
                          }`
                        : props.addOnsData.price}
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="onetimefee__img">
                  <img src={onetimefee} className="img-fluid" />
                </div>
              </div>
            </div>

            <div className="bottom__button">
              {!props.ordersAdded.professionalPhotos ? (
                <button
                  class="btn btn-primary pop-up-property-detaile-btn cursor btn-red-color"
                  onClick={() => {
                    props.handleAddMyOrder();
                    props.setOrdersAdded({
                      ...props.ordersAdded,
                      professionalPhotos: true,
                    });
                  }}
                >
                  ADD TO MY ORDER
                </button>
              ) : (
                <button
                  class="btn btn-primary pop-up-property-detaile-btn cursor btn-red-color"
                  onClick={() => {
                    props.handleRemoveMyOrder();
                    props.setOrdersAdded({
                      ...props.ordersAdded,
                      professionalPhotos: false,
                    });
                  }}
                >
                  REMOVE FROM MY ORDER
                </button>
              )}
              <a href="#" className="linkText" onClick={props.handleNext}>
                No Thank you, I am not interested in this offer.
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="right__section professional__photo"></div>
      </div>
    </div>
    // <div>
    //   <h1>Add Professional Photos of your space to attract Tenants</h1>
    // </div>
  );
};

export default ProfessionalPhotos;
