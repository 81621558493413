import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function getPlans(roleId) {
    return axios.get(`${APP_SETTINGS.API_PATH.PLAN.getPlans}${roleId}`)
}
export function changeSubscription(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.SUBSCRIPTION.changeSubscription}`, data, {
        headers: {
            timeout: 20000,
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function cancelSubscription(auth) {
    return axios.delete(`${APP_SETTINGS.API_PATH.SUBSCRIPTION.cancelSubscription}`, {
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function suspendUser(userId,auth) {
    return axios.put(`${APP_SETTINGS.API_PATH.SUBSCRIPTION.suspendUser}/${userId}`, {userId:userId},{
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}
export function getSubscription(auth){
    return axios.get(APP_SETTINGS.API_PATH.SUBSCRIPTION.getSubscription,{
        headers: {
            Authorization: 'Bearer ' + auth.real_estate_user.token
        }
    })
}