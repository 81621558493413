import { INDUSTRY_REQUEST,POST_PROPERTY } from "../actions/utilities";

const INITIAL_STATE = {
    getIndustryLoading: false,
    getIndustrySuccess: false,
    getIndustryFailure: false,
    getIndustryError: null,
    industries: [],
    addIndustryLoading: false,
    addIndustrySuccess: false,
    addIndustryFailure: false,
    addIndustryError: null,
    selectedIndustry: [],
    getAddressLoading: false,
    getAddressSuccess: false,
    getAddressFailure: false,
    getAddressError: null,
    addressDetail: [],
}

export const industryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case POST_PROPERTY.RESET_ADDRESS:
            return{
                ...state,
                selectedIndustry:[]
            }
        case POST_PROPERTY.GET_ADDRESS_LOADING:
            return {
                ...state,
                getAddressLoading: true,
                getAddressSuccess: false,
                getAddressFailure: false,
                getAddressError: null,
                addressDetail: [],
            }
        case POST_PROPERTY.GET_ADDRESS_SUCCESS:
            let idArray = [];
            if(action&&action.payload&&action.payload.industries){
                for(let i =0; i<action.payload.industries.length;i++){
                    idArray.push(action.payload.industries[i].industryId)
                }
            }
            return {
                ...state,
                getAddressLoading: false,
                getAddressSuccess: true,
                getAddressFailure: false,
                getAddressError: null,
                addressDetail: action.payload,
                selectedIndustry:idArray
            }
        case POST_PROPERTY.GET_ADDRESS_FAILURE:
            return {
                ...state,
                getAddressLoading: false,
                getAddressSuccess: false,
                getAddressFailure: true,
                getAddressError: action.payload,
                addressDetail: [],
            }
        case INDUSTRY_REQUEST.GET_INDUSTRY_REQUEST:
            return {
                ...state,
                getIndustryLoading: true,
                getIndustrySuccess: false,
                getIndustryFailure: false,
                getIndustryError: null
            }
        case INDUSTRY_REQUEST.GET_INDUSTRY_SUCCESS:
            return {
                ...state,
                getIndustryLoading: false,
                getIndustryFailure: false,
                getIndustrySuccess: true,
                getIndustryError: null,
                industries: action.payload
            }
        case INDUSTRY_REQUEST.GET_INDUSTRY_FAILURE:
            return {
                ...state,
                getIndustryLoading: false,
                getIndustryFailure: true,
                getIndustrySuccess: false,
                getIndustryError: action.payload
            }
        case INDUSTRY_REQUEST.ADD_INDUSTRY_REQUEST:
            return {
                ...state,
                addIndustryLoading: true,
                addIndustrySuccess: false,
                addIndustryFailure: false,
                addIndustryError: null
            }
        case INDUSTRY_REQUEST.ADD_INDUSTRY_SUCCESS:
            let industry_list = state.industries;
            industry_list.push(action.payload);

            return {
                ...state,
                addIndustryLoading: false,
                addIndustryFailure: false,
                addIndustrySuccess: true,
                addIndustryError: null,
                industries: industry_list
            }
        case INDUSTRY_REQUEST.ADD_INDUSTRY_FAILURE:
            return {
                ...state,
                addIndustryLoading: false,
                addIndustryFailure: true,
                addIndustrySuccess: false,
                addIndustryError: action.payload
            }
        case INDUSTRY_REQUEST.ADD_OR_REMOVE_INDUSTRY:
            let selectedList = state.selectedIndustry
            if (selectedList.includes(action.payload)) {
                for (let i = 0; i < selectedList.length; i++) {
                    if (selectedList[i] === action.payload) {
                        selectedList.splice(i, 1)
                    }
                }
            }
            else {
                selectedList.push(action.payload)
            }
            return {
                ...state,
                selectedIndustry: selectedList
            }
        default:
            return state
    }
}